import React, { useState } from 'react';

import './styles.scss';

import { useAnalytics } from '../../../hooks/Firebase';
import ModalBaseContainer from '../../../components/ModalBaseContainer';

const genderValues = [
  'Male',
  'Female',
  'Non-binary',
  'Other',
  'Prefer not to say',
];

const GenderPickerModal = ({ show, onClose, setGenderHandler, prefilledGender }) => {
  const [selectedGender, setSelectedGender] = useState(prefilledGender);
  const { clickEvent, TYPES: { CLICK } } = useAnalytics();

  const radioChange = (e) => {
    const gender = { value: e.target.value };
    setSelectedGender(gender);
  };

  const inputChange = (e) => {
    const gender = {
      value: 'Other',
      specified: e.target.value,
    };
    setSelectedGender(gender);
  };

  const onCloseHandler = () => {
    setSelectedGender(prefilledGender);
    onClose();
  };

  return (
    <ModalBaseContainer show={show} onClose={onCloseHandler}>
      <h2>What is your gender?</h2>
      <ul className='content-margin-base'>
        {genderValues.map((gender, i) =>
          <li key={i} className='gender-option'>
            <div className='gender-radio-btn'>
              <input type='radio' name='genders'
                value={gender}
                onChange={radioChange}
                defaultChecked={prefilledGender && prefilledGender.value === gender || selectedGender && selectedGender.value === gender }
              />
              <span className='checked-icon gender-radio-icon'></span>
              <span className='gender-radio-label'>{gender}</span>
            </div>
            { (gender === 'Other' && selectedGender?.value === 'Other') ?
              <input type='text'
                className='other-input'
                placeholder={'Specify here if you want'}
                onChange={inputChange}
                defaultValue={selectedGender && selectedGender.specified || prefilledGender && prefilledGender.specified || ''}
              />
              : ''
            }
          </li>
        )}
      </ul>
      <div className='btn btn-primary'
        onClick={() => {
          setGenderHandler(selectedGender);
          clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.GENDER, CLICK.LOCATION.BASIC_INFO);
        }}>Set gender</div>
    </ModalBaseContainer >
  );
};

export default GenderPickerModal;
