import React from 'react';
import PropTypes from 'prop-types';

const WarningIcon = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 22.6633C9.891 22.6633 7.82935 22.0379 6.07578 20.8662C4.32221 19.6945 2.95547 18.0291 2.14839 16.0807C1.34131 14.1322 1.13015 11.9882 1.5416 9.91968C1.95304 7.8512 2.96863 5.95118 4.45992 4.45989C5.95122 2.9686 7.85124 1.95302 9.91972 1.54158C11.9882 1.13013 14.1322 1.3413 16.0807 2.14838C18.0292 2.95546 19.6945 4.32221 20.8662 6.07578C22.0379 7.82936 22.6633 9.891 22.6633 12C22.6601 14.8271 21.5357 17.5375 19.5366 19.5366C17.5375 21.5356 14.8271 22.6601 12 22.6633ZM12 24C14.3734 24 16.6934 23.2962 18.6668 21.9776C20.6402 20.6591 22.1783 18.7849 23.0865 16.5922C23.9948 14.3995 24.2324 11.9867 23.7694 9.65893C23.3064 7.33115 22.1635 5.19295 20.4853 3.51472C18.807 1.83649 16.6689 0.693605 14.3411 0.230582C12.0133 -0.232441 9.60051 0.00519943 7.4078 0.913451C5.21509 1.8217 3.34094 3.35978 2.02236 5.33317C0.703785 7.30656 0 9.62663 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76515 22.7357 8.8174 24 12 24Z"
        fill={color}/>
      <path d="M12.0004 6.01172C12.1777 6.01172 12.3477 6.08214 12.473 6.20748C12.5983 6.33282 12.6688 6.50283 12.6688 6.68008V13.4635C12.6681 13.6403 12.5974 13.8096 12.4721 13.9344C12.3468 14.0592 12.1772 14.1293 12.0004 14.1293C11.8236 14.1293 11.654 14.0592 11.5287 13.9344C11.4034 13.8096 11.3327 13.6403 11.332 13.4635V6.68006C11.332 6.50281 11.4025 6.33281 11.5278 6.20747C11.6531 6.08214 11.8231 6.01172 12.0004 6.01172V6.01172Z"
        fill={color}/>
      <path d="M12.0002 17.5055C12.5224 17.5055 12.9457 17.0822 12.9457 16.56C12.9457 16.0378 12.5224 15.6145 12.0002 15.6145C11.478 15.6145 11.0547 16.0378 11.0547 16.56C11.0547 17.0822 11.478 17.5055 12.0002 17.5055Z"
        fill={color}/>
    </svg>

  );
};

WarningIcon.propTypes = { color: PropTypes.string.isRequired };

export default WarningIcon;
