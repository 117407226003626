import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../../Button';
import InputWithIcon from '../../../InputWithIcon';

import ModalBaseContainer from '../../../ModalBaseContainer';
import UserIcon from '../../../../statics/Images/Input/UserIcon';
import { FirebaseContext } from '../../../Firebase';

const AddCommunityModal = () => {
  const firebase = useContext(FirebaseContext);
  const navigate = useNavigate();
  const onClose = () => navigate('..');

  const onSubmit = async (event) => {
    event.preventDefault();

    await firebase.addCommunity(event.target.elements.communityName.value);

    onClose();
  };

  return (
    <ModalBaseContainer show={true} onClose={onClose}>
      <h2>Add Community</h2>
      <form onSubmit={onSubmit}>
        <div className='content-margin-base'>
          <InputWithIcon
            id='communityName'
            name='communityName'
            type='text'
            placeholder='New Community'
            icon={<UserIcon />}
          />
        </div>
        <div className='btn-inline-wrapper'>
          <Button theme='btn-text' type='button' onClick={onClose}>Cancel</Button>
          <Button theme='btn-primary' type='submit'>Save</Button>
        </div>
      </form>
    </ModalBaseContainer>
  );
};

export default AddCommunityModal;
