import React from 'react';
import PropTypes from 'prop-types';

/**
 * Button wrapper component, to be used instead of <button/> tag
 *
 * @name Button
 *
 *
 * @param {func} onClick Expected behaviour
 * @param {bool} disabled
 * @param {string} type type of button
 * @param {string} children Text to be displayed on the button
 * @param {string} theme Theme to apply
 *
 */

const Button = ({ theme, onClick, children, type, disabled }) => {
  return (
    <button className={`btn ${theme}`} type={type} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.string,
  theme: PropTypes.string,
};

export default Button;
