import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { FirebaseContext } from '../../components/Firebase';
import { StorageContext } from '../../components/Storage';

/**
 * @summary Logout route
 * Logout User from the App
 * @name Logout
 */
const Logout = () => {
  const navigate = useNavigate();
  const firebase = useContext(FirebaseContext);
  const [, dispatch] = useContext(StorageContext);

  useEffect(async () => {
    dispatch({ type: 'CLEAR_PROFILE' });
    const error = await firebase.doSignOut();

    if (error !== undefined) {
      console.error(error);
    }

    navigate('/');
  },[]);

  return (
    <></>
  );
};

export default Logout;
