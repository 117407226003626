import React, { useContext, useEffect } from 'react';
import { urlRegex } from 'react-linkify-it';
import PropTypes from 'prop-types';

import { FirebaseContext } from '../../../../../components/Firebase';
import { StorageContext } from '../../../../../components/Storage';

import useToasts from '../../../../../hooks/useToasts';

import ModalBaseContainer from '../../../../../components/ModalBaseContainer';
import useOpenGraph from '../../../../../hooks/useOpenGraph';

import OpenGraphPreview from '../../../../../components/OpenGraphPreview';
import { debounce } from 'throttle-debounce';

import { useAnalytics } from '../../../../../hooks/Firebase';

const RESTRICTED_COMMUNITIES = ['RECOMMENDED'];

const ACCESS_RESTRICTED_COMMUNITIES_ROLES = [
  'admin',
  'productOwner',
  'talentManager',
];

/**
 * Modal to create new post
 * @name PostContentModal
 * @param {bool} show - Show modal
 * @param {function} onClose - Close modal
 * @param {Object} user - User object
 */
const PostContentModal = ({ show, user, onClose, communitiesSubscribed }) => {
  const firebase = useContext(FirebaseContext);
  const [state] = useContext(StorageContext);
  const { openGraph, fetchOpenGraphData, resetMetaData } = useOpenGraph();
  const { publishSuccess } = useToasts();
  const { clickEvent, TYPES: { CLICK } } = useAnalytics();

  const debouncedFetch = debounce(500, fetchOpenGraphData);

  const onTextAreaChange = async (e) => {
    const matches = urlRegex.exec(e.target.value);

    if (!Array.isArray(matches)) {
      resetMetaData();
      return;
    }

    debouncedFetch(matches[0]);
  };

  /**
   * It takes the user's post and adds it to the database
   * @param e - the event object
   */
  const onSubmit = async (e) => {
    e.preventDefault();

    clickEvent(CLICK.TYPE.ADD, CLICK.CATEGORY.COMMUNITY_QUESTION);

    const communitySelected = communitiesSubscribed.filter(community => community.communityID === e.target.communities.value);

    if (e.target.question.value.length) {

      await firebase.addPostToCommunity({

        communityID: communitySelected[0].communityID,
        communityName: communitySelected[0].communityName,
        ...user,
        text: e.target.question.value,
        likes: [],
        createdAt: firebase.convertDate(new Date()),
        numberOfComments: 0,
        parentID: null,
        openGraph: openGraph,
        flags: {
          BLOCKED: false,
          DELETED: false,
          REPORTED: false,
        },
      });

      await publishSuccess('Post successfully shared!');
      resetMetaData();
      onClose();
    }

  };

  useEffect(() => {
    // Ensure no old meta data present when modal is opened
    resetMetaData();
  }, []);

  return (
    <ModalBaseContainer show={show} onClose={() => { resetMetaData(); debouncedFetch.cancel(); onClose();}}>
      <form onSubmit={onSubmit}>
        <h2>Share post</h2>
        { communitiesSubscribed?.length < 2 && !(state.claims.some(role => ACCESS_RESTRICTED_COMMUNITIES_ROLES.includes(role))) ?
          <p className='content-margin-large'>Please first choose at least one community group. You can do so by clicking the + icon in the Community page and selecting one or more community groups.</p>
          :
          <div className='select content-margin-large'>
            <label htmlFor='communities'>Select community group</label>
            <select name='communities' id='communities'>
              { communitiesSubscribed?.map ((community, i) => (
                state.claims.some(role => ACCESS_RESTRICTED_COMMUNITIES_ROLES.includes(role)) ?
                  <option key={i} value={community.communityID}>{community.communityName === 'RECOMMENDED' ? 'For You' : community.communityName}</option>
                  :
                  !RESTRICTED_COMMUNITIES.includes(community.communityName)
                  &&
                  <option key={i} value={community.communityID}>{community.communityName}</option>
              ))
              }
            </select>
          </div>
        }
        <textarea
          id="question"
          name="question"
          rows="4"
          cols="50"
          placeholder='Type your question here...'
          onChange={onTextAreaChange}
          disabled={!(communitiesSubscribed?.length > 1) && !(state.claims.some(role => ACCESS_RESTRICTED_COMMUNITIES_ROLES.includes(role)))}
        />
        <button className='btn btn-primary' type='submit' disabled={!(communitiesSubscribed?.length > 1) && !(state.claims.some(role => ACCESS_RESTRICTED_COMMUNITIES_ROLES.includes(role))) }>Post question</button>
      </form>
      {
        openGraph && <OpenGraphPreview openGraph={openGraph} />
      }
    </ModalBaseContainer>
  );
};

PostContentModal.propTypes = {
  show: PropTypes.bool.isRequired,
  user: PropTypes.object,
  communityId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  communityName: PropTypes.string,
};

export default PostContentModal;
