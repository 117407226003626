import React, { useState } from 'react';
import './styles.scss';

import { useAnalytics } from '../../hooks/Firebase';

import ModalBaseContainer from '../ModalBaseContainer';
import SkillsModal from '../SkillsModal';

import NewFeatureToucan from '../../statics/Images/Illustrations/Toucans/NewFeatureToucan.svg';

/**
 * Modal to give feedback
 *
 * @name NewFeatureSkillsModal
 *
 * @param {bool} show Defines if modal is shown or not
 * @param {function} onClose Handler to close the modal
 * @param {object} feature Object {name: string, collection: string} with information about the feature feedback is for
 *
 */
const NewFeatureSkillsModal = ({ show, onClose, optOutModals, setOptOutModals }) => {
  const [showSkillsModal, setShowSkillsModal] = useState(false);
  const { clickEvent, TYPES: { CLICK } } = useAnalytics();

  const toggleShowSkillsModal = () => {
    setShowSkillsModal(!showSkillsModal);
  };

  const onOptOutHandler = () => {
    const optOutModalsCopy =[...optOutModals];

    if(!optOutModals.includes('skills')){
      optOutModalsCopy.push('skills');
      setOptOutModals(optOutModalsCopy);
      localStorage.setItem('featModals', JSON.stringify(optOutModalsCopy));
    } else {
      const index = optOutModalsCopy.indexOf('skills');
      optOutModalsCopy.splice(index, 1);
      setOptOutModals(optOutModalsCopy);
      localStorage.setItem('featModals', JSON.stringify(optOutModalsCopy));
    }

  };

  return (
    <ModalBaseContainer show={show} onClose={onClose}>
      <div className="centred-text">
        <img className='new-feature-toucan-img' src={NewFeatureToucan} alt='Data' />
        <h3 className='centred-text padding-layout-base'>Add your skills</h3>
        <p>Add your soft & hard skills and unlock your personal job matches!</p>
        <button className='btn btn-primary'
          onClick={() => {
            clickEvent(CLICK.TYPE.ADD, CLICK.CATEGORY.ADD_SKILL_FROM_MODAL, CLICK.LOCATION.RELEASE_NOTE);
            toggleShowSkillsModal();
          }}>
          Add Your Skills Now
        </button>
        <div className="checkbox-wrapper padding-layout-base new-feature-centered">
          <input
            type='checkbox'
            id='opt_out_slider'
            onChange={onOptOutHandler}
            onClick={() => {
              clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.NO_SKILL_MODAL, CLICK.LOCATION.RELEASE_NOTE);
            }}
          />
          <label htmlFor='opt_out_slider'>Don&rsquo;t show again</label>
        </div>
      </div>
      <SkillsModal show={showSkillsModal} onClose={toggleShowSkillsModal} closeParentModal={onClose}/>
    </ModalBaseContainer>
  );
};

export default NewFeatureSkillsModal;
