import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FirebaseContext } from '../../../../components/Firebase';
import PropTypes from 'prop-types';

import './styles.scss';

import defaultAvatar from '../../../../statics/Images/Illustrations/Toucans/AppIconToucan.svg';
import { getDateDiff } from '../../../../tools/PrettyDateDiff';

/**
 * Displays singe notification
 * @name NotificationsItem
 */
const NotificationsItem = ({ item }) => {
  const firebase = useContext(FirebaseContext);
  const [state, setState] = useState({ profilePicture: null });
  const navigate = useNavigate();
  const { value: duration, unit } = getDateDiff(item.createdAt.toDate());

  /**
   * When a notification is clicked, mark it as received if it hasn't been already,
   * and then navigate to the post that the notification is about
   */
  const clickNotification = async () => {
    if (!item.received) {
      await firebase.markNotificationAsReceived(item.ref);
    }

    if (item.type === 'blocked') {
      // Not possible to navigate to blocked post at the moment
      return;
    }

    navigate(`/app/community/${item.typeData.communityID}/${item.typeData.parentID || item.typeData.postID}`);
  };

  useEffect(async () => {
    const [folder, fileName] = ['profile-pictures', item.trigger.userID];

    const url = await firebase.getImageUrl(folder, fileName);

    if(!url) {
      setState((s) => ({
        ...s,
        profilePicture: null,
      }));
      return;
    }

    setState((s) => ({
      ...s,
      profilePicture: url,
    }));
  }, []);

  return (
    <div className='notifications-item'>
      <button onClick={clickNotification}>
        {/* Display only if message is unread: */}
        {!item.received && <div className='notifications-unread-dot'></div>}
        <div className='notifications-group'>
          <div className='avatar-notifications-wrapper'>
            <img className='avatar-notifications' src={state.profilePicture || defaultAvatar} alt='Profile picture of user that triggered the notification' />
          </div>
          <p className='notifications-content'>
            {
              /* Display only if user is TM: */
              item.trigger.roles.includes('talentManager') &&
              <span className='tm-badge'>
                <span className='tm-badge-icon'></span>
              </span>
            }
            <span className='notifications-text'>
              <b>{item.trigger.username}</b>
              {
                {
                  'like': ' liked your post.',
                  'comment': ' commented on your post.',
                  'blocked': ' blocked your post, because it violated our community guidelines. If you think this was a mistake, reach out to customer service.',
                }[item.type]
              }
            </span>
          </p>
        </div>
        <p className='notifications-elapsed-time'>{duration}{unit.slice(0,1)}</p>
      </button>
    </div>
  );
};

NotificationsItem.propTypes = { item: PropTypes.object.isRequired };

export default NotificationsItem;
