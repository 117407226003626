import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ProgressToucan1 from '../../statics/Images/Illustrations/Toucans/ProgressToucan1.png';
import ProgressToucan2 from '../../statics/Images/Illustrations/Toucans/ProgressToucan2.png';

import './styles.scss';

/**
 * LoadingToucan
 *
 * @name LoadingToucan
 * @param {bool} loading Check if it is still loading
 * @param {func} setIsAnimating Set if animation is still in progress
 *
 */
const LoadingToucan = ({ loading, setIsAnimating }) => {
  const [percentage, setPercentage] = useState(0);
  const [limit, setLimit] = useState(180);

  useEffect(()=> {
    let timerId = 0;
    if(loading) {
      setPercentage(0);
    }

    if(percentage === 0) {
      timerId = setTimeout(() => {
        setPercentage(1);
      }, 1000);
    }

    if(percentage < (limit - 10) && percentage > 0) {
      timerId = setTimeout(() => {
        setPercentage(percentage + 1);
      }, 10);
    }
    if(percentage >= (limit - 10) && percentage < limit) {
      timerId = setTimeout(() => {
        setPercentage(percentage + 1);
      }, 50);
    }
    if(percentage === 200) {
      timerId = setTimeout(() => {
        setIsAnimating(false);
      }, 100);
    }

    // Ensure timer is cleared on unmount
    return () => { clearTimeout(timerId); };
  }, [percentage, limit]);

  useEffect(()=> {
    if(!loading){
      setLimit(200);
    }
  },[loading]);

  return (
    <div className='toucan-progress-container'>
      <div className='balls-container-1'>
        <div className='ball ball-1'></div>
        <div className='ball ball-2'></div>
        <div className='ball ball-3'></div>
        <div className='ball ball-4'></div>
      </div>
      <div className='balls-container-2'>
        <div className='ball ball-1'></div>
        <div className='ball ball-2'></div>
        <div className='ball ball-3'></div>
      </div>
      <div className='balls-container-3'>
        <div className='ball ball-1'></div>
        <div className='ball ball-3'></div>
        <div className='ball ball-4'></div>
      </div>
      <img className='progress-toucan-1' src={ProgressToucan1} />
      <img className='progress-toucan-2' src={ProgressToucan2} />

      { !loading &&
        <div className='progress-toucan-percentage'>
          <p>{Math.floor((percentage * 100) / 200)}%</p>
        </div>
      }
    </div>
  );
};
LoadingToucan.propTypes = {
  loading: PropTypes.bool,
  setIsAnimating: PropTypes.func,
};
export default LoadingToucan;
