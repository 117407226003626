import React from 'react';
import PropTypes from 'prop-types';

const HomeIcon = ({ width, height, color, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 170 208" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path d="M164.39 77.32V194.32C164.39 196.492 163.527 198.575 161.991 200.111C160.455 201.647 158.372 202.51 156.2 202.51H122.51C120.338 202.51 118.255 201.647 116.719 200.111C115.183 198.575 114.32 196.492 114.32 194.32V157.76C114.321 156.684 114.11 155.618 113.699 154.624C113.288 153.63 112.685 152.727 111.924 151.966C111.163 151.205 110.26 150.602 109.266 150.191C108.271 149.78 107.206 149.569 106.13 149.57H63.8699C62.794 149.569 61.7284 149.78 60.7341 150.191C59.7399 150.602 58.8365 151.205 58.0757 151.966C57.315 152.727 56.7117 153.63 56.3006 154.624C55.8895 155.618 55.6785 156.684 55.6799 157.76V194.31C55.6799 196.482 54.817 198.565 53.2811 200.101C51.7452 201.637 49.662 202.5 47.4899 202.5H13.7999C11.6277 202.5 9.54458 201.637 8.00865 200.101C6.47273 198.565 5.60986 196.482 5.60986 194.31V77.31C5.60947 76.2073 5.83258 75.116 6.2657 74.1019C6.69882 73.0879 7.33298 72.1721 8.12987 71.41L79.5299 7.57996C81.0337 6.23343 82.9813 5.48889 84.9999 5.48889C87.0184 5.48889 88.9661 6.23343 90.4699 7.57996L161.87 71.41C162.668 72.1733 163.303 73.0906 163.736 74.1065C164.169 75.1224 164.392 76.2156 164.39 77.32Z" stroke={color} strokeWidth="10.59" strokeMiterlimit="10"/>
    </svg>
  );
};

HomeIcon.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired,

};

export default HomeIcon;
