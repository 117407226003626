import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

/**
 * Slider / Likert test component
 *
 * @name TestSlider
 *
 * @param {object} question
 */
const TestSlider = ({ question, setAnwserHandler }) => {

  return (
    <ul className='likert'>
      { question.scoring.map((item, idx) => (
        <li key={idx + question.questionID} style={{ width: `calc(100% / ${question.scoring.length})` }}>
          <input
            className='likert-radio'
            type='radio'
            name={question.name}
            value={item.score}
            onClick={() => setAnwserHandler(
              {
                questionID: question.questionID,
                score: item.score,
              }
            )}
          />
          <p className='label'>{item.label}</p>
        </li>
      ))}
    </ul>
  );
};

TestSlider.propTypes = { question: PropTypes.object };

export default TestSlider;
