import React, { useContext } from 'react';
import './styles.scss';
import { FirebaseContext } from '../../../../../components/Firebase';
import ToucanBlack from '../../../../../statics/Images/ToucanBlack.svg';
import { createPersistentToken } from '../../../../../tools/FetchTUApi';

const jobItem = ({ jobData }) => {
  const firebase = useContext(FirebaseContext);

  const redirectWithToken = async () => {
    const persistentToken = await createPersistentToken(firebase.auth.currentUser.accessToken);

    const jobUrl = process.env.REACT_APP_PROJECT_ID === 'hero-career-coach' ?
      `https://match.hros.io/en/jobs/${jobData.url}` :
      `https://next.talentuno.test.gwsdev.com/en/jobs/${jobData.url}`;

    if (!persistentToken.success) {
      window.open(jobUrl, '_blank');
      return;
    }
    window.open(jobUrl + `?persistenttoken=${persistentToken.data.persistentToken}`, '_blank');
  };

  return (
    <div onClick={redirectWithToken}>
      <div className='job-item-container'>
        <div className='job-item-image-wrapper'>
          {
            jobData.companyLogo ?
              <img src={jobData.companyLogo.url} alt='Company logo' /> :
              <img className='company-logo-placeholder' src={ToucanBlack} alt='HROS toucan mascot' />
          }
        </div>
        <div className='job-item-content-container'>
          <h3>{jobData.title}</h3>
          <div className='job-item-description'>
            {jobData.company}
          </div>
          <div className='job-item-description'>
            {jobData.location?.country ? jobData.location.country : null}
          </div>
          <div className='job-item-description'>
            {jobData.location?.city ? jobData.location.city : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default jobItem;
