import React, { useState, useEffect, useContext, useRef } from 'react';

import OptionsMenu from '../OptionsMenu';

import { FirebaseContext } from '../../../../components/Firebase';
import { LinkItUrl } from 'react-linkify-it';

import DotsIcon from '../../../../statics/Images/DotsIcon.svg';
import defaultAvatar from '../../../../statics/Images/Illustrations/Toucans/AppIconToucan.svg';
import LikeIcon from '../../../../statics/Images/LikeIcon';

const CommentItem = ({ comment, post,fetchMorePostsTrigger ,fetchMorePosts , ICON_COLOR_GRAY, likeAction, ICON_COLOR_PRIMARY }) => {
  const firebase = useContext(FirebaseContext);
  const [state, updateState] = useState({
    showOptionsMenu: false,
    profilePicture: null,
    isVisible: false,
    fetchedNewPosts: false,
    liked: false,
  });
  const ref = useRef();

  useEffect(() => {
    if(comment.likes){
      const liked = comment.likes.find((like) => like.id === firebase.auth.currentUser.uid);
      updateState(s => ({
        ...s,
        liked,
      }));
    }
  }, [comment]);

  useEffect(async () => {
    // Profile pictures can be found under profile-pictures/${userID}.webp by convention
    const [folder, fileName] = ['profile-pictures', comment.userRef.id];
    const url = await firebase.getImageUrl(folder, fileName);
    updateState(s => ({
      ...s,
      profilePicture: url,
    }));
  }, [comment.userRef]);

  const showOptionsMenuHandler = () => {
    updateState(s => ({
      ...s,
      showOptionsMenu: !s.showOptionsMenu,
    }));
  };

  // Check if post is visible in viewport
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      updateState(s => ({
        ...s,
        isVisible: entry.isIntersecting,
      }))
    );

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  // Check if last item is visible
  // If last item is visible, fetch more posts
  useEffect(() => {
    if (fetchMorePostsTrigger && state.isVisible && !state.fetchedNewPosts) {
      fetchMorePosts();

      updateState(s => ({
        ...s,
        fetchedNewPosts: true,
      }));
    }
  },[state.isVisible]);

  return (
    <div className='comment-item' ref={ref}>
      <div className='community-item-header content-margin-base'>
        <div className='community-avatar-group'>
          <div className='avatar-community-wrapper'>
            { state.profilePicture ?
              <img className='avatar-community' src={state.profilePicture} alt='' /> :
              <img className='avatar-community' src={defaultAvatar} alt='' />
            }
          </div>
          <div>
            <div className='user-name-community'>{comment.username}</div>
            <div className='user-title-community'>Replying to {post.username}</div>
          </div>
        </div>
        <div className='options-menu-wrapper'>
          <img className='dots-icon' src={DotsIcon} width='16px' height='4px' onClick={() => showOptionsMenuHandler()} />
          { state.showOptionsMenu && <OptionsMenu post={comment} /> }
        </div>
      </div>
      <LinkItUrl className='color-primary'>
        <p className='content-margin-base comment-content'>{ comment.text }</p>
      </LinkItUrl>
      <div className='community-item-time content-margin-base'>{ comment.time } | { comment.date }</div>
      <div className='community-item-footer'>
        <div role='button' onClick={() => likeAction( comment )} className='num-likes'>
          <LikeIcon color={state.liked ? ICON_COLOR_PRIMARY : ICON_COLOR_GRAY} />
          <span style={{ color: state.liked ? ICON_COLOR_PRIMARY : ICON_COLOR_GRAY }}>{ comment.likes?.length || 0 }</span>
        </div>
      </div>
    </div>
  );
};

export default CommentItem;
