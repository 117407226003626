import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import './styles.scss';

import Button from '../../../../components/Button';
import CloseIcon from '../../../../statics/Images/CloseIcon';

import { debounce } from 'throttle-debounce';
import { useAnalytics } from '../../../../hooks/Firebase';
import { FirebaseContext } from '../../../../components/Firebase';
import { StorageContext } from '../../../../components/Storage';
import Autocomplete from '../../../../components/Autocomplete';
import useElasticSearchSkills from '../../../../hooks/useElasticSearchSkills';
import useToasts from '../../../../hooks/useToasts';

import Loader from '../../../../statics/Images/Loader';

/**
 * Signup skills page
 * @name SkillsForm
 * @memberof SignUp
 */
const SkillsForm = ({ onFormSubmit }) => {
  const navigate = useNavigate();
  const firebase = useContext(FirebaseContext);
  const [appContext, dispatch] = useContext(StorageContext);
  const { clickEvent, customEvent, searchItemEvent, TYPES: { SEARCH, CLICK } } = useAnalytics();
  const { publishError } = useToasts();
  const [state, updateState] = useState({
    selectedSkills: [],
    autocompleteInput: '',
    btnDisabled: true,
    skillsFetched: [],
    lastSelected: {},
  });

  const location = useLocation();

  const { fetchSkills, clearResults, skillSelected, elasticSearchState } = useElasticSearchSkills();
  const skillInputRef = useRef();
  const performDebouncedSearch = useMemo(() => debounce(333, (term) => { fetchSkills(term); }), []);

  useEffect(async() => {
    updateState(s => ({
      ...s,
      selectedSkills: appContext.profile.skills || [],
      btnDisabled: appContext.profile.skills === null ? true : false,
    }));
  }, [appContext.isLoading]);

  const onChangeSkillsAutocomplete = (event) =>{
    let term = event.target.value;
    if (!term.length) {
      clearResults();
    }

    performDebouncedSearch(term);

    updateState(s => ({
      ...s,
      autocompleteInput: term,
      skillsFetched: elasticSearchState.results,
    }));
  };

  const onClose = (ref) => {
    clearResults();
    ref.current.value = '';
  };

  const selectSkillHandler = async (doc, idx) => {

    if(state.selectedSkills.length > 9) {
      publishError('You can only select 10 skills for now!');
      clearResults();
      return;
    }

    updateState(s => ({
      ...s,
      lastSelected: doc.name,
    }));

    searchItemEvent(SEARCH.TYPE.SKILL_NAME, SEARCH.CATEGORY.ADD_SKILL_SEARCH_TERM, doc.name, idx, state.autocompleteInput);

    await skillSelected(doc);

    const filteredskills = state.selectedSkills.filter(skill => skill.id !== doc.id);

    updateState(s => ({
      ...s,
      selectedSkills: [...filteredskills, doc],
      btnDisabled: false,
    }));

    clearResults();
    skillInputRef.current.value = '';
  };

  const removeSkill = (filteredSkill) => {
    const filteredSkills = state.selectedSkills.filter(skill => skill.id !== filteredSkill.id);
    updateState(s => ({
      ...s,
      selectedSkills: filteredSkills,
    }));
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    if(skillInputRef.current.value !== '') {
      publishError('Please select from the list.');
      return;
    }

    const mergedSkills = appContext.profile.skills === null ? state.selectedSkills : [...state.selectedSkills, ...appContext.profile.skills];
    const diff = new Set();

    mergedSkills.forEach(skill => {
      diff.has(skill.id) ? diff.delete(skill.id) : diff.add(skill.id);
    });

    if(diff.size > 0) {
      await firebase.updateUserSkills(state.selectedSkills);
      dispatch({
        type: 'SET_PROFILE',
        payload: {
          ...appContext.profile,
          skills: state.selectedSkills,
        },
      });

      // Log custom event for each new selected and saved skill:
      state.selectedSkills.forEach(skill => {
        let name = skill.name;
        name = name.replace(/\s+/g, '_').toLowerCase();
        customEvent(`add_skill_${name}`);
      });

      if (location.pathname === '/create-profile/skills') navigate('/create-profile/complete');

      clearResults();
    }

    if (location.pathname === '/create-profile/skills') navigate('/create-profile/complete');
    onFormSubmit(event);
  };

  return (
    <form className={ location.pathname === '/create-profile/skills' ? 'signup-step-flex-grow' : '' } onSubmit={onSubmit}>
      <div className={ location.pathname === '/create-profile/skills' ? 'signup-step-card' : '' }>
        <div className={ location.pathname === '/create-profile/skills' ? 'backdrop-filter' : '' }></div>
        { appContext.isLoading ?
          <Loader className='loading-animation'></Loader>
          :
          <>
            <div className='content-margin-base'>
              <Autocomplete
                onChange={onChangeSkillsAutocomplete}
                searchResult={elasticSearchState.results}
                selectEntryHandler={selectSkillHandler}
                inputPlaceholder='Skills'
                inputName='skill-search'
                icon={true}
                searchInputRef={skillInputRef}
                onClose={() => onClose(skillInputRef)}
              />
            </div>
            <div className='skills-wrapper content-margin-xlarge'>
              {
                state.selectedSkills.map((skill) => (
                  <div key={skill.id} className='skill'>
                    <span>{skill.name}</span>
                    <span className='close-icon-wrapper' onClick={() => removeSkill(skill)}>
                      <CloseIcon color='#ffffff'/>
                    </span>
                  </div>
                ))
              }
            </div>
          </>
        }
      </div>
      <div className={location.pathname === '/create-profile/skills' ? 'content-margin-xlarge' : ''}>
        <Button
          theme='btn-primary'
          type='submit'
          onClick={() => {
            location.pathname === '/create-profile/skills' ?
              clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.ALL_SKILLS_SAVE)
              :
              clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.ALL_SKILLS_SAVE_PROFILE);
          }}
          disabled={state.btnDisabled}>{ location.pathname === '/create-profile/skills' ? '(3/3) Save' : 'Save' }</Button>
      </div>
    </form>
  );
};

export default SkillsForm;
