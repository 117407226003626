import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

/**
 * TestSingleChoice test component
 *
 * @name TestSingleChoice
 *
 * @param {object} question
 */
const TestSingleChoice = ({ question, setAnwserHandler }) => {

  return (
    <ul>
      { question.scoring.map((item, idx) => (
        <li className='survey-option' key={idx + question.questionID}>
          <input type='radio' name={question.name} value={item.score}
            onClick={() => setAnwserHandler(
              {
                questionID: question.questionID,
                score: item.score,
              }
            )}
          />
          <label>{item.label}</label>
        </li>
      ))}
    </ul>
  );
};

TestSingleChoice.propTypes = { question: PropTypes.object };

export default TestSingleChoice;
