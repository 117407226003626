import React from 'react';
import PropTypes from 'prop-types';

const SuccessIcon = ({ color }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.5638 0.101831C11.0776 -0.224075 8.55171 0.236806 6.34077 1.41976C4.12984 2.60272 2.34487 4.44835 1.23645 6.69758C0.128028 8.9468 -0.248192 11.4867 0.160606 13.9606C0.569403 16.4346 1.74269 18.7184 3.51576 20.4915C5.28883 22.2646 7.57265 23.4379 10.0466 23.8468C12.5206 24.2556 15.0604 23.8794 17.3097 22.771C19.5589 21.6626 21.4046 19.8777 22.5876 17.6667C23.7705 15.4558 24.2314 12.9299 23.9056 10.4437C23.561 7.82171 22.3606 5.38678 20.4907 3.51681C18.6207 1.64684 16.1858 0.446403 13.5638 0.101831V0.101831ZM13.8126 22.5176C11.5803 22.9017 9.28376 22.5653 7.25542 21.5571C5.22707 20.549 3.57224 18.9215 2.53052 16.9102C1.4888 14.8989 1.11426 12.6082 1.46115 10.3699C1.80804 8.13152 2.85833 6.06168 4.45997 4.46003C6.0616 2.85838 8.13145 1.80808 10.3698 1.46118C12.6081 1.11427 14.8988 1.48879 16.9101 2.5305C18.9214 3.5722 20.5489 5.22703 21.5571 7.25536C22.5652 9.2837 22.9016 11.5803 22.5176 13.8125C22.142 15.9843 21.1038 17.9868 19.5453 19.5452C17.9868 21.1037 15.9844 22.142 13.8126 22.5176V22.5176Z"
        fill={color}/>
      <path d="M16.0142 8.51575L10.4562 14.0739L7.9935 11.6113C7.86816 11.486 7.69817 11.4155 7.5209 11.4155C7.34364 11.4155 7.17364 11.486 7.04829 11.6113C6.92295 11.7366 6.85254 11.9066 6.85254 12.0839C6.85254 12.2612 6.92295 12.4312 7.04829 12.5565L9.98355 15.4918C10.1089 15.6171 10.2789 15.6875 10.4562 15.6875C10.6335 15.6875 10.8035 15.6171 10.9289 15.4918L16.9595 9.46095C17.0849 9.33559 17.1553 9.16558 17.1553 8.98831C17.1553 8.81104 17.0848 8.64103 16.9595 8.51569C16.8341 8.39034 16.6641 8.31993 16.4868 8.31995C16.3096 8.31996 16.1396 8.39039 16.0142 8.51575H16.0142Z"
        fill={color}/>
    </svg>
  );
};

SuccessIcon.propTypes = { color: PropTypes.string.isRequired };

export default SuccessIcon;
