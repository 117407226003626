import React from 'react';
import PropTypes from 'prop-types';

const PrivacyPolicyIcon = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 170 186" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1651_31)">
        <path d="M90.34 7.58963C98.93 15.9396 118.34 33.2596 156.19 34.9096C158.305 34.9922 160.307 35.8898 161.776 37.4144C163.244 38.9391 164.066 40.9726 164.07 43.0896V98.3096C164.074 107.723 161.76 116.993 157.332 125.3C152.904 133.607 146.498 140.696 138.68 145.94L89.28 179C87.9311 179.904 86.3439 180.387 84.72 180.387C83.0961 180.387 81.5089 179.904 80.16 179L30.68 145.94C22.8548 140.7 16.4409 133.614 12.0056 125.306C7.57022 116.999 5.24999 107.727 5.25 98.3096V43.0896C5.25355 40.9726 6.0757 38.9391 7.54442 37.4144C9.01314 35.8898 11.0146 34.9922 13.13 34.9096C50.93 33.2596 70.39 15.9096 78.98 7.58963C80.4987 6.1066 82.5373 5.27637 84.66 5.27637C86.7827 5.27637 88.8213 6.1066 90.34 7.58963Z" stroke={color} strokeWidth="10.59" strokeMiterlimit="10"/>
        <path d="M57.4004 93.1296L75.9204 111.64L113.97 73.5996" stroke={color} strokeWidth="10.59" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_1651_31">
          <rect width="169.37" height="185.68" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  );
};

PrivacyPolicyIcon.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default PrivacyPolicyIcon;
