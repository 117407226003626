import React, { useContext, useState } from 'react';

import { FirebaseContext } from '../../../Firebase';

import InputWithLabel from '../../InputWithLabel';
import Button from '../../../Button';

const EMPTY_STATE = {
  name: null,
  text: null,
  choice_options: 4,
};

const SingleChoice = ({ type, clearState }) => {
  const firebase = useContext(FirebaseContext);
  const [state, setState] = useState(EMPTY_STATE);

  const onChange = (e) => {
    let value = e.target.value;

    if (e.target.type === 'number') {
      value = e.target.valueAsNumber;
    }

    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  const onSubmit = (e) => {
    const scoring = [];

    for (let i = 0 ; i < state.choice_options; i++) {
      scoring.push({
        label: state[`option_${i}_text`] || null,
        score: state[`option_${i}_score`] || 0,
      });
    }

    e.preventDefault();
    firebase.addCollectionItem('survey_questions', {
      createdAt: firebase.convertDate(new Date()),
      name: state.name,
      text: state.text,
      type: type,
      scoring,
    });

    setState(EMPTY_STATE);
    // Clears the state of the parent component
    clearState({ type: null });
  };

  return (
    <>
      <form className='padding-layout-base' onSubmit={onSubmit}>
        <InputWithLabel
          className='content-margin-base'
          name='name'
          type='text'
          onChange={onChange}
          value={state.name}
        />
        <InputWithLabel
          className='content-margin-base'
          name='text'
          type='text'
          onChange={onChange}
          value={state.text}
        />
        <input
          id='choice_options'
          name='choice_options'
          className='input-group-item-small'
          type='number'
          onChange={onChange}
          min={0}
          value={state.choice_options || 0}
        />

        {
          state.choice_options &&
        [...Array(state.choice_options)].map((element, i) => (

          <div className='input-group content-margin-base' key={i}>
            <input
              name={`option_${i}_text`}
              type='text'
              onChange={onChange}
              label='First Option'
              className='input-group-item-large'
              placeholder={`Option ${ i }`}
            />

            <input
              id={`option_${i}_score`}
              name={`option_${i}_score`}
              className='input-group-item-small'
              type='number'
              onChange={onChange}
              min={0}
              value={state[`option_${i}_score`] || 0}
            />

          </div>
        ))
        }

        <Button onClick={onSubmit} theme='btn-primary' type='submit'>Save</Button>
      </form>

    </>
  );
};

export default SingleChoice;
