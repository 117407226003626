import React, { useState, useContext, useEffect } from 'react';

import { FirebaseContext } from '../../../../../components/Firebase';
import { StorageContext } from '../../../../../components/Storage';
import useToasts from '../../../../../hooks/useToasts';

import ModalBaseContainer from '../../../../../components/ModalBaseContainer';
import { useAnalytics } from '../../../../../hooks/Firebase';

/**
 * Renders a modal that allows users to report posts and comments.
 * @name ChooseCommunitiesModal

 */
const ChooseCommunitiesModal = ({ show, onClose }) => {
  const firebase = useContext(FirebaseContext);
  const [state, dispatch] = useContext(StorageContext);
  const [communities, setCommunities] = useState([]);
  const [selectedCommunities, setSelectedCommunities] = useState([]);
  const { publishSuccess, publishError } = useToasts();
  const { clickEvent, TYPES: { CLICK }, customEvent } = useAnalytics();

  useEffect(async() => {
    if(show) {
      const communitiesData = await firebase.getCommunities();

      const communitiesArray = [];

      communitiesData.forEach(community => {
        communitiesArray.push({
          communityID: community.id,
          communityName: community.data().name,
        });
      });

      setCommunities(communitiesArray);

      setSelectedCommunities(state.profile.communities);
    }
  }, [show]);

  const onCommunitiesSelectedHandler = async (event) => {
    event.preventDefault();

    // Log custom event for each new selected and saved group:
    selectedCommunities.forEach(community => {
      if (!state.profile.communities.includes(community)) {
        let name = community.communityName;
        name = name.replace(/\s+/g, '_').toLowerCase();
        customEvent(`join_group_${name}`);
      }
    });

    const mergedCommunities = [...selectedCommunities, ...state.profile.communities];
    const diff = new Set();

    mergedCommunities.forEach(c => {
      diff.has(c.communityID) ? diff.delete(c.communityID) : diff.add(c.communityID);
    });

    if(diff.size > 0){
      await firebase.updateUserCommunities(selectedCommunities);
      dispatch({
        type: 'SET_PROFILE',
        payload: {
          ...state.profile,
          communities: selectedCommunities,
        },
      });
      await publishSuccess('Communities updated successfully');
      onClose();
      return;
    }
    onClose();
  };

  const onChange = (event) => {
    if(!event.target.checked) {
      setSelectedCommunities(selectedCommunities.filter(community => community.communityID !== event.target.value));
      return;
    }

    if(selectedCommunities.length === 9){
      publishError('You can only select 9 groups for now!');
      event.target.checked = false;
      return;
    }

    setSelectedCommunities([
      ...selectedCommunities,
      {
        communityName: event.target.name,
        communityID: event.target.value,
      },
    ]);

  };

  return (
    <ModalBaseContainer show={show} onClose={onClose}>
      <h2>What field(s) are you interested in?</h2>
      <form onSubmit={onCommunitiesSelectedHandler}>
        <ul className='content-margin-base'>
          {
            communities && communities.map((community, i) => (
              <li key={i} className='report-radio-btn'>
                <input
                  onChange={onChange}
                  type='checkbox'
                  name={community.communityName}
                  value={community.communityID}
                  defaultChecked={state.profile.communities.some(subscribedCommunity => community.communityID === subscribedCommunity.communityID)}
                />
                <span className='checked-icon report-radio-icon'></span>
                <span className='report-radio-label'>{community.communityName}</span>
              </li>
            ))
          }
        </ul>
        <button type='submit' className='btn btn-primary'
          onClick={() => {
            clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.CHOOSE_COMMUNITY_MODAL_SAVE);
          }}
        >Send</button>
      </form>
    </ModalBaseContainer >
  );
};

export default ChooseCommunitiesModal;
