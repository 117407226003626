import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import './styles.scss';

import BackIcon from '../../statics/Images/BackIcon';

/**
 * Top back button with redirect
 *
 * @name Topbackbutton
 *
 *
 * @param {string} width Default 28px
 * @param {string} height Default 28px
 * @param {string} color Default white
 * @param {string} url URL of the page to be redericted to, if nothing is passed the back btn will redirect by default to last page visited.
 *
 */
const BackButton = ({ width, height, color, url }) => {
  const navigate = useNavigate();

  return (
    <button className='back-btn' onClick={() => url ? navigate(url) : navigate(-1)}>
      <BackIcon width={width} height={height} color={color}/>
    </button>
  );
};

// propTypes for <BackIcon /> controlled by itself
BackButton.propTypes = { url: PropTypes.string };

export default BackButton;
