import React from 'react';
import Loader from '../../statics/Images/Loader';
import './styles.scss';

/**
 * Full page loading screen
 * @name InitialLoading
 */
const InitialLoading = () => {
  return (
    <div className='inital-loading-container'>
      <div className='loader-wrapper'>
        <Loader className='loadingAimation' color='#eb4a5a' />
      </div>
    </div>
  );
};

export default InitialLoading;
