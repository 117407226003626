import React from 'react';
import PropTypes from 'prop-types';

const CalendarIcon = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 171 161" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M164.78 92.43V146.83C164.78 151.35 161.11 155.02 156.59 155.02H14.19C9.67 155.02 6 151.35 6 146.83V92.43" stroke={color} strokeWidth="10.5856" strokeMiterlimit="10"/>
      <path d="M6 120.1V57.51H164.78V120.1" stroke={color} strokeWidth="10" strokeMiterlimit="10"/>
      <path d="M45.4805 30.05V6" stroke={color} strokeWidth="10" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M74.1904 81.07H96.5905" stroke={color} strokeWidth="10" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M120.08 81.07H142.48" stroke={color} strokeWidth="10" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M28.2998 106.64H50.6998" stroke={color} strokeWidth="10" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M74.1904 106.64H96.5905" stroke={color} strokeWidth="10" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M120.08 106.64H142.48" stroke={color} strokeWidth="10" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M28.2998 132.22H50.6998" stroke={color} strokeWidth="10" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M74.1904 132.22H96.5905" stroke={color} strokeWidth="10" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M125.3 30.05V6" stroke={color} strokeWidth="10" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M144.97 18.03H156.59C161.11 18.03 164.78 21.7 164.78 26.22V80.62" stroke={color} strokeWidth="10" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M65.1396 18.03H105.64" stroke={color} strokeWidth="10" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M6 80.61V26.21C6 21.69 9.67 18.02 14.19 18.02H25.81" stroke={color} strokeWidth="10" strokeMiterlimit="10" strokeLinecap="round"/>
    </svg>
  );
};

CalendarIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};
CalendarIcon.defaultProps = {
  width: '20px',
  height: '20px',
  color: '#9E9E9E',
};

export default CalendarIcon;
