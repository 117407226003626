import React, { createContext, useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Reducer from './reducer';
import { useLocation } from 'react-router-dom';
import { isEmptyObject } from '../../tools/IsEmptyObject';
import useFillState from '../../hooks/useFillState';
import useNotifications from '../../hooks/useNotifications';

/**
 * Array with base scheme of global storage
 * @const {object} emptyStorage
 */
const emptyStorage = {
  authenticationProvider: '',
  career: [],
  profile: {},
  profilePicture: '',
  error: null,
  startupFitTestResults: {},
  careerPredictionResults: {},
  notifications: [],
  notificationSettings: {
    pushEnabled: false,
    emailEnabled: true,
  },
  toasts: [],
  isLoading: true,
  claims: [],
  eqResults: {},
};

const StorageContext = createContext(emptyStorage);

const DANGEROUS_PATHS = ['/logout', '/delete-me', '/google-auth', '/signup', '/login', '/'];

/**
 * Global storage context
 *
 * @name Storage
 * @description Fetches data from firestore on the first render
 *
 * @param {node} children App content
 * @param {bool} signedIn Checks if user is logged in
 *
 */

const Storage = ({ children, signedIn }) => {
  const [state, dispatch] = useReducer(Reducer, emptyStorage);
  const location = useLocation();
  const [isDangerous, setIsDangerous] = useState(DANGEROUS_PATHS.includes(location.pathname));
  const [isListeningNotifications, setIsListeningNotifications] = useState(false);
  const {
    loadUserData,
    loadStartupFitResults,
    loadCareerPredictionResults,
    // loadProfilePicture,
    loadCustomClaims,
    loadEqTestResults,
  } = useFillState(dispatch);

  const { listenToNotifications, unsubscribeNotifications } = useNotifications(dispatch);

  useEffect(() => {
    if (isDangerous !== DANGEROUS_PATHS.includes(location.pathname)) {
      setIsDangerous(DANGEROUS_PATHS.includes(location.pathname));
    }
  }, [location.pathname]);

  useEffect(() => {

    if (signedIn && !isDangerous) {
      try {
        if (isEmptyObject(state.careerPredictionResults)) {
          loadCareerPredictionResults();
        }
        if (state.claims.length == 0) {
          loadCustomClaims();
        }
        if (isEmptyObject(state.startupFitTestResults)) {
          loadStartupFitResults();
        }
        if (isEmptyObject(state.profile)) {
          loadUserData();
        }
        if (isEmptyObject(state.eqResults)) {
          loadEqTestResults();
        }
        if (!isListeningNotifications) {
          listenToNotifications();
          setIsListeningNotifications(true);
        }
      }
      catch (err) {
        console.error(err);
      }
    }

    return () => {
      unsubscribeNotifications();
    };

  }, [signedIn, isDangerous]);
  return (
    <StorageContext.Provider value={[state, dispatch]}>
      {children}
    </StorageContext.Provider>
  );
};

Storage.propTypes = {
  signedIn: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Storage;

export { StorageContext, emptyStorage };
