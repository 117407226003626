import React from 'react';
import './styles.scss';

const EDIT_PROFILE_TABS = [
  'Overview',
  'Preferences',
  'Experience',
];

/**
 * @name EditProfileTabs
 */
const EditProfileTabs = ({ activeTab, setActiveTab }) => {

  return (
    <>
      <div className='edit-profile-tab-filters-wrapper'>
        <div className='edit-profile-tab-filters content-margin-large'>

          {EDIT_PROFILE_TABS.map((tab, i) => (
            <div className='profile-filter-tab' key={i}>
              <button
                className={activeTab === tab ? 'active' : undefined}
                id={tab}
                name={tab}
                onClick={() => setActiveTab(tab)}>
                {tab}
              </button>
            </div>
          ))}
          <div className='profile-tab-line' />
        </div>
      </div>

    </>
  );
};

EditProfileTabs.propTypes = {};

export default EditProfileTabs;
