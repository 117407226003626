import React from 'react';

import Career from '../../../../../components/Career';

/**
 * @name EditProfileExperience
 */
const EditProfileExperience = () => {

  return (
    <div className='tab-content'>
      <p className='content-margin-large'>Fill out with relevant job experience which will reflect on your profile.</p>
      <Career />
    </div>
  );
};

EditProfileExperience.propTypes = {};

export default EditProfileExperience;
