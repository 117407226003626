import React, { useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { FirebaseContext } from '../../components/Firebase';
import { StorageContext } from '../../components/Storage';

import Loader from '../../statics/Images/Loader';
/**
 * AuthenticateWithCustomToken screen
 * @name AuthenticateWithCustomToken
 */
const AuthenticateWithCustomToken = ({ signedIn }) => {
  const navigate = useNavigate();
  const firebase = useContext(FirebaseContext);
  const [, dispatch] = useContext(StorageContext);
  const { targetUrl, OneTimeUseToken } = useParams();
  const customTokenURL = !process.env.REACT_APP_USE_EMULATORS ? `https://europe-west1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/customTokenAuthentication` : 'http://localhost:5001/hero-career-coach-dev/europe-west1/customTokenAuthentication';

  // Set page title and send page view event
  useEffect(async () => {
    if (!OneTimeUseToken) {
      navigate('/login', { replace: true });
    }

    if (signedIn) {
      await dispatch({ type: 'CLEAR_PROFILE' });
      const error = await firebase.doSignOut();

      //TODO: Handle error to redirect to login page or dashboard?
      if (error !== undefined) {
        navigate('/login');
        console.error(error);
        return;
      }

      window.location.reload(false);
      return;
    }

    const request = await fetch(customTokenURL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: OneTimeUseToken }),
    });

    const response = await request.json();

    if (request.status !== 200) {
      navigate('/login', { replace: true });
      console.error(response);
      return;
    }

    await firebase.doSignInWithCustomToken(response.customToken);

    navigate(`/app/${targetUrl}`);

  }, []);

  //TODO: What should be added to the this page, like a loading indicator or something else
  return (
    <div >
      <Loader className='loading-animation' />
    </div>
  );
};

export default AuthenticateWithCustomToken;
