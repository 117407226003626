import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

/**
 * Component with text expansion
 * @name ExpansionText
 *
 * @param {string} text Text to show | hide
 */
const ExpansionText = ({ text }) => {
  const [isTextVisible, setIsTextVisible] = useState(false);

  return (
    <>
      <p className='expansion-link' onClick={() => { setIsTextVisible(!isTextVisible); }}>
        <span className={isTextVisible ? 'arrow-hide' : 'arrow-show'}></span>
        { isTextVisible ? 'Hide' : 'See more' }
      </p>
      <p className={isTextVisible ? 'expansion-text visible' : 'expansion-text'}>{text}</p>
    </>
  );
};

ExpansionText.propTypes = { text: PropTypes.string.isRequired };

export default ExpansionText;
