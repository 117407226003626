import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleAuthProvider } from 'firebase/auth';

import { FirebaseContext } from '../Firebase';
import useToasts from '../../hooks/useToasts';
import { useAnalytics } from '../../hooks/Firebase';

const GoogleButton = ({ useOneTap = false }) => {
  const sendUserCreatedMessageUrl = !process.env.REACT_APP_USE_EMULATORS ? `https://europe-west1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/sendUserCreatedMessage` : 'http://localhost:5001/hero-career-coach-dev/europe-west1/sendUserCreatedMessage';
  const sendUserSignedInMessageUrl = !process.env.REACT_APP_USE_EMULATORS ? `https://europe-west1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/sendUserSignedInMessage` : 'http://localhost:5001/hero-career-coach-dev/europe-west1/sendUserSignedInMessage';

  const firebase = useContext(FirebaseContext);
  const { publishError } = useToasts();
  const { clickEvent, errorEvent, TYPES: { CLICK }, customEvent } = useAnalytics();
  const navigate = useNavigate();

  const signUpHandler = async (accessToken) => {
    // Checks if the user already exists on the Firestore
    const snapshot = await firebase.getUser();

    // Creates user if not on Firestore
    if (!snapshot.exists()) {
      await firebase.setUser(firebase.auth.currentUser.uid, {
        email: firebase.auth.currentUser.email || firebase.auth.currentUser.providerData[0].email,
        username: null,
        firstName: null,
        lastName: null,
        createdAt: firebase.convertDate(new Date(firebase.auth.currentUser.metadata.creationTime)),
        lastLoginAt: firebase.convertDate(new Date(firebase.auth.currentUser.metadata.lastSignInTime)),
        career: [],
        roles: [],
        notificationSettings: {
          pushEnabled: false,
          emailEnabled: true,
        },
      });

      fetch(sendUserCreatedMessageUrl, {
        method: 'POST',
        body: JSON.stringify({
          email: firebase.auth.currentUser.email || firebase.auth.currentUser.providerData[0].email,
          firebaseId: firebase.auth.currentUser.uid,
          socialToken: accessToken,
        }),
      });

      customEvent('sign_up_complete');
      navigate('/create-profile/profile');
      // If user exists on firestore
    } else {
      firebase.updateUser({ lastLoginAt: firebase.convertDate(new Date(firebase.auth.currentUser.metadata.lastSignInTime)) });
      const data = snapshot.data();
      fetch(sendUserSignedInMessageUrl, {
        method: 'POST',
        body: JSON.stringify({
          email: data.email,
          firebaseId: snapshot.id,
          socialToken: accessToken,
        }),
      });
      navigate('/app/dashboard');
    }
  };

  const onSuccess = async (token) => {
    clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.GOOGLE_SIGN_UP);

    const oAuthCredential = GoogleAuthProvider.credential(token.credential);
    const result = await firebase.signInWithGoogle(oAuthCredential);

    if (result.user) {
      signUpHandler(result.user.accessToken);
    }
  };

  const onError = (error) => {
    publishError('Unable to authenticate with Google');
    errorEvent(error.stack, false);
  };

  return (
    <GoogleLogin
      onSuccess={onSuccess}
      onError={onError}
      width={280}
      useOneTap={useOneTap}
    />
  );
};

export default GoogleButton;
