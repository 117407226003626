import React from 'react';
import { Route, Routes } from 'react-router-dom';

import SignIn from '../../pages/SignIn';
import ResetPassword from '../../pages/SignIn/ResetPassword';

/**
 * @name LoginRoutes
 */
const LoginRoutes = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<SignIn />}/>
        <Route path='reset-password' element={<ResetPassword />}/>
      </Routes>
    </>

  );
};

export default LoginRoutes;
