import React from 'react';
import PropTypes from 'prop-types';

const BirthdayIcon = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.0795 10.5638H3.92036C3.37431 10.5638 2.93164 11.0064 2.93164 11.5525V19.6184C2.93164 20.1644 3.37431 20.6071 3.92036 20.6071H21.0795C21.6255 20.6071 22.0682 20.1644 22.0682 19.6184V11.5525C22.0682 11.0064 21.6255 10.5638 21.0795 10.5638Z" stroke={color} strokeWidth="1.38972" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M2.93164 14.9828C4.52364 14.9828 4.52365 16.188 6.11565 16.188C7.70946 16.188 7.70946 14.9828 9.30327 14.9828C10.8973 14.9828 10.8973 16.188 12.4913 16.188C14.0857 16.188 14.0857 14.9828 15.6802 14.9828C17.2772 14.9828 17.2772 16.188 18.8742 16.188C20.4712 16.188 20.4712 14.9828 22.0682 14.9828" stroke={color} strokeWidth="1.38972" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M11.8393 5.8851H13.1609C13.4232 5.8851 13.6746 5.98927 13.8601 6.17469C14.0455 6.36011 14.1497 6.61159 14.1497 6.87382V10.5638H10.8506V6.87382C10.8506 6.61159 10.9547 6.36011 11.1402 6.17469C11.3256 5.98927 11.5771 5.8851 11.8393 5.8851Z" stroke={color} strokeWidth="1.38972" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M14.1497 4.23558C14.1497 4.67307 13.9759 5.09263 13.6665 5.40198C13.3572 5.71133 12.9376 5.88512 12.5001 5.88512C12.0626 5.88512 11.6431 5.71133 11.3337 5.40198C11.0244 5.09263 10.8506 4.67307 10.8506 4.23558C10.9912 3.30132 11.4343 2.43897 12.1121 1.78073C12.161 1.72627 12.2208 1.68271 12.2877 1.65289C12.3545 1.62308 12.4269 1.60767 12.5001 1.60767C12.5733 1.60767 12.6457 1.62308 12.7126 1.65289C12.7794 1.68271 12.8393 1.72627 12.8882 1.78073C13.5659 2.43897 14.009 3.30132 14.1497 4.23558V4.23558Z" stroke={color} strokeWidth="1.38972" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M1.44221 20.6071H23.5582C23.6141 20.6071 23.6677 20.6293 23.7073 20.6689C23.7468 20.7084 23.769 20.762 23.769 20.8179V21.7184C23.769 22.1624 23.5927 22.5881 23.2787 22.9021C22.9648 23.216 22.539 23.3923 22.0951 23.3923H2.90538C2.46142 23.3923 2.03566 23.216 1.72173 22.9021C1.40781 22.5881 1.23145 22.1624 1.23145 21.7184V20.8179C1.23145 20.762 1.25365 20.7084 1.29318 20.6689C1.33272 20.6293 1.38634 20.6071 1.44225 20.6071H1.44221Z" stroke={color} strokeWidth="1.38972" strokeMiterlimit="10" strokeLinecap="round"/>
    </svg>
  );
};

BirthdayIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};
BirthdayIcon.defaultProps = {
  width: '20px',
  height: '20px',
  color: '#9E9E9E',
};

export default BirthdayIcon;
