import React from 'react';
import { Routes ,Route } from 'react-router-dom';

import StartupFitTestOverview from './StartupFitTestOverview';
import StartupFitResults from './StartupFitResults';
import CatchAll from '../../components/CatchAll';

/**
 * Startup Fit test routes
 * @name StartupFit
 */
const StartupFit = () => {
  return (
    <Routes>
      <Route path='/' element={<StartupFitTestOverview />} />
      <Route path='results' element={ <StartupFitResults /> } />
      <Route path='*' element={ <CatchAll />} />
    </Routes>
  );
};

export default StartupFit;
