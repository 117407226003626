import React from 'react';

import './styles.scss';

/**
 * BannerBaseContainer to wrap all banner components
 *
 * @name BannerBaseContainer
 *
 * */
const BannerBaseContainer = ({ children }) => {
  return (
    <div className='banner-container'>
      {children}
    </div>
  );
};

export default BannerBaseContainer;
