import React from 'react';
import PropTypes from 'prop-types';
import { useAnalytics } from '../../hooks/Firebase';

/**
 * Filter component for the community tab
 * @name CommunityFilter
 * @param {Array, <Object>} communitiesSubscribed - Array of objects with subscribed communities
 * @param {String} activeCommunityTab - The currently active community tab
 * @param {Function} setActiveCommunityTab - Function to set the active community tab
 */
const CommunityTabs = ({ communitiesSubscribed, activeCommunityTab, setActiveCommunityTab, showChooseCommunityModalHandler }) => {
  const { clickEvent, TYPES: { CLICK } } = useAnalytics();

  const onCommunityTabChange = (e) => {
    setActiveCommunityTab(e.target.name);
  };

  return (
    <div className='community-tab-filters-wrapper'>
      <div className='community-tab-filters content-margin-large'>
        <div className='filter-tab'>
          <button
            className='fab-button-small'
            id='addCommunityTab'
            name='ADD_COMMUNITY'
            onClick={() => {
              showChooseCommunityModalHandler();
              clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.CHOOSE_COMMUNITY_MODAL);
            }}
          >
            +
          </button>
        </div>
        <div className='filter-tab'>
          <button
            className= {activeCommunityTab === 'ALL' ? 'active' : undefined}
            id='allTab'
            name='ALL'
            onClick={onCommunityTabChange}
          >
            All
          </button>
        </div>
        <div className='filter-tab'>
          <button
            className= {activeCommunityTab === 'MY_POSTS' ? 'active' : undefined}
            id='myPostsTab'
            name='MY_POSTS'
            onClick={onCommunityTabChange}
          >
            My Posts
          </button>
        </div>

        {communitiesSubscribed &&
          communitiesSubscribed.map((community, i) => (
            <div className='filter-tab' key={i}>
              <button
                className= {activeCommunityTab === community.communityName ? 'active capitalize' : 'capitalize'}
                id={community.communityID}
                name={community.communityName}
                onClick={onCommunityTabChange}
              >
                {community.communityID === 'RECOMMENDED' ? 'For You' : community.communityName}
              </button>
            </div>
          ))
        }
      </div>
      <div className='filters-shade-right'></div>
    </div>
  );
};

CommunityTabs.propTypes = {
  communitiesSubscribed: PropTypes.array,
  activeCommunityTab: PropTypes.string,
  setActiveCommunityTab: PropTypes.func,
};

export default CommunityTabs;
