import React, { useContext } from 'react';

import { StorageContext } from '../Storage';
import { useUserCareer } from '../../hooks/Firebase';
import CareerShowcase from '../CareerShowcase';

import JobIcon from '../../statics/Images/JobIcon.svg';

/**
 * Career component wrapper with career showcase for user profile
 * @name Career
 */
const Career = () => {
  const [{ isLoading }, { updateCareer }] = useUserCareer();
  const [state, dispatch] = useContext(StorageContext);

  return (
    <div className='banner-container'>
      <img className='banner-icon' src={JobIcon} />
      <h3 className='content-margin-base'>Job history</h3>
      <p className='content-margin-base'>Add your relevant work experience now and receive better jobs.</p>
      <div className='career-showcase'>
        <CareerShowcase jobs={state.career} isLoading={isLoading} dispatch={dispatch} updateCareer={updateCareer} />
      </div>
    </div>
  );
};

export default Career;
