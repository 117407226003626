import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ModalBaseContainer from '../ModalBaseContainer';
import SkillsForm from '../../pages/SignUp/Skills/SkillsForm';
import WarningDialog from '../JobsList/WarningDialog';

/**
 * Modal container for add/edit skills
 * @name SkillsModal
 *
 * @param {bool} show Defines if modal is shown or not
 * @param {function} onClose Handler to close the modal
 * @param {array} skills Skills user saved already
 */
const SkillsModal = ({ show, onClose, skills, closeParentModal }) => {
  const [showWarningModal, setShowWarningModal] = useState(false);

  const onFormSubmitHandler = () => {
    onClose();
    if(closeParentModal){
      closeParentModal();
    }
  };

  const onCancelHandler = () => {
    setShowWarningModal(!showWarningModal);
  };

  const cancelWarning = () => {
    setShowWarningModal(false);
  };

  const skipEditing = () => {
    setShowWarningModal(false);
    onClose();
  };

  return (
    <ModalBaseContainer show={show} onClose={onCancelHandler}>
      <h2>{skills?.length ? 'Edit' : 'Add'} skills</h2>
      <SkillsForm onFormSubmit={onFormSubmitHandler}/>

      <WarningDialog
        show={showWarningModal}
        cancelAction={cancelWarning}
        skipAction={skipEditing}
      />
    </ModalBaseContainer>
  );
};
ModalBaseContainer.propTypes = { skills: PropTypes.array };

export default SkillsModal;
