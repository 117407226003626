import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.scss';

import { FirebaseContext } from '../../../components/Firebase';
import { StorageContext } from '../../../components/Storage';
import { useAnalytics } from '../../../hooks/Firebase';

import useToasts from '../../../hooks/useToasts';

import BackIcon from '../../../statics/Images/BackIcon';
import Button from '../../../components/Button';

import TestQuestionsIndicator from '../../../components/TestQuestionsIndicator';
import TestSlider from '../../../components/TestSlider';
import TestSingleChoice from '../../../components/TestSingleChoice';
import Loader from '../../../statics/Images/Loader';
import ToucanEQtest from '../../../statics/Images/Illustrations/ToucanEQtest.svg';

import ConfirmCloseModal from '../ConfirmCloseModal';

const EQSurveyOverview = () => {
  const [URL_GET_SURVEY, URL_GET_SURVEY_RESULTS] = !process.env.REACT_APP_USE_EMULATORS ? [
    new URL(`https://europe-west1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/getSurvey`),
    new URL(`https://europe-west1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/getSurveyResults`),
  ] : [
    new URL('http://localhost:5001/hero-career-coach-dev/europe-west1/getSurvey'),
    new URL('http://localhost:5001/hero-career-coach-dev/europe-west1/getSurveyResults'),
  ];

  const { pageViewEvent, errorEvent, clickEvent, TYPES: { CLICK }, customEvent } = useAnalytics();
  const firebase = useContext(FirebaseContext);
  const navigate = useNavigate();
  const { publishError, publishInfo } = useToasts();
  const [appContext, dispatch] = useContext(StorageContext);
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [resultsID, setResultsID] = useState('');
  const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false);

  const showConfirmCloseModalHandler = () => {
    setShowConfirmCloseModal(!showConfirmCloseModal);
  };

  useEffect(() => {
    document.title = 'EQ Test | HROS Career Coach';
    pageViewEvent();
    window.scrollTo(0, 0);
    publishInfo('Heads up! You can not go back, once you saved your answer!');
  }, []);

  useEffect(() => {
    if (appContext.isLoading) return;

    async function fetchNewTest() {
      setLoading(true);

      const userID = firebase.auth.currentUser.uid;

      let headers = new Headers();
      headers.append('Content-Type', 'application/x-www-form-urlencoded');

      const options = {
        method: 'GET',
        headers,
      };

      URL_GET_SURVEY.searchParams.append('surveyName', process.env.REACT_APP_CURRENT_EQ_SURVEY_NAME);
      URL_GET_SURVEY.searchParams.append('userID', userID);

      const payload = await fetch(URL_GET_SURVEY, options)
        .catch(
          () => {
            errorEvent('Fetching new EQ test failed');
            publishError('Something went wrong, please try again later');
            return 'error';
          }
        );

      if (payload === 'error') {
        navigate('/app/profile');
        return;
      }

      const results = await payload.json();

      if (payload.status === 409) {
        navigate('/app/eq-survey/results/' + appContext.eqResults?.find(survey => survey.surveyName === process.env.REACT_APP_CURRENT_EQ_SURVEY_NAME).surveyResultsID);
        publishError('You have already completed this test');
        return;
      }

      if (payload.status !== 200) {
        errorEvent('Fetching failed with status code: ' + payload.status);
        navigate('/app/profile');
        publishError('Something went wrong, please try again later');
        return;
      }

      setResultsID(results.surveyResultsID);

      const questionsArray = [];
      results.steps.forEach(step => {
        step.questions.forEach(question => {
          questionsArray.push(question);
        });
      });

      setQuestions(questionsArray);
      setLoading(false);
    }
    fetchNewTest();
  }, [appContext.isLoading]);

  const nextStep = () => {
    if (currentStep < questions.length - 1) setCurrentStep(currentStep + 1);
  };

  const completeEQtest = async (e) => {
    e.preventDefault();

    const answersPayload = Object.keys(answers).reduce((acc, curr) => {
      acc.push({
        questionID: curr,
        score: answers[curr],
      });
      return acc;
    }, []);

    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    const options = {
      method: 'POST',
      headers,
      body: JSON.stringify({
        surveyResultsID: resultsID,
        userID: firebase.auth.currentUser.uid,
        answers: answersPayload,
      }),
    };

    const resultsPayload = await fetch(URL_GET_SURVEY_RESULTS, options);
    const resultsData = await resultsPayload.json();
    const results = {
      surveyName: process.env.REACT_APP_CURRENT_EQ_SURVEY_NAME,
      results: resultsData,
      surveyResultsID: resultsID,
      surveyResultsRef: firebase.getCollectionItemRef('survey_results', resultsID),
    };
    await dispatch({
      type: 'SET_EQ_RESULTS',
      payload: [...appContext.eqResults, results],
    });

    navigate(`results/${resultsID}`);

  };

  const setAnwserHandler = (value) => {
    setAnswers({
      ...answers,
      [value.questionID]: value.score,
    });
  };

  return (
    <>
      {
        !appContext.isLoading &&
        <div className='auth-wrapper'>
          <div className='eq-test-tucan'>
            <img src={ToucanEQtest} alt='HROS logo' />
          </div>

          <div className='eq-test-wrapper'>
            <div className='survey-header'>
              <div className='survey-header-title-group'>
                <div className='eq-test-back-btn' onClick={() => showConfirmCloseModalHandler()}>
                  <BackIcon color='#303030' width='20' height='20' />
                </div>
                <h1 className='title'>EQ test</h1>
              </div>

              <div className='content-margin-large'>
                <TestQuestionsIndicator loading={loading} totalCount={questions.length} currentStep={currentStep} />
              </div>
            </div>

            <div className='eq-test-container'>
              {loading ?
                <div className='loading-test'>
                  <Loader />
                </div>
                :
                <form onSubmit={completeEQtest}>
                  {questions.map((question, idx) => (
                    currentStep === idx &&
                    <div key={idx + 100}>
                      <h2 className='content-margin-xlarge '>{question.text}</h2>

                      {question.type === 'slider' &&
                        <div className='content-margin-xlarge ' key={idx + 200}>
                          <TestSlider question={question} setAnwserHandler={setAnwserHandler} />
                        </div>
                      }

                      {question.type === 'single-choice' &&
                        <div className='content-margin-xlarge' key={idx + 300}>
                          <TestSingleChoice question={question} setAnwserHandler={setAnwserHandler} />
                        </div>
                      }
                    </div>
                  ))}

                  <div className='question-btn-wrapper'>
                    {currentStep === questions.length - 1 ?
                      <Button
                        theme={'btn-primary'}
                        type='submit'
                        disabled={answers[questions[currentStep]?.questionID] === undefined}
                        onClick={() => {
                          clickEvent(CLICK.TYPE.ADD, CLICK.CATEGORY.EQ_TEST, CLICK.LOCATION.LAST_QUESTION_EQ_TEST);
                          customEvent('eq_test_complete');
                        }}>Finish Test</Button>
                      :
                      <button
                        type='button'
                        className='btn btn-primary'
                        disabled={answers[questions[currentStep]?.questionID] === undefined}
                        onClick={() => {
                          nextStep();
                          clickEvent(CLICK.TYPE.ADD, CLICK.CATEGORY.EQ_TEST, CLICK.LOCATION.DURING_EQ_TEST);
                        }}>
                        Next Question
                      </button>
                    }
                  </div>
                </form>
              }
            </div>
          </div>
        </div>
      }

      <ConfirmCloseModal
        show={showConfirmCloseModal}
        onClose={showConfirmCloseModalHandler}
      />
    </>
  );
};

export default EQSurveyOverview;
