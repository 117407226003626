import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './styles.scss';

import { useAnalytics } from '../../hooks/Firebase';
import ErrorIcon from '../../statics/Images/ErrorIcon.svg';

const ErrorPage = ({ stack }) => {
  const { errorEvent } = useAnalytics();

  useEffect(() => {
    errorEvent(stack, true);
  }, []);

  return (
    <>
      <div className='error-page-background' />
      <div className='yelllow-wrapper'>
        <div className='vertical-horizonal-center'>
          <img className='error-page-image' src={ErrorIcon} />
          <h2 className='error-page-heading'>
            Ooops
          </h2>
          <p className='error-page-text'>
            Looks like something went wrong. Please try again or go back to the dashboard!
          </p>
        </div>
        <div className='error-page-nav-button'>
          <Link
            className='btn btn-primary'
            to={'/app/dashboard'}
            role='button'
          >
            Go back to dashboard
          </Link>
        </div>
      </div>
    </>
  );
};

ErrorPage.propTypes = { stack: PropTypes.string };

export default ErrorPage;
