import React from 'react';
import './styles.scss';

const StartupFitResultsScoreBoard = ({ results }) => {

  return (
    <div className='score-board-container'>
      {
        results.startupFitResults.slice(1).map((doc, i) => (
          <div className={i%2 !== 0 ? 'score-board-item score-board-item-shadow' : 'score-board-item'} key={i}>
            <div className='score-board-item-title'>
              <p className='score-board-title'>{doc.label}</p>
              <p className='score-board-score'>{doc.score}%</p>
            </div>
            <div className='score-board-text-container'>
              <p className='score-board-text'>{doc.scoreText}</p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default StartupFitResultsScoreBoard;
