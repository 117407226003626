import { useContext } from 'react';
import { StorageContext } from '../../components/Storage';

/**
 * Custom hook to publish messages on toast
 *
 * @typedef {Hook} useToasts
 *
 */

const useToasts = () => {
  const [, dispatch] = useContext(StorageContext);

  const publish = async (type, message) => {
    await dispatch({
      type: 'ADD_TOASTS',
      payload:{
        message,
        type,
      },
    });
  };

  /**
   * Sets success message
   * @method publishSuccess
   * @param {string} message Message to be displayed on the toast
   */
  const publishSuccess = async (message) => {
    await publish('SUCCESS', message);
  };

  /**
   * Sets error message
   * @method publishError
   * @param {string} message Message to be displayed on the toast
   */
  const publishError = async (message) => {
    await publish('ERROR', message);
  };

  /**
   * Sets warning message
   * @method publishWarning
   * @param {string} message Message to be displayed on the toast
   */
  const publishWarning = async (message) => {
    await publish('WARNING', message);
  };

  /**
   * Sets info message
   * @method publishInfo
   * @param {string} message Message to be displayed on the toast
   */
  const publishInfo = async (message) => {
    await publish('INFO', message);
  };

  /**
   * Publish a foreground push notification as toast
   * @method publishPushNotification
   * @param {import('firebase/messaging').MessagePayload} notification
   */
  const publishPushNotification = (notification) => {
    // TODO: Add specific style and process more than only notification body
    if (notification.data.type === 'NEW_MESSAGE'){
      publish('INFO', notification.notification.body);
    }

  };

  return {
    publishSuccess,
    publishError,
    publishWarning,
    publishInfo,
    publishPushNotification,
  };
};

export default useToasts;
