import React from 'react';

import CompleteIcon from '../../../../../statics/Images/CompleteIcon.svg';
import ExpansionText from '../../../../../components/ExpansionText';

/**
 * List with completed steps
 *
 * @name CompletedStepsList
 * @memberof CompleteProfileList
 *
 * @param {Array, <Object>} completedSteps Completed steps list
 *
 */
const CompletedStepsList = ({ completedSteps }) => {
  return (
    <>
      {
        completedSteps.map((doc,i) => (
          <li className='item-container completed' key={i}>
            <div className='item-left-wrapper'>
              <img src={CompleteIcon} alt='Tick' />
            </div>
            <div className='item-right-wrapper'>
              <div className='item-right-title'>
                <h2>{doc.title}</h2>
              </div>
              <ExpansionText text={doc.text} />
            </div>
          </li>
        ))
      }
    </>
  );
};

export default CompletedStepsList;
