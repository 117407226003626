import { useState, useContext } from 'react';
import { FirebaseContext } from '../../components/Firebase';

// TODO: Should possibly be refactored and merged with the similar useElasticSearchSkills hook
const useElasticSearch = () => {
  const firebase = useContext(FirebaseContext);
  const [elasticSearchState, updateElasticSearchState] = useState({
    results: [],
    lastQuery: '',
    isFetching: false,
  });

  /**
   * Function to fetch job titles based on a query
   * @param {string} query - search string
   */
  const fetchJobTitles = async (query) => {

    const trimmed = query.trim();

    // Minimum length is 3 characters, reset results:
    if (trimmed.length < 3) {
      updateElasticSearchState(s => ({
        ...s,
        lastQuery: trimmed,
        results: [],
      }));
      return;
    }

    // If lastQuery is the same as current, no need to search again:
    if (elasticSearchState.lastQuery === trimmed) {
      return;
    }

    // Update state to indicate that a search is in progress
    updateElasticSearchState(s => ({
      ...s,
      isFetching: true,
    }));

    // Prepare the payload for Elasticsearch search
    const payload = {
      query: {
        multi_match: {
          query,
          type: 'most_fields',
          fields: [
            'jobTitle',
            'jobTitle.full^2',
          ],
        },
      },
    };

    // Send a POST request to Elasticsearch to fetch search results
    const results = await fetch('https://search.hros.io/jobtitles_v2/_search?size=5', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    // Parse the results into an array of objects
    const resultsObj = await results.json();
    const resultsArray = [];
    resultsObj.hits.hits.forEach(item => {
      resultsArray.push(
        {
          id: item._id,
          name: item._source.jobTitle,
          associatedJobs: item._source.associatedJobs,
          relevance: item._score,
        }
      );
    });

    // Update state with the fetched results and set isFetching to false
    updateElasticSearchState(s => ({
      ...s,
      results: resultsArray,
      isFetching: false,
    }));
  };

  /**
   * Handle storing of elasticseach data into firestore to have valid references
   * @param {object} jobTitleData - Raw json response from elasticsearch
   * */
  const jobTitleSelected = async (jobTitleData) => {
    const jobOccupationData = jobTitleData.associatedJobs[0];

    // Fetch job occupation and title data from firestore
    const jobOccupationSnapshot = await firebase.getJobOccupationByName(jobOccupationData.name);
    const jobTitleSnapshot = await firebase.getJobTitleByName(jobTitleData.name);

    const sortedJobOccupationsSnapshot = jobOccupationSnapshot.docs.sort((a, b) => a.data().createdAt - b.data().createdAt);

    let jobOccupationRef;

    if (jobOccupationSnapshot.size >= 1) {
      jobOccupationRef = sortedJobOccupationsSnapshot[0].ref;
    }

    if (jobOccupationSnapshot.size === 0) {
      // Add job occupation to firestore if it doesn't exist
      jobOccupationRef = await firebase.addCollectionItem('job_occupations', {
        createdAt: firebase.convertDate(new Date()),
        ...jobOccupationData,
      });
    }

    let jobTitleRef;
    if (jobTitleSnapshot.size === 1) {
      jobTitleSnapshot.forEach(doc => {
        jobTitleRef = doc.ref;
        if (!doc.data().provider.includes('elasticsearch')) {
          // Add Elasticsearch as a provider for the job title if it's not already included
          firebase.addJobTitleProvider(jobTitleRef, 'elasticsearch');
        }
      });
    }

    if (jobTitleSnapshot.size === 0) {
      // Add job title to firestore if it doesn't exist
      jobTitleRef = await firebase.addCollectionItem('job_titles', {
        name: jobTitleData.name,
        provider: ['elasticsearch'],
        createdAt: firebase.convertDate(new Date()),
        jobOccupationName: jobOccupationData.name,
        jobOccupationID: jobOccupationRef.id,
        jobOccupationRef,
        ealsticSerachID: jobTitleData.id,
      });
    }

    return [
      jobTitleRef,
      {
        jobOccupationRef,
        jobOccupationName: jobOccupationData.name,
      },
    ];
  };

  /** Function to clear search results */
  const clearResults = () => {
    updateElasticSearchState(s => ({
      ...s,
      results: [],
    }));
  };

  return {
    fetchJobTitles,
    jobTitleSelected,
    clearResults,
    elasticSearchState,
  };
};

export default useElasticSearch;
