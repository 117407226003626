import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { AnimatePresence } from 'framer-motion';

import './styles.scss';

import CalendarModal from './CalendarModal';

import CalendarIcon from '../../statics/Images/CalendarIcon';
/**
 * Custom that month and year picker that sets with on change a new Date () object
 *
 * @typedef MonthYearPicker
 *
 * @param {bool} disabled
 * @param {func} onChange Sets the state with the changes on the date picker
 * @param {string} name
 * @param {object} value Value passed with a date that can pre fill the date picker
 * @param {string} placeholder To be displayed by the input
 * @param {bool} error Display component in error state
 *
 */

const MonthYearPicker = (props) => {
  const {
    disabled,
    onChange,
    name,
    value,
    placeholder,
    error,
  } = props;

  const [showCalendar, setShowCalendar] = useState(false);

  const [monthSelected, setMonthSelected] = useState(null);
  const [yearSelected, setYearSelected] = useState(null);

  const [changedbyUser, setChangedbyUser] = useState(true);

  useEffect (()=> {
    if (value){
      setMonthSelected(value.toDate().getMonth());
      setYearSelected(value.toDate().getFullYear());
    }
  }, []);

  useEffect (() => {
    if (!disabled && (typeof value === 'undefined' || value === null)) {
      setChangedbyUser(false);
    }
  }, [value, monthSelected, yearSelected, disabled]);

  useEffect (() => {
    if (disabled) {
      onChange({
        target: {
          name,
          value: null,
        },
      });
    }
  }, [disabled]);

  const openCalendarHandler = () => {
    if (!disabled && (typeof value === 'undefined' || value === null)) {
      setChangedbyUser(false);
      const today = new Date();
      setMonthSelected(today.getMonth());
      setYearSelected(today.getFullYear());
      onChange({
        target: {
          name,
          value: new Date( today.getFullYear(), today.getMonth()),
        },
      });
    }
    setShowCalendar(!showCalendar);
    setChangedbyUser(true);
  };

  const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

  return (
    <div className='date-input-wrapper'>
      <div className='month-year-picker-container'>
        { !changedbyUser && !disabled &&
          <div className='calendar-icon'>
            <CalendarIcon color={error ? '#ff5252' : undefined} />
          </div>
        }
        <input
          value={
            disabled ? 'Current job'
              :
              changedbyUser ? `${months[monthSelected]} ${yearSelected}`
                :
                placeholder
          }
          type='text'
          className={`${!changedbyUser && !disabled ? 'month-year-picker-input not-picked' : 'month-year-picker-input'}${error ? ' error' : ''}`}
          readOnly
          onClick={!disabled ? openCalendarHandler : null}
        />
      </div>
      <AnimatePresence exitBeforeEnter onExitComplete={() => setShowCalendar(false)}>
        { showCalendar &&
          <CalendarModal
            setShowCalendar={setShowCalendar}
            setMonthSelected={setMonthSelected}
            monthSelected={monthSelected}
            setYearSelected={setYearSelected}
            yearSelected={yearSelected}
            onChange={onChange}
            name={name}
            months={months}
          />
        }
      </AnimatePresence>
    </div>
  );
};

MonthYearPicker.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.object,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
};
MonthYearPicker.defaultProps = {
  disabled: false,
  error: false,
};

export default MonthYearPicker;
