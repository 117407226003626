import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import './styles.scss';
import BackIcon from '../../../statics/Images/BackIcon';
import { useAnalytics } from '../../../hooks/Firebase';
import ToucanEQtest from '../../../statics/Images/Illustrations/ToucanEQtest.svg';

/**
 * EQTestPreScreen ...
 *
 * @name EQTestPreScreen
 */
const EQTestPreScreen = () => {
  const { clickEvent, TYPES: { CLICK }, customEvent } = useAnalytics();
  const navigate = useNavigate();

  const closePageHandler = () => {
    navigate('/app/profile');
  };

  return (
    <div className='eq-prescreen'>
      <div className='eq-prescreen-tucan'>
        <img src={ToucanEQtest} alt='HROS logo'/>
      </div>
      <div className='eq-prescreen-header'>
        <div className='eq-prescreen-header-group'>
          <div onClick={closePageHandler}>
            <BackIcon color='#303030' width='20' height='20'/>
          </div>
          <h1 className='eq-prescreen-title'>EQ test</h1>
        </div>
      </div>
      <div className='eq-prescreen-wrapper'>
        <div className='eq-prescreen-container padding-layout-horisontal'>
          <h1 className='eq-prescreen-title-desktop'>EQ test</h1>
          <h2 className='content-margin-base'>What is Emotional Intelligence?</h2>
          <p className='content-margin-base'>Emotional Intelligence describes how clearly you understand and communicate emotions. These social skills affect your work life every day.</p>
          <h2 className='content-margin-base'>What you get</h2>
          <ul className='content-list'>
            <li className='content-margin-base'><span className='list-item-dot'></span>A better understanding of your own emotions and how they affect your thoughts and behaviour</li>
            <li className='content-margin-base'><span className='list-item-dot'></span>Insights into your emotional strengths and areas for improvement</li>
            <li className='content-margin-base'><span className='list-item-dot'></span>Ideas on how to improve focus and happiness through managing emotions</li>
          </ul>
          <Link
            className='btn btn-primary content-margin-xlarge'
            to='../../app/eq-survey'
            role='button'
            onClick={() => {
              clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.EQ_TEST, CLICK.LOCATION.EQ_TEST_PRESCREEN);
              customEvent('eq_test_start');
            }}>Start test</Link>
        </div>
      </div>
    </div>
  );
};

export default EQTestPreScreen;
