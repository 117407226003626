import React from 'react';

import EditProfileDetailsForm from './EditProfileDetailsForm';

/**
 * @name EditProfileOverview
 */
const EditProfileOverview = ({ setActiveTab }) => {

  return (
    <>
      <p className='content-margin-large'>This information will be displayed publicly so be careful what you share.</p>

      <EditProfileDetailsForm setActiveTab={setActiveTab} />

    </>
  );
};

EditProfileOverview.propTypes = {};

export default EditProfileOverview;
