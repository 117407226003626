import React from 'react';
import { Routes ,Route } from 'react-router-dom';

import ProfileDetails from './ProfileDetails';
import OnboardingFlow from './OnboardingFlow';
import Skills from './Skills';
import CompletePage from './CompletePage';
import CatchAll from '../../components/CatchAll';

/**
 * Create profile routes flow after initial login
 * @name SignupUp
 */
const SignupUp = () => {
  return (
    <>
      <Routes>
        <Route path='profile' element={<ProfileDetails />}/>
        <Route path='onboarding' element={<OnboardingFlow />} />
        <Route path='skills' element={<Skills />} />
        <Route path='complete' element={<CompletePage />} />
        <Route path='*' element={ <CatchAll />} />
      </Routes>
    </>
  );
};

export default SignupUp;
