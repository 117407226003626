import React from 'react';
import { Route, Routes } from 'react-router-dom';

import CommunityOverview from './CommunityOverview';
import CommunityItemOverview from './CommunityItemOverview';

/**
 * Community routing component
 * @name Community
 */
const Community = () => {

  return (
    <Routes>
      <Route path='/' element={ <CommunityOverview /> }/>
      <Route path=':community/:postId' element={<CommunityItemOverview />} />
    </Routes>
  );
};

export default Community;
