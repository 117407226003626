import React from 'react';

/**
 * Grid with months for datepicker and monthyearpicker,
 * should not be used by itself
 *
 *
 * @name MonthGrid
 *
 * @memberof DatePicker
 * @memberof MonthYearPicker
 * @see {@link DatePicker}{@link MonthYearPicker}
 * @protected
 *
 */

const MonthGrid = ({ selectMonthHandler, MONTHS, currentYear }) => {
  return (
    <div className='modal-entry-container'>
      { MONTHS.map((month, idx) => (
        currentYear && idx > new Date().getMonth() ?
          <p className='entry-list-item disabled'
            key={idx}>
            {month.substring(0,3)}
          </p> :
          <p className='entry-list-item'
            key={idx}
            onClick={() => selectMonthHandler(idx)}>
            {month.substring(0,3)}
          </p>
      ))}
    </div>
  );
};

export default MonthGrid;
