import React, { useContext, useState } from 'react';

import { FirebaseContext } from '../../../Firebase';

import InputWithLabel from '../../InputWithLabel';
import Button from '../../../Button';

const EMPTY_STATE = {
  name: null,
  text: null,
  slide_steps: 7,
  reverse_score: false,
};

const Slider = ({ type, clearState }) => {
  const firebase = useContext(FirebaseContext);
  const [state, setState] = useState(EMPTY_STATE);

  const onChange = (e) => {
    let value = e.target.value;

    if (e.target.type === 'number') {
      value = e.target.valueAsNumber;
    }

    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  const onSubmit = (e) => {
    const scoring = [];

    for (let i = 0; i < state.slide_steps; i++) {
      scoring.push({
        label: state[`option_${i}_text`] || null,
        score: i,
      });
    }

    e.preventDefault();
    firebase.addCollectionItem('survey_questions', {
      createdAt: firebase.convertDate(new Date()),
      name: state.name,
      text: state.text,
      type: type,
      scoring: state.reverse_score ? scoring.reverse() : scoring,
    });

    setState(EMPTY_STATE);
    // Clears the state of the parent component
    clearState({ type: null });
  };

  const onReverseScore = (e) => {
    setState({
      ...state,
      reverse_score: e.target.checked,
    });
  };

  const renderSliderOptionsArray = () => {
    return (
      [...Array(state.slide_steps)].map((element, i) => (

        <div className='input-group content-margin-base' key={i}>
          <input
            name={`option_${i}_text`}
            type='text'
            onChange={onChange}
            label='First Option'
            className='input-group-item-large'
            required={i === 0 || i === 6}
            placeholder={ i === 0 || i === 6 ? `Label ${ i } (required)` : `Label ${ i }`}
          />

          <input
            id={`option_${i}_score`}
            name={`option_${i}_score`}
            className='input-group-item-small'
            type='number'
            onChange={onChange}
            value={i}
            disabled
          />

        </div>
      ))
    );
  };

  return (
    <>
      <form className='padding-layout-base' onSubmit={onSubmit}>
        <InputWithLabel
          className='content-margin-base'
          name='name'
          type='text'
          onChange={onChange}
          value={state.name}
        />
        <InputWithLabel
          className='content-margin-base'
          name='text'
          type='text'
          onChange={onChange}
          value={state.text}
        />
        <input
          id='slide_steps'
          name='slide_steps'
          className='input-group-item-small'
          type='number'
          onChange={onChange}
          min={0}
          value={state.slide_steps || 0}
        />
        <input
          type='checkbox'
          name='reverse_slider'
          id='reverse_slider'
          onChange={onReverseScore}
        />
        <label htmlFor='reverse_slider'>Reverse Slider Score</label>

        {
          state.slide_steps &&

          state.reverse_score ?
            renderSliderOptionsArray().reverse()
            :
            renderSliderOptionsArray()
        }

        <Button onClick={onSubmit} theme='btn-primary' type='submit'>Save</Button>
      </form>

    </>
  );
};

export default Slider;
