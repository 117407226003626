import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { FirebaseContext } from '../../../Firebase';
import useToasts from '../../../../hooks/useToasts';

import Button from '../../../Button';
import InputWithIcon from '../../../InputWithIcon';

import ModalBaseContainer from '../../../ModalBaseContainer';
import UserIcon from '../../../../statics/Images/Input/UserIcon';

const TASKS_CATEGORIES = [
  'looking_for_a_job',
  'improve_current_job',
];

const AddCommunityModal = () => {
  const firebase = useContext(FirebaseContext);
  const navigate = useNavigate();
  const { publishError, publishSuccess } = useToasts();

  const onClose = () => navigate('..');

  const onSubmit = async (event) => {
    event.preventDefault();

    if(
      !event.target.taskTitle.value ||
      !event.target.highlight2Text.value ||
      !event.target.highlight1Text.value ||
      !event.target.highlight1.value||
      !event.target.highlight2.value
    ) {
      publishError('Please fill all the fields');
      return;
    }

    const task = {
      createdAt: firebase.convertDate(new Date()),
      taskType: event.target.taskType.value,
      task: event.target.taskTitle.value,
      highlight_1: {
        highlightText: event.target.highlight1Text.value,
        highlightTitle: event.target.highlight1.value,
      },
      highlight_2: {
        highlightText: event.target.highlight2Text.value,
        highlightTitle: event.target.highlight2.value,
      },
    };

    try {
      await firebase.addDailyTask(task);
      publishSuccess('Task added successfully');
      onClose();

    } catch (error) {
      console.error(error);
      onClose();
    }

  };

  return (
    <ModalBaseContainer show={true} onClose={onClose}>
      <h2>Create daily task</h2>
      <form onSubmit={onSubmit}>
        <div className='content-margin-base'>
          <select name='taskType' id='taskType'>
            {
              TASKS_CATEGORIES.map ((category, i) => (
                <option key={i} value={category}>{category}</option>

              ))
            }
          </select>
          <InputWithIcon
            id='taskTitle'
            name='taskTitle'
            type='text'
            placeholder='Task title'
            icon={<UserIcon />}
          />
          <InputWithIcon
            id='highlight1'
            name='highlight1'
            type='text'
            placeholder='Highlight 1 title'
            icon={<UserIcon />}
          />
          <textarea
            placeholder='Highlight 1 description'
            name="highlight1Text"
            id="hightlight1Text"
            cols="30"
            rows="5"
          />
          <InputWithIcon
            id='highlight2'
            name='highlight2'
            type='text'
            placeholder='Highlight 2 title'
            icon={<UserIcon />}
          />
          <textarea
            placeholder='Highlight 2 description'
            name="highlight2Text"
            id="hightlight2Text"
            cols="30"
            rows="5"
          />
        </div>
        <div className='btn-inline-wrapper'>
          <Button theme='btn-primary-outlined' type='button' onClick={onClose}>Cancel</Button>
          <Button theme='btn-primary' type='submit'>Save</Button>
        </div>
      </form>
    </ModalBaseContainer>
  );
};

export default AddCommunityModal;
