/**
 * Checks if the browser supports notifications
 * @returns {Boolean}
 */
const isSupported = () => 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;

/**
 * Checks if Notifcation.permission is granted
 * @returns {Boolean}
 */
const hasPermissions = () => {
  if (isSupported()) {
    return Notification.permission === 'granted';
  }
  return false;
};

export { isSupported, hasPermissions };
