import React from 'react';
import './styles.scss';

import { useNavigate } from 'react-router-dom';
import ModalBaseContainer from '../../../ModalBaseContainer';

/**
 * @summary Modal to signout of the app
 * Modal to signout of the app, uses url instead of handlers to execute action.
 *
 * @name SignOutModal
 * @memberof SettingsList
 */
const SignOutModal = () => {
  const navigate = useNavigate();

  const onClose = () => navigate('..');
  const onLogout = () => navigate('/logout');

  return (
    <ModalBaseContainer show={true} onClose={onClose}>
      <h2>Log out from profile?</h2>
      <div className='signout-modal-spacer'/>
      <div className='btn-inline-wrapper'>
        <div className='btn btn-text' onClick={onClose}>Cancel</div>
        <div className='btn btn-primary' onClick={onLogout}>Log out</div>
      </div>
    </ModalBaseContainer>
  );
};

export default SignOutModal;
