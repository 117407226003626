import React from 'react';

import './styles.scss';

import DropDown from '../../../../Dropdown';
import InputWithLabel from '../../../InputWithLabel';

const SurveyFormStepQuestion = ({ questions, idx, step, surveyState, setSurveyState }) => {

  const onQuestionChange = (e, idx, qidx) => {
    const steps = surveyState.steps;
    const questions = steps[idx].questions;

    const value = e.target.name !== 'negativeAttribute' ? e.target.value : e.target.checked;

    if (typeof value === 'object') {
      questions[qidx] = {
        ...questions[qidx],
        questionID: e.target.value.id,
        questionRef: e.target.value.ref,
      };
    } else {
      questions[qidx] = {
        ...questions[qidx],
        [e.target.name]: value,
      };
    }

    steps[idx] = {
      ...steps[idx],
      questions,
    };

    setSurveyState({
      ...surveyState,
      steps,
    });
  };

  return (
    <>
      {
        step.questions.map((question, qidx) => (
          <div className='padding-layout-horisontal' key={qidx}>
            <InputWithLabel
              className='content-margin-small'
              label={`Facet ${qidx+1}`}
              name='facet'
              type='text'
              onChange={(e) => onQuestionChange(e, idx, qidx)}
              value={question.facet}
            />
            <div className='sub-facet-row'>
              <InputWithLabel
                className='content-margin-small sub-facet-name'
                label={`Sub-Facet ${qidx+1}`}
                name='subFacet'
                type='text'
                onChange={(e) => onQuestionChange(e, idx, qidx)}
                value={question.subFacet}
              />
              <label>
                <input
                  type='checkbox'
                  name='negativeAttribute'
                  id='negativeAttribute'
                  onChange={(e) => onQuestionChange(e, idx, qidx)}
                />
                Negative Attribute?
              </label>
            </div>
            <DropDown
              name='question'
              onChange={((e) => onQuestionChange(e, idx, qidx))}
              options={questions}
            />
          </div>
        ))
      }
    </>
  );
};

export default SurveyFormStepQuestion;
