import React from 'react';
import PropTypes from 'prop-types';

const MoreIcon = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.2449 8.31001C19.6035 8.31001 20.7049 7.20863 20.7049 5.85001C20.7049 4.49139 19.6035 3.39001 18.2449 3.39001C16.8863 3.39001 15.7849 4.49139 15.7849 5.85001C15.7849 7.20863 16.8863 8.31001 18.2449 8.31001Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.16484 8.31001C8.52346 8.31001 9.62483 7.20863 9.62483 5.85001C9.62483 4.49139 8.52346 3.39001 7.16484 3.39001C5.80622 3.39001 4.70483 4.49139 4.70483 5.85001C4.70483 7.20863 5.80622 8.31001 7.16484 8.31001Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.2449 20.6101C19.6035 20.6101 20.7049 19.5087 20.7049 18.1501C20.7049 16.7914 19.6035 15.6901 18.2449 15.6901C16.8863 15.6901 15.7849 16.7914 15.7849 18.1501C15.7849 19.5087 16.8863 20.6101 18.2449 20.6101Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.16484 20.6101C8.52346 20.6101 9.62483 19.5087 9.62483 18.1501C9.62483 16.7914 8.52346 15.6901 7.16484 15.6901C5.80622 15.6901 4.70483 16.7914 4.70483 18.1501C4.70483 19.5087 5.80622 20.6101 7.16484 20.6101Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

MoreIcon.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default MoreIcon;
