import { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../../components/Firebase';

/**
 *  * Custom hook to User career
 *
 * @typedef {Hook} useUserCareer
 * @deprecated by useFillState
 *
 * // TODO: Refactor so it can be deleted and use useFillState instead
 */
const useUserCareer = () => {
  const firebase = useContext(FirebaseContext);
  const [career, setCareer] = useState([]); // Data read from firestore
  const [isLoading, setIsLoading] = useState(false);
  const [update, updateCareer] = useState(null); // Data that should be written to firestore
  const [updateCompleted, setUpdateCompleted] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const user = (await firebase.getUser()).data();
        const career = user.career?.sort((a,b) => b.start_date.seconds - a.start_date.seconds) || [];

        // Return data sorted by start_date
        setCareer(career);
      } catch (error) {
        console.error(error);
      }

      setIsLoading(false);
    };

    const updateData = () => {
      try {
        setUpdateCompleted(false);

        firebase.updateUserCareer(update);

        // Reset update; state data will be refetched from firebase
        updateCareer(null);
        setUpdateCompleted(true);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();

    if (update) {
      updateData();
    }

  }, [update]);

  return [{
    data: career,
    isLoading,
  }, {
    updateCareer,
    updateCompleted,
  }];
};

export default useUserCareer;
