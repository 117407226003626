import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './styles.scss';

import { StorageContext } from '../Storage';

import InfoIcon from '../../statics/Images/InfoIcon';
import SuccessIcon from '../../statics/Images/SuccessIcon';
import WarningIcon from '../../statics/Images/WarningIcon';
import CloseIcon from '../../statics/Images/CloseIcon';

/**
 * Toast system
 *
 * @name Toast
 *
 * @example
 * // Expected notifications types:
 * // INFO,
 * // SUCCESS,
 * // WARNING,
 * // ERRROR
 * //
 * // Notifications shoud be passsed on the custom hook @see {@link useToasts}
 * // do not dispatch notifications directly to this component.
 *
 */

const Toast = () => {

  const [state, dispatch] = useContext(StorageContext);
  const [darkBg, setDarkBg] = useState();
  const [clearToastTrigger, setClearToastTrigger] = useState(false);
  const location = useLocation();

  useEffect(()=> {
    location.pathname.includes('career-prediction') || location.pathname.includes('startup-fit')
      ?
      setDarkBg(true)
      :
      setDarkBg(false);
  }, [location]);

  useEffect(() => {
    let toastId = '';
    if(state.toasts.length){
      toastId = setTimeout(() => setClearToastTrigger(!clearToastTrigger), 5000);
    }

    return () => clearTimeout(toastId);
  }, [state.toasts, clearToastTrigger]);

  useEffect(() => {
    if(state.toasts.length){
      deleteNotificationHandler(0);
    }
  },[clearToastTrigger]);

  const deleteNotificationHandler = (idx) => {
    dispatch({
      type: 'DELETE_TOASTS',
      payload: { idx },
    });
  };

  const renderIcon = (type) => {
    const iconStyle = darkBg ? '#fff': '#636363';
    switch(type){
      case 'INFO':
        return <InfoIcon color={iconStyle}/>;
      case 'SUCCESS':
        return <SuccessIcon color={iconStyle}/>;
      default:
        return <WarningIcon color={iconStyle}/>;
    }
  };

  return (
    <>
      {
        state.toasts.length
          ?
          <div className='toast-container'>
            {
              state.toasts.map((doc, i) => (
                <div key={i} className={'toast-message-container ' + doc.type.toLowerCase() + '-type' + (darkBg ? '-dark' : '')} >
                  {renderIcon(doc.type)}
                  <p>{doc.message}</p>
                  <div className='close-toast-wrapper' onClick={() => deleteNotificationHandler(i)}>
                    <CloseIcon color={darkBg ? '#fff': '#636363'} />
                  </div>
                </div>
              ))
            }
          </div>
          :
          null
      }
    </>
  );
};

export default Toast;
