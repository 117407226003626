import React from 'react';
import { Routes, Route } from 'react-router-dom';

import NavBar from '../NavBar';
import Content from '../../pages/Content';
import Settings from '../../pages/Content/Profile/Settings';
import StartupFit from '../../pages/StartupFit';
import Notifications from '../../pages/Content/Notifications';
import CatchAll from '../CatchAll';

/**
 * Main app container with a header and a navbar rendered conditionally
 * @name MainAppContainer
 */
const MainAppContainer = () => {

  return (
    <div className='content-wrapper'>
      <NavBar />
      <Routes>
        <Route path='/*' element={<Content />} />
        <Route path='settings/*' element={<Settings />} />
        <Route path='startup-fit/*' element={<StartupFit />} />
        <Route path='notifications/*' element={<Notifications />} />
        <Route path='*' element={ <CatchAll />} />
      </Routes>
    </div>
  );
};

export default MainAppContainer;
