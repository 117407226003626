import { useContext } from 'react';
import { StorageContext } from '../../components/Storage';
import { FirebaseContext } from '../../components/Firebase';

/**
 * Custom hook to enable push notifications and publish as toasts
 * @typedef {Hook} usePushNotifications
 *
 * @param {Function} publishToast Callback to publish push notifications as toast
 * @returns {Function} Enable push notifications globally
 */
const usePushNotifications = (publishToast) => {
  const [state, dispatch] = useContext(StorageContext);
  const firebase = useContext(FirebaseContext);

  const enablePushNotifications = () => {
    if (!firebase.messaging) {
      console.info('Push notifications are not supported in your browser');
      return;
    }

    firebase.enableMessaging()
      .then(async token => {
        if (token && token !== state.profile.registeredDevice) {
          firebase.updateUser({ registeredDevice: token });
          dispatch({
            type: 'SET_PROFILE',
            payload: {
              ...state.profile,
              registeredDevice: token,
            },
          });
        }
      })
      .catch(err => console.error(err));

    const unsubscribe = firebase.receiveMessages(publishToast);
    return unsubscribe;
  };

  return enablePushNotifications;
};

export default usePushNotifications;
