import React from 'react';
import { Link } from 'react-router-dom';

import { useAnalytics } from '../../hooks/Firebase';

import BannerBaseContainer from '../BannerBaseContainer';
import TestIcon from '../../statics/Images/TestIcon.svg';

/**
 * Banner to access startup fit test.
 *
 * @name StartupFitBanner
 */
const StartupFitBanner = () => {
  const { clickEvent, TYPES: { CLICK }, customEvent } = useAnalytics();

  return (
    <BannerBaseContainer>
      <img className='banner-icon' src={TestIcon} />
      <h3 className='content-margin-base'>Startup Fit Test</h3>
      <p className='content-margin-base'>Ready to become an entrepreneur? You haven’t taken the test yet.</p>
      <Link className='btn btn-primary' to='../../startup-fit' role='button'
        onClick={() => {
          clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.STARTUP_FIT_TEST);
          customEvent('startup_fit_test_start');
        }}>
        <div className='btn btn-primary content-margin-base'>Take the test</div>
      </Link>
    </BannerBaseContainer>
  );
};

export default StartupFitBanner;
