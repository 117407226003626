import React from 'react';
import PropTypes from 'prop-types';

const InfoIcon = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 1.33668C14.109 1.33668 16.1707 1.96208 17.9242 3.13378C19.6778 4.30548 21.0445 5.97087 21.8516 7.91934C22.6587 9.86781 22.8699 12.0118 22.4584 14.0803C22.047 16.1488 21.0314 18.0488 19.5401 19.5401C18.0488 21.0314 16.1488 22.047 14.0803 22.4584C12.0118 22.8699 9.86777 22.6587 7.9193 21.8516C5.97084 21.0445 4.30546 19.6778 3.13376 17.9242C1.96206 16.1707 1.33667 14.109 1.33667 12C1.33986 9.17289 2.46434 6.46249 4.46342 4.46342C6.46249 2.46435 9.1729 1.33987 12 1.33668V1.33668ZM12 0C9.62663 0 7.30656 0.703785 5.33317 2.02236C3.35978 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65893 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6934 24 14.3734 24 12C24 8.8174 22.7357 5.76515 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0Z"
        fill={color}/>
      <path d="M12.0004 17.9884C11.8231 17.9884 11.6531 17.918 11.5278 17.7926C11.4025 17.6673 11.332 17.4973 11.332 17.32V10.5366C11.3327 10.3598 11.4034 10.1905 11.5287 10.0657C11.654 9.94092 11.8236 9.87085 12.0004 9.87085C12.1772 9.87085 12.3468 9.94092 12.4721 10.0657C12.5974 10.1905 12.6681 10.3598 12.6688 10.5366V17.3201C12.6687 17.4973 12.5983 17.6673 12.473 17.7926C12.3477 17.918 12.1777 17.9884 12.0004 17.9884V17.9884Z"
        fill={color}/>
      <path d="M12.0002 8.38538C12.5224 8.38538 12.9457 7.96207 12.9457 7.43988C12.9457 6.9177 12.5224 6.49438 12.0002 6.49438C11.478 6.49438 11.0547 6.9177 11.0547 7.43988C11.0547 7.96207 11.478 8.38538 12.0002 8.38538Z"
        fill={color}/>
    </svg>

  );
};

InfoIcon.propTypes = { color: PropTypes.string.isRequired };

export default InfoIcon;
