import React from 'react';

/**
 * Grid with days for datepicker and monthyearpicker,
 * should not be used by itself
 *
 *
 * @name DaysGrid
 *
 * @memberof DatePicker
 * @see {@link DatePicker}
 * @protected
 *
 */

const DaysGrid = ({ days, setSelectedDay }) => {
  return (
    <div className='modal-entry-container'>
      { days.map((day, idx) => (
        <p className='entry-list-item'
          key={idx}
          onClick={() => setSelectedDay(day)}>
          {day}
        </p>
      ))}
    </div>
  );
};

export default DaysGrid;
