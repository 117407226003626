import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

/**
 * Toggle Switch component to be used to enabled / disable options
 *
 * @name ToggleSwitch
 *
 * @param {boolean} isOn Specifiy if toggle switch is on or off
 * @param {function} onChange Function to be called when toggle switch is toggled
 * @returns {React.Component}
 */
const ToggleSwitch = ({ isOn, onChange }) => {
  return (
    <label className='switch'>
      <input type="checkbox" checked={isOn} onChange={onChange} />
      <span className='slider round'></span>
    </label>
  );
};

ToggleSwitch.propTypes = {
  isOn: PropTypes.bool,
  onChange: PropTypes.func,
};

export default ToggleSwitch;
