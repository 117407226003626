import React, { useContext, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.scss';

import { convertToWebp } from '../../../tools/ProfilePictureAux';
import { StorageContext } from '../../../components/Storage';
import { FirebaseContext } from '../../../components/Firebase';
import useToasts from '../../../hooks/useToasts';

import Button from '../../../components/Button';

import InputWithIcon from '../../../components/InputWithIcon';
import defaultAvatar from '../../../statics/Images/defaultAvatar.svg';
import DatePicker from '../../../components/DatePicker';
import GenderPicker from '../../../components/GenderPicker';

import UserIcon from '../../../statics/Images/Input/UserIcon';

import { useAnalytics } from '../../../hooks/Firebase';

/**
 * Form to input profile details, part of the signup flow
 * @name ProfileDetails
 * @memberof SignUp
 */
const ProfileDetails = () => {
  const sendUserUpdatedMessage = !process.env.REACT_APP_USE_EMULATORS ? `https://europe-west1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/sendUserUpdatedMessage` : 'http://localhost:5001/hero-career-coach-dev/europe-west1/sendUserUpdatedMessage';
  const firebase = useContext(FirebaseContext);
  const { publishError } = useToasts();
  const [appContext, dispatch] = useContext(StorageContext);
  const { pageViewEvent, clickEvent, TYPES: { CLICK } } = useAnalytics();

  const [state, setState] = useReducer(
    (prev, next) => {
      const nextEvent = {
        ...prev,
        ...next,
      };

      nextEvent.firstName = nextEvent.firstName.trim();
      nextEvent.lastName = nextEvent.lastName.trim();

      if (nextEvent.firstName && nextEvent.lastName && nextEvent.birthdate && nextEvent.gender) {
        nextEvent.btnDisabled = false;
      }

      // Disable button again if either first or last name is empty
      if (!nextEvent.firstName || !nextEvent.lastName || !nextEvent.birthdate || !nextEvent.gender) {
        nextEvent.btnDisabled = true;
      }

      return nextEvent;
    },
    {
      firstName: '',
      suggestedFirstName: '',
      lastName: '',
      suggestedLastName: '',
      birthdate: '',
      gender: '',
      btnDisabled: false,
      profilePicture: null,
      profilePictureData: null,
    }
  );

  const navigate = useNavigate();

  useEffect(() => {

    if (!appContext.profile.firstName && appContext.authenticationProvider === 'google.com') {

      const nameArray = firebase.auth.currentUser.providerData[0].displayName.split(' ');
      const firstName = nameArray[0];
      const lastName = nameArray.splice(1).join(' ');

      setState({
        firstName,
        lastName,
        suggestedLastName: lastName, // Used as default value for input since it needs to be static
        suggestedFirstName: firstName, // Used as default value for input since it needs to be static
      });

    }

    if (appContext.profile.firstName || appContext.profile.lastName) {
      setState({
        firstName: appContext.profile.firstName || '',
        lastName: appContext.profile.lastName || '',
      });
    }

    setState({
      birthdate: appContext.profile.birthdate || '',
      gender: appContext.profile.gender || '',
      profilePicture: appContext.profilePicture || null,
    });

  }, [appContext.isLoading]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const profileDetails = {
      birthdate: state.birthdate,
      gender: state.gender,
      firstName: state.firstName,
      lastName: state.lastName,
      username: `${state.firstName} ${state.lastName}`,
    };

    if (state.profilePictureData) {
      const fileName = firebase.auth.currentUser.uid;
      dispatch({
        type: 'SET_PROFILE_PICTURE',
        payload: state.profilePicture,
      });

      // Upload image to firebase storage and assign path to auth.currentUser
      await firebase.uploadImage('profile-pictures', fileName, state.profilePictureData);
    }

    firebase
      .updateUser(profileDetails)
      .then(() => {
        const firebaseId = firebase.auth.currentUser.uid;
        return fetch(sendUserUpdatedMessage, {
          method: 'POST',
          body: JSON.stringify({
            firebaseId,
            firstName: profileDetails.firstName,
            lastName: profileDetails.lastName,
          }),
        });
      })
      .catch((error) => {
        console.error(error);
        return publishError('Something went wrong, please try again later');
      });
    dispatch({
      type: 'SET_PROFILE',
      payload: {
        ...appContext.profile,
        ...profileDetails,
      },
    });

    navigate('../onboarding');
  };

  const updateAvatar = async (event) => {
    // Update only necessary when a file has been selected
    if (event.target.files.length === 1) {
      try {

        const webpImage = await convertToWebp(event.target.files[0]);
        setState({
          profilePicture: URL.createObjectURL(webpImage),
          profilePictureData: webpImage,
        });

      }
      catch (err) {
        console.error(err);
        return publishError('Something went wrong, please try again later');
      }
    }
  };

  // Set page title and send page view event
  useEffect(() => {
    document.title = 'Sign Up - Basic Information | HROS Career Coach';
    pageViewEvent();
  }, []);

  const onChange = (event) => {

    const today = new Date();
    if (event.target.name === 'birthdate' && event.target.value > today) {
      publishError('Birthdate cannot be in the future');
      return;
    }

    setState({ [event.target.name]: event.target.value });
  };

  return (
    <div className='signup-step'>
      <div className='signup-step-content'>
        <h1 className='content-margin-large'>Basic info</h1>
        <form className='signup-step-flex-grow' onSubmit={handleSubmit}>
          {
            // TODO: Add loading animation
            !appContext.isLoading && (
              <div className='signup-step-card'>
                <div className='backdrop-filter'></div>

                <div className="content-margin-large">
                  <InputWithIcon
                    key='firstName'
                    icon={<UserIcon />}
                    id='firstName'
                    name='firstName'
                    type='text'
                    placeholder='First Name *'
                    defaultValue={state.suggestedFirstName || appContext.profile.firstName}
                    onChange={onChange}
                    required={true}
                    autoComplete='given-name'
                  />
                </div>

                <div className="content-margin-large">
                  <InputWithIcon
                    icon={<UserIcon />}
                    id='lastName'
                    name='lastName'
                    type='text'
                    placeholder='Last Name *'
                    defaultValue={state.suggestedLastName || appContext.profile.lastName}
                    onChange={onChange}
                    required={true}
                    autoComplete='family-name'
                  />
                </div>

                <div className='content-margin-large'>
                  <p className='data-picker-label'>When were you born?*</p>
                  <DatePicker
                    onChange={onChange}
                    name='birthdate'
                    value={state.birthdate || null}
                    onClick={() => {
                      clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.BIRTHDATE, CLICK.LOCATION.BASIC_INFO);
                    }} />
                </div>

                <div className='content-margin-large'>
                  <p className='data-picker-label'>What is your gender?*</p>
                  <GenderPicker
                    onChange={onChange}
                    name='gender'
                    value={state.gender || null}
                    onClick={() => {
                      clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.GENDER, CLICK.LOCATION.BASIC_INFO);
                    }} />
                </div>

                <div className='content-margin-large'>
                  <p className='data-picker-label'>{appContext.profilePicture ? 'Current Photo' : 'Add a profile photo!'}</p>
                  <div className='avatar-upload-container'>
                    {
                      state.profilePicture ?
                        <img className='avatar avatar-uploaded' src={state.profilePicture} alt='Profile avatar' />
                        :
                        <img className='avatar' src={defaultAvatar} alt='Profile avatar' />
                    }
                    <label className='btn btn-primary avatar-label' htmlFor='profile-avatar-input'
                      onClick={() => {
                        clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.PHOTO, CLICK.LOCATION.BASIC_INFO);
                      }}>{appContext.profilePicture ? 'Change photo' : 'Add photo'}</label>
                  </div>
                  <input id='profile-avatar-input' type='file' accept='image/*' onChange={updateAvatar} />
                </div>
              </div>)
          }
          <div className='content-margin-xlarge'>
            <Button
              theme={'btn-primary'}
              type='submit'
              disabled={state.btnDisabled}
              onClick={() => {
                clickEvent(CLICK.TYPE.ADD, CLICK.CATEGORY.BASIC_INFO, CLICK.LOCATION.BASIC_INFO);
              }}>(1/3) Save</Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileDetails;
