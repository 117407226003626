import React, { useState, useEffect, useContext } from 'react';
import './styles.scss';
import { FirebaseContext } from '../Firebase';
import ModalBaseContainer from '../ModalBaseContainer';
import RatingStar from '../../statics/Images/RatingStar.js';
import useToasts from '../../hooks/useToasts';
import { useAnalytics } from '../../hooks/Firebase';

/**
 * Modal to give feedback
 *
 * @name GeneralFeedbackModal
 *
 * @param {bool} show Defines if modal is shown or not
 * @param {function} onClose Handler to close the modal
 * @param {object} feature Object {name: string, collection: string} with information about the feature feedback is for
 *
 */
const GeneralFeedbackModal = ({ show, onClose, feature }) => {
  const firebase = useContext(FirebaseContext);
  const { publishSuccess, publishError } = useToasts();
  const [rating, setRating] = useState();
  const [feedback, setFeedback] = useState('');
  const { clickEvent, TYPES: { CLICK } } = useAnalytics();
  const [storedFeedback, setStoredFeedback] = useState(JSON.parse(localStorage.getItem(`${feature.collection}_feedback`) || '[]'));

  useEffect(() => {
    setRating();
    setFeedback('');
  }, [show]);

  const ratingStarHandler = (e) => {
    setRating(e.target.value);
  };

  const onChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if(!rating) {
      publishError('Stars rating is required.');
    }
    else{
      const date = new Date();
      try {
        firebase.uploadFeedback(
          {
            rating,
            feedback,
            createdAt: firebase.convertDate(date),
            tag: `${feature.collection}_feedback`,
            userID: firebase.auth.currentUser.uid,
          }
        );
        publishSuccess('Thank you! We successfully received your review.');
        storedFeedback.push(firebase.auth.currentUser.uid);
        setStoredFeedback(storedFeedback);
        localStorage.setItem(`${feature.collection}_feedback`, JSON.stringify(storedFeedback));
      } catch (error) {
        console.error(error);
        publishError('Something went wrong, please try again later.');
      }
      onClose();
    }
  };

  return (
    <ModalBaseContainer show={show} onClose={onClose}>
      <h2>What do you think about the {feature.name}?</h2>
      <form action='submit' onSubmit={handleSubmit}>
        <div className='rating-stars'>
          {
            [1,2,3,4,5].map((i) => (
              <div key={i}>
                <input type='radio' name='star' className={`star${i} star-rating`} id={`star${i}`} value={i} onClick={ratingStarHandler}/>
                <label htmlFor={`star${i}`}><RatingStar fill={ rating >= i ? '#f3ca50' : '#cfcfcf'} /></label>
              </div>
            ))
          }
        </div>
        <textarea id='feedback' name='feedback' rows='4' cols='50' placeholder='Help us by leaving a review...' onChange={onChange}>
        </textarea>
        <button className='btn btn-primary'
          onClick={() => {
            clickEvent(CLICK.TYPE.ADD, CLICK.CATEGORY.REVIEW, CLICK.LOCATION.DASHBOARD);
          }}>Send review</button>
      </form>
    </ModalBaseContainer>
  );
};

export default GeneralFeedbackModal;
