import React, { useContext } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';

import { FirebaseContext } from '../../../Firebase';
import Button from '../../../Button';
import InputWithIcon from '../../../InputWithIcon';
import ModalBaseContainer from '../../../ModalBaseContainer';
import PasswordIcon from '../../../../statics/Images/Input/PasswordIcon';
import useToasts from '../../../../hooks/useToasts';
import { useAnalytics } from '../../../../hooks/Firebase';

/**
 * @summary Modal to change password
 * Modal to change password, uses url instead of handlers to execute action.
 *
 * @name ChangePasswordModal
 * @memberof SettingsList
 */
const ChangePasswordModal = () => {
  const navigate = useNavigate();
  const firebase = useContext(FirebaseContext);
  const { publishInfo, publishError } = useToasts();
  const location = useLocation();
  const { clickEvent, TYPES: { CLICK } } = useAnalytics();
  const onClose = () => navigate('..');

  const onSubmit = async (event) => {
    event.preventDefault();

    if (event.target.password_new.value !== event.target.password_new_confirm.value) {
      publishError('New password does not match');
      return;
    }

    try {
      await firebase.updateUserPassword(event.target.password_new.value, event.target.password_old.value);
    } catch (e) {
      if (e.code === 'auth/wrong-password') {
        publishError('Invalid password');
      }
      return;
    }

    navigate('..');
  };

  const resetPassword = () => {
    firebase.doSendPasswordResetEmail(firebase.auth.currentUser.email);
    publishInfo('You can find an email with the password reset link in you inbox!');
  };

  return (
    <>
      {/* Show can always be true, due to visibility handled by router */}
      <ModalBaseContainer show={true} onClose={onClose}>
        <h2>Password</h2>
        <form onSubmit={onSubmit}>
          <div className='content-margin-base'>
            <InputWithIcon
              id='password_old'
              name='password_old'
              type='password'
              placeholder='Current password'
              icon={<PasswordIcon />}
            />
            <div className='content-margin-base'>
              <Link className='link color-primary' to={location} onClick={resetPassword}>Forgot password?</Link>
            </div>
          </div>

          <div className='content-margin-base'>
            <InputWithIcon
              id='password_new'
              name='password_new'
              type='password'
              placeholder='New password'
              icon={<PasswordIcon />}
            />
          </div>
          <div className='content-margin-base'>
            <InputWithIcon
              id='password_new_confirm'
              name='password_new_confirm'
              type='password'
              placeholder='Confirm new password'
              icon={<PasswordIcon />}
            />
          </div>
          <div className='btn-inline-wrapper'>
            <Button theme='btn-text' type='button' onClick={onClose}>Cancel</Button>
            <Button theme='btn-primary' type='submit'
              onClick={() => {
                clickEvent(CLICK.TYPE.EDIT, CLICK.CATEGORY.PASSWORD, CLICK.LOCATION.SETTINGS);
              }}>Save</Button>
          </div>
        </form>
      </ModalBaseContainer>
    </>
  );
};

export default ChangePasswordModal;
