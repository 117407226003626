import React from 'react';
import { Routes ,Route } from 'react-router-dom';

import EQSurveyOverview from './EQSurveyOverview';
import EQTestPreScreen from './EQTestPreScreen';
import EQSurveyResults from './EQSurveyResults';
import CatchAll from '../../components/CatchAll';

/**
 * EQ test routes
 * @name EQtest
 */
const EQtest = () => {
  return (
    <Routes>
      <Route path='/' element={<EQSurveyOverview />} />
      <Route path='about' element={<EQTestPreScreen />} />
      <Route path='results/:surveyResultsID' element={ <EQSurveyResults /> } />
      <Route path='*' element={ <CatchAll />} />
    </Routes>
  );
};

export default EQtest;
