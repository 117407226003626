import React from 'react';

import EditProfileSkills from './EditProfileSkills';
/**
 * @name EditProfilePreferences
 */
const EditProfilePreferences = () => {

  return (
    <>
      <EditProfileSkills />
    </>
  );
};

EditProfilePreferences.propTypes = {};

export default EditProfilePreferences;
