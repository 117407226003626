import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

import OptionsPost from './OptionsPost';

import ReportContentModal from '../CommunityModals/ReportContentModal';
import ConfirmDeleteModal from '../CommunityModals/ConfirmDeleteModal';

/**
 * Shows post entry options
 * @name OptionsMenu
 * @param {Object} post - post data
 * @param {Object} comment - comment data
 */
const OptionsMenu = ({ post }) => {
  const [showReportContentModal, setShowReportContentModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const showReportContentModalHandler = () => {
    setShowReportContentModal(!showReportContentModal);
  };

  const showConfirmDeleteModalHandler = () => {
    setShowConfirmDeleteModal(!showConfirmDeleteModal);
  };

  return (
    <>
      <div className='options-menu'>
        <ul>
          <OptionsPost
            post={post}
            showReportContentModalHandler={showReportContentModalHandler}
            showConfirmDeleteModalHandler={showConfirmDeleteModalHandler}
          />
        </ul>
      </div>
      <ReportContentModal
        post={post}
        show={showReportContentModal}
        onClose={showReportContentModalHandler}
      />

      <ConfirmDeleteModal
        post={post}
        show={showConfirmDeleteModal}
        onClose={showConfirmDeleteModalHandler}
      />

    </>
  );
};

OptionsMenu.propTypes = {
  post: PropTypes.object.isRequired,
  comment: PropTypes.object,
  communityId: PropTypes.string,
};

export default OptionsMenu;
