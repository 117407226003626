import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { AnimatePresence } from 'framer-motion';

import './styles.scss';

import DatePickerModal from './DatePickerModal';
import BirthdayIcon from '../../statics/Images/BirthdayIcon';

/**
 * Custom that picker that sets with on change a new Date () object
 *
 * @name DatePicker
 *
 *
 * @param {func} onChange Sets the state with the changes on the date picker
 * @param {string} name
 * @param {object} value Value passed with a date that can pre fill the date picker
 *
 */

const DatePicker = ({ onChange, name, value }) => {
  const [baseDate, setBaseDate] = useState(value || new Date());
  const [showCalendar, setShowCalendar] = useState(false);
  const [userInteracted, setUserInteracted] = useState(false);
  const [daySelected, setDayselected] = useState(baseDate.getDate());
  const [monthSelected, setMonthSelected] = useState(baseDate.getMonth());
  const [yearSelected, setYearSelected] = useState(baseDate.getFullYear());

  useEffect(()=>{
    if (value) {
      setBaseDate(value);
      setDayselected(value.getDate());
      setMonthSelected (value.getMonth());
      setYearSelected(value.getFullYear());
    }
  },[value]);

  const openCalendarHandler = () => {
    setShowCalendar(!showCalendar);
  };

  return (
    <>
      <div className='data-picker' onClick={openCalendarHandler}>
        <div className='data-picker-icon'>
          <BirthdayIcon />
        </div>
        <div className='data-picker-text'>
          {
            ( !value && !userInteracted ?
              <p>Pick from calendar</p>
              :
              <p>{yearSelected}.{monthSelected < 9 ? `0${monthSelected + 1}` : monthSelected + 1}.{daySelected < 10 ? `0${daySelected}` : daySelected}</p>)
          }
        </div>
      </div>
      <AnimatePresence exitBeforeEnter onExitComplete={() => setShowCalendar(false)}>
        {
          showCalendar &&
          <DatePickerModal
            setDayselected={setDayselected}
            daySelected={daySelected}
            setMonthSelected={setMonthSelected}
            monthSelected={monthSelected}
            setYearSelected={setYearSelected}
            yearSelected={yearSelected}
            onChange={onChange}
            name={name}
            setShowCalendar={setShowCalendar}
            setUserInteracted={setUserInteracted}
          />
        }
      </AnimatePresence>
    </>
  );
};

DatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  value: PropTypes.object,
};

export default DatePicker;
