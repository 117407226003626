import React, { useContext, useEffect, useState } from 'react';
import './styles.scss';

import { FirebaseContext } from '../Firebase';
import QuestionsForm from './QuestionsForm';
import SurveyForm from './SurveyForm';

const EMPTY_STATE = {
  tab: 'questions',
  questions: [],
  surveys: [],
  edit: [],
};

const SurveyAdmin = () => {
  const firebase = useContext(FirebaseContext);
  const [state, setState] = useState(EMPTY_STATE);

  const selectTab = (e) => {
    const name = e.target.getAttribute('name');
    if (['surveys', 'questions'].includes(name)) {
      setState({
        ...state,
        tab: name,
      });
    }
  };

  const onQuestionsChange = async (docs) => {
    const questions = [];
    docs.forEach(doc => {
      questions.push({
        id: doc.id,
        ref: doc.ref,
        ...doc.data(),
      });
    });

    setState((s) => {
      return {
        ...s,
        questions,
      };
    });
  };

  const onSurveysChange = async (docs) => {
    const surveys = [];
    docs.forEach(doc => {
      surveys.push({
        id: doc.id,
        ref: doc.ref,
        ...doc.data(),
      });
    });

    setState((s) => {
      return {
        ...s,
        surveys,
      };
    });
  };

  useEffect(async () => {
    const unsubscribeQuestions = firebase.subscribeCollectionListener('survey_questions', onQuestionsChange);
    const unsubscribeSurveys = firebase.subscribeCollectionListener('surveys', onSurveysChange);

    return () => {
      unsubscribeQuestions();
      unsubscribeSurveys();
    };
  }, []);

  const editQuestion = (i) => {
    setState({
      ...state,
      edit: state.questions[i],
    });
  };

  return (
    <div className='padding-layout-horisontal'>
      <h1 className='padding-layout-base'>
        Survey Admin
      </h1>
      <div className='padding-layout-base admin-tabs'>
        <h2 name='surveys' onClick={selectTab}>Surveys</h2>
        <h2 name='questions' onClick={selectTab}>Questions</h2>
      </div>
      <div className='padding-layout-base'>
        {
          state[state.tab].length ?
            <table className='admin-list'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Text</th>
                </tr>
              </thead>
              <tbody>
                {
                  state[state.tab].map((q, i) =>
                    <tr key={i} onClick={() => editQuestion(i)}>
                      <td>
                        {q.name}
                      </td>
                      <td>
                        {q.type}
                      </td>
                      <td>
                        {q.text}
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table> :
            <div className='padding-layout-base'>No {state.tab} yet</div>
        }
      </div>
      {
        state.tab === 'surveys' && <SurveyForm questions={state.questions} />
      }
      {
        state.tab === 'questions' && <QuestionsForm editQuestion={state.edit}/>
      }
    </div>
  );
};

export default SurveyAdmin;
