import React, { useState } from 'react';
import PropTypes from 'prop-types';

import GenderIcon from '../../statics/Images/GenderIcon';

import { AnimatePresence } from 'framer-motion';

import GenderPickerModal from './GenderPickerModal';

/**
 * @name GenderPicker
 *
 * @param {func} onChange Sets the state with the changes on the gender picker
 * @param {object} value Value passed with a gender that can pre fill the gender picker
 *
 */

const GenderPicker = ({ onChange, value }) => {
  const [showGenderPicker, setShowGenderPicker] = useState(false);
  const [gender, setGender] = useState();

  const openGenderPickerHandler = () => {
    setShowGenderPicker(!showGenderPicker);
  };

  const setGenderHandler = (value) => {
    setGender(value);
    setShowGenderPicker();
    onChange({
      target:
      {
        name: 'gender',
        value,
      },
    });
  };

  return (
    <>
      <div className='data-picker' onClick={openGenderPickerHandler}>
        <div className='data-picker-icon'>
          <GenderIcon />
        </div>
        <div className='data-picker-text'>
          { value ?
            <p>{ value.specified ? value.specified : value.value }</p>
            :
            <p>Pick gender</p>
          }
        </div>
      </div>

      <AnimatePresence exitBeforeEnter onExitComplete={() => setShowGenderPicker(false)}>
        <GenderPickerModal
          setShowGenderPicker={setShowGenderPicker}
          show={showGenderPicker}
          onClose={openGenderPickerHandler}
          setGenderHandler={setGenderHandler}
          onChange={onChange}
          gender={gender}
          prefilledGender={value || null}
        />
      </AnimatePresence>
    </>
  );
};

GenderPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
};

export default GenderPicker;
