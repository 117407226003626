import React from 'react';

const ResultsSection = ({ erFlexibility, scores, eqResults }) => {
  return (
    <>
      <div className='result-section'>
        <div className='result-title-group'>
          <h3 className='content-margin-base'>Emotion Regulation Flexibility</h3>
          <h2>{Math.round(erFlexibility.score * 100)}%</h2>
        </div>
        {erFlexibility.heading && <h2 className='results-heading'>{erFlexibility.heading}</h2>}
        <p className='content-margin-base'>{erFlexibility.description}</p>
        <p>{erFlexibility.text}</p>
        { erFlexibility.type && <p className='content-margin-base'>Your emotion regulation type is: <strong style={{ textTransform: 'capitalize' }}>{erFlexibility.type}</strong></p> }
      </div>
      {
        Object.keys(scores).map((key, idx) => (
          <div key={idx} className='result-section'>
            <div className='result-title-group'>
              <h3 className='content-margin-base'>{key}</h3>
              <h2>{Math.round(eqResults[key].score * 100)}%</h2>
            </div>
            {eqResults[key].heading && <h2 className='results-heading'>{eqResults[key].heading}</h2>}
            <p className='content-margin-base'>{eqResults[key].description}</p>
            <p>{eqResults[key].text}</p>
          </div>
        ))
      }
    </>
  );
};

export default ResultsSection;
