import React from 'react';

import InputWithLabel from '../../InputWithLabel';
import Button from '../../../Button';
import SurveyFormStepQuestion from './SurveyFormStepQuestion';

const EMPTY_QUESTION = {
  facet: null,
  subFacet: null,
  negativeAttribute: false,
  questionID: null,
  questionRef: null,
};

const SurveyFormStep = ({ addStep, surveyState, setSurveyState, questions }) => {

  const onStepChange = (e, idx) => {
    const steps = surveyState.steps;
    steps[idx] = {
      ...steps[idx],
      [e.target.name]: e.target.value,
    };

    setSurveyState({
      ...surveyState,
      steps,
    });
  };

  const addQuestion = (idx) => {
    const steps = surveyState.steps;
    steps[idx] = {
      ...steps[idx],
      questions: [...steps[idx].questions, EMPTY_QUESTION],
    };

    setSurveyState({
      ...surveyState,
      steps,
    });
  };

  return (
    <>
      {
        surveyState.steps.map((step, idx) =>
          <div className='survey-step content-margin-large' key={idx}>
            <InputWithLabel
              className='content-margin-small'
              label={`Step Name ${idx+1}`}
              name='name'
              type='text'
              onChange={(e) => onStepChange(e, idx)}
              value={step.name}
            />
            <InputWithLabel
              className='content-margin-small'
              label={`Step Description ${idx+1}`}
              name='description'
              type='text'
              onChange={(e) => onStepChange(e, idx)}
              value={step.name}
            />
            <SurveyFormStepQuestion
              questions={questions}
              idx={idx}
              step={step}
              surveyState={surveyState}
              setSurveyState={setSurveyState}
            />
            <Button onClick={() => addQuestion(idx)} theme='btn-primary' type='button'>Add Question</Button>
          </div>
        )
      }
      <Button onClick={addStep} theme='btn-primary content-margin-base' type='button'>Add Step</Button>
    </>
  );
};

export default SurveyFormStep;
