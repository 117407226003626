import { useContext, useEffect, useState } from 'react';

import { FirebaseContext } from '../../components/Firebase';

/**
 * Custom hook to fetch from firestore and fill global state
 *
 * @typedef {Hook} useFillState
 *
 * @returns {object}
 *
 */

const useFillState = (dispatch) => {

  const firebase = useContext(FirebaseContext);
  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingStartupFitResults, setLoadingStartupFitResults] = useState(true);
  const [loadingCareerPrediction, setLoadingCareerPrediction] = useState(true);
  const [loadingProfileImg, setLoadingProfileImg] = useState(true);
  const [loadingEQResults, setLoadingEQResults] = useState(true);

  const loadUserData = async () => {
    setLoadingUser(true);
    // Fetch user data from firestore
    let snapshot = await firebase.getUser();

    if(!snapshot.exists()){
      await firebase.setUser(firebase.auth.currentUser.uid, {
        email: firebase.auth.currentUser.email || firebase.auth.currentUser.providerData[0].email,
        username: null,
        firstName: null,
        lastName: null,
        createdAt: firebase.convertDate(new Date(firebase.auth.currentUser.metadata.creationTime)),
        lastLoginAt: firebase.convertDate(new Date(firebase.auth.currentUser.metadata.lastSignInTime)),
        career: [],
        roles: [],
        notificationSettings: {
          pushEnabled: false,
          emailEnabled: true,
        },
      });

      snapshot = await firebase.getUser();
    }

    const userData = snapshot.data();
    const career = userData.career?.sort((a,b) => b.start_date.seconds - a.start_date.seconds) || [];

    const notificationSettings = userData.notificationSettings || {
      pushEnabled: false,
      emailEnabled: true,
    };

    if(userData.notificationSettings?.emailEnabled === undefined){
      notificationSettings.emailEnabled = true;
    }

    if(userData.notificationSettings?.pushEnabled === undefined){
      notificationSettings.pushEnabled = false;
    }

    loadProfilePicture(snapshot.id);

    dispatch({
      type: 'SET_AUTHENTICATION_PROVIDER',
      payload: firebase.auth.currentUser.providerData[0].providerId,
    });

    // Update profile in state
    dispatch({
      type: 'SET_PROFILE',
      payload: {
        birthdate: userData.birthdate ? userData.birthdate.toDate() : null,
        goal: userData.goal,
        username: userData.username,
        firstName: userData.firstName,
        lastName: userData.lastName,
        gender: userData.gender ? userData.gender : null,
        registeredDevice: userData.registeredDevice,
        roles: userData.roles || [],
        onboardingFlow: userData.onboardingFlow || [],
        skills: userData.skills || null, // Initialy is null so that [Save] button can be initialy disabled
        communities: userData.communities || [],
        dashboardTasks: userData.dashboardTasks || [],
        dailyTask: userData.dailyTask || null,
      },
    });

    dispatch({
      type: 'SET_CAREER_INFO',
      payload: career,
    });

    dispatch({
      type: 'SET_NOTIFICATION_SETTINGS',
      payload: notificationSettings,
    });

    setLoadingUser(false);
  };

  /** @method loadStartupFitResults Retrieves startup fit results from firestore */
  const loadStartupFitResults = async () => {
    setLoadingStartupFitResults(true);
    try {
      const snapshot = (await firebase.getUserStartupFitResults());
      if(snapshot.exists()){
        const snapshotData = snapshot.data();
        const startupFitResults = Object
          .keys(snapshotData)
          .reduce((acc, curr) => {
            if (acc.Upload?.seconds > snapshotData[curr].Upload.seconds) {
              return acc;
            } else {
              return snapshotData[curr];
            }
          }, {});

        dispatch({
          type: 'SET_STARTUP_FIT_RESULTS',
          payload: startupFitResults,
        });
      }
    } catch (error) {
      console.error(error);
    }
    setLoadingStartupFitResults(false);
  };

  /** @method loadCareerPredictionResults Retrieves career prediction results from firestore */
  const loadCareerPredictionResults = async () => {
    setLoadingCareerPrediction(true);
    try{
      const snapshot = (await firebase.getCareerPredictionResults());

      if (snapshot.exists()) {
        const snapshotData = snapshot.data();
        const snapshotSorted = Object
          .keys(snapshotData).sort().reduce(
            (obj, key) => {
              obj[key] = snapshotData[key];
              return obj;
            },{});

        const prediction = snapshotSorted[
          Object.keys(snapshotSorted)[Object.keys(snapshotSorted).length-1]
        ];
        dispatch({
          type: 'SET_CAREER_PREDICTION_RESULTS',
          payload: prediction,
        });
      }
    } catch (error) {
      console.error(error);
    }
    setLoadingCareerPrediction(false);
  };

  /** @method loadProfilePicture Retrieves user picture from storage */
  const loadProfilePicture = async (userID) => {
    setLoadingProfileImg(true);

    // Profile pictures can be found under profile-pictures/${userID}.webp by convention
    const [folder, fileName] = ['profile-pictures', userID];

    const url = await firebase.getImageUrl(folder, fileName);

    if (!url) {
      // Skip if user hasn't uploaded an profile picture yet
      dispatch({
        type: 'SET_PROFILE_PICTURE',
        payload: null,
      });

      setLoadingProfileImg(false);

      return;
    }

    const response = await fetch(url);
    const blob = await response.blob();

    // Load image from firebase and convert to blob
    // const response = await fetch(url);
    // const blob = await response.blob();

    // Update profilePicture in state
    dispatch({
      type: 'SET_PROFILE_PICTURE',
      payload: URL.createObjectURL(blob),
    });

    setLoadingProfileImg(false);
  };

  /** @method loadCustomClaims retrieves custom claims */
  const loadCustomClaims = async () => {
    const claims = await firebase.customClaims();
    dispatch({
      type: 'SET_CUSTOM_CLAIMS',
      payload: claims || [],
    });
  };

  const loadEqTestResults = async () => {
    setLoadingEQResults(true);

    const snapshot = await firebase.getEqTestResults();
    let eqResults = [];

    await snapshot.forEach( async (doc) => {

      const results = {
        results: doc.data().results,
        surveyName: doc.data().surveyData.name,
        surveyResultsID: doc.id,
        surveyResultsRef: doc.ref,
      };

      eqResults.push(results);

    });

    await dispatch({
      type: 'SET_EQ_RESULTS',
      payload: eqResults || {},
    });

    setLoadingEQResults(false);
  };

  /** Sets the global loading based on the status of the loading status of each fetching method */
  useEffect(() => {
    if (loadingUser || loadingCareerPrediction || loadingStartupFitResults || loadingProfileImg || loadingEQResults) {
      dispatch({
        type: 'SET_LOADING',
        payload: true,
      });
    } else {
      dispatch({
        type: 'SET_LOADING',
        payload: false,
      });
    }
  }, [loadingUser, loadingCareerPrediction, loadingStartupFitResults, loadingProfileImg, loadingEQResults]);

  return {
    loadUserData,
    loadStartupFitResults,
    loadCareerPredictionResults,
    loadCustomClaims,
    loadEqTestResults,
  };
};

export default useFillState;
