import React from 'react';
import { Link } from 'react-router-dom';

import ExpansionText from '../../../../../components/ExpansionText';
/**
 * List with missing steps
 *
 * @name TodoStepsList
 * @memberof CompleteProfileList
 *
 * @param {Array, <Object>} todoSteps Missing steps list
 * @param {Array, <Object>} completedSteps Completed steps list, only passed to continue the numbering started on CompletedStepsList
 *
 */
const TodoStepsList = ({ todoSteps }) => {
  return (
    <>
      {
        todoSteps.map((doc,i) => (
          // Checks for index 0 and applies different style
          <li className={ 'item-container to-do'} key={i}>
            <div className='item-left-wrapper'></div>
            <div className='item-right-wrapper'>
              <div className='item-right-title'>
                <Link to={doc.link} onClick={doc.onClick}>
                  <h2 className='item-title'>{doc.title}</h2>
                </Link>
                <Link to={doc.link} onClick={doc.onClick}>
                  <img src={doc.img}/>
                </Link>
              </div>
              <ExpansionText text={doc.text} />
            </div>
          </li>
        ))
      }
    </>
  );
};

export default TodoStepsList;
