import React from 'react';
import PropTypes from 'prop-types';

const RatinStar = ({ fill, height, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 31 30">
      <path fill={fill} d="M13.5979 1.8541C14.1966 0.0114759 16.8034 0.0114805 17.4021 1.8541L19.6535 8.78319C19.9213 9.60723 20.6892 10.1652 21.5556 10.1652H28.8413C30.7787 10.1652 31.5843 12.6444 30.0169 13.7832L24.1226 18.0656C23.4217 18.5749 23.1283 19.4776 23.3961 20.3017L25.6475 27.2308C26.2462 29.0734 24.1372 30.6056 22.5698 29.4668L16.6756 25.1844C15.9746 24.6751 15.0254 24.6751 14.3244 25.1844L8.43019 29.4668C6.86277 30.6056 4.75381 29.0734 5.35251 27.2307L7.60391 20.3017C7.87166 19.4776 7.57834 18.5749 6.87737 18.0656L0.983134 13.7832C-0.584293 12.6444 0.221262 10.1652 2.15871 10.1652H9.44438C10.3108 10.1652 11.0787 9.60723 11.3465 8.78319L13.5979 1.8541Z"/>
    </svg>
  );
};

RatinStar.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
};
RatinStar.defaultProps = {
  width: '31px',
  height: '30px',
};

export default RatinStar;
