import React from 'react';
import PropTypes from 'prop-types';

const SearchIcon = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 190 190" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M85.4401 165.31C129.551 165.31 165.31 129.551 165.31 85.4401C165.31 41.3291 129.551 5.57007 85.4401 5.57007C41.3291 5.57007 5.57007 41.3291 5.57007 85.4401C5.57007 129.551 41.3291 165.31 85.4401 165.31Z" stroke={color} strokeWidth="10.59" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M141.91 141.91L184.43 184.43" stroke={color} strokeWidth="10.59" strokeMiterlimit="10" strokeLinecap="round"/>
    </svg>
  );
};

SearchIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};
SearchIcon.defaultProps = {
  width: '24px',
  height: '24px',
};

export default SearchIcon;
