import React from 'react';
import PropTypes from 'prop-types';

import ModalBaseContainer from '../../ModalBaseContainer';

/**
 * @name WarningDialog
 *
 * @param {boolean} show Toggle modal visibilty
 * @param {function} skipAction Specify action when dialog confirmed
 * @param {function} cancelAction Specify action when dialog canceled
 */
const WarningDialog = ({ show, skipAction, cancelAction }) => {

  return (
    <ModalBaseContainer show={show} warningModal={true}>
      <h2>Skip editing?</h2>
      <p className='centred-text content-margin-large'><strong>All unsaved data will be lost.</strong></p>
      <div className='btn-inline-wrapper'>
        <div className='btn btn-primary' onClick={cancelAction}>Cancel</div>
        <div className='btn btn-text' onClick={skipAction}>Skip</div>
      </div>
    </ModalBaseContainer>
  );
};

WarningDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  skipAction: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
};

export default WarningDialog;
