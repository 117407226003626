import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { FirebaseContext } from '../../components/Firebase';
import Button from '../../components/Button';
import GoogleButton from '../../components/GoogleButton';
import InputWithIcon from '../../components/InputWithIcon';
import BackButton from '../../components/BackButton';
import EmailIcon from '../../statics/Images/Input/EmailIcon';
import PasswordIcon from '../../statics/Images/Input/PasswordIcon';
import { useAnalytics } from '../../hooks/Firebase';
import useToasts from '../../hooks/useToasts';
import RainbowTucan from '../../statics/Images/RainbowTucan.svg';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: '',
};

/**
 * Form to signin with email and password
 * @name SignInForm
 */
const SignInForm = () => {
  const sendUserSignedInMessageUrl = !process.env.REACT_APP_USE_EMULATORS ? `https://europe-west1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/sendUserSignedInMessage` : 'http://localhost:5001/hero-career-coach-dev/europe-west1/sendUserSignedInMessage';
  const firebase = useContext(FirebaseContext);
  const navigate = useNavigate();
  const [state, setState] = useState(INITIAL_STATE);
  const { pageViewEvent } = useAnalytics();
  const { publishError } = useToasts();

  /**
   * Firebase error handling, displaying with toast
   */
  useEffect(() => {
    if (state.error?.includes('auth/too-many-requests')) {
      publishError('Error: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.');
    }
    if (state.error?.includes('user-not-found')) {
      publishError('There is no account associated with this email address or the login method is incorrect.');
    }
    if (state.error?.includes('auth/wrong-password')) {
      publishError('Incorrect password.');
    }
  }, [state.error]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { email, password } = state;

    await firebase
      .doSignInWithEmailAndPassword(email, password)
      .then((authUser) => {
        firebase.updateUser({ lastLoginAt: firebase.convertDate(new Date(authUser.user.metadata.lastSignInTime)) });
        // send pubsub message of user sign in
        fetch(sendUserSignedInMessageUrl, {
          method: 'POST',
          body: JSON.stringify({
            email,
            password,
            firebaseId: authUser.user.uid,
          }),
        });
        navigate('/app');

      })
      .catch((err) => {
        console.error(err);
        setState({
          ...state,
          error: err.message,
        });
      });

  };

  const onChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
    if (state.error) {
      setState({
        ...state,
        error: null,
      });
    }
  };

  // Set page title and send page view event
  useEffect(() => {
    document.title = 'Sign In | HROS Career Coach';
    pageViewEvent();
  }, []);

  return (
    <div className='signup-auth-wrapper'>
      <div className='signup-tucan'>
        <img src={RainbowTucan} alt='HROS logo' />
      </div>
      <div className='signup-wrapper'>
        <div className='signup-back-btn'><BackButton color='#303030' /></div>
        <div className='signup-container'>
          <form className='logged-out-form' onSubmit={handleSubmit}>
            <h1 className='content-margin-xlarge'>Log in</h1>
            <h2>Welcome back to HROS!</h2>
            <div className='content-margin-large'>
              <InputWithIcon
                icon={<EmailIcon />}
                id='email'
                name='email'
                type='email'
                placeholder='E-mail'
                onChange={onChange}
                required={true}
                autoComplete='e-mail'
                error={state.error?.includes('user-not-found')}
              />
            </div>
            <div className='content-margin-large'>
              <InputWithIcon
                icon={<PasswordIcon />}
                id='password'
                name='password'
                type='password'
                placeholder='Password'
                onChange={onChange}
                required={true}
                error={state.error?.includes('auth/wrong-password')}
                autoComplete='current-password'
              />
            </div>

            <div className='content-margin-large'>
              <Link className='link color-primary' to='/login/reset-password'>Forgot password?</Link>
            </div>

            <div className='content-margin-xlarge'>
              <Button theme={'btn-primary'} type='submit'>Log in</Button>
            </div>
            <GoogleButton useOneTap />

            <p className='content-margin-large'>
              Don&apos;t have an account yet? <Link className='link color-primary' to='/signup'>Sign up</Link>
            </p>
          </form>

        </div>

        <div className='privacy-policy centred-text content-margin-large'>
          <p>By signing up you agree to our</p>
          <Link className='link color-primary' to='/privacy-policy'>Privacy Policy</Link>
        </div>
      </div>
    </div>
  );
};

export default SignInForm;
