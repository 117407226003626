import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import BackButton from '../../../components/BackButton';
import { useAnalytics } from '../../../hooks/Firebase';
import SkillsForm from './SkillsForm/index.js';

/**
 * Signup skills page
 * @name Skills
 * @memberof SignUp
 */
const Skills = () => {
  const navigate = useNavigate();
  const { pageViewEvent } = useAnalytics();

  useEffect(() => {
    document.title = 'Sign Up - Skills | HROS Career Coach';
    pageViewEvent();
  }, []);

  const skipInterestsFlow = () => {
    navigate('/create-profile/complete');
  };

  return (
    <div className='signup-step'>
      <div className='signup-header-container'>
        <div className='signup-step-header'>
          <BackButton url={'/create-profile/onboarding'} color='#303030'/>
          <button
            onClick={skipInterestsFlow}
            className='btn btn-secondary'>
            Skip step
          </button>
        </div>
      </div>
      <div className='signup-step-content'>
        <h1 className='content-margin-base'>What are your skills?</h1>
        <p className='content-margin-base'>We gather your skills so we can find the perfect career path for you.</p>
        <SkillsForm />
      </div>
    </div>
  );
};

export default Skills;
