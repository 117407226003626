import React, { useState, useContext, useEffect } from 'react';
import { StorageContext } from '../../../../components/Storage';
import { useAnalytics } from '../../../../hooks/Firebase';

import ModalBaseContainer from '../../../../components/ModalBaseContainer';
import { isEmptyObject } from '../../../../tools/IsEmptyObject';
import useToasts from '../../../../hooks/useToasts';

/**
 * Filter jobs modal
 * @name FilterJobsModal
 * @component
 * @category Pages
 * @subcategory JobMatching
 */
const FilterJobsModal = ({ show, onClose, changeFilterArray }) => {
  const { clickEvent, TYPES: { CLICK } } = useAnalytics();
  const [appContext] = useContext(StorageContext);
  const { publishError } = useToasts();

  const [state, setState] = useState({
    JobsFilter: [],
    filteredMatchingJobs: JSON.parse(localStorage.getItem('matching_jobs') || '[]'),
  });

  useEffect(() => {
    if(!appContext.isLoading) {
      const jobTitlesTemp = [];

      if(!isEmptyObject(appContext.careerPredictionResults)) {
        for (const [key] of Object.entries(appContext.careerPredictionResults.output)) {
          jobTitlesTemp.push(key);
        }
      }

      if(!jobTitlesTemp.includes(appContext.career[0].jobTitle)) {
        jobTitlesTemp.push(appContext.career[0].jobTitle);
      }

      setState(s => ({
        ...s,
        JobsFilter: jobTitlesTemp,
      }));
    }
  }, [appContext.isLoading]);

  const applyFilterHandler = async (event) => {
    event.preventDefault();
    changeFilterArray(state.filteredMatchingJobs);
    onClose();
  };

  const onChange = (event) => {

    if(!state.filteredMatchingJobs.includes(event.target.value)) {
      setState(s => ({
        ...s,
        filteredMatchingJobs: [...state.filteredMatchingJobs, event.target.value],
      }));
      return;
    }

    if(state.filteredMatchingJobs.length === 1 && state.filteredMatchingJobs.includes(event.target.value)) {
      publishError('You must select at least one job');
    }

    state.filteredMatchingJobs.splice(state.filteredMatchingJobs.indexOf(event.target.value), 1);

  };

  return (
    <ModalBaseContainer show={show} onClose={onClose}>
      <h2>Filter?</h2>
      <form onSubmit={applyFilterHandler}>
        <ul className='content-margin-base'>
          {
            state.JobsFilter && state.JobsFilter.map((job, i) => (
              <li key={i} className='report-radio-btn'>
                <input
                  onChange={onChange}
                  type='checkbox'
                  name={job}
                  value={job}
                  defaultChecked={state.filteredMatchingJobs.some(j => job === j)}
                />
                <span className='checked-icon report-radio-icon'></span>
                <span className='report-radio-label'>{job}</span>
              </li>
            ))
          }
        </ul>
        <button
          disabled={state.filteredMatchingJobs.length === 0} type='submit' className='btn btn-primary'
          onClick={() => {
            clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.CHANGE_FILTER, CLICK.LOCATION.MTCHING_RESULTS);
          }}>Send</button>
      </form>
    </ModalBaseContainer >
  );
};

export default FilterJobsModal;
