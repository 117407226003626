import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator, sendPasswordResetEmail, createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, onAuthStateChanged, updateProfile, signOut, deleteUser, reauthenticateWithCredential, EmailAuthProvider, updateEmail, updatePassword, signInWithRedirect, getRedirectResult, signInWithCredential, signInWithCustomToken } from 'firebase/auth';
import { getStorage, connectStorageEmulator, ref, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage';
import { getFirestore, connectFirestoreEmulator, doc, updateDoc, getDoc, getDocs, collection, addDoc, Timestamp, deleteDoc, query, where, orderBy, setDoc, limit, onSnapshot, increment, arrayRemove, arrayUnion, startAfter } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import { isSupported } from '../../tools/Notifications';

/**
 * Constants
 *
 * @readonly
 *
 * @const {object} config Config object with firebase keys
 *
 */
const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const vapidKey = process.env.REACT_APP_VAPID_KEY;

/**
 * Firebase class with connection methods
 *
 * @name Firebase
 *
 */

class Firebase {
  constructor() {
    // Initialize firebase connection
    initializeApp(config);

    // Initializes google analytics
    this.analytics = getAnalytics();
    // Initializes authentication
    this.auth = getAuth();
    // Initializes Firestore
    this.firestore = getFirestore();
    // Initializes Storage
    this.storage = getStorage();

    // Initializes Messaging
    if (isSupported()) {
      this.messaging = getMessaging();
    }

    // Initializes authentication with google
    this.googleProvider = new GoogleAuthProvider();
    this.googleProvider.addScope('email');

    if (process.env.REACT_APP_USE_EMULATORS) {
      console.debug('Connecting to firestore emulator');
      connectAuthEmulator(this.auth, 'http://localhost:9099', { disableWarnings: true });
      connectFirestoreEmulator(this.firestore, 'localhost', 8080);
      connectStorageEmulator(this.storage, 'localhost', 9199);
    }
  }

  /**
   * Enable push notifications and ask for permissions if not yet given
   * @method enableMessaging
   * @returns {Promise} Returns a promise with the user's token
   */
  enableMessaging = () =>
    getToken(this.messaging, { vapidKey: vapidKey });

  /**
   * Push Notification Listener
   * @method receiveMessages
   * @param {Function} publishMessage Callback function to publish message in frontend
   * @returns {Function} unsubscribe
   */
  receiveMessages = (publishMessage) =>
    onMessage(this.messaging, (payload) => publishMessage(payload));

  /**
   * Sends password reset request, reset sent via email
   * @method doSendPasswordResetEmail
   * @param {string} email User email
   */
  doSendPasswordResetEmail = (email) =>
    sendPasswordResetEmail(this.auth, email);

  /**
   * Creates user with email and password
   * @method doCreateUserWithEmailAndPassword
   * @param {string} email User email
   * @param {string} password User password
   */
  doCreateUserWithEmailAndPassword = (email, password) =>
    createUserWithEmailAndPassword(this.auth, email, password);

  /**
   * Signs in user with email and password
   * @method doSignInWithEmailAndPassword
   * @param {string} email User email
   * @param {string} password User password
   **/
  doSignInWithEmailAndPassword = (email, password) =>
    signInWithEmailAndPassword(this.auth, email, password);

  /**
   * Signs in user with google
   * @method doSigninWithGoogle
   **/
  doSigninWithGoogle = () =>
    signInWithRedirect(this.auth, this.googleProvider);

  getSignInWithGoogleResult = () =>
    getRedirectResult(this.auth);

  signInWithGoogle = (credential) =>
    signInWithCredential(this.auth, credential);

  doSignInWithCustomToken = (token) => signInWithCustomToken(this.auth, token)

  /**
   * Listener to check if the user is signed in
   * @method userSignedInListener
   * @param {func} setSignedIn Function to fill state with sign in status
   **/
  userSignedInListener = (setSignedIn) =>
    onAuthStateChanged(this.auth, (user) => {
      user ? setSignedIn(true) : setSignedIn(false);
    });

  /**
   * Signs out
   * @method doSignOut
   **/
  doSignOut = () => signOut(this.auth);

  /**
   * Deletes current user auth
   * @method deleteUser
   **/
  deleteUser = () => deleteUser(this.auth.currentUser);

  /**
   * Retrives current user ref on firestore
   * @method getCurrentUserRef
   **/
  getCurrentUserRef = () => doc(this.firestore, 'users', this.auth.currentUser.uid);

  updateAuthUser = ({ displayName }) => updateProfile(this.auth.currentUser, { displayName });

  /**
   * Update user email by reauthenticating with password
   * @method updateUserEmail
   * @param {string} newEmail
   * @param {string} password
   * @returns {Promise}
   */
  updateUserEmail = (newEmail, password) => {
    return reauthenticateWithCredential(this.auth.currentUser, EmailAuthProvider.credential(this.auth.currentUser.email, password)).then(() => {
      updateEmail(this.auth.currentUser, newEmail);
    });
  }
  /**
   * Update user password by reauthenticating with previous password
   * @method updateUserPassword
   * @param {string} newPassword
   * @param {string} oldPassword
   * @returns {Promise}
   */
  updateUserPassword = (newPassword, oldPassword) => {
    return reauthenticateWithCredential(this.auth.currentUser, EmailAuthProvider.credential(this.auth.currentUser.email, oldPassword)).then(() => {
      updatePassword(this.auth.currentUser, newPassword);
    });
  }

  /**
   * Retrives current user document fromm firestore
   * @method getUser
   **/
  getUser = () => getDoc(this.getCurrentUserRef());

  /**
   * Creates user document on firestore
   * @method setUser
   * @param {string} uid
   * @param {object} data
   * @example
   * // authUser.user.uid, {
   * //    username: username.trim(),
   * //    email,
   * //    createdAt: authUser.user.metadata.creationTime,
   * //    lastLoginAt: authUser.user.metadata.lastSignInTime,
   * // }
   **/
  setUser = (uid, data) => setDoc(doc(this.firestore, 'users', uid), data);

  /**
   * Updates user document on firestore
   * @method updateUser
   * @param {object} data
   * @example
   * // { lastLoginAt: authUser.user.metadata.lastSignInTime }
   **/
  updateUser = (data) => updateDoc(this.getCurrentUserRef(), data);

  updateTargetUser = (uid, data) => updateDoc(doc(this.firestore, 'users', uid), data);

  getUserCareer = () => getDocs(collection(this.firestore, `users/${this.auth.currentUser.uid}/career`));

  /**
   * Updates user career document on firestore
   * @method updateUserCareer
   * @param {Array.<Object>} career
   */
  updateUserCareer = (career) => updateDoc(this.getCurrentUserRef(), { career })

  customClaims = () => {
    const customs = new Promise((resolve, reject) => {
      this.auth.currentUser.getIdTokenResult().then((idTokenResult) => {
        resolve(idTokenResult.claims.roles);
      }).catch((error) => {
        reject(error);
        console.error(error);
      });
    });
    return customs;
  }

  /**
   * Converts date object to firestore date object with server timezone
   * @method convertDate
   * @param {object} date
   */
  convertDate = (date) => Timestamp.fromDate(date);

  /**
   * Retrieved profile img url from storage
   * @method getImageUrl
   * @param {string} folder if user have picture it's the uid
   * @param {string} name file name
   */
  getImageUrl = (folder, name) =>
    getDownloadURL(ref(this.storage, `${folder}/${name}.webp`))
      .then(url => url)
      .catch((err) => {
        if (err.code !== 'storage/object-not-found') {
          return Promise.reject(err);
        }
        return Promise.resolve(null); // File not found in storage, nothing to see here
      });

  /**
   * Uploads profile img url to storage
   * @method uploadImage
   * @param {string} folder if user have picture it's the uid
   * @param {string} name file name
   */
  uploadImage = (folder, name, file) => uploadBytes(ref(this.storage, `${folder}/${name}.webp`), file);

  /**
   * Fetches all results of a collection
   * @method allResultsFirestore
   * @param {string} dbCollection Collection name
   */
  allResultsFirestore = (dbCollection) => getDocs(query(collection(this.firestore, dbCollection)));

  subscribeCollectionListener = (dbCollection, onChange) => onSnapshot(query(collection(this.firestore, dbCollection)), onChange);

  /**
   * Searchs firestore collection based on query terms
   * @method searchFirestore
   *
   * @param {string} dbCollection Collection name
   * @param {string} fieldName Collection name
   * @param {string} conditionalTerm Collection name
   * @see {@link https://firebase.google.com/docs/firestore/query-data/queries}
   * @param {string} term Collection name
   *
   * @example
   * // firebase.searchFirestore('job_titles', 'name', '==', job_title);
   *
   */
  searchFirestore = (dbCollection, fieldName, conditionalTerm, term) => getDocs(query(collection(this.firestore, dbCollection), where(fieldName, conditionalTerm, term), limit(10)));

  /**
   * Searchs for specific job title base on the term passed
   // Simple because it can not handle typos in the term
   * @method allResultsFirestore
   * @param {string} term String with job term
   */
  simpleJobSearch = (term) => getDocs(query(collection(this.firestore, 'job_titles'), where('name', '>=', term), where('name', '<=', term + '\uf8ff'), limit(10)));

  getJobTitle = (id) => getDoc(doc(this.firestore, 'job_titles', id));

  getJobTitleByName = (jobTitle) => getDocs(query(collection(this.firestore, 'job_titles'), where('name', '==', jobTitle)));
  getJobOccupationByName = (jobOccupation) => getDocs(query(collection(this.firestore, 'job_occupations'), where('name', '==', jobOccupation)));

  addJobTitleProvider = (jobTitleRef, providerName) => updateDoc(jobTitleRef, { provider: arrayUnion(providerName) })
  /**
   * Skills
   */
  getSkillByName = (skillName) => getDocs(query(collection(this.firestore, 'skills'), where('name', '==', skillName)));

  updateUserSkills = (skills) => updateDoc(this.getCurrentUserRef(), { skills });

  getSkillByName = (skill) => getDocs(query(collection(this.firestore, 'skills'), where('name', '==', skill)));

  /**
   * Add item to collection
   * @method addCollectionItem
   * @deprecated preferably used by admin app
   *
   * @param {string} dbCollection Collection targeted
   * @param {object} data Data to save
   */
  addCollectionItem = (dbCollection, data) => addDoc(collection(this.firestore, dbCollection), data);

  /**
   * Getting a document from a collection.
   * @method getCollectionItem
   *
   * @param {string} dbCollection Collection identifier
   * @param {string} id Document identifier
   */
  getCollectionItem = (dbCollection, id) => getDoc(doc(this.firestore, dbCollection, id));

  getCollectionItemRef = (dbCollection, id) => doc(this.firestore, dbCollection, id)

  /**
   * Set item to collection by id
   * @method setCollectionItem
   *
   * @param {string} dbCollection Collection identifier
   * @param {string} id Document identifier
   * @param {object} data Data to save
   */
  setCollectionItem = (dbCollection, id, data) => setDoc(doc(this.firestore, dbCollection, id), data);

  /**
   * Add item to collection
   * @method deleteCollectionItem
   * @deprecated preferably used by admin app
   *
   * @param {string} dbCollection Collection targeted
   * @param {string} id ID of the item to be deleted
   */
  deleteCollectionItem = (dbCollection, id) => deleteDoc(doc(this.firestore, dbCollection, id))

  /**
   * Uploads user startup fit test results, creates new colection if there is none
   * @method uploadStartupFitTest
   *
   * @param {object} testData Object with test results
   */
  uploadStartupFitTest = (testData) => setDoc(doc(this.firestore, 'startup_fit_tests', this.auth.currentUser.uid), testData, { merge: true });

  /**
   * Retrieves from firestore user startup fit test results.
   * @method getUserStartupFitResults
   */
  getUserStartupFitResults = () => getDoc(doc(this.firestore, `startup_fit_tests/${this.auth.currentUser.uid}`), orderBy('Upload', 'desc'));

  /**
   * Uploads user career prediction results, creates new colection if there is none
   * @method uploadCareerPredictionResults
   *
   * @param {object} prediction Object with prediction results
   */
  uploadCareerPredictionResults = (prediction) => setDoc(doc(this.firestore, 'career_prediction_results', this.auth.currentUser.uid), prediction, { merge: true });

  /**
   * Retrieves from firestore user career prediction results.
   * @method getCareerPredictionResults
   */
  getCareerPredictionResults = () => getDoc(doc(this.firestore, `career_prediction_results/${this.auth.currentUser.uid}`), orderBy('Upload', 'desc'));

  /**
   * Uploads user career prediction results feedback, creates new colection if there is none
   * @method uploadCareerPredictionFeedback
   * @param {object} feedback Object with prediction results feedback
   */
  uploadCareerPredictionFeedback = (feedback) => setDoc(doc(this.firestore, 'career_prediction_feedbacks', this.auth.currentUser.uid), feedback, { merge: true });

  /**
   * Uploads user feedback
   * @method uploadFeedback
   * @param {object} feedback Object with the feedback content
   */
  uploadFeedback = (feedback) => addDoc(collection(this.firestore, 'feedbacks'), feedback, { merge: true });

  addCommunity = (communityName) => addDoc(collection(this.firestore, 'communities'), {
    name: communityName,
    createdAt: Timestamp.now(),
  })

  getCommunities = () => getDocs(query(collection(this.firestore, 'communities')));

  updateUserCommunities = (communities) => updateDoc(this.getCurrentUserRef(), { communities });

  getCommunity = (commmunityId) => getDoc(doc(this.firestore, `communities/${commmunityId}/`));

  setCommunity = (commmunityId, community) => setDoc(doc(this.firestore, `communities/${commmunityId}`), community, { merge: true });

  subscribePostListener = (postID, onChange) => onSnapshot(doc(this.firestore, 'posts', postID), onChange);

  /* Subscribing to the posts collection and listening for changes. */
  subscribeCommunitiesListener = (postID, communities, onChange) =>
    onSnapshot(query(collection(this.firestore, 'posts'),
      where('parentID', '==', postID),
      where('communityID', 'in', communities),
      where('flags.REPORTED', '==', false),
      where('flags.BLOCKED', '==', false),
      where('flags.DELETED', '==', false),
      orderBy('createdAt', 'desc'),
      limit(10)
    ), onChange);

  /* Fetching more posts from the database. */
  communityFetchMore = (postID, communities, lastPost) => {
    return getDocs(query(collection(this.firestore, 'posts'),
      where('parentID', '==', postID),
      where('communityID', 'in', communities),
      where('flags.REPORTED', '==', false),
      where('flags.BLOCKED', '==', false),
      where('flags.DELETED', '==', false),
      orderBy('createdAt', 'desc'),
      startAfter(lastPost),
      limit(3))
    );
  }

  /* Fetching user posts from the database. */
  communityFetchMyPosts = (onChange, postID = null) =>
    onSnapshot(query(collection(this.firestore, 'posts'),
      where('userRef', '==', this.getCurrentUserRef()),
      where('parentID', '==', postID),
      where('flags.REPORTED', '==', false),
      where('flags.BLOCKED', '==', false),
      where('flags.DELETED', '==', false),
      orderBy('createdAt', 'desc'),
      limit(10)
    ), onChange);

  // Fetching more user posts from the database.
  communityFetchMoreMyPosts = (lastPost, postID = null) => {
    return getDocs(query(collection(this.firestore, 'posts'),
      where('userRef', '==', this.getCurrentUserRef()),
      where('parentID', '==', postID),
      where('flags.REPORTED', '==', false),
      where('flags.BLOCKED', '==', false),
      where('flags.DELETED', '==', false),
      orderBy('createdAt', 'desc'),
      startAfter(lastPost),
      limit(3))
    );
  }

  commentsCounter = (parentID, amount) => {
    if (parentID) {
      updateDoc(doc(this.firestore, 'posts', parentID), { 'numberOfComments': increment(amount) }, { merge: true });
    }
  };

  /**
   * Listening to the notifications collection for a specific userID.
   * @param {string} userID ID of recipient
   * @param {function} onChange On change callback
   */
  addNotificationsListener = (userID, onChange) =>
    onSnapshot(query(collection(this.firestore, 'notifications'), where('userID', '==', userID)), onChange);

  /**
   * Getting all the notifications for a user.
   * @param {string} userID ID of recipient
   * @returns {Promise} DocumentQuerySnapshot
   */
  getNotifications = (userID) =>
    getDocs(query(collection(this.firestore, 'notifications'), where('userID', '==', userID)));

  /**
   * Updating the received field of the notification document to true.
   * @param {import('firebase/firestore').DocumentReference} notificationRef
   * @returns {Promise}
   */
  markNotificationAsReceived = (notificationRef) =>
    updateDoc(notificationRef, { received: true });

  /**
   * Adding a post to a community.
   * @method addPostToCommunity
   * @param {string} communityId Community ID
   * @param {object} content Post object
   */
  addPostToCommunity = (content, parentID) => {
    addDoc(collection(this.firestore, 'posts'), content);
    this.commentsCounter(parentID, 1);
  }

  /**
   * Adds flag to the post DELETED or BLOCKED
   * @method flagPostFromCommunity
   * @param {string} postId Post ID
   * @param {string} flag Flag to be added
   */
  flagPostFromCommunity = (postID, parentID, flag) => {
    updateDoc(doc(this.firestore, 'posts', postID), {
      [flag]: true,
      flaggedBy: this.getCurrentUserRef(),
    });
    this.commentsCounter(parentID, -1);
  };

  /**
   * Creating a report for a post and uploading report.
   * @method reportPost
   * @param {string} postId Post ID
   * @param {string} report Report to be added
   */
  reportPost = (postID, parentID, reports) => {
    updateDoc(doc(this.firestore, 'posts', postID), {
      'flags.REPORTED': true,
      reports,
    });
    this.commentsCounter(parentID, -1);
  };

  addLikeToPost = (postId, userRef) => updateDoc(doc(this.firestore, 'posts', postId), { likes: arrayUnion(userRef) });
  removeLikeFromPost = (postId, userRef) => updateDoc(doc(this.firestore, 'posts', postId), { likes: arrayRemove(userRef) });

  addDailyTask = async (task) => {
    const latestTask = await getDocs(query(collection(this.firestore, 'daily_tasks'), where('taskType', '==', task.taskType), orderBy('step', 'desc'), limit(1)));

    let latestTaskData;
    latestTask.forEach((snap) => {
      latestTaskData = snap.data();
    });

    addDoc(collection(this.firestore, 'daily_tasks'), {
      ...task,
      step: latestTaskData?.step + 1 || 0,
    });
  }

  getDailyTask = (taskGroup, taskStep) => getDocs(query(collection(this.firestore, 'daily_tasks'), where('taskType', '==', taskGroup), where('step', '>=', taskStep), orderBy('step', 'asc'), limit(1)));

  getEqTestResults = () => getDocs(query(collection(this.firestore, 'survey_results'), where('userID', '==', this.auth.currentUser.uid)));

  getEqTestResultbySurveyResultsID = (surveyResultsID) => getDocs(query(collection(this.firestore, 'public_results'), where('surveyResultsID', '==', surveyResultsID)));

  updateEqTestPublicResult = (surveyResultsID, data) => updateDoc(doc(this.firestore, 'public_results', surveyResultsID), { ...data });

  shareResult = async (result, authorName) => addDoc(collection(this.firestore, 'public_results'), {
    userID: this.auth.currentUser.uid,
    userRef: this.getCurrentUserRef(),
    results: result.results,
    type: 'eq_result',
    surveyResultsID: result.surveyResultsID,
    surveyResultsRef: result.surveyResultsRef,
    createdAt: Timestamp.now(),
    authorName,
  });

  /**
   * Uploads user prediction rating
   * @method uploadPredictionRating
   *
   * @param {object} ratingData Object with prediction rating
   */
  uploadPredictionRating = (ratingData) => setDoc(doc(this.firestore, 'user_prediction_ratings', this.auth.currentUser.uid), ratingData, { merge: true });

  // CAUTION!!!
  // Please only use this if you are sure you want to delete the current user data
  /**
   * Deletes current user data colection from firestore
   * @method deleteUserData
   **/
  deleteUserData = async () => {
    // Delete profile Image if available
    await deleteObject(ref(this.storage, `profile-pictures/${this.auth.currentUser.uid}.webp`))
      .catch((err) => console.warn('Most likely user didn\'t upload an image error: ', err));

    // Delete user document from firestore
    await deleteDoc(this.getCurrentUserRef());

    // Delete user from auth
    await this.deleteUser();
  }
}

export default Firebase;
