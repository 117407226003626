import React from 'react';
import './styles.scss';

import useToasts from '../../../../hooks/useToasts';
import { useAnalytics } from '../../../../hooks/Firebase';

import ModalBaseContainer from '../../../../components/ModalBaseContainer';
import CopyIcon from '../../../../statics/Images/CopyIcon.svg';

/**
 * Share results modal
 * @param {boolean} show - show modal
 * @param {function} onClose - close modal handler
 * @param {string} baseURL - base url
 * @param {string} publicID - public id
*/
const ShareResultsModal = ({ show, onClose, shortLink }) => {
  const { clickEvent, TYPES: { CLICK } } = useAnalytics();
  const { publishSuccess } = useToasts();

  const shareResultsHandler = () => {
    navigator.clipboard.writeText(shortLink);
    publishSuccess('Link copied to clipboard');
  };

  return (
    <ModalBaseContainer show={show} onClose={onClose}>
      <h2 className='share-results-title'>Share</h2>
      <p className='share-results-text'>Page link</p>
      <div className="share-results-input" onClick={() => {
        shareResultsHandler();
        clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.EQ_TEST_SHARE_FINISH, CLICK.LOCATION.EQ_TEST_RESULTS);
      }}>
        <input className='input-read-only' value={shortLink} readOnly/>
        <img className='share-results-icon' src={CopyIcon} />
      </div>
    </ModalBaseContainer>
  );
};

export default ShareResultsModal;
