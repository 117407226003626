import React, { useContext, useEffect, useState } from 'react'; // useRef

import { StorageContext } from '../../../../../../components/Storage';

import TestIcon from '../../../../../../statics/Images/TestIcon.svg';

import { useAnalytics } from '../../../../../../hooks/Firebase';

import SkillsModal from '../../../../../../components/SkillsModal';

/**
 * Profile page
 * @name EditProfileSkills
 */
const EditProfileSkills = () => {
  const [appContext] = useContext(StorageContext);
  const { clickEvent, TYPES: { CLICK } } = useAnalytics();
  // const scrollIntoCareer = useRef();

  const [state, updateState] = useState({
    selectedSkills: [],
    skillsModal: false,
  });

  const toggleShowSkillsModal = () => {
    updateState(s => ({
      ...s,
      skillsModal: !state.skillsModal,
    }));
  };

  useEffect(async () => {
    updateState(s => ({
      ...s,
      selectedSkills: appContext.profile.skills || [],
    }));
  }, [appContext.isLoading, appContext.profile.skills]);

  return (
    <div className='tab-content'>
      <p className='content-margin-large'>Share your skills, experience, and other expectations. These details will help employers discover your profile.</p>
      <div className='profile-banners'>
        <div className='banner-container'>
          <img className='banner-icon' src={TestIcon} />
          <h3 className='content-margin-base'>Skills</h3>
          <p className='content-margin-base'>{!state.selectedSkills.length ? 'Choose the appropriate skills relevant to you from the skills suggested.' : 'Choose the appropriate skills relevant to you from the skills suggested.'}</p>
          <button className='btn btn-primary'
            type='button'
            onClick={() => {
              clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.ADD_EDIT_SKILL_PROFILE);
              toggleShowSkillsModal();
            }}>
            {!state.selectedSkills.length ? 'Add skills' : 'Edit skills'}
          </button>
          <div className='skills-wrapper'>
            {state.selectedSkills.map((skill) => (
              <div key={skill.id} className='skill'>
                <span>{skill.name}</span>
              </div>
            ))}
          </div>
          <SkillsModal show={state.skillsModal} onClose={toggleShowSkillsModal} skills={appContext.profile.skills} />
        </div>
      </div>
    </div>
  );
};

export default EditProfileSkills;
