import { useContext, useEffect } from 'react';
import { FirebaseContext } from '../../components/Firebase';
import { logEvent, setUserId } from 'firebase/analytics';
import { useLocation } from 'react-router-dom';

const config = { userIdSet: false };

/**
 * Custom hook to use analytics
 *
 * @typedef {Hook} useAnalytics
 *
 * @returns {object}
 *
 */
const useAnalytics = () => {
  const firebase = useContext(FirebaseContext);
  const location = useLocation();
  const analytics = firebase.analytics;

  const TYPES = {
    CLICK: {
      TYPE: {
        ACTION: 'action',
        ADD: 'add',
        EDIT: 'edit',
        DELETE: 'delete',
        NAVIGATION: 'navigation',
        VIEW: 'view',
      },
      CATEGORY: {
        NAV_DASHBOARD : 'nav_dashboard',
        NAV_GOAL : 'nav_goal',
        NAV_COMMUNITY : 'nav_community',
        NAV_JOBS : 'nav_jobs',
        NAV_ME : 'nav_me',
        GENDER: 'gender',
        EMAIL:'email',
        PASSWORD: 'password',
        FULL_NAME: 'full_name',
        BIRTHDATE: 'birthdate',
        PHOTO: 'photo',
        DASHBOARD: 'dashboard',
        BASIC_INFO: 'basic_info',
        HAPPINESS_TRACKER: 'happiness_tracker',
        GOAL: 'goal',
        JOB_TITLE_PREDICTION: 'job_title_prediction',
        COMMUNITY: 'community',
        CHOOSE_COMMUNITY_MODAL: 'choose_community_modal',
        CHOOSE_COMMUNITY_MODAL_SAVE: 'choose_community_modal_save',
        COMMUNITY_SELECTION: 'community_selection',
        COMMUNITY_QUESTION: 'community_question',
        COMMUNITY_COMMENT: 'community_comment',
        COMMUNITY_COMMENT_READ: 'community_comment_read',
        JOB_BOARD: 'job_board',
        PROFILE: 'profile',
        CAREER: 'career',
        STARTUP_FIT_TEST: 'startup_fit_test',
        EQ_TEST: 'emotional_intelligence_test',
        EQ_TEST_SHARE_START: 'emotional_intelligence_test_share_start',
        EQ_TEST_SHARE_FINISH: 'emotional_intelligence_test_share_finish',
        EQ_SHARED_SIGNUP: 'EQ_shared_signup',
        REVIEW: 'review',
        NOTIFICATIONS: 'notifications',
        SETTINGS: 'settings',
        NOTIFICATIONS_FROM_PROFILE_MOBILE: 'notification_from_profile_mobile',
        SETTINGS_FROM_PROFILE_MOBILE: 'settings_from_profile_mobile',
        TM_PROFILE: 'tm_profile',
        PRIVACY_POLICY: 'privacy_policy',
        PUSH_NOTIFICATIONS_ENABLE: 'push_notifications_enable',
        LOGIN_CREDENTIALS: 'login_credentials',
        LOG_OUT: 'log_out',
        COMPLETE_TASK: 'complete_task',
        TASK_GROUP: 'task_group',
        EMAIL_SIGN_UP: 'email_sign_up',
        GOOGLE_SIGN_UP: 'google_sign_up',
        EXTERNAL: 'external',
        FILE: 'file',
        ALL_SKILLS_SAVE: 'all_skills_save',
        ALL_SKILLS_SAVE_PROFILE: 'all_skills_save_profile',
        ADD_EDIT_SKILL_PROFILE: 'add_edit_skill_profile',
        NO_SKILL_MODAL: 'no_skill_modal',
        ADD_SKILL_FROM_MODAL: 'add_skill_from_modal',
        START_JOB_MATCHING: 'start_job_matching',
        READ_MORE_MATCHING: 'read_more_matching',
        BACK_FROM_MATCHING: 'back_from_matching',
        CHANGE_FILTER: 'change_filter',
        FILTER_JOBS: 'filter_jobs',
        MORE: 'more',
        HELP: 'help',
      },
      STATE: {
        GOAL_SELECTED: 'goal_selected',
        NO_GOAL_SELECTED: 'no_goal_selected',
      },
      LOCATION: {
        DASHBOARD: 'dashboard',
        GOAL: 'goal',
        PREDICTION: 'prediction',
        BANNER: 'banner',
        HEADER: 'header',
        SETTINGS: 'settings',
        PUSH_NOTIFICATIONS: 'push_notifications',
        EQ_TEST_PRESCREEN: 'eq_test_preescreen',
        EQ_TEST_RESULTS: 'eq_test_results',
        EQ_TEST_SHARED_RESULTS: 'shared_results_eq_test',
        DURING_EQ_TEST: 'during_eq_test',
        LAST_QUESTION_EQ_TEST: 'last_question_screen_eq_test',
        JOBS: 'jobs',
        BASIC_INFO: 'basic_info',
        SIGN_UP: 'sign_up',
        SPLASH: 'splash',
        COMMUNITY: 'community',
        INPUT_BAR: 'input_bar',
        PROFILE_PAGE: 'profile_page',
        CHOOSE_COMMUNITY_MODAL: 'choose_community_modal',
        RELEASE_NOTE: 'release_note',
        EXPLANATION_SCREEN: 'explanation_screen',
        CAREER_PREDICTION_RESULTS: 'career_prediction_results',
        MTCHING_RESULTS: 'matching_results',
        NO_JOBS_SCREEN: 'no_jobs_screen',
        NAV_BAR: 'nav_bar',

      },
    },
    SEARCH: {
      TYPE: {
        JOB_TITLE: 'job_title',
        SKILL_NAME: 'skill_name',
      },
      CATEGORY: {
        JOB: 'job',
        GOAL: 'goal',
        ADD_SKILL_SEARCH_TERM: 'add_skill_search_term',
      },
    },
    SELECT: {
      CATEGORY: {
        ECONOMIC_ASSESSMENT: 'economic_assessment',
        PERSONAL_ASSESSMENT: 'personal_assessment',
        STARTUP_FIT_TEST: 'startup_fit_test',
      },
    },
  };

  useEffect(() => {
    if (firebase.auth.currentUser?.uid && !config.userIdSet) {
      setUserId(analytics, firebase.auth.currentUser.uid);
      config.userIdSet = true; // Only works when component was at least rendered once. Should work globally.
    }
  }, []);

  /**
   * Logs an event to Firebase Analytics when a page has been visited
   * @param {string} path
   */
  const pageViewEvent = () => {
    logEvent(analytics, 'page_view', { page_path: location.pathname });
  };

  const outcomeEvent = (outcome) => {
    logEvent(analytics, 'outcome_event', {
      outcome,
      page_path: location.pathname,
    });
  };

  /**
   * Logs an event to Firebase Analytics when a job title has been selected from autocomplete.
   * @param {string} type job_title
   * @param {string} category job | goal
   * @param {string} name job title name
   * @param {number} index position of job title in autocomplete list
   * @param {string} search_term search query
   */
  const searchItemEvent = (type, category, name, index, search_term) => {
    logEvent(analytics, 'search_item', {
      item_type: type,
      item_category: category,
      item_name: name,
      item_position: index+1,
      search_term,
    });
  };

  /**
   * Logs an event to Firebase Analytics when a button has been clicked
   * @param {string} type edit | delete | add | action | navigation | view
   * @param {string} category goal | job | prediction | startup_fit_test | ...
   * @param {string} state (optional) goal_selected | no_goal_selected
   * @param {string} location (optional) dashboard | banner | ...
   */
  const clickEvent = (type, category, location, state) => {
    const e = {
      button_type: type,
      button_category: category,
    };

    if (state) {
      e.button_state = state;
    }

    if (location) {
      e.button_location = location;
    }

    logEvent(analytics, 'click_button', e);
  };

  /**
   * Logs an event to Firebase Analytics when a value in an assessment has been selected
   * @param {string} category startup_fit_test | economic_assessment | personal_assessement
   * @param {string} assessmentId assosiated assessment id
   * @param {string} questionId assosiated question id
   * @param {string} value selected answer value
   */
  const selectItemEvent = (category, assessmentId, questionId, value) => {
    logEvent(analytics, 'select_item', {
      assessment_id: assessmentId,
      item_category: category,
      item_name: questionId,
      item_value: value,
    });
  };

  /**
   * It takes an error and a boolean, and logs an exception event to Google Analytics
   * @param {string} error - The error message
   * @param {boolean} [fatal=false] - true if the error is fatal, false otherwise.
   */
  const errorEvent = (error, fatal = false) => {
    logEvent(analytics, 'exception', {
      description: error,
      fatal,
    });
  };

  /**
   * Logs custom event to Firebase Analytics
   * @param {string} eventName custom name
   */
  const customEvent = (eventName) => {
    logEvent(analytics, eventName);
  };

  return {
    clickEvent,
    errorEvent,
    pageViewEvent,
    searchItemEvent,
    selectItemEvent,
    customEvent,
    outcomeEvent,
    TYPES,
  };
};

export default useAnalytics;
