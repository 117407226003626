import React, { useContext, useState } from 'react';
import './styles.scss';

import { FirebaseContext } from '../../Firebase';

import SurveyFormStep from './SurveyFormStep';

import Button from '../../Button';
import InputWithLabel from '../InputWithLabel';

const EMPTY_STATE = {
  name: null,
  steps: [],
};

const EMPTY_STEP = {
  name: null,
  description: null,
  questions: [],
};

const SurveyForm = ({ questions }) => {
  const [state, setState] = useState(EMPTY_STATE);
  const firebase = useContext(FirebaseContext);

  const addStep = () => {
    setState({
      ...state,
      steps: [...state.steps, EMPTY_STEP],
    });
  };

  const onChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    firebase.addCollectionItem('surveys', {
      ...state,
      createdAt: firebase.convertDate(new Date()),
    });
  };

  return (
    <form className='padding-layout-base' onSubmit={onSubmit}>
      <InputWithLabel
        className='content-margin-base'
        name='name'
        type='text'
        onChange={onChange}
        value={state.name}
      />
      <SurveyFormStep
        addStep={addStep}
        surveyState={state}
        setSurveyState={setState}
        questions={questions}
      />
      <Button onClick={onSubmit} theme='btn-primary' type='submit'>Save</Button>
    </form>
  );
};

export default SurveyForm;
