import React from 'react';
import PropTypes from 'prop-types';

const LocationIcon = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 52 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="#303030" fillRule="evenodd" clipRule="evenodd" d="M26.3034 67.9283C29.7622 67.7284 51.5 41.6254 51.5 28C51.5 12.536 41.464 0 26 0C10.536 0 0.5 12.536 0.5 28C0.5 41.2823 22.6321 66.4215 26.0256 67.8645C26.0912 67.9093 26.1738 67.9306 26.2723 67.9294C26.2825 67.9293 26.2929 67.9289 26.3034 67.9283ZM26 45C36.4934 45 45 36.4934 45 26C45 15.5066 36.4934 7 26 7C15.5066 7 7 15.5066 7 26C7 36.4934 15.5066 45 26 45Z"/>
    </svg>
  );
};

LocationIcon.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default LocationIcon;
