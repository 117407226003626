import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.scss';

import { FirebaseContext } from '../../../../components/Firebase';
import { StorageContext } from '../../../../components/Storage';

import { useAnalytics } from '../../../../hooks/Firebase';
import Loader from '../../../../statics/Images/Loader';
import Button from '../../../../components/Button';
import StartupFitQuestion from './StartupFitQuestion';

const StartupFitTest = () => {
  const firebase = useContext(FirebaseContext);
  const { clickEvent, TYPES: { CLICK }, customEvent } = useAnalytics();
  const navigate = useNavigate();
  const [,dispatch] = useContext(StorageContext);
  const [testId, setTestId] = useState('');
  const [test, setTest] = useState({});
  const [loading, setLoading] = useState(true);
  const [requestNewTestTrigger, setRequestNewTestTrigger] = useState(false);
  const [formValidationArray, setFormValidationArray] = useState([]);

  useEffect(() => {
    async function fetchNewTest(){
      setLoading(true);
      let myHeaders = new Headers();
      myHeaders.append('X-AUTH-TOKEN', 'API-X-TOKEN $8l5BuucI36LZ9sjfLYBnz.OVzFAlGYM5dRxbfoMFK7L511gaPN4ce');
      myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
      myHeaders.append('Cookie', 'PHPSESSID=vpvte226ss0jkomnd10loff0sj');
      let urlencoded = new URLSearchParams();
      urlencoded.append('accessCode', 'dTVIbENRPT0%3D');
      const requestNewTestIDOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow',
      };
      const requestTestOptions = {
        method: 'GET',
        headers: myHeaders,
      };

      // Request new test ID
      const init = await fetch('https://app.mind-one.com/en/api/init_test', requestNewTestIDOptions);
      const surveyId = JSON.parse(await init.json()).SurveyId;

      // Request questionaire linked to previous test ID
      const questionaire = JSON.parse(await (await fetch(`https://app.mind-one.com/en/api/get_questionnaire?SurveyId=${surveyId}`, requestTestOptions)).json());

      setTestId(surveyId);
      setTest(questionaire);
      setLoading(false);
    }
    fetchNewTest();
  }, [requestNewTestTrigger]);

  const completeStartupFit = async (e) => {
    e.preventDefault();
    clickEvent(CLICK.TYPE.ADD, CLICK.CATEGORY.STARTUP_FIT_TEST);

    // Requests the test again after user completes so we can save the answers
    let myHeaders = new Headers();
    myHeaders.append('X-AUTH-TOKEN', 'API-X-TOKEN $8l5BuucI36LZ9sjfLYBnz.OVzFAlGYM5dRxbfoMFK7L511gaPN4ce');
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    myHeaders.append('Cookie', 'PHPSESSID=vpvte226ss0jkomnd10loff0sj');

    const requestTestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    const fullQuestionaire = JSON.parse(await (await fetch(`https://app.mind-one.com/en/api/get_questionnaire?SurveyId=${testId}`, requestTestOptions)).json());

    // Firebase does not support nested arrays, deconstruct object received
    // {
    //  [question.id]: [answer value]
    //  }
    let replies = {};
    fullQuestionaire.items.forEach((doc) => {
      replies = {
        ...replies,
        [doc.id]: doc.answer,
      };
    });

    // Resquests the test results
    const formBody = [];
    formBody.push(encodeURIComponent('SurveyId')+'='+encodeURIComponent(testId));
    const requestResultsOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formBody.join('&'),
    };

    const requestResult = await fetch('https://app.mind-one.com/en/api/display_results', requestResultsOptions);
    const results = await requestResult.json();

    const payload = {
      startupFitAnswers: replies,
      startupFitResults: results.levels,
      Upload: new Date(),
    };

    await firebase
      .uploadStartupFitTest( { [fullQuestionaire.SurveyId]: payload });

    dispatch({
      type: 'SET_STARTUP_FIT_RESULTS',
      payload,
    });

    navigate('./results');
  };

  return (
    <>
      {
        loading ?
          <div className='loading-test'>
            <Loader color='#fff' />
          </div>
          :
          <form onSubmit={completeStartupFit}>
            {
              test.items.map((doc, i) => (
                <div className='question-wrapper' key={i}>
                  <StartupFitQuestion
                    question={doc.label}
                    index={i}
                    questionID={doc.id}
                    testID={testId}
                    requestNewTestTrigger={requestNewTestTrigger}
                    setRequestNewTestTrigger={setRequestNewTestTrigger}
                    formValidationArray={formValidationArray}
                    setFormValidationArray={setFormValidationArray}
                  />
                </div>

              ))
            }
            <div className='submit-btn-wrapper'>
              <Button theme={'btn-secondary'}
                type='submit'
                disabled={formValidationArray.length < 24}
                onClick={() => {
                  clickEvent(CLICK.TYPE.ADD, CLICK.CATEGORY.STARTUP_FIT_TEST);
                  customEvent('startup_fit_test_complete');
                }}>Save</Button>
            </div>
          </form>
      }
    </>
  );
};

export default StartupFitTest;
