import React, { useState, useEffect, useContext } from 'react';

import './styles.scss';

import { FirebaseContext } from '../../../../components/Firebase';

import { isEmptyObject } from '../../../../tools/IsEmptyObject';
import { useAnalytics } from '../../../../hooks/Firebase';

import CompletedStepsList from './CompletedStepsList';
import TodoStepsList from './TodoStepsList';

import BasicInfo from '../../../../statics/Images/Illustrations/BasicInfo.svg';
import CareerHistory from '../../../../statics/Images/Illustrations/CareerHistory.svg';
import Community from '../../../../statics/Images/Illustrations/Community.svg';
import CareerPrediction from '../../../../statics/Images/Illustrations/CareerPrediction.svg';
import EQTest from '../../../../statics/Images/Illustrations/EQTest.svg';

import StartUpFit from '../../../../statics/Images/Illustrations/StartUpFit.svg';

/**
 * List with steps missing for profile completion
 *
 * @name CompleteProfileList
 * @memberof DashboardTab
 *
 * @param {object} state Global state
 *
 */
const CompleteProfileList = ({ state }) => {
  const firebase = useContext(FirebaseContext);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [todoSteps, setTodoSteps] = useState([]);
  const { clickEvent, TYPES: { CLICK } } = useAnalytics();
  const [dashboardTasks, setDashboardTasks] = useState(state.profile.dashboardTasks || []);

  const markDashboardTaskDone = async (task) => {
    if (!Object.values(state.profile.dashboardTasks).includes(task)) {
      dashboardTasks.push(task);
      setDashboardTasks(dashboardTasks);
      await firebase.updateUser({ dashboardTasks });
    }
  };

  useEffect(() => {
    const completed = [];
    const todo = [];
    setDashboardTasks(state.profile.dashboardTasks);

    if (state.profile.birthdate && state.profile.gender){
      completed.push({
        title:'Add your basics',
        text: 'Let us know your name, your birthday and set your profile pic.',
      });
    } else {
      todo.push({
        title:'Add your basics',
        link: '/create-profile/profile',
        text: 'Let us know your name, your birthday and set your profile pic.',
        img: BasicInfo,
        onClick: () => { clickEvent(CLICK.TYPE.NAVIGATION, CLICK.CATEGORY.BASIC_INFO, CLICK.LOCATION.DASHBOARD); },
      });
    }

    if (state.career.length) {
      completed.push({
        title:'Add your past jobs',
        text: 'Based on your past experiences, we can make job and career recommendations for you!',
      });
    } else {
      todo.push({
        title:'Add your past jobs',
        link: '/app/profile',
        text: 'Based on your past experiences, we can make job and career recommendations for you!',
        img: CareerHistory,
        onClick: () => { clickEvent(CLICK.TYPE.NAVIGATION, CLICK.CATEGORY.CAREER, CLICK.LOCATION.DASHBOARD); },
      });
    }

    if (state.eqResults?.find(survey => survey.surveyName === process.env.REACT_APP_CURRENT_EQ_SURVEY_NAME)) {
      completed.push({
        title:'How high is your EQ?',
        text: 'Are you an emotional genius? This test will tell.',
      });
    } else {
      todo.push({
        title:'How high is your EQ?',
        link: '/app/profile',
        text: 'Are you an emotional genius? This test will tell.',
        img: EQTest,
        onClick: () => { clickEvent(CLICK.TYPE.NAVIGATION, CLICK.CATEGORY.EQ_TEST, CLICK.LOCATION.DASHBOARD); },
      });
    }

    if (!isEmptyObject(state.careerPredictionResults)) {
      completed.push({
        title:'Plan your career',
        text: 'Discover and embrace your talents to grow into your ideal career!',
      });
    } else {
      todo.push({
        title:'Plan your career',
        link: '/app/profile',
        text: 'Discover and embrace your talents to grow into your ideal career!',
        img: CareerPrediction,
        onClick: () => { clickEvent(CLICK.TYPE.NAVIGATION, CLICK.CATEGORY.PROFILE, CLICK.LOCATION.DASHBOARD); },
      });
    }

    if (Object.values(dashboardTasks).includes('community')) {
      completed.push({
        title:'Meet the Community',
        text: 'Share content, expertise and advice with peers striving toward the same goal as you.',
      });
    } else {
      todo.push({
        title:'Meet the Community',
        link: '/app/community',
        text: 'Share content, expertise and advice with peers striving toward the same goal as you.',
        img: Community,
        onClick: () => {
          markDashboardTaskDone('community');
          clickEvent(CLICK.TYPE.NAVIGATION, CLICK.CATEGORY.COMMUNITY, CLICK.LOCATION.DASHBOARD);
        },
      });
    }

    if (!isEmptyObject(state.startupFitTestResults)) {
      completed.push({
        title:'Challenge yourself',
        text: 'Find out if your personality traits fit a career in startups with our test.',
      });
    } else {
      todo.push({
        title:'Challenge yourself',
        link: '/app/startup-fit',
        text: 'Find out if your personality traits fit a career in startups with our test.',
        img: StartUpFit,
        onClick: () => { clickEvent(CLICK.TYPE.NAVIGATION, CLICK.CATEGORY.STARTUP_FIT_TEST, CLICK.LOCATION.DASHBOARD); },
      });
    }

    setCompletedSteps(completed);
    setTodoSteps(todo);
  }, [state]);
  return (
    <ul className='complete-profile-list-container content-margin-large'>
      <CompletedStepsList completedSteps={completedSteps}/>
      <TodoStepsList todoSteps={todoSteps}/>
    </ul>
  );
};

export default CompleteProfileList;
