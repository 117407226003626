import React, { useState } from 'react';

import { motion } from 'framer-motion';

import YearGrid from '../../DatePicker/DatePickerModal/YearGrid';
import MonthGrid from '../../DatePicker/DatePickerModal/MonthGrid';

import CalendarPolygon from '../../../statics/Images/CalendarPolygon.svg';

const backdrop = {
  visible: {
    opacity: 1,
    transition: { duration: 0.25 },
  },
  hidden: {
    opacity: 0,
    transition: { duration: 0.25 },
  },
};

/**
 * Constants
 *
 * @readonly
 *
 * @const {array, <String>} YEARS
 *
 */

const YEARS = Array.from(new Array(80), (val, index) => new Date().getFullYear() - index);

/**
 * Modal with the calendar for the month year component,
 * should not be used by itself
 *
 * @name CalendarModal
 *
 * @memberof MonthYearPicker
 * @see {@link MonthYearPicker}
 * @protected
 *
 */

const CalendarModal = ( props ) => {
  const {
    setShowCalendar,
    setMonthSelected,
    monthSelected,
    setYearSelected,
    yearSelected,
    onChange,
    name,
    months,
  } = props;

  const [chooseYear, setChooseYear] = useState(true);

  const closeCalendarHandler = () => {
    setShowCalendar(false);
  };

  const selectYearHandler = (year) => {
    setYearSelected(year);
    setChooseYear(false);
  };

  const selectMonthHandler = (idx) => {
    setMonthSelected(idx);
    setChooseYear(true);
    onChange({
      target: {
        name,
        value: new Date(yearSelected, idx),
      },
    });
    closeCalendarHandler();
  };

  const showMonthHandler = () => {
    setChooseYear(false);
  };

  const showYearHandler = () => {
    setChooseYear(true);
  };

  return (
    <motion.div className='calendar-modal-overlay' onClick={closeCalendarHandler}
      variants={backdrop}
      initial='hidden'
      animate='visible'
      exit='hidden'>
      <div className='calendar-modal-container' onClick={e => e.stopPropagation()}>
        <div className='calendar-modal-top-container'>
          <div className='calendar-date-container'>
            <div className={chooseYear ? 'calendar-date-item-container active' : 'calendar-date-item-container'} onClick={showYearHandler}>
              <p>{ yearSelected }</p>
              <img src={CalendarPolygon} />
            </div>
            <div className={!chooseYear ? 'calendar-date-item-container active' : 'calendar-date-item-container'} onClick={showMonthHandler}>
              <p>{ months[monthSelected].substring(0,3) }</p>
              <img src={CalendarPolygon} />
            </div>
          </div>
        </div>
        <div className='calendar-modal-bottom-container'>
          { chooseYear
            ?
            <YearGrid YEARS={YEARS} selectYearHandler={selectYearHandler} />
            :
            <MonthGrid selectMonthHandler={selectMonthHandler} MONTHS={months} currentYear={yearSelected == new Date().getFullYear()} />
          }
        </div>
      </div>
    </motion.div>
  );
};

export default CalendarModal;
