import React, { useContext } from 'react';

import { FirebaseContext } from '../../../../../components/Firebase';
import useToasts from '../../../../../hooks/useToasts';

import Button from '../../../../../components/Button';
import ModalBaseContainer from '../../../../../components/ModalBaseContainer';

const ConfirmDeleteModal = ({ show, onClose, post }) => {
  const firebase = useContext(FirebaseContext);
  const { publishSuccess } = useToasts();

  const deletePostHandler = async (post) => {
    const flag = firebase.auth.currentUser.uid === post.userRef.id ? 'flags.DELETED' : 'flags.BLOCKED';
    await firebase.flagPostFromCommunity(post.id, post.parentID, flag);
    await publishSuccess('Post deleted');
    onClose();
  };

  return (
    <ModalBaseContainer show={show} onClose={onClose}>
      <h2>Remove content?</h2>
      <p>Are you sure you want to remove this post? This action cannot be undone.</p>
      <div className='btn-inline-wrapper'>
        <Button
          onClick={onClose}
          theme='btn-secondary'
          type='button'
        >Cancel</Button>
        <Button
          onClick={() => deletePostHandler( post )}
          theme='btn-primary'
          type='button'
        >Delete</Button>
      </div>
    </ModalBaseContainer>
  );
};

export default ConfirmDeleteModal;
