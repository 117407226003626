import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

import Logout from '../../pages/Logout';
import SignupUp from '../../pages/SignUp';
import MainAppContainer from '../MainAppContainer';

import { FirebaseContext } from '../Firebase';
import ProtectedRoute from '../ProtectedRoute';
import Storage from '../Storage';
import InitialLoading from '../InitialLoading';
import PublicRoutes from '../PublicRoutes';
import Toast from '../Toast';
import PushNotifications from '../PushNotifications';
import ErrorBoundary from '../ErrorBoundary';
import SurveyAdmin from '../SurveyAdmin';
import AdminRoutes from '../AdminRoutes';
import EQSurvey from '../../pages/EQSurvey';
import JobMatching from '../../pages/JobMatching';

const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID;

function App() {
  const firebase = useContext(FirebaseContext);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [signedIn, setSignedIn] = useState(null);

  useEffect(() => {
    const unsubscribe = firebase.userSignedInListener(setSignedIn);
    setLoading(typeof signedIn !== 'boolean');

    return () => unsubscribe();
  }, [signedIn]);

  return (
    <Storage signedIn={signedIn}>
      <GoogleOAuthProvider clientId={clientId}>
        {/* <ErrorBoundary /> must be nested inside Storage otherwise state will be fetched for every re-render */}
        <ErrorBoundary key={location.pathname}>
          {
            loading ?
              <InitialLoading/> :
              <>
                <Routes>

                  {/* PUBLIC ROUTE */}
                  <Route path='/*' element={<PublicRoutes signedIn={signedIn} />}/>

                  {/* PROTECTED ROUTES */}
                  <Route element={<ProtectedRoute signedIn={signedIn} />}>

                    <Route path='app/*' element={<MainAppContainer />} />

                    <Route path='app/eq-survey/*' element={<EQSurvey />} />

                    <Route path='create-profile/*' element={<SignupUp />} />

                    <Route path='logout' element={<Logout />} />

                    <Route path='app/matching/*' element={<JobMatching />} />

                    <Route element={<AdminRoutes />}>

                      <Route path='admin/surveys' element={<SurveyAdmin />} />

                    </Route>

                  </Route>

                </Routes>
              </>

          }
          <PushNotifications signedIn={signedIn}/>
        </ErrorBoundary>
        <Toast/>
      </GoogleOAuthProvider>
    </Storage>
  );
}

export default App;
