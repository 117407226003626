import React, { useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { FirebaseContext } from '../../../Firebase';
import Button from '../../../Button';
import InputWithIcon from '../../../InputWithIcon';
import ModalBaseContainer from '../../../ModalBaseContainer';
import EmailIcon from '../../../../statics/Images/Input/EmailIcon';
import PasswordIcon from '../../../../statics/Images/Input/PasswordIcon';
import useToasts from '../../../../hooks/useToasts';
import { useAnalytics } from '../../../../hooks/Firebase';

/**
 * @summary Modal to change email
 * Modal to change email, uses url instead of handlers to execute action.
 *
 * @name ChangeEmailModal
 * @memberof SettingsList
 */
const ChangeEmailModal = () => {
  const navigate = useNavigate();
  const firebase = useContext(FirebaseContext);
  const { publishInfo, publishError } = useToasts();
  const location = useLocation();
  const { clickEvent, TYPES: { CLICK } } = useAnalytics();
  const onClose = () => navigate('..');

  const onSubmit = async (event) => {
    event.preventDefault();

    if (event.target.email.value !== event.target.email_confirm.value) {
      publishError('Email does not match');
      return;
    }

    try {
      await firebase.updateUserEmail(event.target.email.value, event.target.password.value);
    } catch (e) {
      if (e.code === 'auth/wrong-password') {
        publishError('Invalid password');
      }
      return;
    }

    navigate('..');
  };

  const resetPassword = () => {
    firebase.doSendPasswordResetEmail(firebase.auth.currentUser.email);
    publishInfo('You can find an email with the password reset link in you inbox!');
  };

  return (
    <>
      {/* Show can always be true, due to visibility handled by router */}
      <ModalBaseContainer show={true} onClose={onClose}>
        <h2>Email address</h2>
        <form onSubmit={onSubmit}>
          <div className='content-margin-base'>
            <InputWithIcon
              id='password'
              name='password'
              type='password'
              placeholder='Password'
              icon={<PasswordIcon />}
            />
            <div className='content-margin-base'>
              <Link className='link color-primary' to={location} onClick={resetPassword}>Forgot password?</Link>
            </div>
          </div>

          <div className='content-margin-base'>
            <InputWithIcon
              id='current_email'
              name='current_email'
              type='email'
              icon={<EmailIcon />}
              disabled
              placeholder='Current email address'
              defaultValue={firebase.auth.currentUser.email}
            />
          </div>
          <div className='content-margin-base'>
            <InputWithIcon
              id='email'
              name='email'
              type='email'
              placeholder='New email address'
              icon={<EmailIcon />}
            />
          </div>
          <div className='content-margin-base'>
            <InputWithIcon
              id='email_confirm'
              name='email_confirm'
              type='email'
              placeholder='Confirm email'
              icon={<EmailIcon />}
            />
          </div>
          <div className='btn-inline-wrapper'>
            <Button theme='btn-text' type='button' onClick={onClose}>Cancel</Button>
            <Button theme='btn-primary' type='submit'
              onClick={() => {
                clickEvent(CLICK.TYPE.EDIT, CLICK.CATEGORY.EMAIL, CLICK.LOCATION.SETTINGS);
              }}>Save</Button>
          </div>
        </form>
      </ModalBaseContainer>
    </>
  );
};

export default ChangeEmailModal;
