import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { StorageContext } from '../../../../components/Storage';
import { isEmptyObject } from '../../../../tools/IsEmptyObject';

import EQTestBanner from '../../../../components/EQTestBanner';
import EQTestResultsBanner from '../../../../components/EQTestResultsBanner';

import StartupFitBanner from '../../../../components/StartupFitBanner';
import StartupFitResultsBanner from '../../../../components/StartupFitResultsBanner';

import { useAnalytics } from '../../../../hooks/Firebase';

/**
 * Profile page
 * @name ProfileContent
 */
const ProfileContent = () => {
  const [appContext] = useContext(StorageContext);
  const { pageViewEvent, clickEvent, TYPES: { CLICK } } = useAnalytics();

  const [state, updateState] = useState({
    selectedSkills: [],
    skillsModal: false,
    // TODO: Refactor the rest...
    currentSurveyDone: false,
  });

  useEffect(async () => {
    updateState(s => ({
      ...s,
      selectedSkills: appContext.profile.skills || [],
    }));
  }, [appContext.isLoading, appContext.profile.skills]);

  // Set page title and send page view event and
  // Scrolls the window to the top when the component is mounted
  useEffect(() => {
    document.title = 'Profile | HROS Career Coach';
    pageViewEvent();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!appContext.isLoading) {
      const currentSurvey = appContext.eqResults?.find(survey => survey.surveyName === process.env.REACT_APP_CURRENT_EQ_SURVEY_NAME);

      if (currentSurvey && currentSurvey.results && currentSurvey.surveyName === process.env.REACT_APP_CURRENT_EQ_SURVEY_NAME) {
        updateState(s => ({
          ...s,
          currentSurveyDone: true,
        }));
      }

    }
  }, [appContext.isLoading]);

  return (
    <>

      <div className='profile-banners-wrapper'>
        <div className='profile-banners'>
          {!isEmptyObject(appContext.startupFitTestResults) ?
            <StartupFitResultsBanner />
            :
            <StartupFitBanner />
          }
          {state.currentSurveyDone ?
            <EQTestResultsBanner resultsID={appContext.eqResults?.find(survey => survey.surveyName === process.env.REACT_APP_CURRENT_EQ_SURVEY_NAME).surveyResultsID} />
            :
            <EQTestBanner />
          }
        </div>

        {/* WARNING: This solution only handles if there is only 2 eq test, needs refactor if needs to handle more than 2*/}
        {appContext.eqResults.length && appContext.eqResults?.find(survey => survey.surveyName !== process.env.REACT_APP_CURRENT_EQ_SURVEY_NAME) && appContext.eqResults?.find(survey => survey.surveyName !== process.env.REACT_APP_CURRENT_EQ_SURVEY_NAME).results ?
          <p lassName='content-margin-xlarge'>
            Access old EQ test result
            <Link
              to={`../../eq-survey/results/${appContext.eqResults?.find(survey => survey.surveyName !== process.env.REACT_APP_CURRENT_EQ_SURVEY_NAME).surveyResultsID}`}
              className='color-primary'
              onClick={() => {
                clickEvent(CLICK.TYPE.VIEW, CLICK.CATEGORY.EQ_TEST);
              }}>
              &nbsp;here
            </Link>
          </p>
          :
          null
        }
      </div>
    </>

  );
};

export default ProfileContent;
