import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

/**
 * Startup fit test results meter,
 * can be animated passing deg incrementally
 *
 * @name TestResultMeter
 *
 *
 * @param {number} width
 * @param {number} height
 * @param {number} deg Degree of the needle, from -90 to 90
 *
 */

const TestResultMeter = ({ deg, width, height }) => {
  return (
    <div className='result-meter-wrapper'>
      <svg width={width} height={height} viewBox='0 0 265 157' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fill='#fff' stroke='#eee' strokeWidth='2' opacity='0.9' d='M255.914 146.801C253.009 149.895 249.502 152.363 245.608 154.052C241.714 155.741 237.516 156.616 233.271 156.624H31.7295C27.4849 156.623 23.2856 155.751 19.3915 154.062C15.4974 152.373 11.9913 149.903 9.0902 146.804C6.18914 143.706 3.95481 140.045 2.5255 136.048C1.0962 132.052 0.502317 127.804 0.780619 123.569C2.0679 103.406 8.02055 83.8186 18.1694 66.3493C29.7599 46.2848 46.4268 29.624 66.4955 18.0407C86.5642 6.45748 109.328 0.359765 132.499 0.36023C155.671 0.360694 178.435 6.45932 198.503 18.0434C218.571 29.6274 235.237 46.289 246.827 66.3539C256.976 83.8183 262.931 103.401 264.22 123.559C264.494 127.795 263.899 132.042 262.47 136.039C261.042 140.036 258.811 143.698 255.914 146.801V146.801Z'/>
        <path fill='url(#gradient)' opacity='0.9' d='M240.038 125.163C238.96 108.721 234.105 92.7494 225.85 78.4892C216.388 62.1038 202.781 48.4973 186.394 39.0371C170.008 29.577 151.42 24.5965 132.499 24.5962C113.578 24.5959 94.9906 29.5757 78.6039 39.0352C62.2173 48.4948 48.6091 62.1008 39.147 78.486C30.8918 92.7471 26.0377 108.72 24.9626 125.163C24.9023 126.089 25.0326 127.017 25.3454 127.891C25.6582 128.764 26.1468 129.564 26.7811 130.241C27.4154 130.918 28.1819 131.458 29.0331 131.827C29.8843 132.196 30.8021 132.386 31.7298 132.386H50.0417C51.7492 132.382 53.3927 131.736 54.6449 130.575C55.8971 129.414 56.6661 127.824 56.7989 126.121C57.858 113.364 62.1235 101.081 69.1994 90.4127C76.2753 79.7446 85.932 71.0374 97.273 65.0995C108.614 59.1616 121.272 56.1857 134.07 56.4479C146.869 56.7102 159.394 60.2022 170.482 66.5997C182.022 73.2773 191.609 82.8635 198.288 94.4024C203.908 104.108 207.294 114.943 208.201 126.121C208.334 127.824 209.103 129.414 210.355 130.575C211.608 131.736 213.251 132.382 214.959 132.386H233.27C234.198 132.386 235.116 132.196 235.967 131.827C236.818 131.458 237.585 130.918 238.219 130.241C238.853 129.564 239.342 128.764 239.655 127.891C239.968 127.017 240.098 126.089 240.038 125.163V125.163Z'/>
        <defs>
          <linearGradient id='gradient' x1='48' y1='175' x2='500' y2='175' gradientUnits='userSpaceOnUse'>
            <stop stopColor='#80C69F'/>
            <stop offset='1' stopColor='#3A8B75'/>
          </linearGradient>
        </defs>
      </svg>

      <div className='pointer-wrapper'
        style={{
          transform: `rotate(${deg}deg)`,
          WebkitTransform: `rotate(${deg}deg)`,
        }}>
        <svg width='122' height='220' viewBox='0 0 123 220' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path fill='#fff' filter='url(#filter)' d='M57.8639 50.2285L54.1438 106.987L50.4238 163.745C50.4054 163.999 50.3943 164.246 50.4017 164.504C50.413 166.01 50.7258 167.498 51.3214 168.881C51.9171 170.264 52.7836 171.514 53.8699 172.557C54.9562 173.6 56.2403 174.415 57.6465 174.954C59.0527 175.493 60.5526 175.745 62.0577 175.695C63.5628 175.645 65.0427 175.294 66.4101 174.663C67.7775 174.032 69.0048 173.134 70.0196 172.021C71.0344 170.909 71.8162 169.604 72.3189 168.185C72.8216 166.765 73.035 165.259 72.9466 163.756L65.5286 50.2175C65.4728 49.2374 65.0441 48.3158 64.3305 47.6417C63.6169 46.9676 62.6724 46.592 61.6907 46.592C60.709 46.592 59.7645 46.9676 59.0509 47.6417C58.3373 48.3158 57.9086 49.2374 57.8528 50.2175L57.8639 50.2285ZM67.8269 164.515C67.8273 165.727 67.468 166.913 66.7945 167.922C66.121 168.93 65.1635 169.716 64.0432 170.18C62.9228 170.645 61.69 170.766 60.5005 170.53C59.3111 170.293 58.2185 169.709 57.361 168.852C56.5035 167.994 55.9195 166.902 55.683 165.712C55.4465 164.523 55.5681 163.29 56.0324 162.17C56.4966 161.049 57.2827 160.092 58.2912 159.418C59.2998 158.745 60.4854 158.385 61.6981 158.386C62.503 158.385 63.3002 158.544 64.044 158.852C64.7878 159.159 65.4636 159.611 66.0328 160.18C66.602 160.749 67.0534 161.425 67.3613 162.169C67.6691 162.913 67.8274 163.71 67.8269 164.515Z'/>
          <defs>
            <filter id='filter' x='0.40' y='0.590' width='122' height='220' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
              <feFlood floodOpacity='0' result='BackgroundImageFix'/>
              <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/>
              <feOffset dy='2'/>
              <feGaussianBlur stdDeviation='24'/>
              <feComposite in2='hardAlpha' operator='out'/>
              <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0.1 0 0 0 0 0.1 0 0 0 0.4 0'/>
              <feBlend mode='normal' in2='BackgroundImageFix' result='dropShadow_1'/>
              <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/>
              <feOffset dy='4'/>
              <feGaussianBlur stdDeviation='2'/>
              <feComposite in2='hardAlpha' operator='out'/>
              <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0.015 0 0 0 0 0.15 0 0 0 0.25 0'/>
              <feBlend mode='normal' in2='dropShadow_1' result='dropShadow_2'/>
              <feBlend mode='normal' in='SourceGraphic' in2='dropShadow_2' result='shape'/>
            </filter>
          </defs>
        </svg>
      </div>
    </div>
  );
};

TestResultMeter.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  deg: function(props, deg) {
    if(props[deg] < -90 || props[deg] > 90) {
      return new Error(`Deg value expected to be from -90 to 90. Value passed: ${props[deg]}`);
    }
  },
};
TestResultMeter.defaultProps = {
  width: 300,
  height: 200,
  deg: -90,
};

export default TestResultMeter;
