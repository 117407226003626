import React from 'react';
import { Routes ,Route } from 'react-router-dom';

import CatchAll from '../../components/CatchAll';
import JobMatchingOverview from './JobMatchingOverview';

import CareerPredictionLoading from './CareerPredictionLoading';
import CareerPredictionResults from './CareerPredictionResults';

/**
 * JobMatching routes
 * @name JobMatching
 * @component
 * @category Pages
 * @subcategory JobMatching
 */
const JobMatching = () => {
  return (
    <>
      <Routes>
        <Route exact path='/' element={<JobMatchingOverview />} />

        <Route path='career-prediction' element={<CareerPredictionLoading />} />

        <Route path='career-prediction-results' element={<CareerPredictionResults />} />

        <Route path='*' element={ <CatchAll />} />
      </Routes>
    </>

  );
};

export default JobMatching;
