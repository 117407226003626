import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

import BackButton from '../../../../components/BackButton';
import { useAnalytics } from '../../../../hooks/Firebase';
import NotificationSettings from '../../../../components/Settings/NotificationSettings';
import GeneralSettings from '../../../../components/Settings/GeneralSettings';
import CatchAll from '../../../../components/CatchAll';
import ConfirmDelete from '../../../../components/Settings/ConfirmDelete';

/**
 * Settings page
 * @name Settings
 */
const Settings = () => {
  const { pageViewEvent } = useAnalytics();

  // Set page title and send page view event
  // Scrolls the window to the top when the component is mounted
  useEffect(() => {
    document.title = 'Settings | HROS Career Coach';
    pageViewEvent();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='yellow-background'></div>
      <div className='yellow-wrapper'>

        <Routes>
          <Route exact path='/'
            element={
              <div className='title-group yellow'>
                <h1 className='title'>Settings</h1>
              </div>
            } />
          <Route path='notifications'
            element={
              <div className='title-group yellow'>
                <BackButton color='#303030' url={'..'}/>
                <h1 className='title'>Notification Settings</h1>
              </div>
            } />
          <Route path='confirm-delete'
            element={
              <div className='title-group yellow'>
                <BackButton color='#303030' url={'..'}/>
                <h1 className='title'>Delete Account</h1>
              </div>
            } />
        </Routes>

        <div className='title-group-content'>
          <Routes>
            <Route exact path='/' element={<GeneralSettings />} />
            <Route path='notifications' element={<NotificationSettings />} />
            <Route path='confirm-delete' element={<ConfirmDelete />} />
            <Route path='*' element={ <CatchAll />} />
          </Routes>
          <Routes>
            <Route exact path='/' element={<div className='settings-app-version'>App Version: Early Development</div>} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default Settings;
