import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { StorageContext } from '../../../components/Storage';
import Loader from '../../../statics/Images/Loader';
import { FirebaseContext } from '../../../components/Firebase';
import useToasts from '../../../hooks/useToasts';

import MatchToucan from '../../../statics/Images/Illustrations/Toucans/MatchToucan.svg';
import JobEntryForm from '../../../components/JobsList/JobEntryForm';
import JobEntryModal from '../../../components/JobsList/JobEntryModal';
import LearnMoreModal from './LearnMoreModal';
import WarningDialog from '../../../components/JobsList/WarningDialog';

import { useAnalytics } from '../../../hooks/Firebase';

/**
 * JobMatchingOverview component
 * @name JobMatchingOverview
 * @component
 * @category Pages
 * @subcategory JobMatching
 */
const JobMatchingOverview = () => {
  const { pageViewEvent, clickEvent, TYPES: { CLICK } } = useAnalytics();
  const firebase = useContext(FirebaseContext);
  const [appContext, dispatch] = useContext(StorageContext);
  const { publishSuccess, publishError } = useToasts();
  const [state, updateState] = useState({
    jobEntry: {},
    showMore: false,
    hasChanges: false,
    showEntryModal: false,
    showWarningModal: false,
  });

  useEffect(() => {
    document.title = 'Career Prediction Info | HROS Career Coach';
    pageViewEvent();
    window.scrollTo(0, 0);
  }, []);

  const onChange = (event) => {
    let newJobEntry = event.target.value ? { ...state.jobEntry } : {};
    switch (event.target.name) {
      case 'start_date':
        newJobEntry[event.target.name] = firebase.convertDate(new Date(event.target.value));
        updateState({
          ...state,
          jobEntry: newJobEntry,
        });
        break;
      case 'end_date':
        newJobEntry[event.target.name] = event.target.value === null ? null : firebase.convertDate(new Date(event.target.value));
        updateState({
          ...state,
          jobEntry: newJobEntry,
        });
        break;
      case 'job':
        newJobEntry['jobOccupationName'] = event.target.value.jobOccupationName;
        newJobEntry['jobOccupationID'] = event.target.value.jobOccupationRef?.id;
        newJobEntry['jobOccupationRef'] = event.target.value.jobOccupationRef;
        newJobEntry['jobTitle'] = event.target.value.jobTitle;
        newJobEntry['jobTitleRef'] = event.target.value.jobTitleRef;
        updateState({
          ...state,
          jobEntry: newJobEntry,
        });
        break;
      default:
        newJobEntry[event.target.name] = event.target.value;
        updateState({
          ...state,
          jobEntry: newJobEntry,
        });
    }
  };

  const onFormSubmitHandler = async (event) => {
    event.preventDefault();

    await firebase
      .updateUser({ career: [state.jobEntry] })
      .then(() => {
        publishSuccess('You successfully added a job.');
      })
      .catch((error) => {
        console.error(error);
        return publishError('Something went wrong, please try again later');
      });

    await dispatch({
      type: 'SET_CAREER_INFO',
      payload: [state.jobEntry],
    });

    toggleEntryModal();
  };

  const showEntryModal = () => {
    if (state.hasChanges) {
      updateState({
        ...state,
        showWarningModal: true,
      });
      return;
    }
    toggleEntryModal();
  };

  const toggleEntryModal = () => {
    updateState({
      ...state,
      showWarningModal: false,
      showEntryModal: !state.showEntryModal,
    });
  };

  const hasChanges = () => {
    updateState({
      ...state,
      hasChanges: true,
    });
  };

  const cancelWarning = () => {
    updateState({
      ...state,
      showWarningModal: false,
    });
  };

  const skipEditing = () => {
    updateState({
      ...state,
      showWarningModal: false,
      showEntryModal: false,
      jobEntry: {},
    });
  };

  const showMoreModal = () => {
    updateState({
      ...state,
      showMore: !state.showMore,
    });
  };

  return (
    <>
      { appContext.isLoading ?
        <Loader className='loading-animation'/>
        :
        <div className='eq-prescreen'>
          <div className='eq-prescreen-tucan'>
            <img src={MatchToucan} alt='HROS logo'/>
          </div>
          <div className='eq-prescreen-header'>
            <div className='eq-prescreen-header-group'>
              <h1 className='eq-prescreen-title content-margin-large'>Jobs matching You.<br/><span className='title-second-row'>Not just your CV.</span></h1>
            </div>
          </div>
          <div className='eq-prescreen-wrapper'>
            <div className='eq-prescreen-container padding-layout-horisontal'>
              <h1 className='eq-prescreen-title-desktop content-margin-large'>Jobs matching You.<br/><span className='title-second-row'>Not just your CV.</span></h1>
              <h4 className='content-margin-base'>Find the job that is right for your personality in just a few clicks.</h4>
              <ul className='content-list'>
                <li className='content-margin-base'><span className='list-item-dot'></span>First, fill in your job history so we can see your qualifications</li>
                <li className='content-margin-base'><span className='list-item-dot'></span>Then fill out the Emotional Intelligence (EQ) test to share more insights about your profile</li>
                <li className='content-margin-base'><span className='list-item-dot'></span>We recommend you job titles and then tailored jobs based on your personality</li>
              </ul>
              { appContext.career.length > 0 ?
                <Link
                  className='btn btn-primary content-margin-xlarge'
                  to='./career-prediction'
                  role='button'
                  onClick={() => {
                    clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.START_JOB_MATCHING, CLICK.LOCATION.EXPLANATION_SCREEN);
                  }}>Start job matching</Link>
                :
                <div className='btn btn-primary content-margin-xlarge'
                  onClick={() => {
                    toggleEntryModal();
                    clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.CAREER, CLICK.LOCATION.EXPLANATION_SCREEN);
                  }}>Add latest job</div>
              }
              <p>Want to learn more? Read more about job matching <span className='link color-primary'
                onClick={() => {
                  showMoreModal();
                  clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.READ_MORE_MATCHING, CLICK.LOCATION.EXPLANATION_SCREEN);
                }}>here</span></p>
            </div>
          </div>

          <LearnMoreModal
            show={state.showMore}
            onClose={showMoreModal}
          />

          <JobEntryModal show={state.showEntryModal} onClose={showEntryModal}>
            <JobEntryForm
              jobData={state.jobEntry || {}}
              isEdit={false}
              onChange={onChange}
              onFormSubmit={onFormSubmitHandler}
              hasChanges={hasChanges}
            />
            <WarningDialog
              show={state.showWarningModal}
              cancelAction={cancelWarning}
              skipAction={skipEditing}
            />
          </JobEntryModal>
        </div>
      }
    </>
  );
};

export default JobMatchingOverview;
