import React,{ useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import { StorageContext } from '../Storage';

/**
 * Wrapper that redirects user outside of the app in case it's not auth
 * @name ProtectedRoute
 * @param {bool} signedIn If user is sign in on firebase, if not redirects to signIn
 */
const ProtectedRoute = ({ signedIn }) => {
  const [appContext] = useContext(StorageContext);
  const navigate = useNavigate();

  useEffect(() => {
    if(!appContext.profile.username && !appContext.isLoading) {
      navigate('/create-profile/profile');
    }
  }, [appContext]);

  return (
    <>
      {
        !signedIn ?
          <Navigate to={{
            pathname: '/',
            state: { referrer: location },
          }}/>
          :
          <Outlet />
      }
    </>
  );
};

ProtectedRoute.propTypes = { signedIn: PropTypes.bool.isRequired };

export default ProtectedRoute;
