import React from 'react';
import { Link } from 'react-router-dom';

import BannerBaseContainer from '../BannerBaseContainer';
import TestIcon from '../../statics/Images/TestIcon.svg';

import { useAnalytics } from '../../hooks/Firebase';

/**
 * Banner to access the career prediction results
 * @name EQTestResultsBanner
 *
 */
const EQTestResultsBanner = ({ resultsID }) => {
  const { clickEvent, TYPES: { CLICK } } = useAnalytics();

  return (
    <BannerBaseContainer>
      <img className='banner-icon' src={TestIcon} />
      <h3 className='content-margin-base'>Emotional Inteligence Test</h3>
      <p className='content-margin-base'>Your personality matters to tailor the best job recommendations for you.</p>
      <Link className='btn btn-primary' to={`../../eq-survey/results/${resultsID}`} role='button'
        onClick={() => {
          clickEvent(CLICK.TYPE.VIEW, CLICK.CATEGORY.EQ_TEST);
        }}>
        <div className='btn btn-primary content-margin-base'>Results</div>
      </Link>
    </BannerBaseContainer>
  );
};

export default EQTestResultsBanner;
