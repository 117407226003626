import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import './styles.scss';

import CommentIcon from '../../../../statics/Images/CommentIcon';
import LikeIcon from '../../../../statics/Images/LikeIcon';
import DotsIcon from '../../../../statics/Images/DotsIcon.svg';
import defaultAvatar from '../../../../statics/Images/Illustrations/Toucans/AppIconToucan.svg';

import OptionsMenu from '../OptionsMenu';
import OpenGraphPreview from '../../../../components/OpenGraphPreview';
import { FirebaseContext } from '../../../../components/Firebase';
import { LinkItUrl } from 'react-linkify-it';
import { useAnalytics } from '../../../../hooks/Firebase';

const ICON_COLOR = '#9c9c9c';
const ICON_COLOR_SELECTED = '#eb4a5a';
/**
 * Post entry
 * @name CommunityItem
 * @param {Object} post - post data
 * @param {Funtion} likeAction - Give like to post function
 */
const CommunityItem = ({ post, likeAction, fetchMorePostsTrigger, fetchMorePosts }) => {
  const datetime = post.createdAt.toDate();
  const date = datetime.toISOString().slice(0, 10);
  const time = datetime.toTimeString().slice(0, 5);
  const firebase = useContext(FirebaseContext);
  const { clickEvent, TYPES: { CLICK } } = useAnalytics();
  const ref = useRef();

  const [state, updateState] = useState({
    showOptionsMenu: false,
    profilePicture: null,
    isVisible: false,
    fetchedNewPosts: false,
  });

  // Check if post is visible in viewport
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      updateState(s => ({
        ...s,
        isVisible: entry.isIntersecting,
      }))
    );

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  // Check if last item is visible
  // If last item is visible, fetch more posts
  useEffect(() => {
    if (fetchMorePostsTrigger && state.isVisible && !state.fetchedNewPosts) {
      fetchMorePosts();

      updateState(s => ({
        ...s,
        fetchedNewPosts: true,
      }));
    }
  },[state.isVisible]);

  const liked = post.likes.find((like) => like.id === firebase.auth.currentUser.uid);

  /* Fetches the profile picture of the user who posted. */
  useEffect(async () => {
    const [folder, fileName] = ['profile-pictures', post.userRef.id];
    const url = await firebase.getImageUrl(folder, fileName);

    updateState(s => ({
      ...s,
      profilePicture: url,
    }));
  }, [post.userRef]);

  const showOptionsMenuHandler = () => {
    updateState(s => ({
      ...s,
      showOptionsMenu: !state.showOptionsMenu,
    }));
  };

  return (
    <div className='community-item' ref={ref}>
      <div className='community-item-header content-margin-base'>
        <div className='community-avatar-group'>
          { /* Display profile card modal only for TMs */ }
          <div className='avatar-community-wrapper'>
            { state.profilePicture ?
              <img className='avatar-community' src={state.profilePicture} alt='' /> :
              <img className='avatar-community' src={defaultAvatar} alt='' />
            }
          </div>
          <div>
            <div className='user-name-community'>{ post.username }
              { /* Display badge only for TMs */ }
              <div className='tm-badge'>
                <div className='tm-badge-icon'></div>
              </div>
            </div>
            <div className='user-title-community'>Group: { post.communityName === 'RECOMMENDED' ? 'For You' : post.communityName }</div>
          </div>
        </div>
        <div className='options-menu-wrapper'>
          <img className='dots-icon' src={DotsIcon} width='16px' height='4px' onClick={() => showOptionsMenuHandler()} />
          { state.showOptionsMenu && <OptionsMenu post={post} /> }
        </div>
      </div>
      <LinkItUrl className='color-primary community-item-link'>
        <p className='content-margin-base community-item-content'
          onClick={() => {
            clickEvent(CLICK.TYPE.NAVIGATION, CLICK.CATEGORY.EXTERNAL, CLICK.LOCATION.COMMUNITY);
          }}>{ post.text }</p>
      </LinkItUrl>
      {
        post.openGraph && <OpenGraphPreview openGraph={post.openGraph}/>
      }
      <div className='community-item-time content-margin-base'>{ time } | { date }</div>
      <div className='community-item-footer'>
        <div className='num-comments'>
          <Link
            to={`${post.communityID}/${post.id}`}
            className='comments-link'
            onClick={() => {
              clickEvent(CLICK.TYPE.NAVIGATION, CLICK.CATEGORY.COMMUNITY_COMMENT_READ, CLICK.LOCATION.COMMUNITY);
            }}>
            <CommentIcon color={ICON_COLOR} />
            <span>{ post.numberOfComments }</span>
          </Link>
        </div>
        <div role='button' onClick={() => likeAction(post)} className='num-likes'>
          <LikeIcon color={liked ? ICON_COLOR_SELECTED : ICON_COLOR} />
          <span style={{ color: liked ? ICON_COLOR_SELECTED : ICON_COLOR }}>{ post.likes.length }</span>
        </div>
      </div>
    </div>
  );
};

CommunityItem.propTypes = {
  community: PropTypes.object,
  post: PropTypes.object,
  likeAction: PropTypes.func,
};

export default CommunityItem;
