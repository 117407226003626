import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import SettingsList from '../../components/Settings/SettingsList';

import { FirebaseContext } from '../../components/Firebase';

const UnsubscribeEmail = () => {
  const firebase = useContext(FirebaseContext);
  // Used to force the button being checked by default
  const [fakeCheck, setFakeCheck] = useState(true);
  const { uid } = useParams();

  const toggleEmailNotifications = async (e) => {
    firebase.updateTargetUser(uid,{ 'notificationSettings.emailEnabled': e.target.checked });
    setFakeCheck(!fakeCheck);
  };

  const settingsList = [
    {
      title: 'Email Notifications',
      settings: [
        {
          title: 'Enabled',
          action: undefined,
          option: {
            name: 'toggle',
            checked: fakeCheck,
            onChange: (e) => toggleEmailNotifications(e),
          },
        },
      ],
    },
  ];

  return (
    <div className='padding-layout-horisontal'>
      <SettingsList settingsList={settingsList} />
    </div>
  );
};

export default UnsubscribeEmail;
