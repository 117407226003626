import React from 'react';
import { Link } from 'react-router-dom';
import { useAnalytics } from '../../hooks/Firebase';
import BannerBaseContainer from '../BannerBaseContainer';
import TestIcon from '../../statics/Images/TestIcon.svg';

/**
 * Banner to access EQ test
 *
 * @name EQTestBanner
 */
const EQTestBanner = () => {
  const { clickEvent, TYPES: { CLICK } } = useAnalytics();

  return (
    <BannerBaseContainer>
      <img className='banner-icon' src={TestIcon} />
      <h3 className='content-margin-base'>Emotional Inteligence Test</h3>
      <p className='content-margin-base'>Your personality matters to tailor the best job recommendations for you.</p>
      <Link className='btn btn-primary' to='../../eq-survey/about' role='button'
        onClick={() => {
          clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.EQ_TEST);
        }}>
        <div className='btn btn-primary content-margin-base'>Take the test</div>
      </Link>
    </BannerBaseContainer>
  );
};

export default EQTestBanner;
