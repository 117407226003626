import React from 'react';
import PropTypes from 'prop-types';

const GenderIcon = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.7605 10.2737C21.2887 10.2737 23.3383 8.22415 23.3383 5.69588C23.3383 3.16761 21.2887 1.11804 18.7605 1.11804C16.2322 1.11804 14.1826 3.16761 14.1826 5.69588C14.1826 8.22415 16.2322 10.2737 18.7605 10.2737Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10"/>
      <path d="M18.7607 10.2737V15.882" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.8672 13.3986H20.6554" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.23898 15.882C8.76725 15.882 10.8168 13.8324 10.8168 11.3041C10.8168 8.77585 8.76725 6.72627 6.23898 6.72627C3.7107 6.72627 1.66113 8.77585 1.66113 11.3041C1.66113 13.8324 3.7107 15.882 6.23898 15.882Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10"/>
      <path d="M6.23926 1.11804V6.72627" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.56201 3.11L6.23901 1.118L3.91602 3.11" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

GenderIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};
GenderIcon.defaultProps = {
  width: '24px',
  height: '20px',
  color: '#9E9E9E',
};

export default GenderIcon;
