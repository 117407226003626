import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import ToggleSwitch from '../../../ToggleSwitch';

/**
 * Renders the item list entry
 *
 * @name SettingsListItem
 * @memberof SettingsList
 *
 * @param {string} title Text with the group settings title
 * @param {array, <Object>} settings Settings inside group
  */
const SettingsListItem = ({ title, settings }) => {
  return (
    <div className='settings-card-container content-margin-large'>
      <h3>{title}</h3>
      <div className='settings-card-items'>
        {
          settings.map((item, index) => {
            const { Icon } = item;
            return (
              <button
                key={index}
                disabled={item.disabled}
                onClick={item.action}
              >
                {Icon && <Icon height='25px' width='25px' color={item.disabled ? '#a4a4a4' : item.dangerous ? '#eb4a5a' : '#636363' }/>}
                <span className={item.dangerous ? 'label dangerous' : 'label'}>
                  {item.title}
                </span>
                <span className='option-item'>
                  { !item.disabled ?
                    {
                      'chevron': <span className='navigation-item'>&rsaquo;</span>,
                      'toggle': <ToggleSwitch isOn={item.option?.checked} onChange={item.option?.onChange} />,
                    }[item.option?.name]
                    :
                    <span className='disabled'>&lt; feature coming soon &gt;</span>
                  }
                </span>
              </button>
            );
          })
        }
      </div>
    </div>
  );
};
SettingsListItem.propTypes = {
  title: PropTypes.string,
  settings: PropTypes.array,
};

export default SettingsListItem;
