import React from 'react';

import ModalBaseContainer from '../../components/ModalBaseContainer';

/**
 * Modal displayed for future features
 * @name FutureFeatureModal
 *
 *
 * @param {bool} show Defines if modal is shown or not
 * @param {function} onClose Handler to close the modal
 *
 */
const FutureFeatureModal = ({ show, onClose }) => {
  return (
    <ModalBaseContainer show={show} onClose={onClose}>
      <h2>Coming soon!</h2>
      <p className='centred-text'>We’re working hard to make sure this feature arrives as early as possible. Don’t worry, you’ll be notified when this happens!</p>
    </ModalBaseContainer>
  );
};

export default FutureFeatureModal;
