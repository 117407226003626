import React, { useContext, useEffect, useState } from 'react';

import './styles.scss';

import { fetchJobList } from '../../../../tools/FetchTUApi';
import Loader from '../../../../statics/Images/Loader';
import JobItem from './JobItem';
import { StorageContext } from '../../../../components/Storage';
import { useAnalytics } from '../../../../hooks/Firebase';
import { FirebaseContext } from '../../../../components/Firebase';
import ConfusedToucan from '../../../../statics/Images/Illustrations/Toucans/ConfusedToucan.svg';

/**
 * Job matching no results screen
 * @name JobMatchingNoJobs
 * @component
 * @category Pages
 * @subcategory JobMatching
  */
const JobMatchingNoJobs = ({ jobs, updateJobBoard }) => {
  const [appContext] = useContext(StorageContext);
  const [loading, setLoading] = useState(true);
  const firebase = useContext(FirebaseContext);
  const { pageViewEvent, clickEvent, TYPES: { CLICK } } = useAnalytics();

  // Set page title and send page view event and
  // Scrolls the window to the top when the component is mounted
  useEffect(() => {
    document.title = 'Jobs | HROS Career Coach';
    pageViewEvent();
    window.scrollTo(0, 0);
  }, []);

  useEffect(async () => {
    if (appContext.isLoading) return;
    if (jobs.length > 0) return setLoading(false);

    let token = firebase.auth.currentUser.accessToken;

    let result = null;

    // result = await fetchJobList(token, state.career[0]?.jobTitle || null); Filtered by job title solution not required for current PR
    result = await fetchJobList(token);

    if (!result.success) {
      console.error('Failed to fetch job list:', result);
    }

    // TU API returns dataCount and pageCount to fetch more jobs if needed
    updateJobBoard(result.data.lines);
    setLoading(false);

  }, [appContext.isLoading]);

  return (
    <>
      {loading ?
        <Loader className='loading-animation' /> :
        jobs.length > 0 ?
          <>
            <p className='content-margin-xlarge'>Wow, look at you and your unique experience!<br />For now, we don’t have suitable jobs for you, but this can change any minute. While you wait, check out what else we have to offer - you might be surprised!</p>
            {jobs.map((job, idx) => (
              <JobItem key={`job-${idx}`} jobData={job}
                onClick={() => {
                  clickEvent(CLICK.TYPE.NAVIGATION, CLICK.CATEGORY.JOB_BOARD, CLICK.LOACATION.JOBS);
                }}
              />
            ))}
          </>
          :
          <>
            <img className='no-jobs-toucan' src={ConfusedToucan} alt='HROS toucan mascot with question mark' />
            <div className='centred-text content-margin-xlarge'>
              <h3 className='content-margin-large'>No jobs available</h3>
              <p className='content-margin-large'>We currently don&apos;t have any jobs we think are good enough for you. You just want to browse some jobs and prove us wrong?</p>
              <p>Visit our <a className='link color-primary' href='https://match.hros.io/en/jobs' target='_blank' rel='noopener noreferrer'>job board</a></p>

            </div>
          </>
      }
    </>
  );
};

export default JobMatchingNoJobs;
