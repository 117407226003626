import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import usePushNotifications from '../../hooks/usePushNotifications';
import useToasts from '../../hooks/useToasts';
import { StorageContext } from '../Storage';
import { hasPermissions } from '../../tools/Notifications';

/**
 * Empty render function to use push notifications across the app
 *
 * @name PushNotifications
 *
 * @param {boolean} signedIn Only enables push notifications if user is signed in
 * @returns {null}
 */
const PushNotifications = ({ signedIn }) => {
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [state] = useContext(StorageContext);
  const { publishPushNotification } = useToasts();
  const enablePushNotifications = usePushNotifications(publishPushNotification);

  useEffect(() => {
    setNotificationsEnabled(state.notificationSettings.pushEnabled && hasPermissions());
  },[state.profile.registeredDevice, state.notificationSettings.pushEnabled]);

  useEffect(() => {
    if (signedIn && !state.isLoading && notificationsEnabled) {
      const unsubscribe = enablePushNotifications();
      return () => unsubscribe();
    }
  }, [signedIn, state.isLoading, notificationsEnabled]);
  return (null);
};

PushNotifications.propTypes = { signedIn: PropTypes.bool };
PushNotifications.defaultProps = { signedIn: false };

export default PushNotifications;
