import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.scss';

import { StorageContext } from '../../../components/Storage';
import { FirebaseContext } from '../../../components/Firebase';
import { useAnalytics } from '../../../hooks/Firebase';
import { createPersistentToken } from '../../../tools/FetchTUApi';
import useToasts from '../../../hooks/useToasts';
import ToucanBlack from '../../../statics/Images/ToucanBlack.svg';
import Filters from '../../../statics/Images/Filters.svg';

import Loader from '../../../statics/Images/Loader';
import FilterJobsModal from './FilterJobsModal';
import JobMatchingNoJobs from './JobMatchingNoJobs';

const INITIAL_STATE = {
  Results: null,
  loading: true,
  error: null,
  ResultsFiltered: [],
  showFilterModal: false,
  filteredMatchingJobs: [],
  jobBoard: [],
};

/**
 * Job matching results screen
 * @name JobMatchingResults
 * @component
 * @category Pages
 * @subcategory JobMatching
  */

const JobMatchingResults = () => {
  const { outcomeEvent, clickEvent, TYPES: { CLICK } } = useAnalytics();
  const [appContext] = useContext(StorageContext);
  const firebase = useContext(FirebaseContext);
  const { errorEvent } = useAnalytics();
  const navigate = useNavigate();
  const { publishError } = useToasts();

  const [state, setState] = useState(INITIAL_STATE);

  const transformResults = (filteredMatchingJobs) => {
    const filteredResults = {};
    // Filter results based on user input
    Object.keys(state.Results).forEach(key => {
      if (filteredMatchingJobs.includes(key)) {
        filteredResults[key] = state.Results[key];
      }
    });

    // Removed key from object
    const transformedResults = Object.values(filteredResults).map(item => item.map(subitem => subitem));

    const flattenedArray = transformedResults.reduce((accumulator, currentValue) => {
      return accumulator.concat(currentValue);
    }, []).flat();

    // Remove duplicates
    const mergedArray = flattenedArray.reduce((accumulator, currentValue) => {
      accumulator.concat(currentValue);
      if (!accumulator.some(item => item.id === currentValue.id)) {
        accumulator.push(currentValue);
      }
      return accumulator;
    }, []).flat();

    if (mergedArray.length === 0) {
      outcomeEvent('no_jobs_screen');
    }

    return mergedArray;
  };

  // Get filtered jobs from local storage
  useEffect(() => {

    if (!appContext.isLoading) {

      const jobsFilteredStorage = JSON.parse(localStorage.getItem('matching_jobs') || '[]');

      if (!jobsFilteredStorage.includes(appContext.career[0].jobTitle)) {
        jobsFilteredStorage.push(appContext.career[0].jobTitle);
      }

      localStorage.setItem('matching_jobs', JSON.stringify(jobsFilteredStorage));

      setState(s => ({
        ...s,
        filteredMatchingJobs: jobsFilteredStorage,
      }));
    }
  }, [appContext.isLoading]);

  useEffect(() => {

    // Check to see if appContext is available in case the user refreshes the page
    if (appContext.isLoading) {
      return;
    }

    if (appContext.career.length === 0) {
      navigate('/app/profile');
      publishError('Please fill your career history first');
      return;
    }

    setState(s => ({
      ...s,
      loading: true,
    }));

    async function fetchNewMatching() {
      const userID = firebase.auth.currentUser.uid;

      let headers = new Headers();
      headers.append('Content-Type', 'application/x-www-form-urlencoded');

      const options = {
        method: 'GET',
        headers,
      };

      const payload = await fetch(`https://europe-west1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/get_match/${userID}`, options);

      const results = await payload.json();

      // Redundant check, but just in case
      if (results.message === 'User career is empty') {
        errorEvent('User tried to do job matching without career: ' + results.message);
        navigate('/app/dashboard');
        publishError('Please fill your career history first');
        return;
      }

      if (payload.status !== 200) {
        errorEvent('Fetching failed with status code: ' + payload.status);
        navigate('/app/dashboard');
        publishError('Something went wrong, please try again later');
        return;
      }

      setState(s => ({
        ...s,
        Results: results.Results,
      }));
    }

    fetchNewMatching();
  }, [appContext.isLoading]);

  useEffect(async () => {
    if (state.Results) {
      const resultsFiltered = await transformResults(state.filteredMatchingJobs);
      setState(s => ({
        ...s,
        ResultsFiltered: resultsFiltered,
        loading: false,
      }));
    }
  }, [state.Results]);

  const changeFilterArray = async (filteredArray) => {
    const resultsFiltered = await transformResults(filteredArray);
    localStorage.setItem('matching_jobs', JSON.stringify(filteredArray));

    setState(s => ({
      ...s,
      filteredMatchingJobs: filteredArray,
      ResultsFiltered: resultsFiltered,
    }));
  };

  const showFilterJobsModalHandler = () => {
    setState((s) => {
      return {
        ...s,
        showFilterModal: !s.showFilterModal,
      };
    });
  };

  const updateJobBoard = (jobBoard) => {
    setState(s => ({
      ...s,
      jobBoard,
    }));
  };

  const redirectWithToken = async (job) => {
    const persistentToken = await createPersistentToken(firebase.auth.currentUser.accessToken);

    const jobUrl = process.env.REACT_APP_PROJECT_ID === 'hero-career-coach' ?
      `https://match.hros.io/en/jobs/${job.url}` :
      `https://next.talentuno.test.gwsdev.com/en/jobs/${job.url}`;

    if (!persistentToken.success) {
      window.open(jobUrl, '_blank');
      return;
    }

    window.open(jobUrl + `?persistenttoken=${persistentToken.data.persistentToken}`, '_blank' );
  };

  return (
    <div className='padding-layout-horisontal padding-layout-vertical'>
      <h1 className='content-margin-large'>Jobs</h1>
      <div className='filters-wrapper'
        onClick={() => {
          showFilterJobsModalHandler();
          clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.FILTER_JOBS, CLICK.LOCATION.MTCHING_RESULTS);
        }}><img src={Filters} /></div>
      {
        state.loading ?
          <Loader className='loading-animation' />
          :
          <>
            {
              state.ResultsFiltered.length > 0 ?
                <>
                  <p className='content-margin-xlarge'>These jobs have been selected for you, based on your experience and goals.</p>
                  {state.ResultsFiltered.map((job, index) => (
                    <div className='res-job-title' key={index}>
                      <div onClick={() => redirectWithToken(job)}>
                        <div className='job-item-container'>
                          <div className='job-item-image-wrapper'>
                            {
                              job.companyLogo ?
                                <img src={job.companyLogo.url} alt='Company logo' /> :
                                <img className='company-logo-placeholder' src={ToucanBlack} alt='HROS toucan mascot' />
                            }
                          </div>
                          <div className='job-item-content-container'>
                            <h3>{job.title}</h3>
                            <div className='job-item-description'>
                              {job.company}
                            </div>
                            <div className='job-item-description'>
                              {job.location?.country ? job.location.country : null}
                            </div>
                            <div className='job-item-description'>
                              {job.location?.city ? job.location.city : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
                :
                <JobMatchingNoJobs matchingResultsLoading={state.loading} fetchTrigger={state.ResultsFiltered.length} jobs={state.jobBoard} updateJobBoard={updateJobBoard} />
            }
            {state.showFilterModal && <FilterJobsModal show={state.showFilterModal} onClose={showFilterJobsModalHandler} changeFilterArray={changeFilterArray} />}
          </>
      }
      <div className='centred-text content-margin-large'>
        <p>Find more jobs on our <a className='link color-primary' href='https://match.hros.io/en/jobs' target='_blank' rel='noopener noreferrer'>job board</a></p>
      </div>
    </div>
  );
};

export default JobMatchingResults;
