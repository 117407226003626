import React from 'react';
import { Route, Routes } from 'react-router-dom';

import NotificationsOverview from './NotificationsOverview';

/**
 * Notifications routing component
 * @name Notifications
 */
const Notifications = () => {

  return (
    <div className='content'>
      <Routes>
        <Route path='/' element={ <NotificationsOverview /> }/>
      </Routes>
    </div>
  );
};

export default Notifications;
