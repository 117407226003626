import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = ({ color }) => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.67 0.330145C12.5655 0.225487 12.4413 0.142462 12.3047 0.0858151C12.168 0.0291679 12.0215 0 11.8736 0C11.7257 0 11.5792 0.0291679 11.4426 0.0858151C11.3059 0.142462 11.1818 0.225487 11.0772 0.330145L6.49991 4.90733L1.9227 0.330145C1.71148 0.118929 1.42499 0.000282879 1.12628 0.000282879C0.827574 0.000282879 0.54111 0.118929 0.329892 0.330145C0.118674 0.541361 0 0.827847 0 1.12655C0 1.42526 0.118674 1.71172 0.329892 1.92293L4.9071 6.50017L0.329892 11.0773C0.225307 11.1819 0.14234 11.3061 0.085739 11.4427C0.0291381 11.5794 4.67531e-09 11.7258 0 11.8737C-4.67531e-09 12.0216 0.0291381 12.1681 0.085739 12.3047C0.14234 12.4414 0.225307 12.5655 0.329892 12.6701C0.434477 12.7747 0.558626 12.8577 0.695273 12.9143C0.831919 12.9709 0.978377 13 1.12628 13C1.27419 13 1.42064 12.9709 1.55729 12.9143C1.69394 12.8577 1.81811 12.7747 1.9227 12.6701L6.49991 8.09283L11.0771 12.67C11.1817 12.7746 11.3059 12.8575 11.4425 12.9141C11.5792 12.9707 11.7256 12.9998 11.8735 12.9998C12.0214 12.9998 12.1679 12.9707 12.3045 12.9141C12.4412 12.8575 12.5653 12.7746 12.6699 12.67C12.7745 12.5654 12.8575 12.4412 12.9141 12.3046C12.9707 12.1679 12.9998 12.0215 12.9998 11.8736C12.9998 11.7257 12.9707 11.5792 12.9141 11.4426C12.8575 11.3059 12.7745 11.1818 12.6699 11.0772L8.09272 6.50017L12.6699 1.92301C12.7746 1.81845 12.8576 1.69429 12.9142 1.55763C12.9708 1.42098 13 1.2745 13 1.12658C13 0.978654 12.9709 0.83218 12.9142 0.695522C12.8576 0.558864 12.7746 0.434719 12.67 0.330145V0.330145Z"
        fill={color}/>
    </svg>

  );
};

CloseIcon.propTypes = { color: PropTypes.string.isRequired };

export default CloseIcon;
