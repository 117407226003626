import React, { useContext, useState, useEffect } from 'react';

import { FirebaseContext } from '../../../components/Firebase';

import useToasts from '../../../hooks/useToasts';

import ResetPasswordMsgScreen from './ResetPasswordMsgScreen';

import Button from '../../../components/Button';
import InputWithIcon from '../../../components/InputWithIcon';

import BackButton from '../../../components/BackButton';
import EmailIcon from '../../../statics/Images/Input/EmailIcon';
import RainbowTucan from '../../../statics/Images/RainbowTucan.svg';

const INITIAL_STATE = {
  email: '',
  isOverlay: false,
  error: '',
};

/**
 * Form to reset password for auth with email and password
 * @name ResetPassword
 */
const ResetPassword = () => {
  const firebase = useContext(FirebaseContext);
  const [state, setState] = useState(INITIAL_STATE);
  const { publishError } = useToasts();
  const { publishInfo } = useToasts();

  useEffect(() => {
    if(state.error?.includes('user-not-found')){
      publishError('There is no account associated with this email address.');
    }
    if(state.error?.includes('auth/too-many-requests')){
      publishError('Error: We received too many requests for password reset. Please try again later or contact office@speedinvest-heroes.com');
    }
  },[state.error]);

  const handleResetLink = (event) => {
    event.preventDefault();

    const { email } = state;

    firebase
      .doSendPasswordResetEmail(email)
      .then(() => {
        console.debug('Email exists!');
        setState({
          ...state,
          email,
          isOverlay: true,
        });
        publishInfo('Please check your email to reset your password.');
      }).catch((err) => {
        console.error(err);
        setState({
          ...state,
          error: err.message,
        });
      });
  };

  const onChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
    if (state.error) {
      setState({
        ...state,
        error: null,
      });
    }
  };

  return (
    <div className='signup-auth-wrapper'>
      <div className='signup-tucan'>
        <img src={RainbowTucan} alt='HROS logo'/>
      </div>
      <div className='signup-wrapper'>
        <div className='signup-back-btn'><BackButton color='#303030'/></div>
        <div className='signup-container'>
          <form className='logged-out-form' onSubmit={handleResetLink}>
            <h1 className='centred-content'>Forgot password?</h1>
            <p className='centred-content'>Enter your email address and we’ll send you a password re-set link!</p>
            <div className='content-margin-large'>
              <InputWithIcon
                icon={<EmailIcon />}
                id='email'
                name='email'
                type='email'
                placeholder='E-mail'
                onChange={onChange}
                required={true}
                autoComplete='e-mail'
                error={state.error?.includes('user-not-found')}
              />
            </div>
            <div className='content-margin-large'>
              <Button theme={'btn-primary'} type='submit' disabled={state.error?.includes('auth/too-many-requests')}>Request re-set link</Button>
            </div>
          </form>

          { state.isOverlay &&
            <ResetPasswordMsgScreen handleResetLink={handleResetLink} error={state.error}/>
          }
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
