import React from 'react';
import PropTypes from 'prop-types';

const Commentcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="19" viewBox="0 0 23 19" fill="none">
      <path stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M18.1821 15.1528V17.024C18.1821 17.1712 18.136 17.3147 18.0502 17.4344C17.9645 17.5541 17.8434 17.6439 17.704 17.6913C17.5646 17.7386 17.4139 17.7411 17.273 17.6984C17.1321 17.6557 17.0081 17.57 16.9185 17.4532L14.6935 14.5559C14.602 14.4367 14.4843 14.3402 14.3495 14.2738C14.2148 14.2074 14.0665 14.1728 13.9163 14.1728H3.83744C3.21078 14.1728 2.60978 13.9239 2.16666 13.4808C1.72355 13.0376 1.47461 12.4367 1.47461 11.81V3.6327C1.47461 3.00604 1.72355 2.40505 2.16666 1.96193C2.60978 1.51881 3.21078 1.26987 3.83744 1.26987H19.162C19.7887 1.26987 20.3897 1.51881 20.8328 1.96193C21.2759 2.40505 21.5248 3.00604 21.5248 3.6327V11.81C21.5248 12.1203 21.4637 12.4275 21.345 12.7142C21.2262 13.0009 21.0522 13.2614 20.8328 13.4808C20.6134 13.7002 20.3529 13.8742 20.0662 13.993C19.7795 14.1117 19.4723 14.1728 19.162 14.1728C18.9021 14.1728 18.6529 14.2761 18.4691 14.4598C18.2853 14.6436 18.1821 14.8929 18.1821 15.1528V15.1528Z"/>
      <path stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M5.33887 5.66336H17.6612"/>
      <path stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M5.33887 9.77937H17.6612"/>
    </svg>
  );
};
Commentcon.propTypes = { color: PropTypes.string };
Commentcon.defaultProps = { color: '#9c9c9c' };

export default Commentcon;
