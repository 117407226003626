import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

import CountryList from '../../statics/Data/Countries.json';
import HomeIcon from '../../statics/Images/HomeIcon';

/**
 * Constants
 *
 * @readonly
 *
 * @const {array, <Object>} sortedCountries Sorts the country list receive in json
 * @const {array, <Object>} dropdownItems Adds remote object to the array
 *
 */

const sortedCountries = CountryList
  .reduce((acc, curr) => {
    // Only add countires with 2 character codes
    if (curr.code.length === 2) {
      acc.push({
        name: curr.name,
        icon: curr.emoji,
      });
    }
    return acc;
  }, [])
  .sort((a, b) => a.name.localeCompare(b.name));

const dropdownItems = [
  {
    name: 'Remote',
    icon: <HomeIcon width='12px' height='12px' color='transparent' fill='#636363'/>,
  },
  ...sortedCountries,
];

/**
 * Dropdown list with countries.
 *
 * @name CountryDropdown
 *
 * @param {string} name
 * @param {string} value
 * @param {func} onChange On change handler
 *
 */
const CountryDropdown = ({ name, value, onChange }) => {
  const inputRef = useRef();
  const [inputValue, setInputValue] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [filteredResults, setFilteredResults] = useState([]);
  const [ignoreBlur, setIgnoreBlur] = useState(false);
  const [floatType, setFloatType] = useState(value ? 'floating-input not-empty' : 'floating-input');

  const handleInputChange = (e) => {
    if (e.target.value === '') {
      onChange({
        target: {
          name,
          value: '',
        },
      });
    }
    setSelectedCountry(null);
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (inputValue) {
      const results = dropdownItems.filter((item) => item.name.toLowerCase().includes(inputValue.toLowerCase()));
      setFilteredResults(results);
    }
  },[inputValue]);

  useEffect(() => {
    if (value) {
      dropdownItems.find(item => item.name === value) && setSelectedCountry(dropdownItems.find(item => item.name === value));
    }
  }, []);

  useEffect(() => {
    if(selectedCountry) {
      setFloatType('floating-input not-empty');
    } else {
      setFloatType('floating-input');
    }
  }, [selectedCountry]);

  return (
    <div className='country-dropdown content-margin-base'>
      <div className='input-container input-wrapper floating-label-content' onClick={() => {setFilteredResults(dropdownItems); setDrawerOpen(true);}}>
        <input
          autoComplete='off'
          onFocus={(e) => e.target.select()}
          onBlur={() => {ignoreBlur ? null : setDrawerOpen(false);}}
          onChange={handleInputChange}
          placeholder={''}
          ref={inputRef}
          value={selectedCountry ? selectedCountry.name : inputValue}
          className={floatType}
        />
        <label className='floating-label'>
          <span>Location</span>
        </label>
        <div className='chevron-container'>
          { drawerOpen ? <div className='arrow-up' /> : <div className='arrow-down' /> }
        </div>

      </div>
      {
        drawerOpen && (
          <ul className='countries-list'>
            {
              filteredResults.length ?
                filteredResults.map(item =>
                  <li
                    className={selectedCountry?.name === item.name ? 'selected' : ''}
                    key={item.name}
                    onMouseDown={() => setIgnoreBlur(true)}
                    onClick={() => {
                      setSelectedCountry(item);
                      setInputValue(item.name);
                      onChange({
                        target: {
                          name,
                          value: item.name,
                        },
                      });
                      setFilteredResults([]);
                      setIgnoreBlur(false);
                      setDrawerOpen(false);
                    }}>
                    <span className='item-icon'>{item.icon}</span>
                    {item.name}
                  </li>
                ) :
                <li>No countries found for &lsquo;{inputValue}&rsquo;</li>
            }
          </ul>
        )
      }
    </div>
  );
};
CountryDropdown.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default CountryDropdown;
