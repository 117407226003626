import React from 'react';
import PropTypes from 'prop-types';

const UserIcon = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 170 166" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path stroke={color} strokeWidth="12" d="M155.66 160.2H14.1998C13.038 160.196 11.8902 159.946 10.8321 159.466C9.77392 158.986 8.82932 158.288 8.0604 157.417C7.29147 156.546 6.71566 155.522 6.37084 154.413C6.02602 153.303 5.92001 152.133 6.05977 150.98C7.42641 139.315 11.3644 128.099 17.5897 118.141C23.815 108.182 32.1726 99.7284 42.0598 93.39C43.3965 92.5125 44.9607 92.0449 46.5598 92.0449C48.1588 92.0449 49.723 92.5125 51.0598 93.39C61.143 100.006 72.94 103.53 84.9998 103.53C97.0596 103.53 108.857 100.006 118.94 93.39C120.277 92.5125 121.841 92.0449 123.44 92.0449C125.039 92.0449 126.603 92.5125 127.94 93.39C137.827 99.7279 146.185 108.181 152.411 118.14C158.636 128.099 162.574 139.315 163.94 150.98C164.081 152.145 163.971 153.327 163.618 154.446C163.265 155.566 162.677 156.596 161.893 157.47C161.109 158.343 160.147 159.038 159.072 159.509C157.997 159.98 156.833 160.216 155.66 160.2V160.2Z"/>
      <path stroke={color} strokeWidth="12" d="M84.9297 77.54C104.685 77.54 120.7 61.5252 120.7 41.77C120.7 22.0148 104.685 6 84.9297 6C65.1744 6 49.1597 22.0148 49.1597 41.77C49.1597 61.5252 65.1744 77.54 84.9297 77.54Z"/>
    </svg>
  );
};

UserIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};
UserIcon.defaultProps = {
  width: '24px',
  height: '24px',
};

export default UserIcon;
