import { useState } from 'react';

const useOpenGraph = () => {
  const openGraphURL = !process.env.REACT_APP_USE_EMULATORS ? `https://europe-west1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/getOpenGraphData` : 'http://localhost:5001/hero-career-coach-dev/europe-west1/getOpenGraphData';
  const [openGraph, setOpenGraph] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [previousRequest, setPreviousRequest] = useState(null);

  const fetchOpenGraphData = async (url) => {
    if (url === previousRequest) {
      return; // No need to run again
    }

    setIsLoading(true);
    const response = await fetch(`${openGraphURL}?url=${encodeURI(url)}`);
    setPreviousRequest(url);

    if (response.status !== 200) {
      setOpenGraph(null);
      setError({
        reason: 'Request Failed',
        code: response.status,
        payload: await response.json(),
      });
      setIsLoading(false);
      return;
    }

    const data = await response.json();

    setOpenGraph({
      title: data['og:title'] || null,
      type: data['og:type'] || null,
      description: data['og:description'] || null,
      image: data['og:image'] || null,
      url: data['og:url'] || null,
    });
    setError(null);
    setIsLoading(false);
  };

  const resetMetaData = () => {
    setOpenGraph(null);
    setIsLoading(false);
    setPreviousRequest(null);
    setError(null);
  };

  return {
    fetchOpenGraphData,
    resetMetaData,
    error,
    isLoading,
    openGraph,
  };

};

export default useOpenGraph;
