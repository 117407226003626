import React from 'react';
import PropTypes from 'prop-types';

import ModalBaseContainer from '../../../components/ModalBaseContainer';

/**
 * Modal container for the job form
 * @name JobEntryModal
 *
 *
 * @param {bool} show Defines if modal is shown or not
 * @param {function} onClose Handler to close the modal
 * @param {node} children Data to be rendered
 *
 */
const JobEntryModal = ({ show, onClose, children }) => {
  return (
    <ModalBaseContainer show={show} onClose={onClose}>
      {children}
    </ModalBaseContainer>
  );
};
JobEntryModal.propTypes = { children: PropTypes.node };

export default JobEntryModal;
