import React, { useEffect } from 'react'; // useState
import { Link } from 'react-router-dom';

import './styles.scss';

import { useAnalytics } from '../../hooks/Firebase';
import RainbowTucan from '../../statics/Images/RainbowTucan.svg';
import GoogleButton from '../../components/GoogleButton';

/**
 * Splash screen
 * @name Splash
 */
const Splash = () => {
  const { pageViewEvent, clickEvent, TYPES: { CLICK }, customEvent } = useAnalytics();

  // Set page title and send page view event
  useEffect(() => {
    document.title = 'HROS Career Coach';
    pageViewEvent();
    customEvent('landing_on_splash');
  }, []);

  return (
    <div className='auth-wrapper centred-text'>
      <div className='splash-tucan'>
        <img src={RainbowTucan} alt='HROS logo'/>
      </div>
      <div className='splash-wrapper'>
        <div className='splash-container'>
          <div className='splash-links-container content-margin-xlarge'>
            <h1 className='splash-title content-margin-xlarge'>Reach your full potential!</h1>
            <Link to='/signup' role='button' className='btn btn-primary content-margin-base'
              onClick={() => {
                clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.EMAIL_SIGN_UP, CLICK.LOCATION.SPLASH);
              }}>Sign up with Email</Link>
            <GoogleButton/>
            <p className='content-margin-base'>Already a hero? <Link className='link color-primary' to='/login'>Log in</Link></p>
          </div>
        </div>
      </div>
      <div className='privacy-policy content-margin-large'>
        <p>By signing up you agree to our</p>
        <Link className='link color-primary' to='/privacy-policy'>Privacy Policy</Link>
      </div>
    </div>
  );
};

export default Splash;
