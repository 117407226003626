import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { FirebaseContext } from '../../../../components/Firebase';

import ThumbDown from '../../../../statics/Images/ThumbDown.svg';
import ThumbUp from '../../../../statics/Images/ThumbUp.svg';

const JobPredicted = ({ index, state, jobTitle, setState }) => {
  const navigate = useNavigate();
  const firebase = useContext(FirebaseContext);

  const predictionResultsRating = (jobRating) => {
    return state.feedback.predictionResults.push(jobRating);
  };

  const populateResultsForMatching = (job) => {
    const filteredMatchingJobs = JSON.parse(localStorage.getItem('matching_jobs') || '[]');

    if(!filteredMatchingJobs.includes(job)) {
      filteredMatchingJobs.push(job);
    }

    localStorage.setItem('matching_jobs', JSON.stringify(filteredMatchingJobs));
  };

  const hideCard = () => {
    setState((s) => {
      return {
        ...s,
        resultCounter: s.resultCounter < s.jobTitles.length - 1 ? s.resultCounter + 1 : s.resultCounter,
      };
    });
  };

  const showResultsScreenSkip = () => {
    uploadPredictionRating();
    navigate('/app/matching-results');
  };

  const uploadPredictionRating = async () => {
    const feedback = state.feedback;
    try {
      await firebase.uploadPredictionRating({ [Date.now()]: feedback });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={index === state.resultCounter ? 'pred-res-card-show' : 'pred-res-card-hide'} key={index}>
      <h1 className='content-margin-large'>{jobTitle.title}</h1>
      <p className='content-margin-large'>Landing a job as a {jobTitle.title} means you are part of the {jobTitle.occupation} squad. {jobTitle.occupationDescription}.</p>
      <div className='action-icons'>
        <img onClick={() => {
          hideCard();
          predictionResultsRating({
            title: jobTitle.title,
            liked: false,
          });
          index === state.jobTitles.length - 1 && showResultsScreenSkip();
        }}src={ThumbDown} />
        <img onClick={() => {
          hideCard();
          predictionResultsRating({
            title: jobTitle.title,
            liked: true,
          });
          populateResultsForMatching(jobTitle.title);
          index === state.jobTitles.length - 1 && showResultsScreenSkip();
        }} src={ThumbUp} />
      </div>
    </div>
  );
};

export default JobPredicted;
