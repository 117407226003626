import React, { useState } from 'react';

import './styles.scss';

import { useAnalytics } from '../../../hooks/Firebase';
import LocationIcon from '../../../statics/Images/LocationIcon';
import RemoteIcon from '../../../statics/Images/RemoteIcon.svg';
import TrashIcon from '../../../statics/Images/TrashIcon.svg';
import CountriesList from '../../../statics/Data/Countries.json';

import JobDeleteModal from '../../JobsList/JobDeleteModal';

/**
 * Group displaying jobs on the same company on the Career timeline
 *
 * @name JobEntry
 * @memberof CareerShowcase
 * @protected
 *
 * @param {array, <Object>} jobGroup Array with jobs on the same company
 * @param {function} editJobHandler Func handling the behaviour when user clicks jobs entry
 * @param {function} deleteJobHandler Func handling the behaviour when user clicks to delete entry
 * @param {function} profileUID User ID
 */
const JobEntry = ({ jobGroup, editJobHandler, deleteJobHandler, profileUID }) => {
  const { clickEvent, TYPES: { CLICK } } = useAnalytics();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState();

  const formatDuration = (start_date, end_date) => {
    let months = ((end_date ? end_date.toDate().getFullYear() : new Date().getFullYear()) - start_date.toDate().getFullYear()) * 12;
    months -= start_date.toDate().getMonth();
    months += end_date ? end_date.toDate().getMonth() : new Date().getMonth();
    // returned months elapsed +1 to avoid having 0 months elapsed
    months += 1;

    const years = Math.floor(months/12);
    const monthConverted = months % 12;

    return `${years ? years === 1 ? years + ' Year' : years + ' Years' : ''} ${monthConverted ? monthConverted === 1 ? monthConverted + ' Month' : monthConverted + ' Months' : ''}`;
  };

  const showDeleteModalHandler = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const onCloseDelete = () => {
    // Close modal
    showDeleteModalHandler();
  };

  return (
    <>
      <div className='company-entry-container'>
        <h4>{jobGroup[0].company}</h4>
        <ul>
          {
            jobGroup.map((doc, idx) =>(
              <li className='job-entry-container' key={idx}>
                {idx + 1 < jobGroup.length && <div className='entry-line'></div> }
                <div className='job-entry-title-wrapper'>
                  <p className='job-entry-title'
                    onClick={() => {
                      !profileUID ? clickEvent(CLICK.TYPE.EDIT, CLICK.CATEGORY.CAREER, CLICK.LOCATION.PROFILE_PAGE) : '';
                      !profileUID ? editJobHandler(doc) : '';
                    }}>
                    {doc.jobTitle}
                    <span className='overflow-shadow'></span>
                  </p>
                  { !profileUID &&
                    <img src={TrashIcon} className='trash-icon' alt='Trash icon'
                      onClick={() => {
                        showDeleteModalHandler();
                        setSelectedJob(doc);
                        clickEvent(CLICK.TYPE.DELETE, CLICK.CATEGORY.CAREER, CLICK.LOCATION.PROFILE_PAGE);
                      }}/>
                  }
                </div>
                <div className='job-entry-content-container'>
                  { doc.country === 'Remote' ?
                    <>
                      <div className='location-icon-wrapper'>
                        <img src={RemoteIcon} width='10px' height='14px' />
                      </div>
                      <p className='location-job-wrapper'>
                        &nbsp;Remote
                      </p>
                    </>
                    :
                    <>
                      <div className='location-icon-wrapper'>
                        <LocationIcon width='10px' height='14px'/>
                      </div>
                      <p className='location-job-wrapper'>&nbsp;{CountriesList.find(c => c.name === doc.country)?.code || 'N/A'}</p>
                    </>
                  }
                  <p>&nbsp;|&nbsp;{formatDuration(doc.start_date, doc.end_date)}</p>
                </div>
              </li>
            ))
          }
        </ul>
      </div>
      { !profileUID &&
        <JobDeleteModal
          show={showDeleteModal}
          onClose={onCloseDelete}
          deleteJobHandler={deleteJobHandler}
          jobToDelete={selectedJob}
        />
      }
    </>
  );
};

export default JobEntry;
