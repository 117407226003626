import React, { useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { StorageContext } from '../Storage';

const ACCESS_ROLES = [
  'admin',
];

/**
 * Admin routes
 * @name AdminRoutes
 */

// TODO: improve this! It renders for a split second before redirecting
const AdminRoutes = () => {
  const [state] = useContext(StorageContext);
  const navigate = useNavigate();

  useEffect(() => {
    if(!state.isLoading && !state.claims.some(role => ACCESS_ROLES.includes(role))) {
      navigate('/app/dashboard', { replace: true });
    }
  }, [state]);

  return (
    <>
      {state.claims.some(role => ACCESS_ROLES.includes(role)) && <Outlet />}
    </>
  );
};

export default AdminRoutes;
