import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import './styles.scss';

import Button from '../../../../components/Button';
import CheckEmail from '../../../../statics/Images/CheckEmail.svg';

/**
 * Reset password message screen
 * @name ResetPasswordMsgScreen
 * @memberof ResetPassword
 *
 * @param {func} handleResetLink Handler to trigger the reset on firebase
 * @param {string} error Error message received by firebase
 */
const ResetPasswordMsgScreen = ({ handleResetLink, error }) => {
  // const { publishInfo } = useToasts();

  const navigate = useNavigate();

  const routeChange = () => {
    let path = '/login';
    navigate(path);
  };

  return (
    <div className='reset-password-msg-screen padding-layout-horisontal'>
      <div className='reset-pass-content'>
        <img className='screen-icon' src={CheckEmail} alt='Check your email' />
        <h1 className='content-margin-large'>{!error?.includes('auth/too-many-requests') ? 'Check your email!' : 'Oops...'}</h1>
        {
          !error?.includes('auth/too-many-requests') &&
            <p className='content-margin-large'>We’ve sent you an email with a link to re-set your password.</p>
        }
        <div className='content-margin-large'>
          <Button className='content-margin-large' theme={'btn-primary'} onClick={routeChange}>Return to Log in</Button>
        </div>
        <div className='content-margin-large'>
          <Button className='content-margin-large' theme={'btn-secondary'} disabled={error?.includes('auth/too-many-requests')} onClick={handleResetLink}>I didn’t get the link</Button>
        </div>
      </div>
    </div>
  );
};
ResetPasswordMsgScreen.propTypes = {
  handleResetLink: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default ResetPasswordMsgScreen;
