import React from 'react';
import { Navigate, useLocation } from 'react-router';

/**
 * Component to catch if wrong URL and redirects to '/'
 *
 * @name CatchAll
 *
 */
const CatchAll = () => {
  const location = useLocation();

  return (
    <Navigate to={{
      pathname: '/app/dashboard',
      state: { referrer: location },
    }}/>
  );
};

export default CatchAll;
