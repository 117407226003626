import React from 'react';
import { Routes ,Route } from 'react-router-dom';

import ProfileOverview from './ProfileOverview';

/**
 * Profile routing
 * @name Profile
 */
const Profile = () => {
  return (
    <Routes>
      <Route path='/' element={<ProfileOverview />}/>
    </Routes>
  );
};

export default Profile;
