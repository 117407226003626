import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FirebaseContext } from '../../../components/Firebase';
import { useLocation } from 'react-router-dom';
import useToasts from '../../../hooks/useToasts';

import './styles.scss';

import Button from '../../../components/Button';
import BackButton from '../../../components/BackButton';
import GoogleButton from '../../../components/GoogleButton';

import EmailIcon from '../../../statics/Images/Input/EmailIcon';
import PasswordIcon from '../../../statics/Images/Input/PasswordIcon';
import InputWithIcon from '../../../components/InputWithIcon';
import { useAnalytics } from '../../../hooks/Firebase';
import RainbowTucan from '../../../statics/Images/RainbowTucan.svg';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: '',
};

/**
 * Form to signup with email and password
 * @name SignUpForm
 * @memberof SignUp
 */
const SignUpForm = () => {
  const sendUserCreatedMessageUrl = !process.env.REACT_APP_USE_EMULATORS ? `https://europe-west1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/sendUserCreatedMessage` : 'http://localhost:5001/hero-career-coach-dev/europe-west1/sendUserCreatedMessage';
  const [state, setState] = useState(INITIAL_STATE);
  const [trigger, setTrigger] = useState(false);
  const firebase = useContext(FirebaseContext);
  const { pageViewEvent, clickEvent, TYPES: { CLICK }, customEvent } = useAnalytics();
  const { publishError } = useToasts();
  const navigate = useNavigate();

  let location = useLocation();

  // Set page title and send page view event
  useEffect(() => {
    document.title = 'Sign Up | HROS Career Coach';
    pageViewEvent();
  }, []);

  useEffect(() => {
    // Check if user received an authentication token while being on the signup page, then Navigate him to create profile
    if (location.pathname === '/signup' && Boolean(firebase.auth.currentUser) && trigger) {
      customEvent('sign_up_complete');
      navigate('/create-profile/profile');
    }
  }, [firebase.auth.currentUser, trigger]);

  /**
   * Firebase error handling, displaying with toast
   */
  useEffect(() => {
    if (state.error?.includes('auth/email-already-in-use')) {
      publishError('There already exists an account registered with this email address. Try to log in!');
    }
    if (state.error?.includes('auth/weak-password')) {
      publishError('Password should be at least 6 characters.');
    }
  }, [state.error]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { email, password } = state;

    await firebase
      .doCreateUserWithEmailAndPassword(email, password)
      .then(async (authUser) => {

        await firebase.setUser(authUser.user.uid, {
          email,
          username: null,
          firstName: null,
          lastName: null,
          createdAt: firebase.convertDate(new Date(authUser.user.metadata.creationTime)),
          lastLoginAt: firebase.convertDate(new Date(authUser.user.metadata.lastSignInTime)),
          career: [],
          roles: [],
          notificationSettings: {
            pushEnabled: false,
            emailEnabled: true,
          },
        });

        fetch(sendUserCreatedMessageUrl, {
          method: 'POST',
          body: JSON.stringify({
            email,
            password,
            firebaseId: authUser.user.uid,
          }),
        });

        setTrigger(true);
      })
      .catch((error) => {
        setState({
          ...state,
          error: error.message,
        });
        return;
      });
  };

  const onChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div className='signup-auth-wrapper'>
      <div className='signup-tucan'>
        <img src={RainbowTucan} alt='HROS logo' />
      </div>
      <div className='signup-wrapper'>
        <div className='signup-back-btn'><BackButton color='#303030' /></div>
        <div className='signup-container'>
          <form className='logged-out-form' onSubmit={handleSubmit}>
            <h1 className='content-margin-xlarge'>Sign up</h1>
            <h2 className=''>Create an account and get started on your journey!</h2>

            <div className='content-margin-large'>
              <InputWithIcon
                icon={<EmailIcon />}
                id='email'
                name='email'
                type='email'
                placeholder='E-mail'
                onChange={onChange}
                required={true}
                autoComplete='email'
                error={state.error?.includes('auth/email-already-in-use')}

              />
            </div>
            <div className='content-margin-large'>
              <InputWithIcon
                icon={<PasswordIcon />}
                id='password'
                name='password'
                type='password'
                placeholder='Password'
                onChange={onChange}
                required={true}
                autoComplete='new-password'
                error={state.error?.includes('auth/weak-password')}
              />
            </div>
            <div className='content-margin-xlarge'>
              <Button theme={'btn-primary'} type='submit'
                onClick={() => {
                  clickEvent(CLICK.TYPE.ADD, CLICK.CATEGORY.EMAIL_SIGN_UP, CLICK.LOCATION.SIGN_UP);
                }}>Sign up</Button>
            </div>
            <GoogleButton />
            <p className='content-margin-large'>
              Already have an account? <Link className='link color-primary' to='/login'>Log in</Link>
            </p>
          </form>
        </div>

        <div className='privacy-policy centred-text content-margin-large'>
          <p>By signing up you agree to our</p>
          <Link className='link color-primary' to='/privacy-policy'>Privacy Policy</Link>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
