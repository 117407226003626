import React from 'react';
import PropTypes from 'prop-types';

const PasswordIcon = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 130 144" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path stroke={color} strokeWidth="12" strokeLinecap="round" d="M117.71 55.14H12.2901C8.86038 55.14 6.08008 57.9203 6.08008 61.35V131.69C6.08008 135.12 8.86038 137.9 12.2901 137.9H117.71C121.14 137.9 123.92 135.12 123.92 131.69V61.35C123.92 57.9203 121.14 55.14 117.71 55.14Z"/>
      <path stroke={color} strokeWidth="12" strokeLinecap="round" d="M28.28 53.14V42.82C28.28 33.0812 32.1487 23.7413 39.0351 16.855C45.9214 9.96868 55.2613 6.09998 65 6.09998C74.7388 6.09998 84.0787 9.96868 90.965 16.855C97.8513 23.7413 101.72 33.0812 101.72 42.82V53.14"/>
      <path stroke={color} strokeWidth="12" strokeLinecap="round" d="M65 108.6V84.4399"/>
    </svg>
  );
};

PasswordIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};
PasswordIcon.defaultProps = {
  width: '24px',
  height: '24px',
};

export default PasswordIcon;
