import { useContext, useState } from 'react';

import { FirebaseContext } from '../../components/Firebase';

const useNotifications = (dispatch) => {
  const firebase = useContext(FirebaseContext);
  const [state, setState] = useState({});

  const onChange = (querySnapshot) => {
    const notifications = [];

    for (let doc of querySnapshot.docs) {
      notifications.push({
        id: doc.id,
        ref: doc.ref,
        ...doc.data(),
      });
    }

    notifications.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());

    dispatch({
      type: 'SET_NOTIFICATIONS',
      payload: notifications,
    });

    setState((s) => ({
      ...s,
      notifications,
    }));
  };

  const listenToNotifications = async () => {
    const unsubscribe = await firebase.addNotificationsListener(firebase.auth.currentUser.uid, onChange);

    setState((s) => {
      if (s.unsubscribe && typeof s.unsubscribe === 'function') {
        s?.unsubscribe(); // Unsubscribe previous listener if there is any
      }

      return {
        ...s,
        unsubscribe,
      };
    });
  };

  const unsubscribeNotifications = () => {
    if (state.unsubscribe && typeof state.unsubscribe === 'function') {
      state.unsubscribe();
    }
  };

  return {
    listenToNotifications,
    unsubscribeNotifications,
  };
};

export default useNotifications;
