import React from 'react';

const InputWithLabel = ({ name, onChange, type, value, label = name, className }) => {
  return(
    <div className={`input-wrapper floating-label-content ${className}`}>
      <input
        id={name}
        name={name}
        type={type}
        placeholder=''
        onChange={onChange}
        className={value ? 'floating-input not-empty' : 'floating-input'}
      />
      <label className='floating-label'>
        <span style={{ textTransform: 'capitalize' }}>{label}</span>
      </label>
    </div>
  );
};

export default InputWithLabel;
