import React from 'react';

const Complete = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 66">
      <path d="M17 33.9315L26.7222 44L47 23" fill="none" stroke={color} strokeWidth="4.5" strokeLinecap="round"/>
      <circle cx="33" cy="33" r="30.75" fill="none" stroke={color} strokeWidth="4.5"/>
    </svg>

  );
};

export default Complete;
