import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.scss';

import { StorageContext } from '../../../components/Storage';
import { isEmptyObject } from '../../../tools/IsEmptyObject';

import InitialLoading from '../../../components/InitialLoading';
import TestResultMeter from '../../../statics/Images/TestResultMeter';
import StartupFitResultsScoreBoard from './StartupFitResultsScoreBoard';

import BackButton from '../../../components/BackButton';
import { useAnalytics } from '../../../hooks/Firebase';

const StartupFitResults = () => {
  const navigate = useNavigate();
  const [state] = useContext(StorageContext);
  const [globalScore, setGlobalScore] = useState();
  const { pageViewEvent } = useAnalytics();

  // Set page title and send page view event
  // Scrolls the window to the top when the component is mounted
  useEffect(() => {
    document.title = 'Startup Fit Results | HROS Career Coach';
    pageViewEvent();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if(isEmptyObject(state.startupFitTestResults) && !state.isLoading){
      navigate('/app/startup-fit');
    }
  }, [state.startupFitTestResults, state.isLoading]);

  useEffect(() => {
    if (state.startupFitTestResults.startupFitResults?.length){
      // Converts result percentage from scale [0, 100] to scale [-90, 90]
      const valueConverted = ( state.startupFitTestResults.startupFitResults[0].score - 0 ) * ( 90 - (-90) ) / ( 100 - 0 ) + (-90);
      setGlobalScore(valueConverted);
    }
  },[state]);

  return (
    <>
      {state.isLoading || isEmptyObject(state.startupFitTestResults) ?
        <>
          {/* Placeholder for a loading animation while fetching results */}
          <InitialLoading/>
        </>
        :
        <>
          <div className='yellow-background'></div>
          <div className='yellow-wrapper'>
            <div className='title-group yellow'>
              <BackButton color='#303030' url={'/app/profile'}/>
              <h1 className='title'>Test result</h1>
            </div>
            <div className='title-group-content padding-layout-horisontal'>
              <div className='startup-fit-meter-wrapper'>
                <TestResultMeter deg={globalScore}/>
              </div>
              <p className='centred-text'>Your startup-fit score is</p>
              <h2 className='startup-fit-results-text centred-text content-margin-large'>
                {state.startupFitTestResults.startupFitResults[0].score}%
              </h2>
              <StartupFitResultsScoreBoard results={state.startupFitTestResults}/>
            </div>
            <div className='copy-text content-margin-large'>POWERED BY MINDONE</div>
          </div>
        </>
      }
    </>
  );
};

export default StartupFitResults;
