import React, { useContext, useReducer } from 'react';
import './styles.scss';

import { StorageContext } from '../../../../../../components/Storage';
import { FirebaseContext } from '../../../../../../components/Firebase';

import InputWithIcon from '../../../../../../components/InputWithIcon';
import UserIcon from '../../../../../../statics/Images/Input/UserIcon';
import Button from '../../../../../../components/Button';
import DatePicker from '../../../../../../components/DatePicker';
import GenderPicker from '../../../../../../components/GenderPicker';

/**
 * @name EditProfileDetailsForm
 */
const EditProfileDetailsForm = ({ setActiveTab }) => {
  const [appContext, dispatch] = useContext(StorageContext);
  const firebase = useContext(FirebaseContext);

  const [state, setState] = useReducer(
    (prev, next) => {
      const nextEvent = {
        ...prev,
        ...next,
      };

      if (next.firstName !== undefined) {
        nextEvent.payload = {
          ...nextEvent.payload,
          firstName: nextEvent.firstName.trim(),
          username: `${nextEvent.firstName} ${nextEvent.lastName || appContext.profile.lastName}`,
        };

      }

      if (next.lastName !== undefined) {
        nextEvent.payload = {
          ...nextEvent.payload,
          lastName: nextEvent.lastName.trim(),
          username: `${nextEvent.firstName || appContext.profile.firstName} ${nextEvent.lastName}`,
        };
      }

      if (next.gender) { nextEvent.payload.gender = nextEvent.gender; }
      if (next.birthdate) { nextEvent.payload.birthdate = nextEvent.birthdate; }

      if (!nextEvent.firstName.trim() || !nextEvent.lastName.trim()) {
        nextEvent.saveDisabled = true;
      } else {
        nextEvent.saveDisabled = false;
      }

      return nextEvent;
    },
    {
      firstName: appContext.profile.firstName,
      lastName: appContext.profile.lastName,
      birthdate: appContext.profile.birthdate,
      gender: appContext.profile.gender,
      payload: {},
      saveDisabled: false,
    }
  );

  const onSubmit = async (event) => {
    event.preventDefault();

    const firebasePayload = { ...state.payload };

    if (firebasePayload.birthdate) {
      firebasePayload.birthdate = firebase.convertDate(firebasePayload.birthdate);
    }

    await firebase.updateUser(firebasePayload);

    dispatch({
      type: 'SET_PROFILE',
      payload: {
        ...appContext.profile,
        ...state.payload,
      },
    });
    setActiveTab('Profile');
  };

  const onChange = (event) => {
    setState({ [event.target.name]: event.target.value });
  };

  return (

    <form className='overview-form' onSubmit={onSubmit} >

      <div className='settings-card-container'>
        <div className='card-header-group'>
          <h3>Details</h3>
          <div className='btn-inline-wrapper'>
            <Button theme={'btn-primary'} type='submit' disabled={state.saveDisabled}>Save</Button>
          </div>
        </div>
        <div className='settings-card-items'>
          <div className='card-input content-margin-large'>
            <InputWithIcon
              key='firstName'
              icon={<UserIcon />}
              id='firstName'
              name='firstName'
              type='text'
              placeholder='First Name'
              defaultValue={appContext.profile.firstName}
              onChange={onChange}
              autoComplete='given-name' />
          </div>

          <div className='card-input content-margin-large'>
            <InputWithIcon
              icon={<UserIcon />}
              id='lastName'
              name='lastName'
              type='text'
              placeholder='Last Name'
              defaultValue={appContext.profile.lastName}
              onChange={onChange}
              autoComplete='family-name' />
          </div>

          <div className='card-input content-margin-large'>
            <DatePicker
              onChange={onChange}
              name='birthdate'
              value={state.birthdate || null} />
          </div>

          <div className='card-input content-margin-large'>
            <GenderPicker
              onChange={onChange}
              name='gender'
              value={state.gender || null} />
          </div>
        </div>
      </div>

    </form>

  );
};

EditProfileDetailsForm.propTypes = {};

export default EditProfileDetailsForm;
