import React from 'react';

/**
 * Grid with months for datepicker and monthyearpicker,
 * should not be used by itself
 *
 * @name YearGrid
 *
 * @memberof DatePicker
 * @memberof MonthYearPicker
 * @see {@link DatePicker}{@link MonthYearPicker}
 * @protected
 *
 */

const YearGrid = ({ YEARS, selectYearHandler }) => {
  return (
    <div className='modal-entry-container'>
      { YEARS.map((year, idx) => (
        <p className='entry-list-item'
          key={idx}
          onClick={() => selectYearHandler(year)}>
          {year}
        </p>
      ))}
    </div>
  );
};

export default YearGrid;
