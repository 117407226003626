/**
 * Checks if object passed it empty
 * @name isEmptyObject
 *
 * @param {object} obj Target object
 * @return {bool}
 */
export const isEmptyObject = (obj) => {
  return obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype;
};
