import React from 'react';
import PropTypes from 'prop-types';

const PasswordShow = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 196.51 119.22">
      <path fill="none" stroke={color} strokeWidth="12" d="M98.25,5.29c-51.34,0-93,43.44-93,54.32s41.62,54.32,93,54.32,93-43.44,93-54.32S149.59,5.29,98.25,5.29Z"/>
      <path fill={color} d="M136.1,59.61A37.86,37.86,0,1,1,61.85,49.18a1.69,1.69,0,0,1,3.06-.42,16.32,16.32,0,1,0,22.5-22.48,1.69,1.69,0,0,1,.42-3.06A37.86,37.86,0,0,1,136.1,59.61Z"/>
    </svg>
  );
};

PasswordShow.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};
PasswordShow.defaultProps = {
  width: '24px',
  height: '24px',
};

export default PasswordShow;
