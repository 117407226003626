import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

import InputWithIcon from '../InputWithIcon';
import SearchIcon from '../../statics/Images/Input/SearchIcon';
import Loader from '../../statics/Images/Loader';

/**
 * Autocomplete component with results drawer
 *
 * @name Autocomplete
 *
 *
 * @param {function} onChange Func handling the behaviour desired when user types on input (i.e. triggers search)
 * @param {array, <Object>} searchResult Array with the results to be displayed on the drawer
 * @param {function} selectEntryHandler Func handling the behaviour desired when user clicks an entry
 * @param {string} inputPlaceholder Placeholder displayed on input
 * @param {string} inputName
 * @param {bool} icon If the input is using an icon on the left inside of the input
 * @param {object} searchInputRef Ref needed so the parent component knows the event result
 * @param {string} classStyle Style
 * @param {function} onClose Handler for close drawers
 * @param {string} defaultValue Value to be shown in case the input is pre filled
 * @param {bool} required If the input is required to continue
 *
 * @see {@link JobEntryForm}
 *
 */

const Autocomplete = (props) => {
  const {
    onChange,
    searchResult,
    selectEntryHandler,
    inputPlaceholder,
    inputName,
    icon,
    isLoading,
    searchInputRef,
    // classStyle,
    // onClose,
    defaultValue,
    required,
    error,
  } = props;
  const [floatType, setFloatType] = useState(defaultValue ? 'floating-input not-empty' : 'floating-input');
  const scrollToViewRef = useRef();

  /**
 * Handles the CSS floating label if the input is empty, and applies onChange to fetch results
 * @method onChangeWithFloaterHandler
 */
  const onChangeWithFloaterHandler = (e) => {
    onChange(e);
    if(e.target.value.length !== 0 ){
      setFloatType('floating-input not-empty');
    } else {
      setFloatType('floating-input');
    }
  };

  return (
    <div className='autocomplete-container'>
      {
        icon ?
          <InputWithIcon
            icon={<SearchIcon width='20px' height='20px' />}
            name={inputName}
            id={inputName}
            type='text'
            placeholder={inputPlaceholder}
            onChange={onChange}
            autoComplete='off'
            searchInputRef={searchInputRef}
            required={required}
            defaultValue={defaultValue || null || undefined}
          />
          :
          <div className={!error ? 'input-wrapper floating-label-content' : 'input-wrapper floating-label-content input-wrapper-error'}>
            <input
              type='text'
              placeholder=''
              name={inputName}
              id={inputName}
              onChange={onChangeWithFloaterHandler}
              autoComplete='off'
              //className= {`${classStyle} ${error ? 'error' : ''}`}
              ref={searchInputRef}
              defaultValue={defaultValue || null || undefined}
              required={required}
              className={floatType}
            />
            <label className='floating-label'>
              <span className={error ? 'placeholder-error' : ''}>{inputPlaceholder}</span>
            </label>
          </div>
      }

      {
        // Should be an up/down arrow:
        // searchResult.length > 0 && <div className='closeIcon' onClick={() => onClose()}></div>
      }

      <div className='results-container' ref={scrollToViewRef}>
        {
          typeof isLoading !== 'undefined' && isLoading ?
            (<div><Loader width={16} height={16} /></div>) :
            searchResult.map((doc, idx) => (
              <p key={idx} onClick={() => selectEntryHandler(doc)} >{doc.name}</p>
            ))
        }
      </div>
    </div>
  );
};

Autocomplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  searchResult: PropTypes.array.isRequired,
  selectEntryHandler: PropTypes.func.isRequired,
  inputPlaceholder: PropTypes.string,
  inputName: PropTypes.string,
  icon: PropTypes.bool,
  searchInputRef: PropTypes.object.isRequired,
  // classStyle: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
};
Autocomplete.defaultProps = {
  inputPlaceholder: 'Autocomplete placeholder',
  icon: false,
  required: false,
  error: false,
};

export default Autocomplete;
