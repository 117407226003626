import React from 'react';
import PropTypes from 'prop-types';

// TODO: Negative value in viewbox needs to be fixed when normalising icon set
const SignOutIcon = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 -4 26 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.3091 15.63C16.1319 15.63 15.9619 15.7005 15.8365 15.8258C15.7112 15.9511 15.6408 16.1211 15.6408 16.2984V20.2607C15.6408 20.4168 15.5788 20.5665 15.4686 20.677C15.3583 20.7875 15.2088 20.8497 15.0527 20.85H2.56824C2.41218 20.8497 2.26262 20.7874 2.15239 20.6769C2.04216 20.5665 1.98025 20.4168 1.98025 20.2607V2.7393C1.98024 2.58322 2.04214 2.43352 2.15237 2.32304C2.26261 2.21255 2.41217 2.15031 2.56824 2.14997H15.0527C15.2088 2.1503 15.3583 2.21253 15.4686 2.32301C15.5789 2.4335 15.6408 2.58321 15.6408 2.7393V6.70162C15.6408 6.87888 15.7112 7.04888 15.8365 7.17422C15.9619 7.29956 16.1319 7.36999 16.3091 7.36999C16.4864 7.36999 16.6564 7.29956 16.7817 7.17422C16.9071 7.04888 16.9775 6.87888 16.9775 6.70162V2.7393C16.9771 2.22883 16.7742 1.73936 16.4133 1.3783C16.0525 1.01723 15.5632 0.814037 15.0527 0.813293H2.56824C2.0578 0.814064 1.56849 1.01727 1.20767 1.37833C0.846852 1.7394 0.643978 2.22885 0.643555 2.7393V20.2607C0.643978 20.7712 0.846852 21.2606 1.20767 21.6217C1.56849 21.9828 2.0578 22.186 2.56824 22.1868H15.0527C15.5632 22.186 16.0525 21.9828 16.4133 21.6217C16.7742 21.2607 16.9771 20.7712 16.9775 20.2607V16.2984C16.9775 16.1212 16.9071 15.9512 16.7817 15.8258C16.6564 15.7005 16.4864 15.63 16.3091 15.63Z" fill={color}/>
      <path d="M24.983 10.8317L20.8188 6.96937C20.7545 6.90968 20.679 6.86325 20.5967 6.83272C20.5144 6.8022 20.4269 6.78819 20.3392 6.79149C20.2515 6.79478 20.1653 6.81531 20.0856 6.85192C20.0058 6.88853 19.934 6.94049 19.8743 7.00484C19.8146 7.06919 19.7682 7.14466 19.7377 7.22695C19.7071 7.30924 19.6931 7.39673 19.6964 7.48444C19.6997 7.57215 19.7203 7.65836 19.7569 7.73813C19.7935 7.81791 19.8454 7.88969 19.9098 7.94938L23.0173 10.8317H7.88419C7.70693 10.8317 7.53692 10.9021 7.41158 11.0274C7.28623 11.1528 7.21582 11.3228 7.21582 11.5C7.21582 11.6773 7.28623 11.8473 7.41158 11.9726C7.53692 12.098 7.70693 12.1684 7.88419 12.1684H23.0174L19.9098 15.0508C19.7811 15.1716 19.7054 15.3385 19.6993 15.515C19.6932 15.6914 19.7572 15.8631 19.8773 15.9926C19.9973 16.122 20.1638 16.1987 20.3402 16.2059C20.5166 16.213 20.6887 16.1501 20.8188 16.0308L24.9832 12.1682C25.0751 12.0828 25.1485 11.9794 25.1986 11.8645C25.2488 11.7495 25.2747 11.6254 25.2747 11.4999C25.2747 11.3745 25.2488 11.2504 25.1986 11.1354C25.1484 11.0204 25.075 10.9171 24.983 10.8317V10.8317Z" fill={color}/>
    </svg>
  );
};

SignOutIcon.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default SignOutIcon;
