import React, { forwardRef, useEffect, useState } from 'react';

import './styles.scss';

import SendIcon from '../../statics/Images/SendIcon.svg';
import FilesIcon from '../../statics/Images/FilesIcon.svg';

import { useAnalytics } from '../../hooks/Firebase';
import FutureFeatureModal from '../../components/FutureFeatureModal';

const InputBar = ({ placeholder, maxRows = 4, onSubmit }, ref) => {
  const [input, setInput] = useState('');
  const { clickEvent, TYPES: { CLICK } } = useAnalytics();
  const [showFeatureCommingModal, setShowFeatureCommingModal] = useState(false);

  const calcRows = (str) => {
    const linebreaks = str.match(/(\r\n|\r|\n)/g)?.length || 0;
    return linebreaks < maxRows ? linebreaks + 1 : maxRows;
  };

  useEffect(() => {
    setInput(ref.current?.value || '');
  },[ref.current?.value]);

  const toggleFeatureCommingModal = () => {
    setShowFeatureCommingModal(!showFeatureCommingModal);
  };

  return (
    <>
      <form className='message-input' onSubmit={onSubmit}>
        <textarea
          autoComplete='off'
          placeholder={placeholder}
          ref={ref}
          rows={calcRows(input)}
          onChange={(e) => setInput(e.target.value)}
        />
        <div className='input-button-group'>
          <button className='btn-file'
            onClick={() => {
              clickEvent(CLICK.TYPE.ADD, CLICK.CATEGORY.FILE);
              toggleFeatureCommingModal();
            }}>
            <img src={FilesIcon} />
            <label>Attach file</label>
          </button>
          <button className='btn-send' type='submit'>
            <img src={SendIcon} />
          </button>
        </div>
      </form>
      <FutureFeatureModal show={showFeatureCommingModal} onClose={toggleFeatureCommingModal}></FutureFeatureModal>
    </>

  );
};

export default forwardRef(InputBar);
