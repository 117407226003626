import React, { useEffect, useState, useContext }from 'react';
import { StorageContext } from '../../../../components/Storage';
import NewFeatureSkillsModal from '../../../../components/NewFeatureSkillsModal';

const NewFeaturesAnnouncements = () => {
  const [appContext] = useContext(StorageContext);
  const [showNewFeatureSkillsModal, setShowNewFeatureSkillsModal] = useState(false);
  const [optOutModals, setOptOutModals] = useState(JSON.parse(localStorage.getItem('featModals') || '[]'));

  useEffect(() => {

    // If the user fills out skills and deletes after it will have an empty array and the modal is not shown
    if (!appContext.isLoading && !optOutModals.includes('skills') && !appContext.profile.skills ) {
      setShowNewFeatureSkillsModal(true);
    }
    if (!appContext.isLoading && appContext.profile.skills && !optOutModals.includes('skills')) {
      const localStorageFeatModals = JSON.parse(localStorage.getItem('featModals')) || [];
      localStorageFeatModals.push('skills');
      localStorage.setItem('featModals', JSON.stringify(localStorageFeatModals));
    }

  }, [appContext.isLoading, appContext.profile.skills]);

  const toggleNewFeatureSkillsModal = () => {
    setShowNewFeatureSkillsModal(!showNewFeatureSkillsModal);
  };

  return (
    <NewFeatureSkillsModal
      show={showNewFeatureSkillsModal}
      onClose={toggleNewFeatureSkillsModal}
      optOutModals={optOutModals}
      setOptOutModals={setOptOutModals}
    />
  );
};

export default NewFeaturesAnnouncements;
