import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import BackButton from '../../../components/BackButton';
import Button from '../../../components/Button';

import { FirebaseContext } from '../../../components/Firebase';

import { useAnalytics } from '../../../hooks/Firebase';
import { StorageContext } from '../../../components/Storage';

import Loader from '../../../statics/Images/Loader';

const ONBOARDING_OPTIONS = [
  'Finding a job',
  'Finding my  perfect career path',
  'Networking with peers',
  'Resolving a career issue',
  'I don’t know yet',
];

/**
 * Signup career history page
 * @name OnboardingFlow
 * @memberof SignUp
 */
const OnboardingFlow =() => {
  const navigate = useNavigate();
  const firebase = useContext(FirebaseContext);
  const [state, dispatch] = useContext(StorageContext);
  const { pageViewEvent } = useAnalytics();
  const [selectedOptions, setSelectedOptions] = useState(state.profile.onboardingFlow || []);
  const [btnDisabled, setBtnDisabled] = useState(true);

  useEffect(() => {
    document.title = 'Sign Up - Onboarding Flow | HROS Career Coach';
    pageViewEvent();
  }, []);

  const onCheckboxSelect = (event) => {
    const option = event.target.value;

    if(event.target.checked) {
      if(!selectedOptions.includes(option)) {
        selectedOptions.push(option);
      }
    } else {
      if(selectedOptions.includes(option)) {
        selectedOptions.splice(selectedOptions.indexOf(option), 1);
      }
    }
    setSelectedOptions(selectedOptions);

    if (selectedOptions.length > 0) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    await firebase.updateUser({ onboardingFlow: selectedOptions });
    dispatch({
      type: 'SET_PROFILE',
      payload: {
        ...state.profile,
        onboardingFlow: selectedOptions,
      },
    });
    navigate('../skills');
  };

  const skipOnboardingFlow =() => {
    if (btnDisabled){
      navigate('../skills');
    }
  };

  return (
    <div className='signup-step'>
      <div className='signup-header-container'>
        <div className='signup-step-header'>
          <BackButton url={'/create-profile/profile'} color='#303030'/>
          <button
            onClick={skipOnboardingFlow}
            className='btn btn-secondary'>
            Skip step
          </button>
        </div>
      </div>
      <div className='signup-step-content'>
        <h1 className='content-margin-base'>What is your aim right now?</h1>
        <form className='signup-step-flex-grow' onSubmit={onSubmit}>
          <div className='signup-step-card'>
            <div className='backdrop-filter'></div>
            { state.isLoading ?
              <Loader className='loading-animation'></Loader>
              :
              <>
                {ONBOARDING_OPTIONS.map((option, i) =>
                  <div key={i} className='align-class checkbox-wrapper content-margin-xlarge'>
                    <input type='checkbox'
                      name={option}
                      id={option}
                      value={option}
                      defaultChecked={state.profile.onboardingFlow.includes(option)}
                      onChange={onCheckboxSelect}/>
                    <span className='checked-icon'></span>
                    <label htmlFor='currentJob'>{option}</label>
                  </div>
                )}
              </>
            }
          </div>
          <div className='content-margin-xlarge'>
            <Button
              theme='btn-primary'
              type='submit'
              disabled={btnDisabled}>(2/3) Save</Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OnboardingFlow;
