import React from 'react';
import PropTypes from 'prop-types';

const LikeIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19" viewBox="0 0 21 19" fill="none">
      <path stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M10.5005 3.35114C14.8438 -1.16827 19.958 1.85445 19.9501 6.31514C19.9431 10.2831 13.314 15.9251 11.1646 17.6503C10.9763 17.8017 10.742 17.8842 10.5005 17.8842C10.2589 17.8842 10.0246 17.8017 9.83637 17.6503C7.68696 15.9251 1.05775 10.2832 1.05079 6.31514C1.04297 1.85445 6.15714 -1.16827 10.5005 3.35114Z"/>
    </svg>
  );
};
LikeIcon.propTypes = { color: PropTypes.string };

export default LikeIcon;
