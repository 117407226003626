import React from 'react';

import SettingsListItem from './SettingsListItem';

/**
 * @summary Settings list with different items
 * Settings list with different items, in can render like a modal but with each indiviudal URL.
 *
 * @name SettingsList
 * @memberof Settings
 */
const SettingsList = ({ settingsList }) => {
  return (
    <>
      {
        settingsList.map((item, index) => {
          return (
            <SettingsListItem key={index} title={item.title} settings={item.settings} />
          );
        })
      }
    </>
  );
};

export default SettingsList;
