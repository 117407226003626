import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { FirebaseContext } from '../../../../../components/Firebase';
import { StorageContext } from '../../../../../components/Storage';

import FlagIcon from '../../../../../statics/Images/FlagIcon.svg';
import ReportIcon from '../../../../../statics/Images/ReportIcon.svg';

/**
 * Shows post entry options
 * @name OptionsMenu
 * @param {Object} post - post data
 * @param {Object} community - community data
 * @param {Function} showReportContentModalHandler - Report modal handler
 */
const OptionsPost = ({ post, showReportContentModalHandler, showConfirmDeleteModalHandler }) => {
  const firebase = useContext(FirebaseContext);
  const [state] = useContext(StorageContext);

  return (
    <>
      {
        firebase.auth.currentUser.uid === post.userRef.id &&
                  <li className='options-menu-item' onClick={showConfirmDeleteModalHandler}>
                    <img className='options-menu-icon' src={FlagIcon} width='16px' height='16px' />
                    Remove content
                  </li>
      }

      {
        state.claims.includes('talentManager') && firebase.auth.currentUser.uid !== post.userRef.id &&
                    <li className='options-menu-item' onClick={showConfirmDeleteModalHandler}>
                      <img className='options-menu-icon' src={FlagIcon} width='16px' height='16px' />
                      Remove content (TM action)
                    </li>
      }

      {
        !state.claims.includes('talentManager') && firebase.auth.currentUser.uid !== post.userRef.id &&
                    <li className='options-menu-item' onClick={showReportContentModalHandler}>
                      <img className='options-menu-icon' src={ReportIcon} width='16px' height='16px' />
                        Report content
                    </li>
      }
    </>
  );
};

OptionsPost.propTypes = {
  post: PropTypes.object,
  community: PropTypes.object,
  showReportContentModalHandler: PropTypes.func,
};

export default OptionsPost;
