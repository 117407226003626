import React from 'react';
import PropTypes from 'prop-types';

const DashboardIcon = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.83 8.00999L15.28 2.76999C14 1.74999 12 1.73999 10.73 2.75999L4.18002 8.00999C3.24002 8.75999 2.67002 10.26 2.87002 11.44L4.13002 18.98C4.42002 20.67 5.99002 22 7.70002 22H18.3C19.99 22 21.59 20.64 21.88 18.97L23.14 11.43C23.32 10.26 22.75 8.75999 21.83 8.00999ZM13.75 18C13.75 18.41 13.41 18.75 13 18.75C12.59 18.75 12.25 18.41 12.25 18V15C12.25 14.59 12.59 14.25 13 14.25C13.41 14.25 13.75 14.59 13.75 15V18Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

DashboardIcon.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default DashboardIcon;
