import React from 'react';
import PropTypes from 'prop-types';

import { motion, AnimatePresence } from 'framer-motion';
import CloseIcon from '../../statics/Images/CloseIcon';

import './styles.scss';

const backdrop = {
  visible: {
    opacity: 1,
    transition: { duration: 0.25 },
  },
  hidden: {
    opacity: 0,
    transition: { duration: 0.25 },
  },
};

/**
 * Container for Modals that controls close when clicked outside of modal and style.
 *
 * @name ModalBaseContainer
 *
 *
 * @param {bool} show Defines if modal is shown or not
 * @param {function} onClose Handler to close the modal
 * @param {node} children Modal content
 * @param {bool} warningModal Defines if modal warning or not (false by default)
 *
 */
const ModalBaseContainer = ({ show, onClose, children, maxWidth, warningModal = false }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      { show &&
        <motion.div className='padding-layout modal-overlay' onClick={onClose}
          variants={backdrop}
          initial='hidden'
          animate='visible'
          exit='hidden'>
          <div className='modal-container' style={{ maxWidth: maxWidth + 'px' }} onClick={e => e.stopPropagation()}>
            { !warningModal &&
              <div className='modal-header'>
                <div className='close-icon' onClick={onClose}>
                  <CloseIcon color='#303030' />
                </div>
              </div>
            }
            {children}
          </div>
        </motion.div>
      }
    </AnimatePresence>
  );
};

ModalBaseContainer.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
  warningModal: PropTypes.bool,
};
ModalBaseContainer.defaultProps = { maxWidth: '400' };

export default ModalBaseContainer;
