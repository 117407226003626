import React, { useContext, useEffect, useState } from 'react';

import './styles.scss';

import { useAnalytics } from '../../../../hooks/Firebase';
import { StorageContext } from '../../../../components/Storage';
import { FirebaseContext } from '../../../../components/Firebase';

import CommunityItem from '../CommunityItem';
import CommunityTabs from '../../../../components/CommunityTabs';

import EditIcon from '../../../../statics/Images/EditIcon';

import PostContentModal from '../CommunityModals/PostContentModal';
import ChooseCommunitiesModal from '../CommunityModals/ChooseCommunitiesModal';

/**
 * Community overview component, shows all the communities and filters
 * @name CommunityOverview
 */
const CommunityOverview = () => {
  const { pageViewEvent } = useAnalytics();
  const firebase = useContext(FirebaseContext);

  const [appContext] = useContext(StorageContext);

  const user = {
    userRef: firebase.getCurrentUserRef(),
    username: appContext.profile.username,
  };

  const [state, updateState] = useState({
    communityData: [],
    showPostContentModal: false,
    showChooseCommunityModal: false,
    communitiesSubscribed: [],
    activeCommunityTab: 'ALL',
    filteredCommunityData: [],
    paginatedPosts: [],
    loadingMorePosts: false,
    unsubscribeActiveListener: null,
  });

  // Set page title and send page view event
  useEffect(() => {
    document.title = 'Community | HROS Career Coach';
    pageViewEvent();
  }, []);

  useEffect(() => {
    if(appContext.profile.communities){
      const subscribedCommunities = [
        {
          communityID: 'RECOMMENDED',
          communityName: 'RECOMMENDED',
        },
        ...appContext.profile.communities,

      ];
      updateState(s => ({
        ...s,
        communitiesSubscribed: subscribedCommunities,
      }));
    }
  }, [appContext.profile.communities]);

  useEffect(async () => {

    if (!appContext.profile.communities || state.communitiesSubscribed.length === 0) {
      return;
    }

    updateState(s => ({
      ...s,
      paginatedPosts: [],
    }));

    const communities = getCommunityFilters();

    if(state.activeCommunityTab === 'MY_POSTS'){
      const unsubscribe = await firebase.communityFetchMyPosts(onCommunityChange);
      updateState(s => ({
        ...s,
        unsubscribeActiveListener: unsubscribe,
      }));
      return() => {
        unsubscribe();
      };
    }

    const unsubscribe = await firebase.subscribeCommunitiesListener(null, communities, onCommunityChange);

    updateState(s => ({
      ...s,
      unsubscribeActiveListener: unsubscribe,
    }));

    return () => {
      unsubscribe();
    };

  }, [state.communitiesSubscribed, state.activeCommunityTab]);

  const onCommunityChange = async (docs) => {
    const posts = [];
    docs.forEach(doc => {
      posts.push({
        id: doc.id,
        ...doc.data(),
        documentSnapshot: doc,
      });
    });

    updateState(s => ({
      ...s,
      communityData: posts,
    }));
  };

  const fetchMorePosts = async () => {
    updateState(s => ({
      ...s,
      loadingMorePosts: true,
    }));

    const communities = getCommunityFilters();

    const lastPostSnapshot = state.paginatedPosts.length ?
      state.paginatedPosts[state.paginatedPosts.length - 1].documentSnapshot
      :
      state.communityData[state.communityData.length - 1].documentSnapshot;

    let newPost;
    if(state.activeCommunityTab === 'MY_POSTS'){
      newPost = await firebase.communityFetchMoreMyPosts(lastPostSnapshot);
    } else {
      newPost = await firebase.communityFetchMore(null, communities, lastPostSnapshot);
    }

    if(!newPost.empty){
      await newPost.forEach(doc => {
        updateState(s => ({
          ...s,
          paginatedPosts: [
            ...s.paginatedPosts,
            {
              id: doc.id,
              ...doc.data(),
              documentSnapshot: doc,
            },
          ],
        }));
      });
    }

    updateState(s => ({
      ...s,
      loadingMorePosts: false,
    }));
  };

  /**
   * It returns an array of community IDs that the user is subscribed to
   * @returns An array of communityID's
   */
  const getCommunityFilters = () => {

    const filter = ['RECOMMENDED', 'ALL', 'MY_POSTS'];

    let communities = [];
    if(state.activeCommunityTab === 'ALL'){
      // Array with communityID subscribed by the user
      communities = state.communitiesSubscribed.reduce((acc, curr) => { acc.push(curr.communityID); return acc;}, []);
    }

    if(state.activeCommunityTab === 'RECOMMENDED'){
      communities = ['RECOMMENDED'];
    }

    if(!filter.includes(state.activeCommunityTab)){
      const communityID = state.communitiesSubscribed.find(community => community.communityName === state.activeCommunityTab).communityID;
      communities = [communityID];
    }

    return communities;
  };

  const setActiveCommunityTab = async (tab) => {
    if(state.unsubscribeActiveListener){
      await state.unsubscribeActiveListener();
    }
    updateState(s => ({
      ...s,
      activeCommunityTab: tab,
    }));
  };

  /**
   * If the current user has already liked the post, remove the like, otherwise add a like
   * @param post - the post object
  */
  const likeAction = async (post) => {
    post.likes.find((like) => like.id === firebase.auth.currentUser.uid) ?
      await firebase.removeLikeFromPost(post.id, user.userRef) :
      await firebase.addLikeToPost(post.id, user.userRef);
  };

  const showPostContentModalHandler = () => {
    updateState(s => ({
      ...s,
      showPostContentModal: !state.showPostContentModal,
    }));
  };

  const showChooseCommunityModalHandler = () => {
    updateState(s => ({
      ...s,
      showChooseCommunityModal: !state.showChooseCommunityModal,
    }));
  };

  return (
    <div className='padding-layout-horisontal padding-layout-vertical'>
      <h1 className='community-tab-title'>Community</h1>

      <CommunityTabs
        communitiesSubscribed={state.communitiesSubscribed}
        activeCommunityTab={state.activeCommunityTab}
        setActiveCommunityTab={setActiveCommunityTab}
        showChooseCommunityModalHandler={showChooseCommunityModalHandler}
      />

      <>
        {state.communityData &&
          state.communityData.map((post, i) =>
            <CommunityItem
              key={i}
              likeAction={likeAction}
              post={post}
              fetchMorePostsTrigger={i === state.communityData.length - 2}
              fetchMorePosts={fetchMorePosts}
            />
          )}
        {
          state.paginatedPosts &&
            state.paginatedPosts.map((post, i) =>
              <CommunityItem
                key={i}
                likeAction={likeAction}
                post={post}
                fetchMorePostsTrigger={i === state.paginatedPosts.length - 2}
                fetchMorePosts={fetchMorePosts}
              />
            )
        }
        {
          // TODO: Add a loading spinner
          state.loadingMorePosts && <div>Loading more posts...</div>
        }
      </>

      {
        state.communityData.length === 0 && state.activeCommunityTab === 'MY_POSTS' &&
          <div>
            You haven’t shared anything yet!
            When you do, your post will appear
            also here so you can keep track better.
          </div>

      }

      <div className='fab-button' onClick={showPostContentModalHandler}>
        <EditIcon color='#fff'/>
      </div>

      <PostContentModal
        show={state.showPostContentModal}
        user={user}
        onClose={showPostContentModalHandler}
        communitiesSubscribed={state.communitiesSubscribed}
      />

      <ChooseCommunitiesModal
        show={state.showChooseCommunityModal}
        onClose={showChooseCommunityModalHandler}
      />

    </div>
  );
};

export default CommunityOverview;
