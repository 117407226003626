import React,{ useState, useEffect } from 'react';

import { motion } from 'framer-motion';

import './styles.scss';

import DaysGrid from './DaysGrid';
import MonthGrid from './MonthGrid';
import YearGrid from './YearGrid';

import CalendarPolygon from '../../../statics/Images/CalendarPolygon.svg';

const backdrop = {
  visible: {
    opacity: 1,
    transition: { duration: 0.25 },
  },
  hidden: {
    opacity: 0,
    transition: { duration: 0.25 },
  },
};

/**
 * Constants
 *
 * @readonly
 *
 * @const {array, <String>} YEARS
 * @const {array, <String>} MONTHS
 *
 */
const YEARS = Array.from(new Array(80), (val, index) => new Date().getFullYear() - index);
const MONTHS = ['January','February','March','April','May','June','July','August','September','October','November','December'];

/**
 * Modal with the calendar for the date picker component,
 * should not be used by itself
 *
 * @name DatePickerModal
 *
 * @memberof DatePicker
 * @see {@link DatePicker}
 * @protected
 *
 */

const DatePickerModal = ( props ) => {
  const {
    setDayselected,
    daySelected,
    setMonthSelected,
    monthSelected,
    setYearSelected,
    yearSelected,
    onChange,
    name,
    setUserInteracted,
    setShowCalendar,
  } = props;

  useEffect (()=> {
    // If the month or year changes checks if the day previous selected is included on current month/year/days array
    // and if not sets the selected day for the last day
    // obj: avoids selecting 29/02 on a non leap year

    if(!days.includes(daySelected)){
      setSelectedDay(days.slice(-1)[0]);
    }
  }, [monthSelected, yearSelected]);

  const [choosenDisplay, setChoosenDisplay] = useState('years');

  const days = Array.from(new Array(new Date(yearSelected, monthSelected + 1, 0).getDate()), (val,index) => index + 1);

  const closeCalendarHandler = () => {
    setShowCalendar(false);
  };

  const showDaysHandler = () => {
    setChoosenDisplay('days');
  };

  const showMonthHandler = () => {
    setChoosenDisplay('months');
  };

  const showYearHandler = () => {
    setChoosenDisplay('years');
  };

  const selectYearHandler = (year) => {
    setYearSelected(year);
    setChoosenDisplay('months');
  };

  const selectMonthHandler = (idx) => {
    setMonthSelected(idx);
    setChoosenDisplay('days');
  };

  const setSelectedDay = (day) => {
    setDayselected(day);
    setChoosenDisplay('years');
    setUserInteracted(true);
    onChange({
      target: {
        name,
        value: new Date(yearSelected, monthSelected, day),
      },
    });
    closeCalendarHandler();
  };

  return (
    <motion.div className='calendar-modal-overlay blure-overlay' onClick={closeCalendarHandler}
      variants={backdrop}
      initial='hidden'
      animate='visible'
      exit='hidden'>
      <div className='calendar-modal-container' onClick={e => e.stopPropagation()}>
        <div className='calendar-modal-top-container'>
          <div className='calendar-date-container'>
            <div
              className={choosenDisplay === 'years' ? 'calendar-date-item-container active' : 'calendar-date-item-container'}
              onClick={showYearHandler}>
              <p>{ yearSelected }</p>
              <img src={CalendarPolygon}/>
            </div>
            <div
              className={choosenDisplay === 'months' ? 'calendar-date-item-container active' : 'calendar-date-item-container'}
              onClick={showMonthHandler}>
              <p>{ MONTHS[monthSelected].substring(0,3) }</p>
              <img src={CalendarPolygon}/>
            </div>
            <div
              className={choosenDisplay === 'days' ? 'calendar-date-item-container active' : 'calendar-date-item-container'}
              onClick={showDaysHandler}>
              <p>{ daySelected < 10 ? `0${daySelected}` : daySelected }</p>
              <img src={CalendarPolygon} />
            </div>
          </div>
        </div>
        <div className='calendar-modal-bottom-container'>
          {
            (() => {
              switch (choosenDisplay) {
                case 'days':
                  return ( <DaysGrid days={days} setSelectedDay={setSelectedDay} /> );
                case 'months':
                  return ( <MonthGrid selectMonthHandler={selectMonthHandler} MONTHS={MONTHS} /> );
                default:
                  return ( <YearGrid YEARS={YEARS} selectYearHandler={selectYearHandler} /> );
              }
            })()
          }
        </div>
      </div>
    </motion.div>
  );
};

export default DatePickerModal;
