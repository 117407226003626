import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.scss';

import { StorageContext } from '../../../components/Storage';
import { FirebaseContext } from '../../../components/Firebase';
import InitialLoading from '../../../components/InitialLoading';

import { isEmptyObject } from '../../../tools/IsEmptyObject';

import BackButton from '../../../components/BackButton';
import MatchToucan from '../../../statics/Images/Illustrations/Toucans/MatchToucan.svg';

import TestQuestionsIndicator from '../../../components/TestQuestionsIndicator';

import { useAnalytics } from '../../../hooks/Firebase';
import JobPredicted from './JobPredicted';

/**
 * Career prediction results screen
 * @name CareerPredictionResults
 * @component
 * @category Pages
 * @subcategory JobMatching
 */
const CareerPredictionResults = () => {
  const { pageViewEvent, clickEvent, TYPES: { CLICK } } = useAnalytics();
  const [appContext] = useContext(StorageContext);
  const firebase = useContext(FirebaseContext);

  const navigate = useNavigate();

  const [state, setState] = useState({
    feedback: {},
    jobTitles: [],
    resultCounter: 0,
  });

  useEffect(async () => {
    const user = (await firebase.getUser()).data();
    setState({
      ...state,
      feedback: {
        userID: firebase.auth.currentUser.uid,
        careerHistory:  user.career,
        predictionResults: [],
        createdAt: new Date(),
      },
    });
  }, []);

  useEffect(() => {
    document.title = 'Career Prediction Results | HROS Career Coach';
    pageViewEvent();
    window.scrollTo(0, 0);
  }, []);

  useEffect(async () => {
    if (appContext.careerPredictionResults.prediction === 'switch-company' && !appContext.isLoading) {

      //* Skips the prediction if the career prediction is not usable
      navigate('/app/matching-results');

      return;
    }

    if(!appContext.isLoading && appContext.career.length === 0){
      navigate('/app/dashboard');
      return;
    }

    if(!appContext.isLoading && isEmptyObject(appContext.careerPredictionResults)){
      navigate('/app/matching');
      return;
    }

    if (appContext.careerPredictionResults.prediction === 'switch-job' && !isEmptyObject(appContext.careerPredictionResults.output)) {
      const jobTitlesTemp = [];
      for (const [key, value] of Object.entries(appContext.careerPredictionResults.output)) {

        if (value > 0) {
          const payload = {
            query: {
              multi_match: {
                query: key,
                type: 'most_fields',
                fields: [
                  'jobTitle',
                  'jobTitle.full^2',
                ],
              },
            },
          };

          // Fetch the results from the ElasticSearch API, elastic hook is not working in this use case. If time allows, refactor to use the hook
          const results = await fetch('https://search.hros.io/jobtitles_v2/_search?size=1', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
          });

          // Parse the results into an array of objects
          const resultsObj = await results.json();
          const resultsArray = [];
          resultsObj.hits.hits.forEach(item => {
            resultsArray.push(
              {
                id: item._id,
                name: item._source.jobTitle,
                associatedJobs: item._source.associatedJobs,
                relevance: item._score,
              }
            );
          });

          jobTitlesTemp.push(
            {
              title: key,
              value,
              occupation: resultsArray[0].associatedJobs[0].name,
              occupationDescription: resultsArray[0].associatedJobs[0].description,
            }
          );
        }
      }
      jobTitlesTemp.sort((x, y) => y.value - x.value);
      setState((s) => {
        return {
          ...s,
          jobTitles: jobTitlesTemp,
        };
      });
      return;
    }
  }, [appContext.careerPredictionResults, appContext.isLoading]);

  useEffect(() => {
    if(!appContext.isLoading && appContext.career[0].jobTitle){
      const filteredMatchingJobs = JSON.parse(localStorage.getItem('matching_jobs') || '[]');

      if(!filteredMatchingJobs.includes(appContext.career[0].jobTitle)) {
        filteredMatchingJobs.push(appContext.career[0].jobTitle);
      }

      localStorage.setItem('matching_jobs', JSON.stringify(filteredMatchingJobs));
    }
  }, [appContext.isLoading]);

  return (
    <>
      <div className='auth-wrapper-primary'>
        <div className='pred-res-tucan'>
          <img src={MatchToucan} alt='HROS logo'/>
        </div>

        <div className='pred-res-wrapper'>
          <div className='pred-res-header'>
            <div className='pred-res-header-title-group'>
              <div className='pred-res-back-btn'>
                <BackButton color='#fff' url={'/app/profile'}
                  onClick={() => {
                    clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.BACK_FROM_MATCHING, CLICK.LOCATION.CAREER_PREDICTION_RESULTS);
                  }}/>
              </div>
            </div>
          </div>

          <div className='pred-res-container'>
            { appContext.loading ?
              <div className='loading-test'>
                <InitialLoading/>
              </div>
              :
              <div className='pres-res-wrapper'>
                <h3 className='content-margin-large white'>Our Matching Finds These Careers Ideal For You!</h3>
                <div className='content-margin-large white'>
                  <TestQuestionsIndicator loading={appContext.loading} totalCount={state.jobTitles.length} currentStep={state.resultCounter} label={false} page={'match'}/>
                </div>
                {
                  state.jobTitles.map((jobTitle, index) => (
                    <div key={index}>
                      <JobPredicted index={index} jobTitle={jobTitle} state={state} setState={setState} />
                    </div>
                  ))
                }
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerPredictionResults;
