const tuAudience = process.env.REACT_APP_PROJECT_ID === 'hero-career-coach' ? 'match.hros.io' : 'next.talentuno.test.gwsdev.com';
const endpoint = process.env.REACT_APP_PROJECT_ID === 'hero-career-coach' ? 'match.hros.io' : 'next-api.test.gwsdev.com';

const fetchJobList = async (token, jobTitle) => {
  const fetchJobListOptions = {
    method: 'POST',
    headers: {
      audience: tuAudience,
      'content-type': 'application/json',
      token,
    },
    body: JSON.stringify({
      actualPage: 1,
      limit: 24,
      order: 'openFrom',
      orderType: 'DESC',
      mainSearch: jobTitle || null,
      languageID: '599ee034dd31c334d2008853',
      requestID: '1683710531210-hubhk5paqxc',
    }),
  };

  try {
    const response = await fetch(`https://${endpoint}/api/job/list`, fetchJobListOptions);
    const data = await response.json();

    if (!data.success) {
      return { success: false };
    }

    return data;
  } catch (err) {
    return { success: false };
  }
};

const createPersistentToken = async (firebaseJWTToken) => {
  const headers = {
    audience: tuAudience,
    'Content-Type': 'application/json',
  };
  const body = JSON.stringify({ firebaseJWTToken });

  try {
    const response = await fetch(`https://${endpoint}/api/firebase-jwt-create-persistent-token`, {
      method: 'POST',
      headers: headers,
      body: body,
    });

    const result = await response.json();

    return result;
  } catch (error) {
    console.error('Failed to fetch persistent token:', error);
  }
};

export {
  fetchJobList,
  createPersistentToken
};
