import React from 'react';
import PropTypes from 'prop-types';

const BackIcon = ({ width, height, color }) => {
  return (
    <>
      <svg width={width} height={height} viewBox="0 0 180 156" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.45 78H168.55" stroke={color} strokeWidth="21" strokeMiterlimit="10" strokeLinecap="round"/>
        <path d="M82.7399 11.19L11.45 78L82.7399 144.81" stroke={color} strokeWidth="21" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </>
  );
};

BackIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};
BackIcon.defaultProps = {
  width: '28px',
  height: '28px',
  color: '#fff',
};

export default BackIcon;
