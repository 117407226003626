import React, { useContext, useEffect, useState } from 'react';
import './styles.scss';

import BackButton from '../../../../components/BackButton';
import { StorageContext } from '../../../../components/Storage';
import { useAnalytics } from '../../../../hooks/Firebase';
import NotificationsItem from '../NotificationsItem';
import { FirebaseContext } from '../../../../components/Firebase';

/**
 * Displays notifications for current user
 * @name NotificationsOverview
 */
const NotificationsOverview = () => {
  const [appContext] = useContext(StorageContext);
  const { pageViewEvent } = useAnalytics();
  const firebase = useContext(FirebaseContext);
  const [showMarkAllAsRead, setShowMarkAllAsRead] = useState(false);

  // Set page title and send page view event and
  // Scrolls the window to the top when the component is mounted
  useEffect(() => {
    document.title = 'Notifications | HROS Career Coach';
    pageViewEvent();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if(appContext.notifications?.length ){
      appContext.notifications.map((note) => {
        if (!note.received) {
          setShowMarkAllAsRead(true);
        }
      });
    }
  }, [appContext.notifications]);

  const markAllAsRead = async () => {
    appContext.notifications.map(async (note) => {
      if (!note.received) {
        await firebase.markNotificationAsReceived(note.ref);
        setShowMarkAllAsRead(false);
      }
    });
  };

  return (
    <div className='full-height-content'>
      <div className='title-group white'>
        <BackButton color='#303030'/>
        <h1 className='title'>Notifications</h1>
      </div>
      <div className='title-group-content'>
        {showMarkAllAsRead && <div className='mark-notifications-button' onClick={markAllAsRead}>Mark all as read</div>}
        {
          appContext.notifications?.length ?
            appContext.notifications.map((note, idx) => (
              <div className='notification-card' key={`notification-${idx}`}>
                <NotificationsItem item={note}/>
              </div>
            )) :
            <h3 className='padding-layout-horisontal'>This is where your notifications will appear</h3>
        }
      </div>
    </div>
  );
};

export default NotificationsOverview;
