import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { FirebaseContext } from '../../../Firebase';
import InputWithIcon from '../../../InputWithIcon';
import ModalBaseContainer from '../../../ModalBaseContainer';
import EmailIcon from '../../../../statics/Images/Input/EmailIcon';

/**
 * @summary Modal to change email
 * Modal to change email, uses url instead of handlers to execute action.
 *
 * @name ShowEmailModal
 * @memberof SettingsList
 */
const ShowEmailModal = () => {
  const navigate = useNavigate();
  const firebase = useContext(FirebaseContext);
  const onClose = () => navigate('..');

  return (
    <>
      {/* Show can always be true, due to visibility handled by router */}
      <ModalBaseContainer show={true} onClose={onClose}>
        <h2>Email address</h2>

        <div className='content-margin-base'>
          <InputWithIcon
            id='current_email'
            name='current_email'
            type='email'
            icon={<EmailIcon />}
            disabled
            placeholder='Current email address'
            defaultValue={firebase.auth.currentUser.providerData[0].email}
          />
        </div>

      </ModalBaseContainer>
    </>
  );
};

export default ShowEmailModal;
