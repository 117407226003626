/**
 * Converts a given image File into webp blob
 * @param {File} file
 * @returns Blob
 */
export const convertToWebp = async (file) => {
  // Load the file into an image (tag)
  const image = await new Promise((resolve) => {
    const rawImage = new Image();
    rawImage.addEventListener('load', () => {
      resolve(rawImage);
    });
    rawImage.src = URL.createObjectURL(file);
  });

  // Setup invisible canvas and draw/scale image onto it
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // We want to sclae profile images to 350x350 pixel
  canvas.width = 350;
  canvas.height = 350;

  // Calculate offset to center image after cropping / resizing
  const ratio = image.width / 350;
  const xOffset = ((image.width/ratio)-350)/2;
  const yOffset = ((image.height/ratio)-350)/2;

  // Draw image offset with new resolution on canvas
  ctx.drawImage(image, -xOffset, -yOffset, image.width/ratio, image.height/ratio);

  // Convert canvas into a webp image
  const webp = await new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, 'image/webp');
  });

  return webp;
};
