import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.scss';

import { FirebaseContext } from '../../../components/Firebase';
import { StorageContext } from '../../../components/Storage';
import { isEmptyObject } from '../../../tools/IsEmptyObject';
import { useAnalytics } from '../../../hooks/Firebase';
import useToasts from '../../../hooks/useToasts';

import LoadingToucan from '../../../components/LoadingToucan';

/**
 * Career prediction loading screen, resolves when prediction should be fetched and fetch it
 * @name CareerPredictionLoading
 * @component
 * @category Pages
 * @subcategory JobMatching
 */
const CareerPredictionLoading = () => {
  const firebase = useContext(FirebaseContext);
  const { publishError } = useToasts();
  const navigate = useNavigate();
  const [appContext, dispatch] = useContext(StorageContext);
  const [loadingCareerPrediction, setLoadingCareerPrediction] = useState(true);
  const [isAnimating, setIsAnimating] = useState(true);
  const { pageViewEvent, errorEvent, customEvent } = useAnalytics();

  const uploadFirestoreHandler = (payload, predictionType) => {
    setLoadingCareerPrediction(false);
    firebase.uploadCareerPredictionResults({ [Date.now()]: payload || { prediction: predictionType } });
    dispatch({
      type: 'SET_CAREER_PREDICTION_RESULTS',
      payload: payload || { prediction: predictionType } ,
    });
  };

  // Set page title and send page view event
  useEffect(() => {
    document.title = 'Loading Prediciton | HROS Career Coach';
    pageViewEvent();
  }, []);

  useEffect(() => {
    ( async function fetchPrediction(){

      const user = (await firebase.getUser()).data();
      const predictionSnapshot = await firebase.getCareerPredictionResults();
      const career = user.career?.sort((a,b) => b.start_date.seconds - a.start_date.seconds) || [];

      if (predictionSnapshot.exists()) {
        const filteredMatchingJobs = JSON.parse(localStorage.getItem('matching_jobs') || '[]');

        if(filteredMatchingJobs.length > 0){
          setTimeout(() => {
            navigate('/app/matching-results');
          }, 2800);
          return;
        }

        navigate('/app/matching/career-prediction-results');
        return;
      }

      if (career?.length){
        dispatch({
          type: 'SET_CAREER_INFO',
          payload: career,
        });

        let payload;

        let myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
        myHeaders.append('sec-fetch-mode', 'navigate');

        const requestOptions = {
          method: 'GET',
          headers: myHeaders,
        };

        await fetch(`https://europe-west1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/get_prediction_ML/${firebase.auth.currentUser.uid}`, requestOptions)
          .then(response => {
            if (!response.ok) {
              throw new Error('Something went wrong');
            }
            return response.json();
          })
          .then(response => {
            payload = {
              prediction: response.missing_flag ? 'switch-company' : 'switch-job',
              input: response.input,
              output: response.output,
            };

            uploadFirestoreHandler(payload, payload.prediction);
            customEvent('job_title_prediction_complete');
          })
          .catch((error) => {
            errorEvent(error.stack, false);
            publishError('Algorithm error. Try again later!');
            navigate('/app/dashboard');
          });
      } else {
        // IF user career is empty Navigates to dashboard without any ceremony :)
        publishError('Please add career history!');
        navigate('/app/dashboard');
      }

    })();
  }, []);

  useEffect (() => {
    if(!isAnimating && !isEmptyObject(appContext.careerPredictionResults)){
      if(appContext.careerPredictionResults.prediction === 'switch-company') {
        setTimeout(() => {
          navigate('/app/matching-results');
        }, 2800);
        return;
      }
      navigate('/app/matching/career-prediction-results');
    }
  }, [isAnimating, appContext.careerPredictionResults]);

  return(
    <div className='career-prediction-loading-container'>
      <LoadingToucan loading={loadingCareerPrediction} setIsAnimating={setIsAnimating} />
    </div>
  );
};

export default CareerPredictionLoading;
