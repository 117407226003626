import React from 'react';

import ChangeEmailModal from './ChangeEmail';
import ChangePasswordModal from './ChangePassword';
import SignOutModal from './SignOut';
import AddCommunityModal from './AddCommunityModal';
import AddDailyTaskModal from './AddDailyTaskModal';
import ShowEmailModal from './ShowEmailModal';

const SettingsModals = ({ child }) => {
  return (
    <>
      {
        {
          'change-mail': <ChangeEmailModal />,
          'show-mail': <ShowEmailModal />,
          'change-password': <ChangePasswordModal />,
          'sign-out': <SignOutModal />,
          'add-community': <AddCommunityModal />,
          'add-daily-task': <AddDailyTaskModal />,
        }[child]
      }
    </>
  );
};

export default SettingsModals;
