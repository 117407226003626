import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

/**
 * TestQuestionsIndicator component
 *
 * @name TestQuestionsIndicator
 *
 * @param {number} currentStep
 * @param {number} totalCount
 * @param {boolean} loading
 */
const TestQuestionsIndicator = ({ totalCount, currentStep, loading, label=true, page='eq' }) => {
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    let steps = [];

    for(let i=0; i < totalCount; i++) {

      const interval = 100 / totalCount;
      const progress = ((currentStep + 1) * interval).toFixed(2);

      steps.push(
        <div
          className={currentStep >= i ? `active-indicator ${page}` : 'indicator'}
          key={i}
          style={{ width: `${progress}%` }}>
        </div>
      );
    }

    setSteps(steps);

  }, [currentStep, totalCount]);

  return (
    <>
      { loading ?
        <>
          <div className='indicators-labels'>
            { label ?
              <span>Progress</span>
              :
              <span> </span>}<span>0/0</span>
          </div>
          <div className='indicators-wrapper'>
            <div
              className='indicator'
              style={{ width: '100%' }}>
            </div>
          </div>
        </>
        :

        <>
          <div className='indicators-labels'>
            { label ?
              <span>Progress</span>
              :
              <span> </span>}<span>{currentStep + 1}/{totalCount}</span>
          </div>
          <div className='indicators-wrapper' >
            { steps }
          </div>
        </>
      }
    </>
  );
};

TestQuestionsIndicator.propTypes = { totalCount: PropTypes.number };
TestQuestionsIndicator.propTypes = { currentStep: PropTypes.number };
TestQuestionsIndicator.propTypes = { loading: PropTypes.bool };

export default TestQuestionsIndicator;
