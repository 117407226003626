import React, { useEffect, useState } from 'react';
import './styles.scss';

const Dropdown = ({ name, value, onChange, options = ['single-choice', 'two-options', 'slider', 'likert-scale'] }) => {
  // const options = ['single-choice', 'two-options', 'slider', 'likert-scale'];
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [floatType, setFloatType] = useState(value ? 'floating-input not-empty' : 'floating-input');

  useEffect(() => {
    if (selectedOption) {
      setFloatType('floating-input not-empty');
    } else {
      setFloatType('floating-input');
    }
  }, [selectedOption]);

  return (
    <div className='dropdown-wrapper'>
      <div className='input-container input-wrapper floating-label-content' onClick={() => setDrawerOpen(!drawerOpen)}>
        <div className={`${floatType} select-box`}>{selectedOption}&nbsp;</div>
        <label className='floating-label'>
          <span>Question</span>
        </label>
      </div>
      {
        drawerOpen && (
          <ul className='dropdown-list'>
            {
              options.map(option =>
                typeof option === 'object' ?
                  <li
                    key={option.name}
                    onClick={() => {
                      setSelectedOption(option.name);
                      onChange({
                        target: {
                          name,
                          value: option,
                        },
                      });
                      setDrawerOpen(false);
                    }}
                  >
                    {option.name}
                  </li>
                  :
                  <li
                    key={option}
                    onClick={() => {
                      setSelectedOption(option);
                      onChange({
                        target: {
                          name,
                          value: option,
                        },
                      });
                      setDrawerOpen(false);
                    }}
                  >
                    {option}
                  </li>
              )
            }
          </ul>
        )
      }
    </div>
  );
};

export default Dropdown;
