import React from 'react';
import { Route, Routes } from 'react-router-dom';

import './styles.scss';

import CommunityTab from './CommunityTab';
import DashboardTab from './DashboardTab';
import Profile from './Profile';
import JobMatchingResults from '../../pages/JobMatching/JobMatchingResults';
import CatchAll from '../../components/CatchAll';

/**
 * Main content container routing.
 * @name ContentContainer
 */
const ContentContainer = () => {

  return (
    <div className='content'>
      <Routes>
        <Route path='dashboard' element={<DashboardTab />} />
        <Route path='community/*' element={ <CommunityTab />} />
        <Route path='profile/*' element={ <Profile />} />
        <Route path='matching-results' element={< JobMatchingResults />} />
        <Route path='*' element={ <CatchAll />} />
      </Routes>
    </div>
  );
};

export default ContentContainer;
