import React, { useContext, useState } from 'react'; // useRef

import { StorageContext } from '../../../../components/Storage';
import { FirebaseContext } from '../../../../components/Firebase';

import { convertToWebp } from '../../../../tools/ProfilePictureAux';

import defaultAvatar from '../../../../statics/Images/Illustrations/Toucans/AppIconToucan.svg';
import EditIcon from '../../../../statics/Images/EditIcon';
import SettingsIcon from '../../../../statics/Images/SettingsIcon';

/**
 * Profile page
 * @name ProfileBanner
 */
const ProfileBanner = ({ setActiveTab, activeTab }) => {
  const [appContext, dispatch] = useContext(StorageContext);
  const firebase = useContext(FirebaseContext);
  const [error, setError] = useState(null);

  const updateAvatar = async (event) => {
    // Update only necessary when a file has been selected
    if (event.target.files.length === 1) {
      try {
        // Load image and use userid as file name
        const fileName = firebase.auth.currentUser.uid;
        const webpImage = await convertToWebp(event.target.files[0]);

        dispatch({
          type: 'SET_PROFILE_PICTURE',
          payload: URL.createObjectURL(webpImage),
        });

        // Upload image to firebase storage and assign path to auth.currentUser
        await firebase.uploadImage('profile-pictures', fileName, webpImage);
      }
      catch (err) {
        console.error(err);
        setError(err);
      }
    }
  };

  return (
    <>
      <div className='header-gradient'></div>
      <div className='padding-layout-horisontal'>

        <div className='profile-img-wrapper'>
          <div className='profile-img-input-group'>
            {appContext.profilePicture ?
              <img className='profile-img' src={appContext.profilePicture} alt='Profile image' /> :
              <img className='profile-img' src={defaultAvatar} alt='Profile image' />
            }
            <label htmlFor='profile-img-input'>
              <div className='edit-icon'>
                <EditIcon color='#303030' />
              </div>
            </label>
          </div>

          <div className='profile-btns-group'>
            <div className='notifications-icon'>
              {/* I'm keeping this div for the icon that comes next into this place in the design. */}
            </div>

            {
              activeTab === 'Profile' ?
                <div className='btn btn-primary settings-icon' onClick={() => setActiveTab('Overview')} >
                  <SettingsIcon color='#fff' /><span>Edit Profile</span>
                </div>
                :
                <div className='btn btn-primary settings-icon' onClick={() => setActiveTab('Profile')} >
                  <span>Cancel Edit</span>
                </div>
            }

          </div>
          <input id='profile-img-input' type='file' accept='image/*' onChange={updateAvatar} />
          {error && <p>{error.message}</p>}
        </div>

        <h1 className='content-margin-base'>{appContext.profile.username}</h1>
        <p>{firebase.auth.currentUser.providerData[0].email}</p>
      </div>
    </>

  );
};

export default ProfileBanner;
