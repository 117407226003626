import React from 'react';
import { Link } from 'react-router-dom';

import BannerBaseContainer from '../BannerBaseContainer';
import TestIcon from '../../statics/Images/TestIcon.svg';

import { useAnalytics } from '../../hooks/Firebase';

/**
 * Banner to access startup fit results
 *
 * @name StartupFitResultsBanner
 */
const StartupFitResultsBanner = () => {
  const { clickEvent, TYPES: { CLICK } } = useAnalytics();

  return (
    <BannerBaseContainer>
      <img className='banner-icon' src={TestIcon} />
      <h3 className='content-margin-base'>Startup Fit Test</h3>
      <p className='content-margin-base'>Ready to become an entrepreneur? Check out your test results.</p>
      <Link className='btn btn-primary' to='../../startup-fit/results' role='button'
        onClick={() => {
          clickEvent(CLICK.TYPE.VIEW, CLICK.CATEGORY.STARTUP_FIT_TEST);
        }}>
        <div className='btn btn-primary content-margin-base'>Results</div>
      </Link>
    </BannerBaseContainer>
  );
};

export default StartupFitResultsBanner;
