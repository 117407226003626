import React, { useContext, useEffect } from 'react';

import SettingsList from '../SettingsList';
import { StorageContext } from '../../Storage';
import { FirebaseContext } from '../../Firebase';
import { isSupported, hasPermissions } from '../../../tools/Notifications';
import { useAnalytics } from '../../../hooks/Firebase';

const NotificationSettings = () => {
  const [appContext, dispatch] = useContext(StorageContext);
  const firebase = useContext(FirebaseContext);
  const { clickEvent, TYPES: { CLICK } } = useAnalytics();

  const togglePushNotifications = async () => {
    await firebase.updateUser({
      notificationSettings: {
        ...appContext.notificationSettings,
        pushEnabled: !appContext.notificationSettings.pushEnabled,
      },
    });
    await dispatch({
      type: 'SET_NOTIFICATION_SETTINGS',
      payload: {
        ...appContext.notificationSettings,
        pushEnabled: !appContext.notificationSettings.pushEnabled,
      },
    });
  };

  const toggleEmailNotifications = async () => {
    await firebase.updateUser({
      notificationSettings: {
        ...appContext.notificationSettings,
        emailEnabled: !appContext.notificationSettings.emailEnabled,
      },
    });
    await dispatch({
      type: 'SET_NOTIFICATION_SETTINGS',
      payload: {
        ...appContext.notificationSettings,
        emailEnabled: !appContext.notificationSettings.emailEnabled,
      },
    });
  };

  const settingsList = [
    {
      title: 'Push Notifications',
      settings: [
        {
          title: 'Enabled',
          action: undefined,
          option: {
            name: 'toggle',
            checked: appContext.notificationSettings.pushEnabled,
            onChange: () => togglePushNotifications(),
          },
          onClick: () => { clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.PUSH_NOTIFICATIONS_ENABLE, CLICK.LOCATION.PUSH_NOTIFICATIONS); },
        },
      ],
    },
    {
      title: 'Email Notifications',
      settings: [
        {
          title: 'Enabled',
          action: undefined,
          option: {
            name: 'toggle',
            checked: appContext.notificationSettings?.emailEnabled,
            onChange: () => toggleEmailNotifications(),
          },
          onClick: () => { clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.PUSH_NOTIFICATIONS); },
        },
      ],
    },
  ];

  // Scrolls the window to the top when the component is mounted
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(async () => {
    if(appContext.notificationSettings.pushEnabled && isSupported() && !hasPermissions()){
      const requestPermission = await Notification.requestPermission();
      if(requestPermission == 'granted') {
        dispatch({
          type: 'SET_NOTIFICATION_SETTINGS',
          payload: { pushEnabled: true },
        });
      }
    }
  },[appContext.notificationSettings.pushEnabled]);

  return (
    <>
      {
        !appContext.isLoading &&
        <div className='padding-layout-horisontal'>
          <SettingsList settingsList={settingsList} />
        </div>
      }
    </>
  );
};

export default NotificationSettings;
