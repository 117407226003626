import { emptyStorage } from '.';

const Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CAREER_INFO':
      return {
        ...state,
        career: action.payload,
      };
    case 'ADD_CAREER_INFO':
      return {
        ...state,
        career: [...state.career, action.payload],
      };
    case 'EDIT_CAREER_INFO':
      return {
        ...state,
        career: state.career.reduce((acc, curr, idx) => { idx === action.payload.idx ? acc.push(action.payload.data) : acc.push(curr); return acc; }, []),
      };
    case 'DELETE_CAREER_INFO':
      return {
        ...state,
        career: state.career.reduce((acc, curr, idx) => { idx === action.payload.idx ? acc.splice(action.payload.idx, 1) : acc.push(curr); return acc; }, []),
      };
    case 'SET_SKILLS':
      return {
        ...state,
        skills: action.payload,
      };
    case 'SET_PROFILE':
      return {
        ...state,
        profile: action.payload,
      };
    case 'SET_PROFILE_PICTURE':
      return {
        ...state,
        profilePicture: action.payload,
      };
    case 'SET_STARTUP_FIT_RESULTS':
      return {
        ...state,
        startupFitTestResults: action.payload,
      };
    case 'SET_CAREER_PREDICTION_RESULTS':
      return {
        ...state,
        careerPredictionResults: action.payload,
      };
    case 'SET_USER_PREDICTION_RATINGS':
      return {
        ...state,
        feedback: action.payload,
      };
    case 'ADD_TOASTS':
      return {
        ...state,
        toasts: [...state.toasts, action.payload],
      };
    case 'DELETE_TOASTS':
      return {
        ...state,
        toasts: state.toasts.reduce((acc, curr, idx) => { idx === action.payload.idx ? acc.splice(action.payload.idx, 1) : acc.push(curr); return acc; }, []),
      };
    case 'SET_NOTIFICATIONS':
      return {
        ...state,
        notifications: action.payload,
      };
    case 'SET_NOTIFICATION_SETTINGS':
      return {
        ...state,
        notificationSettings: action.payload,
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_AUTHENTICATION_PROVIDER':
      return {
        ...state,
        authenticationProvider: action.payload,
      };
    case 'SET_CUSTOM_CLAIMS':
      return {
        ...state,
        claims: action.payload,
      };
    case 'SET_EQ_RESULTS':
      return {
        ...state,
        eqResults: action.payload,
      };
    case 'CLEAR_PROFILE':
      return emptyStorage;
    default:
      return state;
  }
};

export default Reducer;
