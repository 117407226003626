import React, { useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

/**
 * Public Redirect, redirects to app if user is logged in
 * @name PublicRedirect
 * @param {bool} signedIn Checks if user is logged in
 */
const PublicRedirect = ({ signedIn }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect user only if signdIn available on component mount
    if (signedIn) {
      navigate('/app', { state: { referrer: location } });
    }
  }, []);

  return (
    <>
      <Outlet />
    </>
  );
};

export default PublicRedirect;
