import React from 'react';
import './styles.scss';

import { useAnalytics } from '../../../../../hooks/Firebase';

const StartupFitQuestion = (props) => {
  const {
    question,
    index,
    questionID,
    testID,
    requestNewTestTrigger,
    setRequestNewTestTrigger,
    setFormValidationArray,
    formValidationArray,
  } = props;

  const { selectItemEvent, TYPES: { SELECT } } = useAnalytics();

  const saveAnswer = async (id, e) => {
    const formBody = [];
    formBody.push(encodeURIComponent('itemId')+'='+encodeURIComponent(id));
    formBody.push(encodeURIComponent('answer')+'='+encodeURIComponent(e.target.value));
    formBody.push(encodeURIComponent('SurveyId')+'='+encodeURIComponent(testID));
    let myHeaders = new Headers();
    myHeaders.append('X-AUTH-TOKEN', 'API-X-TOKEN $8l5BuucI36LZ9sjfLYBnz.OVzFAlGYM5dRxbfoMFK7L511gaPN4ce');
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formBody.join('&'),
    };

    selectItemEvent(SELECT.CATEGORY.STARTUP_FIT_TEST, testID, id, e.target.name, e.target.value);

    const sendAnswer = await fetch('https://app.mind-one.com/en/api/save_answer', requestOptions);
    const res = await sendAnswer.json();

    if (!formValidationArray.includes(id)) {
      setFormValidationArray([
        ...formValidationArray,
        id,
      ]);
    }

    //TODO: Remove below's console.debug. Leave while startup fit test is being tested
    console.debug(`Answer ID${id}`,res, e.target.value);

    // Requests new test in case task is no longer available
    if(res.status === 'Error'){
      setRequestNewTestTrigger(!requestNewTestTrigger);
    }

  };

  return (
    <div className='question-container'>
      <div className='question-title-container'>
        <p className='question-number'>{index + 1}.</p>
        <p className='question-wrapper'>{question}</p>
      </div>
      <div className='bottom-question-card'>
        <div className='question-form-wrapper'>
          <ul className='question-form-likert-scale-container'>
            <li>
              <input required type='radio' name={questionID} value='1' onClick={(e) => saveAnswer(questionID, e)}/>
              <span className='question-checked-icon'></span>
            </li>
            <li>
              <input type='radio' name={questionID} value='2' onClick={(e) => saveAnswer(questionID, e)}/>
              <span className='question-checked-icon'></span>
            </li>
            <li>
              <input type='radio' name={questionID} value='3' onClick={(e) => saveAnswer(questionID, e)}/>
              <span className='question-checked-icon'></span>
            </li>
            <li>
              <input type='radio' name={questionID} value='4' onClick={(e) => saveAnswer(questionID, e)}/>
              <span className='question-checked-icon'></span>
            </li>
            <li>
              <input type='radio' name={questionID} value='5' onClick={(e) => saveAnswer(questionID, e)}/>
              <span className='question-checked-icon'></span>
            </li>
          </ul>
          <div className='form-question-label'>
            <p>DISAGREE</p>
            <p>AGREE</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartupFitQuestion;
