import React from 'react';
import PropTypes from 'prop-types';

const PasswordHide = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 196.5 168.62">
      <path fill={color} d="M167.09,48.79c-2.19-1.59-4.79-3.37-7.76-5.22l-7.69,7.69c3.37,2,6.28,4,8.67,5.69,17.44,12.53,25.38,24.68,25.61,27.32-.23,2.73-8.17,14.88-25.61,27.41C149,119.77,126,133.34,98.25,133.34a92,92,0,0,1-25.1-3.59l-8.46,8.46a104.93,104.93,0,0,0,33.56,5.72c31,0,56.45-15.1,68.84-24.09,14.62-10.62,29.41-26.23,29.41-35.53S181.71,59.41,167.09,48.79ZM10.58,84.36c.23-2.73,8.17-14.89,25.61-27.41C47.45,48.86,70.5,35.29,98.25,35.29a91.86,91.86,0,0,1,25.09,3.59l8.47-8.47A105,105,0,0,0,98.25,24.7c-31,0-56.46,15.1-68.85,24.09C14.79,59.41,0,75,0,84.31s14.79,24.91,29.4,35.53c2.19,1.59,4.79,3.37,7.76,5.22l7.69-7.69c-3.37-2-6.28-4-8.66-5.69C18.75,99.15,10.81,87,10.58,84.36Z
      M80.89,81l.41-.06-18,18a37.15,37.15,0,0,1-2.88-12.42,38.27,38.27,0,0,1,1.39-12.59,1.7,1.7,0,0,1,3.07-.42A16.31,16.31,0,0,0,80.89,81Z
      M87.82,47.92a38.12,38.12,0,0,1,25,1.46l-18,18c0-.12,0-.24,0-.36a16.3,16.3,0,0,0-7.5-16A1.69,1.69,0,0,1,87.82,47.92Z
      M136.1,84.31A37.84,37.84,0,0,1,97,122.14a37.31,37.31,0,0,1-13.36-2.92l49.5-49.5A37.69,37.69,0,0,1,136.1,84.31Z"/>
      <line fill="none" stroke={color} strokeWidth="12" strokeLinecap="round" x1="19.23" y1="163.33" x2="177.26" y2="5.29"/>
    </svg>
  );
};

PasswordHide.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};
PasswordHide.defaultProps = {
  width: '24px',
  height: '24px',
};

export default PasswordHide;
