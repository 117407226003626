import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

import PasswordShow from '../../statics/Images/Input/PasswordShow';
import PasswordHide from '../../statics/Images/Input/PasswordHide';

/**
 * Input with Icon on the left
 *
 * @name InputWithIcon
 *
 * @param {node} icon Icon to be displayed
 * @param {string} id Input id to use
 * @param {string} type type of input
 * @param {string} placeholder Placeholder displayed on input
 * @param {string} defaultValue Value to be shown in case the input is pre filled, needs to be static
 * @param {func} onChange Func handling the behaviour desired when user types on input (i.e. triggers search)
 * @param {bool} required If the input is required to continue
 * @param {string} autoComplete Native autocomplete behaviour on|off
 * @param {string} searchInputRef Ref needed so the parent component knows the event result
 * @param {bool} error Input turns red
 */
const InputWithIcon = (props) => {
  const {
    icon,
    id,
    type,
    placeholder,
    onChange,
    required,
    defaultValue,
    autoComplete,
    searchInputRef,
    error,
    disabled,
  } = props;
  const [passwordVisible, togglePasswordShow] = useState(false);
  const [floatType, setFloatType] = useState('floating-input');

  useEffect(() => {
    if(defaultValue?.length){
      setFloatType('floating-input not-empty');
    } else {
      setFloatType('floating-input');
    }
  }, [defaultValue]);

  const toggleViewPasswordHandler = () => {
    togglePasswordShow(!passwordVisible);
  };

  const onChangeWithFloaterHandler = (e) => {
    if(e.target.value.length !== 0 ){
      setFloatType('floating-input not-empty');
      onChange ? onChange(e) : null;
    } else {
      setFloatType('floating-input');
      onChange ? onChange(e) : null;
    }
  };

  return (
    <div className={!error ? 'input-wrapper floating-label-content' : 'input-wrapper floating-label-content input-wrapper-error'} key={defaultValue}>

      <input
        style={type === 'password' ? { paddingRight: 40 + 'px' } : null }
        id={id}
        name={id}
        type={ type !== 'password' ? type : passwordVisible ? 'text' : 'password'}
        placeholder={''}
        onChange={onChangeWithFloaterHandler}
        required={required}
        defaultValue={defaultValue}
        autoComplete={autoComplete}
        ref={searchInputRef}
        className={ floatType }
        disabled={disabled}
      />
      <label className='floating-label'>
        <span className='icon-wrapper'>
          {icon}
        </span>
        <span className={error ? 'placeholder-error' : ''}>{placeholder}</span>
      </label>
      {
        id === 'password'
        &&
        <div className='show-password-wrapper' onClick={toggleViewPasswordHandler}>
          { passwordVisible ? <span className='password-show'><PasswordShow color={error ? '#ff5252' : '#a4a4a4'}/></span> : <span className='password-hide'><PasswordHide color={error ? '#ff5252' : '#a4a4a4'}/></span> }
        </div>
      }
    </div>
  );
};

InputWithIcon.propTypes = {
  icon: PropTypes.node.isRequired,
  id: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
  autoComplete: PropTypes.string,
  searchInputRef: PropTypes.object,
  error: PropTypes.bool,
};

export default InputWithIcon;
