import React from 'react';
import './styles.scss';

const OpenGraphType = (type) => {
  return type === 'article' ? 'article' : 'div';
};

const OpenGraphPreview = ({ openGraph }) => {
  const Type = OpenGraphType(openGraph.type);
  const hostname = new URL(openGraph.url).hostname.replace('www.', '');
  return (
    <Type className='open-graph-item content-margin-base'>
      <a href={openGraph.url} target='_blank' rel='noopener noreferrer'>
        {
          openGraph.image ? (
            <div className='open-graph-image-container'>
              <img src={openGraph.image}/>
              <div className='open-graph-image-text-container padding-layout-base'>
                <p className='color-primary'>{hostname}</p>
                <p className='color-text-base open-graph-item-title'>{openGraph.title}</p>
              </div>
            </div>
          ) : (
            <div className='padding-layout-base open-graph-background-container'>
              <p className='color-primary'>{hostname}</p>
              <p className='color-text-base open-graph-item-title'>{openGraph.title}</p>
            </div>
          )
        }
      </a>
    </Type>
  );
};

export default OpenGraphPreview;
