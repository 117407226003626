import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { FirebaseContext } from '../../components/Firebase';
import { StorageContext } from '../Storage';

import { useAnalytics } from '../../hooks/Firebase';
import useWindowDimensions from '../../hooks/useWindowDimensions';

import DashboardIcon from '../../statics/Images/DashboardIcon';
import CommunityIcon from '../../statics/Images/CommunityIcon';
import SettingsIcon from '../../statics/Images/SettingsIcon';
import FeedbackIcon from '../../statics/Images/FeedbackIcon';
import HelpIcon from '../../statics/Images/HelpIcon';
import MoreIcon from '../../statics/Images/MoreIcon';
import JobsIcon from '../../statics/Images/JobsIcon';
import MeIcon from '../../statics/Images/MeIcon';
import CloseIcon from '../../statics/Images/CloseIcon';
import NotificationsIcon from '../../statics/Images/NotificationsIcon';
import smallToucan from '../../statics/Images/Illustrations/Toucans/SmallToucan.svg';
import ToucanDesktop from '../../statics/Images/ToucanDesktop.svg';

import GeneralFeedbackModal from '../../components/GeneralFeedbackModal';

/**
 * A list of paths that we want to show the navbar on
 * @readonly
 * @const {array, <String>} SHOW_NAVBAR_PATHS_MOBILE
 */
const SHOW_NAVBAR_PATHS_MOBILE = [
  '/app/dashboard/',
  '/app/jobs/',
  '/app/community/',
  '/app/profile/',
  '/app/matching-results',
  '/app/settings/',
];

/**
 * Bottom Nav on main content
 * @name NavBar
 */
const NavBar = () => {
  /**
   * Object with Navbar links details
   * @readonly
   * @const {array, <Object>} BOTTOM_LINKS
   */
  const BOTTOM_LINKS = [
    {
      icon: DashboardIcon,
      text: 'Home',
      path: 'dashboard',
      onClick: () => { clickEvent(CLICK.TYPE.NAVIGATION, CLICK.CATEGORY.NAV_DASHBOARD, CLICK.LOACATION.NAV_BAR); },
    },
    {
      icon: JobsIcon,
      text: 'Career',
      path: 'matching',
      onClick: () => { clickEvent(CLICK.TYPE.NAVIGATION, CLICK.CATEGORY.NAV_JOBS, CLICK.LOACATION.NAV_BAR); },
    },
    {
      icon: MoreIcon,
      text: 'More',
      path: '',
      onClick: () => { clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.MORE, CLICK.LOACATION.NAV_BAR); },
    },
  ];

  /**
   * Object with More links details
   * @readonly
   * @const {array, <Object>} MORE_LINKS
   */
  const MORE_LINKS = [
    {
      icon: MeIcon,
      text: 'Profile',
      path: 'profile',
      onClick: () => { clickEvent(CLICK.TYPE.NAVIGATION, CLICK.CATEGORY.NAV_ME, CLICK.LOACATION.NAV_BAR); },
    },
    {
      icon: CommunityIcon,
      text: 'Community',
      path: 'community',
      onClick: () => {
        markDashboardTaskDone();
        clickEvent(CLICK.TYPE.NAVIGATION, CLICK.CATEGORY.NAV_COMMUNITY, CLICK.LOACATION.NAV_BAR);
      },
    },
    {
      icon: SettingsIcon,
      text: 'Settings',
      path: 'settings',
      onClick: () => {
        markDashboardTaskDone();
        clickEvent(CLICK.TYPE.NAVIGATION, CLICK.CATEGORY.SETTINGS, CLICK.LOACATION.NAV_BAR);
      },
    },
    {
      icon: NotificationsIcon,
      text: 'Notifications',
      path: 'notifications',
      onClick: () => {
        clickEvent(CLICK.TYPE.NAVIGATION, CLICK.CATEGORY.NOTIFICATIONS, CLICK.LOCATION.NAV_BAR);
      },
    },
  ];

  const { pathname } = useLocation();
  const { width } = useWindowDimensions();
  const { clickEvent, TYPES: { CLICK } } = useAnalytics();
  const [appContext] = useContext(StorageContext);
  const firebase = useContext(FirebaseContext);
  const [state] = useContext(StorageContext);
  const [dashboardTasks, setDashboardTasks] = useState(state.profile.dashboardTasks || []);
  const [moreVisible, setMoreVisible] = useState(false);
  const [showGeneralFeedbackModal, setShowGeneralFeedbackModal] = useState(false);
  const unreadNotifications = state.notifications.filter(n => !n.received).length;

  const moreVisibleHandler = () => {
    setMoreVisible(!moreVisible);
  };

  const showGeneralFeedbackModalHandler = () => {
    setShowGeneralFeedbackModal(!showGeneralFeedbackModal);
  };

  useEffect(() => {
    setDashboardTasks(state.profile.dashboardTasks);
  }, [state]);

  const markDashboardTaskDone = async () => {
    const task = 'community';
    if (!Object.values(state.profile.dashboardTasks).includes(task)) {
      dashboardTasks.push(task);
      setDashboardTasks(dashboardTasks);
      await firebase.updateUser({ dashboardTasks });
    }
  };

  const NavBarContent = () => {
    return(
      <>
        <nav className='tabs-nav'>
          <Link className='logo-desktop'
            role='button' to='/app/dashboard' onClick={() => {
              clickEvent(CLICK.TYPE.NAVIGATION, CLICK.CATEGORY.NAV_DASHBOARD, CLICK.LOCATION.HEADER);
            }}>
            <img src={ToucanDesktop} alt='HROS Toucan' />
          </Link>
          { width >= 1024 ?
            BOTTOM_LINKS
              .concat(MORE_LINKS) // Combined links for the desktop view
              .filter(link => link.icon !== undefined)
              .map((link, idx) => {
                const Icon = link.icon;
                const activeTab = pathname.includes(link.path);

                return (
                  <div key={idx}>
                    { link.path &&
                      <Link to={link.path} onClick={() => link.text === 'Community' ? markDashboardTaskDone() : null}>
                        <div className={activeTab ? 'tab-link-group active' : 'tab-link-group'}>
                          <Icon
                            color={activeTab ? '#eb4a5a' : '#696969'}
                            fill='none'
                            width='28px'
                            height='28px'
                          />
                          <div className={activeTab ? 'tab-text active' : 'tab-text'}>{link.text}</div>
                          {link.path === 'notifications' &&
                            unreadNotifications > 0 &&
                            <div data-badge={unreadNotifications}></div>
                          }
                        </div>
                      </Link>
                    }
                  </div>
                );
              })
            :
            BOTTOM_LINKS // Separated links for the mobile view
              .filter(link => link.icon !== undefined)
              .map((link, idx) => {
                const Icon = link.icon;
                const activeTab = pathname.includes(link.path);

                return (
                  <div key={idx}>
                    { link.path ?
                      <Link to={link.path} onClick={() => link.text === 'Community' ? markDashboardTaskDone() : null}>
                        <div className={activeTab ? 'tab-link-group active' : 'tab-link-group'}>
                          <Icon
                            color={activeTab ? '#eb4a5a' : '#696969'}
                            fill='none'
                            width='28px'
                            height='28px'
                          />
                          <div className={activeTab ? 'tab-text active' : 'tab-text'}>{link.text}</div>
                        </div>
                      </Link>
                      :
                      <>
                        <div className='tab-link-group' onClick={moreVisibleHandler}>
                          <Icon
                            color='#696969'
                            fill='none'
                            width='28px'
                            height='28px'
                          />
                          <div className='tab-text'>{link.text}</div>
                        </div>
                      </>
                    }
                  </div>
                );
              })
          }
          { width >= 1024 && // Additional links for the desktop view
            <>
              <div className='tab-link-group'
                onClick={() => {
                  clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.REVIEW, CLICK.LOCATION.NAV_BAR);
                  showGeneralFeedbackModalHandler();
                }}>
                <FeedbackIcon
                  color='#696969'
                  fill='none'
                  width='28px'
                  height='28px'/>
                <div className='tab-text'>Give feedback</div>
              </div>
              <a className='tab-link-group'
                href={'https://hros.io/contact/'}
                rel='noopener noreferrer'
                target='_blank'
                onClick={() => {
                  clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.HELP, CLICK.LOCATION.NAV_BAR);
                }}>
                <HelpIcon
                  color='#696969'
                  fill='none'
                  width='28px'
                  height='28px'/>
                <div className='tab-text'>Help</div>
              </a>
            </>
          }
        </nav>
        { moreVisible &&
          <div className='more-wrapper'>
            <div className='more-content'>
              <Link className='logo-mobile'
                role='button' to='/app/dashboard' onClick={() => {
                  clickEvent(CLICK.TYPE.NAVIGATION, CLICK.CATEGORY.NAV_DASHBOARD, CLICK.LOCATION.HEADER);
                }}>
                <img src={ToucanDesktop} alt='HROS Toucan' />
              </Link>
              <div className='more-close-icon' onClick={() => { moreVisibleHandler(); }}>
                <CloseIcon color='#303030'/>
              </div>
              <div className='more-feedback-group'>
                <img className='content-margin-base' src={smallToucan} />
                <div>
                  <h3 className='content-margin-base'>Your feedback</h3>
                  <p className='content-margin-base'>This app is in testing phase. We’d love to hear your thoughts, so we can improve the final product.</p>
                  <button className='btn btn-primary content-margin-base'
                    onClick={() => {
                      clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.REVIEW, CLICK.LOCATION.NAV_BAR);
                      showGeneralFeedbackModalHandler();
                    }}>Give feedback</button>
                </div>
              </div>
              { // Separated links for the mobile view
                MORE_LINKS.map((link, idx) => {
                  return (
                    <Link to={link.path} onClick={() => link.text === 'Community' ? markDashboardTaskDone() : null} key={idx}
                      className={pathname.includes(link.path) ? 'more-link-group content-margin-base active' : 'more-link-group content-margin-base'}>

                      <link.icon
                        color={pathname.includes(link.path) ? '#eb4a5a' : '#696969'}
                        fill='none'
                        width='28px'
                        height='28px'/>

                      <div className={pathname.includes(link.path) ? 'more-link-text content-margin-base active' : 'more-link-text content-margin-base'}>{link.text}</div>
                      {link.path === 'notifications' &&
                        unreadNotifications > 0 &&
                        <div data-badge={unreadNotifications}></div>
                      }
                    </Link>
                  );
                })
              }
              <div className='more-link-group content-margin-xlarge' // Additional links for More on mobile
                onClick={() => {
                  clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.REVIEW, CLICK.LOCATION.NAV_BAR);
                  showGeneralFeedbackModalHandler();
                }}>
                <FeedbackIcon
                  color='#696969'
                  fill='none'
                  width='28px'
                  height='28px'/>
                <div className='more-link-text'>Give feedback</div>
              </div>
              <a className='more-link-group content-margin-xlarge'
                href={'https://hros.io/contact/'}
                rel='noopener noreferrer'
                target='_blank'
                onClick={() => {
                  clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.HELP, CLICK.LOCATION.NAV_BAR);
                }}>
                <HelpIcon
                  color='#696969'
                  fill='none'
                  width='28px'
                  height='28px'/>
                <div className='more-link-text'>Help</div>
              </a>
            </div>
          </div>
        }

        <GeneralFeedbackModal
          show={showGeneralFeedbackModal}
          onClose={showGeneralFeedbackModalHandler}
          state={appContext}
          feature={{
            name: 'Career Coach', // feature name that is part of the modal title
            collection: 'career_coach', // separate_words_with_low_dash
          }}/>
      </>
    );
  };

  return (
    <>
      { width >= 1024 ? <NavBarContent /> :
        SHOW_NAVBAR_PATHS_MOBILE.find(url => url.includes(pathname)) && <NavBarContent />
      }
    </>

  );
};

export default NavBar;
