// Heavily inspired by https://github.com/zarkoselak/pretty-date-js

const unitHelper = (value, name, units) => {
  return value > 1 ? units[name][1] : units[name][0];
};

const prettifyDate = (diffs, units) => {
  const data = diffs.find(d => Boolean(d.value));

  return {
    value: data?.value || 1,
    unit: data?.unit || 'seconds',
    misc: units.misc[0],
  };
};

/**
 * Gets the time difference between two dates in pretty language
 * @param {Date} startDate - Start date to compare to
 * @param {Date} [endDate] - End date to compare with, default is now
 */
export const getDateDiff = (startDate, endDate = new Date()) => {
  const start = startDate.getTime();
  const end = endDate.getTime();

  const units = {
    seconds: ['just now', 'seconds'],
    minutes: ['minute', 'minutes'],
    hours: ['hour', 'hours'],
    days: ['day', 'days'],
    months: ['month', 'months'],
    years: ['year', 'years'],
    misc: ['ago', 'Invalid input, please check formating'],
  };

  if (!startDate || !(startDate instanceof Date)) {
    throw new Error('startDate not defined or not of type \'Date\'.');
  }

  const ms = end - start;
  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  const diffs = [{
    value: years,
    unit: unitHelper(years, 'years', units),
  },{
    value: months % 30,
    unit: unitHelper(months, 'months', units),
  },{
    value: days % 24,
    unit: unitHelper(days, 'days', units),
  },{
    value: hours % 60,
    unit: unitHelper(hours, 'hours', units),
  },{
    value: minutes % 60,
    unit: unitHelper(minutes, 'minutes', units),
  },{
    value: seconds % 60,
    unit: unitHelper(seconds, 'seconds', units),
  }];

  return prettifyDate(diffs, units);
};
