import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../../components/Button';
import ModalBaseContainer from '../../../components/ModalBaseContainer';

const ConfirmCloseModal = ({ show, onClose }) => {
  const navigate = useNavigate();

  const deleteResultsHandler = async () => {
    onClose();
    navigate('/app/profile');
  };

  return (
    <ModalBaseContainer show={show} onClose={onClose}>
      <h2>Quit Test</h2>
      <p>Are you sure you want to quit test, you will need to start it over.</p>
      <div className='btn-block-wrapper'>
        <Button
          onClick={onClose}
          theme='btn-primary'
          type='button'>Continue Test</Button>
        <Button
          onClick={() => deleteResultsHandler()}
          theme='btn-secondary'
          type='button'>Quit Test</Button>
      </div>
    </ModalBaseContainer>
  );
};

export default ConfirmCloseModal;
