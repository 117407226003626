import React, { useContext, useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';

import { useAnalytics } from '../../hooks/Firebase';
import { FirebaseContext } from '../../components/Firebase';
import Loader from '../../statics/Images/Loader';
import ToucanEQtest from '../../statics/Images/Illustrations/ToucanEQtest.svg';
import useToasts from '../../hooks/useToasts';
import ResultsSection from '../EQSurvey/ResultsSection';

const EMPTY_STATE = {
  scores: null,
  totalScore: null,
  erFlexibility: null,
  authorName: null,
};

/**
 * Public EQ results page
 * @param {boolean} signedIn - signed in
 */
const PublicResult = ({ signedIn }) => {
  const firebase = useContext(FirebaseContext);
  const { pageViewEvent, clickEvent, TYPES: { CLICK } } = useAnalytics();
  const { publishError } = useToasts();
  const navigate = useNavigate();
  const [state, setState] = useState(EMPTY_STATE);
  const { resultID } = useParams();

  useEffect(() => {
    document.title = 'EQ Survey Shared Results | HROS Career Coach';
    pageViewEvent();
    window.scrollTo(0, 0);
  }, []);

  useEffect(async () => {
    const snapshot = await firebase.getCollectionItem('public_results', resultID);

    if (!snapshot.exists()) {
      publishError('Results not found. Please try again later.');
      navigate('/');
      return;
    }
    const results = { ...snapshot.data().results };

    const totalScore = results['Emotional Intelligence Score'];
    const erFlexibility = results['Emotion Regulation Flexibility'];
    const scores = { ...results };

    delete scores['Emotional Intelligence Score'];
    delete scores['Emotion Regulation Flexibility'];

    setState({
      scores,
      totalScore,
      erFlexibility,
      authorName: snapshot.data().authorName,
    });

    // Get author name if the user is signed in
    if(!snapshot.data().authorName && signedIn){
      const userData = await firebase.getCollectionItem('users', snapshot.data().userID);
      if (userData.exists()) {
        const firstName = userData.data().firstName;
        setState(s=>({
          ...s,
          authorName: firstName,
        }));
      }
    }

  }, []);

  return (
    <>
      {
        state.scores === null ?
          <div className='loading-test'>
            <Loader />
          </div>
          :
          <div className='eq-result'>
            <div className='eq-result-tucan'>
              <img src={ToucanEQtest} alt='HROS logo'/>
            </div>
            <div className='eq-result-wrapper'>
              <div className='eq-result-container padding-layout-horisontal'>

                {
                  !signedIn &&
                    <div className='centred-text'>
                      <h2 className='content-margin-xlarge'>Test your Emotional Intelligence now!</h2>
                      <Link to='/signup' role='button' className='btn btn-primary content-margin-base'
                        onClick={() => {
                          clickEvent(CLICK.TYPE.NAVIGATION, CLICK.CATEGORY.EQ_SHARED_SIGNUP, CLICK.LOCATION.EQ_TEST_SHARED_RESULTS);
                        }}>Sign up</Link>
                      <p className='content-margin-base'>Already a hero? <Link className='link color-primary' to='/login'>Log in</Link></p>
                    </div>
                }

                <h2 className='score-title content-margin-xlarge'>{state.authorName && `${state.authorName}'s`} EQ score is<span className='score-number'>{Math.floor(state.totalScore*100)}%</span></h2>

                <ResultsSection
                  erFlexibility={state.erFlexibility}
                  scores={state.scores}
                  eqResults={state.scores}
                />

                {
                  signedIn && <Link to='/app' role='button' className='btn btn-primary content-margin-xlarge'>Back to app</Link>
                }
              </div>
            </div>
          </div>
      }
    </>
  );
};

export default PublicResult;
