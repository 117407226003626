import React from 'react';
import PropTypes from 'prop-types';

import ModalBaseContainer from '../../../../components/ModalBaseContainer';

/**
* LearnMoreModal component
 * @name LearnMoreModal
 * @component
 * @category Pages
 * @subcategory JobMatching
 * @param {bool} show - Show modal
 * @param {function} onClose - Close modal
 */
const LearnMoreModal = ({ show, onClose }) => {

  return (
    <ModalBaseContainer show={show} onClose={onClose}>
      <h1 className='content-margin-base'>Welcome to our Career Matching!</h1>
      <p>Here, you&apos;ll find exciting career opportunities tailored to match not just your experience &amp; skills, but personality as well. We believe in empowering individuals as they are, because thriving in your dream job also means that you have the right personality for it. </p>
      <p>Personality-based matching: we know it&rsquo;s a big promise, but we take it step by step. We started with building an algorithm, that takes not just your experience but also your Emotional Intelligence into account. After analyzing your profile, our AI algorithm recommends you suitable job titles and then jobs that you can directly apply to!</p>
      <p>Sounds exciting, right? Stay tuned for more &amp; get ready for the new era of job matching!</p>
      <div className='btn btn-primary content-margin-base' role='button' onClick={onClose}>Got it</div>
    </ModalBaseContainer>
  );
};

LearnMoreModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default LearnMoreModal;
