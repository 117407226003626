import React, { useContext, useState } from 'react'; // useRef

import './styles.scss';

import { StorageContext } from '../../../../components/Storage';

import ProfileBanner from '../ProfileBanner';
import ProfileContent from '../ProfileContent';

import EditProfileOverview from '../EditProfile/EditProfileOverview';
import EditProfilePreferences from '../EditProfile/EditProfilePreferences';
import EditProfileExperience from '../EditProfile/EditProfileExperience';
import EditProfileTabs from '../EditProfile/EditProfileTabs';

import Loader from '../../../../statics/Images/Loader';

/**
 * Profile page
 * @name ProfileOverview
 */

const ProfileOverview = () => {
  const [appContext] = useContext(StorageContext);
  const [activeTab, setActiveTab] = useState('Profile');

  const renderContent = () => {
    switch (activeTab) {
      case 'Overview':
        return <EditProfileOverview setActiveTab={setActiveTab} />;
      case 'Preferences':
        return <EditProfilePreferences setActiveTab={setActiveTab} />;
      case 'Experience':
        return <EditProfileExperience setActiveTab={setActiveTab} />;
      default:
        return <ProfileContent />;
    }
  };

  return (
    <>
      {
        appContext.isLoading ?
          <Loader className='loading-animation' />
          :
          <>
            <ProfileBanner activeTab={activeTab} setActiveTab={setActiveTab} />

            <div className='padding-layout-horisontal'>
              {activeTab !== 'Profile' && <EditProfileTabs activeTab={activeTab} setActiveTab={setActiveTab} />}

              {renderContent()}
            </div>
          </>
      }
    </>

  );
};

export default ProfileOverview;
