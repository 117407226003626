import { useState, useContext } from 'react';
import { FirebaseContext } from '../../components/Firebase';

// TODO: Should possibly be refactored and merged with the similar useElasticSearch hook
const useElasticSearchSkills = () => {
  const firebase = useContext(FirebaseContext);
  const [elasticSearchState, updateElasticSearchState] = useState({
    results: [],
    lastQuery: '',
    isFetching: false,
  });

  const fetchSkills = async (query) => {

    const trimmed = query.trim();

    // Minimum length is 1 characters, reset results:
    if (trimmed.length === 0) {
      updateElasticSearchState(s => ({
        ...s,
        lastQuery: trimmed,
        results: [],
      }));
      return;
    }

    // If lastQuery is the same as current, no need to search again:
    if (elasticSearchState.lastQuery === trimmed) {
      return;
    }

    updateElasticSearchState(s => ({
      ...s,
      isFetching: true,
    }));

    const payload = {
      query: {
        bool: {
          must: {
            match: {
              skillName: {
                query,
                analyzer: 'edge_ngram_analyzer',
              },
            },
          },
          should: { prefix: { 'skillName.keyword': { value: query } } },
        },
      },
    };

    const results = await fetch('https://search.hros.io/skills/_search?size=5', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    const resultsObj = await results.json();
    const resultsArray = [];
    resultsObj.hits.hits.forEach(item => {
      resultsArray.push(
        {
          id: item._id,
          name: item._source.skillName,
          relevance: item._score,
        }
      );
    });
    updateElasticSearchState(s => ({
      ...s,
      results: resultsArray,
      isFetching: false,
    }));
  };

  const skillSelected = async (skillData) => {
    const snapshot = await firebase.getSkillByName(skillData.name);
    let skillTitleRef;

    if (snapshot.size === 1) {
      snapshot.forEach(doc => {
        skillTitleRef = doc.ref;
      });
    }

    if (snapshot.size === 0) {
      skillTitleRef = await firebase.addCollectionItem('skills', {
        name: skillData.name,
        provider: ['elasticsearch'],
        createdAt: firebase.convertDate(new Date()),
        ealsticSerachID: skillData.id,
      });
    }

    return skillTitleRef;
  };

  const clearResults = () => {
    updateElasticSearchState(s => ({
      ...s,
      results: [],
    }));
  };

  return {
    fetchSkills,
    skillSelected,
    clearResults,
    elasticSearchState,
  };
};

export default useElasticSearchSkills;
