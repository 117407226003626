import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { FirebaseContext } from '../Firebase';

import JobsList from '../JobsList';
import JobEntryForm from '../JobsList/JobEntryForm';
import JobEntryModal from '../JobsList/JobEntryModal';
import WarningDialog from '../JobsList/WarningDialog';

/**
 * Career showcase that contains career timeline and job entry modal
 * to add job and change current
 *
 * @name CareerShowcase
 *
 * @param {array, <Object>} jobs Array with user career stored globally
 * @param {func} dispatch Function that updates global state
 * @example
 * // dispatch({
 * //   type: 'SET_CAREER_INFO',
 * //   payload: newJobs,
 * // });
 * @param {bool} isLoading Returns bool regarding loading career
 * @param {func} updateCareer Function to update career on firestore
 */
const CareerShowcase = ({ jobs, dispatch, isLoading, updateCareer }) => {
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobEntry, setJobEntry] = useState(null);
  const [showEntryModal, setShowEntryModal] = useState(false);
  const firebase = useContext(FirebaseContext);
  const [hasChanges, setHasChanges] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const onChange = (event) => {
    if (event.target.value === null) {
      setJobEntry({
        ...jobEntry,
        [event.target.name]: event.target.value,
      });
      return;
    }

    switch (event.target.name) {
      case 'start_date':
      case 'end_date':
        setJobEntry({
          ...jobEntry,
          [event.target.name]: firebase.convertDate(new Date(event.target.value)),
        });
        break;
      case 'job':
        setJobEntry({
          ...jobEntry,
          jobOccupationName: event.target.value.jobOccupationName,
          jobOccupationID: event.target.value.jobOccupationRef?.id,
          jobOccupationRef: event.target.value.jobOccupationRef,
          jobTitle: event.target.value.jobTitle,
          jobTitleRef: event.target.value.jobTitleRef,
        });
        break;
      default:
        setJobEntry({
          ...jobEntry,
          [event.target.name]: event.target.value,
        });
    }
  };

  const deleteJobHandler = (selectedJob) => {

    const newJobs = [...jobs];
    newJobs.splice(jobs.findIndex((e => e === selectedJob)), 1);

    if(Boolean(updateCareer) && typeof updateCareer === 'function') {
      updateCareer(newJobs);
    }

    dispatch({
      type: 'SET_CAREER_INFO',
      payload: newJobs,
    });
  };

  const onFormSubmitHandler = (event) => {
    event.preventDefault();

    const newJobs = [...jobs];

    if (!selectedJob) {
      newJobs.push(jobEntry);
    }

    if (selectedJob && JSON.stringify(selectedJob) !== JSON.stringify(jobEntry)) {
      newJobs[jobs.findIndex((e => e === selectedJob))] = jobEntry;
    }

    newJobs.sort((a,b) => b.start_date.seconds - a.start_date.seconds);

    if(Boolean(updateCareer) && typeof updateCareer === 'function') {
      updateCareer(newJobs);
    }

    dispatch({
      type: 'SET_CAREER_INFO',
      payload: newJobs,
    });

    toggleEntryModal();
  };

  const toggleEntryModal = () => {
    setShowEntryModal(!showEntryModal);
  };

  const onCloseEntry = () => {
    if (hasChanges) {
      setShowWarningModal(true);
      return;
    }

    // Close modal
    toggleEntryModal();
  };

  const editJobHandler = (job) => {
    // Assign selected job object to state. Helps to remember which job was selected from the list
    setSelectedJob(job);

    // Set the values of the selected job. These will be updated by the <form />
    setJobEntry(job);
    toggleEntryModal();
  };

  const cancelWarning = () => {
    setShowWarningModal(false);
  };

  const skipEditing = () => {
    // Reset state
    setShowWarningModal(false);
    setJobEntry(null);
    setSelectedJob(null);

    // Close modal
    toggleEntryModal();
  };

  return (
    <>
      <JobsList
        jobs={jobs}
        editJobHandler={editJobHandler}
        deleteJobHandler={deleteJobHandler}
        isLoading={isLoading} />
      <JobEntryModal show={showEntryModal} onClose={onCloseEntry}>
        <JobEntryForm
          jobData={jobEntry}
          isEdit={Boolean(selectedJob)}
          onChange={onChange}
          onFormSubmit={onFormSubmitHandler}
          hasChanges={setHasChanges}
        />
        <WarningDialog
          show={showWarningModal}
          cancelAction={cancelWarning}
          skipAction={skipEditing}
        />
      </JobEntryModal>
    </>
  );
};
CareerShowcase.propTypes = {
  jobs: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  updateCareer: PropTypes.func,
};
export default CareerShowcase;
