import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.scss';

import Complete from '../../../statics/Images/Complete';

import { useAnalytics } from '../../../hooks/Firebase';

const SIGNUP_COMPLETE_PAGE_VISIBLE = 3000;

/**
 * Complete signup process page, with a timeout to redirects to dashboard
 * @name CompletePage
 * @memberof SignUp
 */
const CompletePage = () => {
  const navigate = useNavigate();
  const { pageViewEvent, customEvent } = useAnalytics();

  // Set page title and send page view event
  useEffect(() => {
    document.title = 'Sign In - Completed | HROS Career Coach';
    pageViewEvent();
    customEvent('onboarding_complete');
  }, []);

  useEffect(() => {
    setTimeout( () => {
      navigate('/app/dashboard');
    }, SIGNUP_COMPLETE_PAGE_VISIBLE);
  }, []);

  return (
    <div className='complete-container'>
      <div className='screen-icon'>
        <Complete color='#eb4a5a' />
      </div>
      <div>
        <h1 className='centred-text content-margin-large'>All done!</h1>
        <p className='centred-text content-margin-large'>You can always edit your information in your profile.</p>
      </div>
    </div>
  );
};

export default CompletePage;
