import React from 'react';
import PropTypes from 'prop-types';

import ModalBaseContainer from '../../../components/ModalBaseContainer';
import { useAnalytics } from '../../../hooks/Firebase';

/**
 * Job delete confirm modal
 * @name JobDeleteModal
 *
 *
 * @param {bool} show Defines if modal is shown or not
 * @param {function} onClose Handler to close the modal
 * @param {function} deleteJobHandler Func handling the behaviour when user clicks to delete entry
 * @param {object} jobToDelete Job to be deleted
 *
 */
const JobDeleteModal = ({ show, onClose, deleteJobHandler, jobToDelete }) => {
  const { clickEvent, TYPES: { CLICK } } = useAnalytics();

  return (
    <ModalBaseContainer show={show} onClose={onClose}>
      <h2>Delete job?</h2>
      <p className='centred-text content-margin-base'>You can&apos;t undo this action.</p>
      <div className='btn-inline-wrapper'>
        <div className='btn btn-text' onClick={onClose}>Cancel</div>
        <div className='btn btn-primary'
          onClick={() => {
            clickEvent(CLICK.TYPE.DELETE, CLICK.CATEGORY.CAREER);
            deleteJobHandler(jobToDelete);
            onClose();}}>Delete</div>
      </div>
    </ModalBaseContainer>
  );
};
JobDeleteModal.propTypes = {
  deleteJobHandler: PropTypes.func.isRequired,
  jobToDelete: PropTypes.object,
};

export default JobDeleteModal;
