import React, { useContext, useEffect, useState } from 'react';

import { StorageContext } from '../../../components/Storage';
import { FirebaseContext } from '../../../components/Firebase';
import { useAnalytics } from '../../../hooks/Firebase';

import Loader from '../../../statics/Images/Loader';
import CloseIcon from '../../../statics/Images/CloseIcon';
// import FeedbackToucan from '../../../statics/Images/Illustrations/FeedbackToucan.svg';

import CompleteProfileList from './CompleteProfileList';
import GeneralFeedbackModal from '../../../components/GeneralFeedbackModal';
import NewFeaturesAnnouncements from './NewFeaturesAnnouncements';

/**
 * Dashboard tab, current landing page.
 * @name DashboardTab
 * @memberof Content
 */
const DashboardTab = () => {
  const [appContext] = useContext(StorageContext);
  const firebase = useContext(FirebaseContext);
  const { pageViewEvent, clickEvent, TYPES: { CLICK } } = useAnalytics();
  const [showGeneralFeedbackModal, setShowGeneralFeedbackModal] = useState(false);
  const [bannerVisible, setBannerVisible] = useState(false);
  const [counter, setCounter] = useState(0);
  const createdAt = firebase.auth.currentUser.metadata.createdAt;
  const [users, setUsers] = useState(JSON.parse(localStorage.getItem('users') || '[]'));

  // feature.collection + _feedback needs to corespond to GeneralFeedbackModal feature prop
  const appFeedback = JSON.parse(localStorage.getItem('app_feedback') || '[]');

  let startInterval;
  useEffect(() => {
    setCounter(parseInt((Date.now() - createdAt) / 1000));
    if (counter < 300) { // 5 min
      startInterval = setInterval(() => {
        setCounter(parseInt((Date.now() - createdAt) / 1000));
      }, 1000);
      return () => {
        window.clearInterval(startInterval);
      };
    }
    else if (counter >= 300) {
      setBannerVisible(true);
    }
  }, [counter]);

  useEffect(() => {
    return window.clearInterval(startInterval);
  }, []);

  const bannerVisibilityHandler = () => {
    users.push(firebase.auth.currentUser.uid);
    setUsers(users);
    localStorage.setItem('users', JSON.stringify(users));
    setBannerVisible(false);
  };

  const showGeneralFeedbackModalHandler = () => {
    setShowGeneralFeedbackModal(!showGeneralFeedbackModal);
  };

  // Set page title and send page view event and
  // Scrolls the window to the top when the component is mounted
  useEffect(() => {
    document.title = 'Dashboard | HROS Career Coach';
    pageViewEvent();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {
        appContext.isLoading ?
          <Loader className='loading-animation'/>
          :
          <div className='padding-layout-vertical'>
            <h1 className='padding-layout-horisontal'>Hi there, {appContext.profile.username}!</h1>
            { !users.includes(firebase.auth.currentUser.uid) && !appFeedback.includes(firebase.auth.currentUser.uid) && bannerVisible &&
              <div className='feedback-banner content-margin-base'>
                <div
                  onClick={() => {
                    clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.REVIEW, CLICK.LOCATION.DASHBOARD);
                    showGeneralFeedbackModalHandler();
                  }}>
                  <p className='content-margin-base'>This app is still in testing phase.</p>
                  <p className='content-margin-base'>Please tell us about your experience so far to help improve the final product.</p>
                  <p className='link color-primary content-margin-base'>Give Feedback Now</p>
                  {/*TODO: Add image when designr prepare svg <img className='' src={FeedbackToucan} /> */}
                </div>
                <div className='close-icon' onClick={() => { bannerVisibilityHandler(); }}>
                  <CloseIcon color='#303030' />
                </div>
              </div>
            }
            <div className='padding-layout-horisontal'>
              <h2 className='content-margin-large'>Become a HERO!</h2>
              <CompleteProfileList state={appContext}/>
            </div>

            <GeneralFeedbackModal
              show={showGeneralFeedbackModal}
              onClose={showGeneralFeedbackModalHandler}
              state={appContext}
              feature={{
                name: 'Career Coach', // feature name that is part of the modal title
                collection: 'career_coach', // separate_words_with_low_dash
              }}/>

            <NewFeaturesAnnouncements />

          </div>
      }
    </>
  );
};

export default DashboardTab;
