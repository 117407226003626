import React, { useState } from 'react';
import './styles.scss';

import Dropdown from '../../Dropdown';

import TwoOptions from './TwoOptions';
import LikertScale from './LikertScale';
import Slider from './Slider';
import SingleChoice from './SingleChoice';

const EMPTY_STATE = { type: null };

const QuestionsForm = () => {
  const [state, setState] = useState(EMPTY_STATE);

  const onChange = (e) => {
    let value = e.target.value;

    if (e.target.type === 'number') {
      value = e.target.valueAsNumber;
    }

    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  return (
    <>
      <Dropdown
        name='type'
        options={['single-choice', 'slider']}
        value={state.type}
        onChange={onChange}
      />
      {
        (() => {
          switch (state.type) {

            case 'two-options':
              return ( <TwoOptions type={state.type} clearState={setState}/> );

            case 'likert-scale':
              return ( <LikertScale type={state.type} clearState={setState} /> );

            case 'slider':
              return ( <Slider type={state.type} clearState={setState} /> );

            case 'single-choice':
              return ( <SingleChoice type={state.type} clearState={setState} /> );

            default:
              return ( <span className='padding-layout-base'>Select a Type to get additional fields </span> );
          }
        })()
      }

    </>
  );
};

export default QuestionsForm;
