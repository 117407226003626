import React, { useContext, useState } from 'react';

import { FirebaseContext } from '../../../Firebase';

import InputWithLabel from '../../InputWithLabel';
import Button from '../../../Button';

const EMPTY_STATE = {
  name: null,
  text: null,
};

const TwoOptions = ({ type, clearState }) => {
  const firebase = useContext(FirebaseContext);
  const [state, setState] = useState(EMPTY_STATE);

  const onChange = (e) => {
    let value = e.target.value;

    if (e.target.type === 'number') {
      value = e.target.valueAsNumber;
    }

    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    firebase.addCollectionItem('survey_questions', {
      createdAt: firebase.convertDate(new Date()),
      name: state.name,
      text: state.text,
      type: type,
      scoring: [{
        label: state.option_1_text,
        score: state.option_1_score || 0,
      },{
        label: state.option_2_text,
        score: state.option_2_score || 0,
      }],
    });
    setState(EMPTY_STATE);
    // Clears the state of the parent component
    clearState({ type: null });
  };

  return (
    <>
      <form className='padding-layout-base' onSubmit={onSubmit}>
        <InputWithLabel
          className='content-margin-base'
          name='name'
          type='text'
          onChange={onChange}
          value={state.name}
        />
        <InputWithLabel
          className='content-margin-base'
          name='text'
          type='text'
          onChange={onChange}
          value={state.text}
        />
        <div className='input-group content-margin-base'>
          <InputWithLabel
            name='option_1_text'
            type='text'
            onChange={onChange}
            value={state.option_1_text}
            label='First Option'
            className='input-group-item-large'
          />
          <input
            id='option_1_score'
            name='option_1_score'
            className='input-group-item-small'
            type='number'
            onChange={onChange}
            value={state.option_1_score || 0}
            min={0}
          />
        </div>
        <div className='input-group content-margin-base'>
          <InputWithLabel
            name='option_2_text'
            type='text'
            onChange={onChange}
            value={state.option_1_text}
            label='Second Option'
            className='input-group-item-large'
          />
          <input
            id='option_2_score'
            name='option_2_score'
            className='input-group-item-small'
            type='number'
            onChange={onChange}
            value={state.option_2_score || 0}
            min={0}
          />
        </div>
        <Button onClick={onSubmit} theme='btn-primary' type='submit'>Save</Button>
      </form>

    </>
  );
};

export default TwoOptions;
