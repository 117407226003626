import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './styles.scss';

import { useAnalytics } from '../../../hooks/Firebase';
import useToasts from '../../../hooks/useToasts';
import { StorageContext } from '../../../components/Storage';
import { FirebaseContext } from '../../../components/Firebase';
import ShareResultsModal from './ShareResultsModal';

import BackIcon from '../../../statics/Images/BackIcon';
import ShareIcon from '../../../statics/Images/ShareIcon.svg';
import Loader from '../../../statics/Images/Loader';
import ToucanEQtest from '../../../statics/Images/Illustrations/ToucanEQtest.svg';
import ResultsSection from '../ResultsSection';

const EMPTY_STATE = {
  publicID: null,
  shortLink: null,
  showShareResultsModal: false,
  eqResults: null,
  totalScore: null,
  erFlexibility: null,
  scores: null,
  isLoading: true,
};

const EQSurveyResults = () => {
  const baseURL =
      process.env.REACT_APP_PROJECT_ID === 'hero-career-coach' ?
        'https://talent.hros.io' :
        'https://thor.hros.io';
  const [appContext] = useContext(StorageContext);
  const { surveyResultsID } = useParams();
  const firebase = useContext(FirebaseContext);
  const [state, setState] = useState(EMPTY_STATE);
  const navigate = useNavigate();
  const { publishError } = useToasts();
  const { pageViewEvent, clickEvent, TYPES: { CLICK } } = useAnalytics();

  useEffect(() => {

    let currentSurvey = null;
    if(!appContext.isLoading) {
      currentSurvey = appContext.eqResults?.find(survey => survey.surveyResultsID === surveyResultsID);
    }

    if(!appContext.isLoading && currentSurvey && currentSurvey.results){
      const eqResults ={ ...currentSurvey.results };

      const scores = { ...eqResults };

      delete scores['Emotional Intelligence Score'];
      delete scores['Emotion Regulation Flexibility'];

      setState({
        ...state,
        eqResults,
        totalScore: eqResults['Emotional Intelligence Score'],
        erFlexibility: eqResults['Emotion Regulation Flexibility'],
        scores,
        isLoading: false,
      });

    }

    if(!appContext.isLoading && !currentSurvey?.results) {
      navigate('/app/profile');
      publishError('No survey results found');
    }

  }, [appContext.isLoading, appContext.eqResults]);

  const fetchShortLinkHandler = async (id) => {
    const shortLinkRequest = await fetch (`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_API_KEY}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          dynamicLinkInfo: {
            domainUriPrefix: `${baseURL}/share/eq`,
            link: `${baseURL}/p/${id}`,
            socialMetaTagInfo: {
              socialTitle: 'Emotional Genius? | Emotional Intelligence Test by HROS',
              socialDescription: `My EQ result is ${Math.floor(state.totalScore*100)}%, test your own today! Take the EQ test by HROS and discover your own strengths. HROS finds your dream job based on personality-based matching >> Explore more!`,
              socialImageLink: 'https://community.hros.io/wp-content/uploads/2023/04/eqShare.png',
            },
          },
          suffix: { option: 'SHORT' },
        }),
      });

    const shortLink = await shortLinkRequest.json();
    return shortLink.shortLink;
  };

  /**
  * The function allows the user to share their results using Firebase and displays a modal with a
  * shareable link, making the results public.
  */
  const onClickShare = async () => {
    const prevResults = await firebase.getEqTestResultbySurveyResultsID(surveyResultsID);
    let shortLink;

    // If the user has already shared their results for this survey
    if(!prevResults.empty) {

      // If the shortLink is not yet created
      if(!prevResults.docs[0].data().shortLink) {
        shortLink = await fetchShortLinkHandler(prevResults.docs[0].id);
        await firebase.updateEqTestPublicResult(prevResults.docs[0].id, { shortLink });
      }

      // If the authorName is not yet created
      if(!prevResults.docs[0].data().authorName) {
        await firebase.updateEqTestPublicResult(prevResults.docs[0].id, { authorName: appContext.profile.firstName });
      }

      setState((s) => ({
        ...s,
        publicID: prevResults.docs[0].id,
        shortLink: prevResults.docs[0].data().shortLink || shortLink,
      }));

      if (navigator.share) {
        navigator.share({
          title: 'Emotional Genius? | Emotional Intelligence Test by HROS',
          text: `My EQ result is ${Math.floor(state.totalScore*100)}%, test your own today! Take the EQ test by HROS and discover your own strengths. HROS finds your dream job based on personality-based matching >> Explore more!`,
          url: prevResults.docs[0].data().shortLink || shortLink,
        });
        return;
      }

      showShareResultsModalHandler();
      return;
    }

    const res = await firebase.shareResult(appContext.eqResults.find(survey => survey.surveyResultsID === surveyResultsID), appContext.profile.firstName);

    shortLink = await fetchShortLinkHandler(res.id);
    await firebase.updateEqTestPublicResult(res.id, { shortLink });

    setState((s) => ({
      ...s,
      publicID: res.id,
      shortLink,
    }));

    if (navigator.share) {
      navigator.share({
        title: 'Emotional Genius? | Emotional Intelligence Test by HROS',
        text: `My EQ result is ${Math.floor(state.totalScore*100)}%, test your own today! Take the EQ test by HROS and discover your own strengths. HROS finds your dream job based on personality-based matching >> Explore more!`,
        url: shortLink,
      });
      return;
    }

    showShareResultsModalHandler();
  };

  const showShareResultsModalHandler = () => {
    setState((s) => ({
      ...s,
      showShareResultsModal: !s.showShareResultsModal,
    }));
  };

  useEffect(() => {
    document.title = 'EQ Survey Results | HROS Career Coach';
    pageViewEvent();
    window.scrollTo(0, 0);
  }, []);

  const closePageHandler = () => {
    navigate('/app/profile');
  };

  return (
    <div className='eq-result'>
      <div className='eq-result-tucan'>
        <img src={ToucanEQtest} alt='HROS logo'/>
      </div>
      <div className='eq-results-header'>
        <div className='eq-results-header-group'>
          <div onClick={closePageHandler}>
            <BackIcon color='#303030' width='20' height='20'/>
          </div>
          <h1 className='eq-results-title'>Results</h1>
        </div>
        <div className='eq-result-share-btn'
          onClick={() => {
            onClickShare();
            clickEvent(CLICK.TYPE.NAVIGATION, CLICK.CATEGORY.EQ_TEST_SHARE_START, CLICK.LOCATION.EQ_TEST_RESULTS);
          }}>
          <img src={ShareIcon}/>
        </div>
      </div>
      <div className='eq-result-wrapper'>
        <div className='eq-result-container padding-layout-horisontal'>
          { state.isLoading || !state.eqResults ?
            <div className='loading-test'>
              <Loader/>
            </div>
            :
            <div className='padding-layout-horisontal'>
              <h2 className='content-margin-base'>Your EQ score is<span className='score-number'>{Math.floor(state.totalScore*100)}</span>%</h2>

              <ResultsSection
                erFlexibility={state.erFlexibility}
                scores={state.scores}
                eqResults={state.eqResults}
              />

              <div className='eq-article padding-layout-horisontal'>
                <h3 className='content-margin-base'>Read more about Emotional Intelligence</h3>
                <p className='content-margin-base'>Do you want to know even more what the results mean? How you can work on them & turn them into real life examples? Then read more now!</p>
                <a href={'https://hros.io/emotional-intelligence'}
                  className='link color-primary'
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={() => { clickEvent(CLICK.TYPE.NAVIGATION, CLICK.CATEGORY.EXTERNAL, CLICK.LOCATION.EQ_TEST_RESULTS); }}>
                    Read more</a>
              </div>
              <ShareResultsModal shortLink={state.shortLink} show={state.showShareResultsModal} onClose={showShareResultsModalHandler}/>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default EQSurveyResults;
