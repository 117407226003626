import React, { useRef, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { debounce } from 'throttle-debounce';

import './styles.scss';

import useToasts from '../../../hooks/useToasts';
import Autocomplete from '../../Autocomplete';
import MonthYearPicker from '../../MonthYearPicker';

// Skills disabled temporarly to be implemented after Beta
// import { FirebaseContext } from '../../Firebase';
// import { executeSearch } from '../../../tools/ExecuteSearch';

import Button from '../../Button';

import CountryDropdown from '../../CountryDropdown';
import { useAnalytics } from '../../../hooks/Firebase';
import { isEmptyObject } from '../../../tools/IsEmptyObject';
import useElasticSearch from '../../../hooks/useElasticSearch';

/**
 * Form to add new job entry
 *
 * @name JobEntryForm
 * @memberof CareerShowcase
 * @protected
 *
 * @param {function} onChange Func handling the behaviour when information is written on inputs and saved on the state.
 * @param {object} jobData Array with job details if user chooses an already existing job to pre-fill with details.
 * @param {function} onFormSubmit Func handling the behaviour when form is submited
 */

const JobEntryForm = ({ jobData: { jobTitle = '', start_date, end_date, country = '', company = '' } = {}, onChange, onFormSubmit, hasChanges }) => {

  const [initState] = useState(JSON.stringify({
    jobTitle,
    start_date,
    end_date,
    country,
    company,
  }));

  const { fetchJobTitles, clearResults, jobTitleSelected, elasticSearchState } = useElasticSearch();
  const [currentJob, setCurrentJob] = useState(false);
  const [formErrors, setFormErrors] = useState({
    jobTitle: false,
    startDate: false,
    endDate: false,
    country: false,
    company: false,
  });
  const { publishError } = useToasts();
  const [autocompleteInput, setAutoCompleteInput] = useState('');
  const jobInputRef = useRef();
  const checkboxRef = useRef();
  const { searchItemEvent, TYPES: { SEARCH }, clickEvent, TYPES: { CLICK } } = useAnalytics();

  const performDebouncedSearch = useMemo(() => debounce(333, (term) => { fetchJobTitles(term); }), []);

  useEffect(() => {
    if(end_date === null) {
      setCurrentJob(true);
      checkboxRef.current.checked = true;
    }
  }, [end_date]);

  useEffect(() => {
    // Clean-up when unmounting
    return () => {
      performDebouncedSearch.cancel();
    };
  }, []);

  useEffect(() => {
    const currentState = JSON.stringify({
      jobTitle,
      start_date,
      end_date,
      country,
      company,
    });

    hasChanges(initState !== currentState);
  }, [jobTitle, start_date, end_date, country, company]);

  // const filteredResults = searchResult.filter((doc) => !skills.find(d => doc.id === d.id));

  const dateChanged = (event) => {
    if (event.target.name === 'start_date') {
      setFormErrors({
        ...formErrors,
        startDate: false,
      });
    } else {
      setFormErrors({
        ...formErrors,
        endDate: false,
      });
    }

    onChange(event);
  };

  const onJobSubmit = async (event) => {
    event.preventDefault();
    let fe = {};

    if (!start_date || !start_date.seconds) {
      fe.startDate = true;
      await publishError('Please select a start date');
    }

    if ((!end_date || !end_date.seconds) && !currentJob) {
      fe.endDate = true;
      await publishError('Please select an end date');
    }

    if (start_date > end_date && !currentJob){
      fe.startDate = true;
      fe.endDate = true;
      await publishError( 'Starting date cannot be after the end date.');
    }

    if (jobInputRef.current.value !== jobTitle || jobInputRef.current.value === '') {
      fe.jobTitle = true;
      await publishError('Please choose a job title from the list.');
    }

    setFormErrors({
      ...formErrors,
      ...fe,
    });

    if (isEmptyObject(fe)) {
      onFormSubmit(event);
    }
  };

  const onChangeJobAutocomplete = (event) =>{
    let term = event.target.value;

    if (term === '') {
      onChange({
        target: {
          name: 'job',
          value: '',
        },
      });
    }

    setAutoCompleteInput(term);
    setFormErrors({
      ...formErrors,
      jobTitle: false,
    });

    if (!term.length) {
      clearResults();
    }

    performDebouncedSearch(term);
  };

  const currentJobHandler = (event) => {
    if(event.target.checked) {
      const currentDate = new Date().toDateString();
      onChange({
        target: {
          name: 'end_date',
          value: currentDate,
        },
      });
    }
    setCurrentJob(event.target.checked);
  };

  const selectJobHandler = async (doc, idx) => {
    searchItemEvent(SEARCH.TYPE.JOB_TITLE, SEARCH.CATEGORY.JOB, doc.name, idx, autocompleteInput);

    const [jobTitleRef, { jobOccupationRef, jobOccupationName }] = await jobTitleSelected(doc);

    onChange({
      target: {
        name: 'job',
        value: {
          jobTitle: doc.name,
          jobTitleRef,
          jobOccupationID: jobOccupationRef.id,
          jobOccupationRef,
          jobOccupationName,
        },
      },
    });

    clearResults();
    jobInputRef.current.value = doc.name;
  };

  const onClose = (ref) => {
    clearResults();
    ref.current.value = '';
  };

  return (
    // TODO: Check job title bug / not populated on job edit:
    <>
      <h2>Job details</h2>
      <form onSubmit={onJobSubmit} autoComplete='off'>
        <Autocomplete
          onChange={onChangeJobAutocomplete}
          searchResult={elasticSearchState.results}
          selectEntryHandler={selectJobHandler}
          inputPlaceholder='Job Title'
          inputName='jobSearch'
          isLoading={false}
          searchInputRef={jobInputRef}
          onClose={() => onClose(jobInputRef)}
          defaultValue={jobTitle || ''}
          error={formErrors.jobTitle}
        />
        <div className='input-wrapper floating-label-content'>
          <input
            type='text'
            id='company'
            name='company'
            placeholder={''}
            onChange={onChange}
            defaultValue={company.length !== 0 ? company : ''}
            autoComplete='off'
            className={company.length !== 0 ? 'floating-input not-empty' : 'floating-input'}
          />
          <label className='floating-label'>
            <span>Company</span>
          </label>
        </div>
        <CountryDropdown
          name='country'
          value={country}
          onChange={onChange}
        />
        <div className='content-margin-base month-year-picker-container'>
          <MonthYearPicker
            name='start_date'
            onChange={dateChanged}
            value={start_date}
            placeholder='Start'
            error={formErrors.startDate}
          />
          <MonthYearPicker
            name='end_date'
            onChange={dateChanged}
            value={end_date}
            disabled={currentJob}
            placeholder='End'
            error={formErrors.endDate}
          />
        </div>
        <div className='content-margin-base align-class-wrappper'>
          <div className='align-class'></div>
          <div className='align-class checkbox-wrapper'>
            <input type='checkbox' name='currentJob' id='currentJob' onChange={currentJobHandler} ref={checkboxRef} />
            <span className='checked-icon'></span>
            <label htmlFor='currentJob'>Current job</label>
          </div>
        </div>
        <Button theme={'btn-primary'} type='submit'
          onClick={() => {
            clickEvent(CLICK.TYPE.ADD, CLICK.CATEGORY.CAREER, CLICK.LOCATION.PROFILE_PAGE);
          }}>Save</Button>
      </form>
    </>
  );
};
JobEntryForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  jobData: PropTypes.object,
  onFormSubmit: PropTypes.func.isRequired,
};

export default JobEntryForm;
