import React from 'react';
import PropTypes from 'prop-types';

const EmailIcon = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 171 119" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path stroke={color} strokeWidth="12" d="M156.58 6H14.2C9.67126 6 6 9.67128 6 14.2V104.73C6 109.259 9.67126 112.93 14.2 112.93H156.58C161.109 112.93 164.78 109.259 164.78 104.73V14.2C164.78 9.67128 161.109 6 156.58 6Z"/>
      <path stroke={color} strokeWidth="12" d="M6 6L80.81 66.38C82.1645 67.2889 83.7588 67.7742 85.39 67.7742C87.0212 67.7742 88.6155 67.2889 89.97 66.38L164.78 6"/>
      <path stroke={color} strokeWidth="12" d="M6 112.92L61.19 52.19" />
      <path stroke={color} strokeWidth="12" d="M164.78 112.92L109.59 52.19"/>
    </svg>

  );
};

EmailIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};
EmailIcon.defaultProps = {
  width: '24px',
  height: '24px',
};

export default EmailIcon;
