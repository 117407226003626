import React, { useEffect } from 'react';
import './styles.scss';

import { useAnalytics } from '../../../hooks/Firebase';
import StartupFitTest from './StartupFitTest';
import BackButton from '../../../components/BackButton';

const StartupFitTestOverview = () => {
  const { pageViewEvent } = useAnalytics();

  // Set page title and send page view event
  // Scrolls the window to the top when the component is mounted
  useEffect(() => {
    document.title = 'Sign In - Basic Information | HROS Career Coach';
    pageViewEvent();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='yellow-background'></div>
      <div className='yellow-wrapper'>
        <div className='title-group yellow'>
          <BackButton color='#303030' url={'/app/profile'}/>
          <h1 className='title'>Startup-fit test</h1>
        </div>
        <div className='title-group-content padding-layout-horisontal'>
          <p className='content-margin-large'>Let us help you more in your career by choosing how much agree with the statements!</p>
          <StartupFitTest/>
        </div>
      </div>
    </>
  );
};

export default StartupFitTestOverview;
