import React, { useContext } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';

import './styles.scss';

import { StorageContext } from '../../Storage';

import SettingsModals from '../Modals';
import SettingsList from '../SettingsList';
import UserIcon from '../../../statics/Images/Input/UserIcon';
// import PasswordIcon from '../../../statics/Images/Input/PasswordIcon';
import EmailIcon from '../../../statics/Images/Input/EmailIcon';
import PrivacyPolicyIcon from '../../../statics/Images/PrivacyPolicyIcon';
import SignOutIcon from '../../../statics/Images/SignOutIcon';
import useQuery from '../../../hooks/useQuery';

import { useAnalytics } from '../../../hooks/Firebase';
import TrashCanIcon from '../../../statics/Images/TrashCanIcon';
import AboutIcon from '../../../statics/Images/AboutIcon';

const ACCESS_ROLES = [
  'admin',
  'productOwner',
];

const GeneralSettings = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const [state] = useContext(StorageContext);
  const { clickEvent, TYPES: { CLICK } } = useAnalytics();

  //! Temporary solution while authentication is synced between Match and Career Coach
  //! TODO: Remove when authentication is synced and uncomment the code below and remove showEmail()
  // const isEmailPasswordAuthentication = () => {
  //   return state.authenticationProvider === 'password' ?
  //     [{
  //       title: 'Email',
  //       Icon: EmailIcon,
  //       action: () => navigate({
  //         pathname: '.',
  //         search: createSearchParams({ modal: 'change-mail' }).toString(),
  //       }),
  //     },
  //     {
  //       title: 'Password',
  //       Icon: PasswordIcon,
  //       action: () => navigate({
  //         pathname: '.',
  //         search: createSearchParams({ modal: 'change-password' }).toString(),
  //       }),
  //     }] :
  //     [];
  // };

  // const isGoogleAuthentication = () => {
  //   return state.authenticationProvider === 'google.com' ?
  //     [{
  //       title: 'Current Email',
  //       Icon: EmailIcon,
  //       action: () => navigate({
  //         pathname: '.',
  //         search: createSearchParams({ modal: 'show-mail' }).toString(),
  //       }),
  //     }] :
  //     [];
  // };

  const showEmail = () => {
    return [
      {
        title: 'Current Email',
        Icon: EmailIcon,
        action: () => navigate({
          pathname: '.',
          search: createSearchParams({ modal: 'show-mail' }).toString(),
        }),
      }];
  };

  const settingsList = [
    {
      title: 'Account settings',
      settings: [
        // ... isEmailPasswordAuthentication(),
        // ... isGoogleAuthentication(),
        ...showEmail(),
      ],
    },
    {
      title: 'Preferences',
      settings: [
        {
          title: 'Push Notifications',
          Icon: EmailIcon,
          action: () => navigate('notifications'),
          option: { name: 'chevron' },
          onClick: () => { clickEvent(CLICK.TYPE.NAVIGATION, CLICK.CATEGORY.PUSH_NOTIFICATIONS, CLICK.LOCATION.SETTINGS); },
        },
      ],
    },
    {
      title: 'Other',
      settings: [
        {
          title: 'Privacy policy',
          Icon: PrivacyPolicyIcon,
          action: () => navigate('/privacy-policy'),
          disabled: false,
          onClick: () => { clickEvent(CLICK.TYPE.NAVIGATION, CLICK.CATEGORY.PRIVACY_POLICY, CLICK.LOCATION.SETTINGS); },
        },
        {
          title: 'About us',
          Icon: AboutIcon,
          action: () => window.open('https://hros.io/', '_blank'),
          disabled: false,
          onClick: () => { clickEvent(CLICK.TYPE.NAVIGATION, CLICK.CATEGORY.EXTERNAL, CLICK.LOCATION.SETTINGS); },
        },
        {
          title: 'Log out',
          Icon: SignOutIcon,
          action: () => navigate({
            pathname: '.',
            search: createSearchParams({ modal: 'sign-out' }).toString(),
          }),
          disabled: false,
          onClick: () => { clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.LOG_OUT, CLICK.LOCATION.SETTINGS); },
        },
        {
          title: 'Delete Account',
          Icon: TrashCanIcon,
          action: () => navigate('confirm-delete'),
          dangerous: true,
        },
      ],
    },
  ];

  const adminFunctions = [
    {
      title: 'ADMIN FUNCTIONS',
      settings: [
        {
          title: 'Add Community',
          Icon: UserIcon,
          action: () => navigate({
            pathname: '.',
            search: createSearchParams({ modal: 'add-community' }).toString(),
          }),
        },
        {
          title: 'Add Daily Task',
          Icon: UserIcon,
          action: () => navigate({
            pathname: '.',
            search: createSearchParams({ modal: 'add-daily-task' }).toString(),
          }),
        },
      ],
    }];

  return (
    <>
      <div className='padding-layout-horisontal'>
        {
          state.claims.some(role => ACCESS_ROLES.includes(role)) && <SettingsList settingsList={adminFunctions} />
        }
        <SettingsList settingsList={settingsList} />
      </div>
      <SettingsModals child={query.get('modal')} />
    </>
  );
};

export default GeneralSettings;
