import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.scss';

import { FirebaseContext } from '../../Firebase';
import useToasts from '../../../hooks/useToasts';

const ConfirmDelete = () => {
  const deleteUserURL = !process.env.REACT_APP_USE_EMULATORS ?
    new URL(`https://europe-west1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/deleteUser`) :
    new URL('http://localhost:5001/hero-career-coach-dev/europe-west1/deleteUser');
  const sendUserDeletedMessage = !process.env.REACT_APP_USE_EMULATORS ?
    new URL(`https://europe-west1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/sendUserDeletedMessage`) :
    new URL('http://localhost:5001/hero-career-coach-dev/europe-west1/sendUserDeletedMessage');
  const [state, setState] = useState({ confirmDelete: '' });
  const firebase = useContext(FirebaseContext);
  const { publishSuccess, publishError } = useToasts();
  const navigate = useNavigate();

  const deleteAccount = async () => {
    if (state.confirmDelete !== 'DELETE') {
      publishError('Confirmation text does not match');
      return;
    }

    const response = await fetch(deleteUserURL, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userID: firebase.auth.currentUser.uid,
        idToken: await firebase.auth.currentUser.getIdToken(),
      }),
    });

    if (response.status !== 204) {
      publishError('Failed to delete some parts of the Data.');
      return;
    }

    // Send user deleted to pubsub
    fetch(sendUserDeletedMessage, {
      method: 'POST',
      body: JSON.stringify({ firebaseId: firebase.auth.currentUser.uid }),
    });

    // FIXME: Success message isn't visible because of navigate
    publishSuccess('Deletion successful, you will be dearly missed');
    navigate('/logout');
  };

  return (
    <>
      <div className='padding-layout-horisontal'>
        <p className='content-margin-large'>
          Your account will be permanently deleted, your data will not be saved.
        </p>
        <p className='content-margin-large'>
          Type DELETE to confirm.
        </p>
        <input
          name='confirmDelete'
          type='text'
          value={state.confirmDelete}
          onChange={(e) => setState({
            ...state,
            [e.target.name]: e.target.value,
          })}
        />
      </div>
      <div className='bottom-container'>
        <button
          className='btn btn-primary'
          onClick={deleteAccount}
          disabled={state.confirmDelete !== 'DELETE'}
        >
          Delete Account
        </button>
      </div>
    </>
  );
};

export default ConfirmDelete;
