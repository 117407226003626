import React, { useState, useContext } from 'react';
import './styles.scss';

import { FirebaseContext } from '../../../../../components/Firebase';
import useToasts from '../../../../../hooks/useToasts';

import ModalBaseContainer from '../../../../../components/ModalBaseContainer';

/**
 * Renders a modal that allows users to report posts and comments.
 * @name ReportContentModal
 * @param {Object} post - post data
 * @param {Object} comment - comment data
 * @param {Boolean} show - show modal
 * @param {Function} onClose - close modal
 */
const ReportContentModal = ({ show, onClose, post }) => {
  const firebase = useContext(FirebaseContext);
  const { publishSuccess } = useToasts();
  const [reportReason, setReportReason] = useState('');
  const [reportDescription, setReportDescription] = useState('');

  const onChangeReasonHandler = (e) => {
    setReportReason(e.target.value);
  };

  const onChangeDescriptionHandler = (e) => {
    setReportDescription(e.target.value);
  };

  const onReportHandler = async (event) => {
    event.preventDefault();
    if(reportReason) {

      /* Reporting a post. */
      const reports = post.reports || [];
      reports.push({
        reason: reportReason,
        description: reportDescription,
        reportedBy: firebase.getCurrentUserRef(),
        createdAt: firebase.convertDate(new Date()),
      });
      await firebase.reportPost( post.id, post.parentID, reports );

      publishSuccess('Reported successfully');
      onClose();
    }
  };

  return (
    <ModalBaseContainer show={show} onClose={onClose}>
      <h2>Why are you reporting?</h2>
      <form onSubmit={onReportHandler}>
        <ul onChange={onChangeReasonHandler} className='content-margin-base'>
          <li className='report-radio-btn'>
            <input type='radio' name='report' value='False information'/>
            <span className='checked-icon report-radio-icon'></span>
            <span className='report-radio-label'>False information</span>
          </li>
          <li className='report-radio-btn'>
            <input type='radio' name='report' value='Harassment'/>
            <span className='checked-icon report-radio-icon'></span>
            <span className='report-radio-label'>Harassment</span>
          </li>
          <li className='report-radio-btn'>
            <input type='radio' name='report' value='Hate Speech'/>
            <span className='checked-icon report-radio-icon'></span>
            <span className='report-radio-label'>Hate Speech</span>
          </li>
          <li className='report-radio-btn'>
            <input type='radio' name='report' value='Spam'/>
            <span className='checked-icon report-radio-icon'></span>
            <span className='report-radio-label'>Spam</span>
          </li>
          <li className='report-radio-btn'>
            <input type='radio' name='report' value='Other'/>
            <span className='checked-icon report-radio-icon'></span>
            <span className='report-radio-label'>Other</span>
          </li>
        </ul>
        <p>Why are you reporting?</p>
        <textarea onChange={onChangeDescriptionHandler} className='content-margin-large' style={{ fontSize: '16px' }} id="happiness-text" name="happiness-text" rows="4" cols="50" placeholder='Your message here...'></textarea>
        <button type='submit' className='btn btn-primary'>Send</button>
      </form>
    </ModalBaseContainer >
  );
};

export default ReportContentModal;
