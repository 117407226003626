import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

import { useAnalytics } from '../../hooks/Firebase';
import Button from '../Button';
import JobEntry from './JobEntry';

/**
 * List of jobs ordered from last to first
 *
 * @name JobList
 * @memberof CareerShowcase
 * @protected
 *
 * @param {function} deleteJobHandler Func handling the behaviour when user clicks to delete entry
 * @param {array, <Object>} jobs Array with user objects
 * @param {function} editJobHandler Func handling the behaviour when user clicks jobs entry
 * @param {function} profileUID User ID
 */
const JobList = ({ jobs, editJobHandler, deleteJobHandler, profileUID }) => {
  const [sortedCareer, setSortedCareer] = useState();
  const [sortLoading, setSortLoading] = useState(true);
  const { clickEvent, TYPES: { CLICK } } = useAnalytics();

  useEffect(() => {
    const career = jobs.reduce(function (acc, payload) {
      acc[payload.company] = acc[payload.company] || [];
      acc[payload.company].push(payload);
      return acc;
    }, Object.create(null));

    setSortedCareer(career);
    setSortLoading(false);
  }, [jobs]);

  return (
    <>
      { !profileUID &&
        <Button theme={'btn-primary'}
          onClick={() => {
            clickEvent(CLICK.TYPE.ACTION, CLICK.CATEGORY.CAREER, CLICK.LOCATION.PROFILE_PAGE);
            editJobHandler();
          }}>{ !jobs.length ? 'Add a job' : 'Add another job'}</Button>
      }
      { !sortLoading &&
        <div className={profileUID !== undefined ? 'job-list grid' : 'job-list'}>
          {
            Object.values(sortedCareer).map((job, idx) =>
              <JobEntry
                key={`job-${idx}`}
                jobGroup={job}
                editJobHandler={editJobHandler}
                deleteJobHandler={deleteJobHandler}
                profileUID={profileUID}
              />
            )
          }
        </div>
      }
    </>
  );
};
JobEntry.propTypes = {
  jobs: PropTypes.array,
  editJobHandler: PropTypes.func,
  deleteJobHandler: PropTypes.func,
};

export default JobList;
