import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.scss';

import CommentItem from '../CommentItem';

import BackButton from '../../../../components/BackButton';
import CommentIcon from '../../../../statics/Images/CommentIcon';
import LikeIcon from '../../../../statics/Images/LikeIcon';
import DotsIcon from '../../../../statics/Images/DotsIcon.svg';
import OptionsMenu from '../OptionsMenu';
import defaultAvatar from '../../../../statics/Images/Illustrations/Toucans/AppIconToucan.svg';
import { LinkItUrl } from 'react-linkify-it';

import { useParams } from 'react-router-dom';
import { FirebaseContext } from '../../../../components/Firebase';
import { StorageContext } from '../../../../components/Storage';
import { useAnalytics } from '../../../../hooks/Firebase';
import OpenGraphPreview from '../../../../components/OpenGraphPreview';
import InputBar from '../../../../components/InputBar';
import { isEmptyObject } from '../../../../tools/IsEmptyObject';

const ICON_COLOR_GRAY = '#9c9c9c';
const ICON_COLOR_PRIMARY = '#eb4a5a';

const CommunityItemOverview = () => {
  const firebase = useContext(FirebaseContext);
  const { pageViewEvent, clickEvent, TYPES: { CLICK } } = useAnalytics();
  const navigate = useNavigate();
  const [appContext] = useContext(StorageContext);
  const inputRef = useRef();
  const { postId, community } = useParams();

  const [state, updateState] = useState({
    post: {},
    liked: false,
    comments: [],
    showOptionsMenu: false,
    profilePicture: null,
    paginatedPosts: [],
    loadingMorePosts: false,
  });

  const user = {
    userRef: firebase.getCurrentUserRef(),
    username: appContext.profile.username,
  };

  // Set page title and send page view event
  useEffect(() => {
    document.title = 'Community post | HROS Career Coach';
    pageViewEvent();
  }, []);

  useEffect(() => {
    if(state.post.likes){
      const liked = state.post.likes.find((like) => like.id === firebase.auth.currentUser.uid);
      updateState(s => ({
        ...s,
        liked,
      }));
    }
  }, [state.post]);

  const likeAction = async (post) => {
    post.likes.find((like) => like.id === firebase.auth.currentUser.uid) ?
      await firebase.removeLikeFromPost(post.id, user.userRef) :
      await firebase.addLikeToPost(post.id, user.userRef);
  };

  useEffect(async () => {
    const communityArray = [community];
    const unsubscribe = firebase.subscribePostListener(postId, onPostChange);
    const unsubscribeComment = firebase.subscribeCommunitiesListener(postId, communityArray, onCommentChange);
    return () =>{
      unsubscribe();
      unsubscribeComment();
    };
  }, []);

  /* Fetches the profile picture of the user who posted. */
  useEffect(async () => {
    let aborted = false;

    if (isEmptyObject(state.post)) {
      return;
    }
    // Profile pictures can be found under profile-pictures/${userID}.webp by convention
    const [folder, fileName] = ['profile-pictures', state.post.userRef.id];
    const url = await firebase.getImageUrl(folder, fileName);

    if (!aborted) {
      updateState(s => ({
        ...s,
        profilePicture: url,
      }));
    }

    return () => aborted = true;
  }, [state.post]);

  const onCommentChange = async (docs) => {
    const comments = [];
    docs.forEach( doc => {
      const datetime = doc.data().createdAt.toDate();
      const date = datetime.toISOString().slice(0, 10);
      const time = datetime.toTimeString().slice(0, 5);

      comments.push({
        id: doc.id,
        ...doc.data(),
        documentSnapshot: doc,
        date,
        time,
      });
    }
    );
    updateState(s => ({
      ...s,
      comments,
    }));
  };

  const onPostChange = async (doc) => {
    const data = doc.data();
    // Temporary redirect if post ID is not correct
    if (!data) {
      return navigate('/app/community');
    }
    // Redirect if post is flagged
    if (data.flags.REPORTED || data.flags.BLOCKED || data.flags.DELETED) {
      return navigate('/app/community');
    }

    const datetime = data.createdAt.toDate();

    const date = datetime.toISOString().slice(0, 10);
    const time = datetime.toTimeString().slice(0, 5);

    const post = {
      communityID: data.communityID,
      communityName: data.communityName,
      username: data.username,
      text: data.text,
      numberOfComments: data.numberOfComments,
      openGraph: data.openGraph,
      date,
      time,
      userRef: data.userRef,
      likes: data.likes,
      id: doc.id,
    };

    updateState( s => ({
      ...s,
      post,
    }));
  };

  const onAddComment = async (event) => {
    event.preventDefault();
    clickEvent(CLICK.TYPE.ADD, CLICK.CATEGORY.COMMUNITY_COMMENT, CLICK.LOCATION.INPUT_BAR);
    const text = inputRef.current.value?.trim();

    if(text){
      const comment = {
        ...user,
        parentID: state.post.id,
        communityID: state.post.communityID,
        communityName: state.post.communityName,
        text,
        createdAt: firebase.convertDate(new Date()),
        userRef: firebase.getCurrentUserRef(),
        likes: [],
        flags:       {
          BLOCKED: false,
          DELETED: false,
          REPORTED: false,
        },
      };
      await firebase.addPostToCommunity(comment, state.post.id);
      inputRef.current.value = '';
    }
  };

  const showOptionsMenuHandler = () => {
    updateState(s => ({
      ...s,
      showOptionsMenu: !s.showOptionsMenu,
    }));
  };

  const fetchMorePosts = async () => {
    updateState(s => ({
      ...s,
      loadingMorePosts: true,
    }));

    const lastCommentSnapshot = state.paginatedPosts.length ?
      state.paginatedPosts[state.paginatedPosts.length - 1].documentSnapshot
      :
      state.comments[state.comments.length - 1].documentSnapshot;

    const communityArray = [community];
    const newPost = await firebase.communityFetchMore(postId, communityArray, lastCommentSnapshot);

    if(!newPost.empty){
      await newPost.forEach(doc => {
        const datetime = doc.data().createdAt.toDate();
        const date = datetime.toISOString().slice(0, 10);
        const time = datetime.toTimeString().slice(0, 5);

        updateState(s => ({
          ...s,
          paginatedPosts: [
            ...s.paginatedPosts,
            {
              id: doc.id,
              ...doc.data(),
              documentSnapshot: doc,
              date,
              time,
            },
          ],
        }));
      });
    }

    updateState(s => ({
      ...s,
      loadingMorePosts: false,
    }));
  };

  return (
    <>
      <div className='community-item-overview'>
        <div className='community-item-header content-margin-base'>
          <div className='back-button padding-layout-horisontal'>
            <BackButton color='#303030'/>
          </div>
          <div className='community-avatar-group'>
            <div className='avatar-community-wrapper'>
              { state.profilePicture ?
                <img className='avatar-community' src={state.profilePicture} alt='' /> :
                <img className='avatar-community' src={defaultAvatar} alt='' />
              }
            </div>
            <div>
              <div className='user-name-community'>{state.post.username}</div>
              <div className='user-title-community'>Group: {state.post.communityName === 'RECOMMENDED' ? 'For You' : state.post.communityName}</div>
            </div>
          </div>
          <div className='options-menu-wrapper'>
            <img className='dots-icon' src={DotsIcon} width='16px' height='4px' onClick={() => showOptionsMenuHandler()} />
            { state.showOptionsMenu && <OptionsMenu post={state.post} /> }
          </div>
        </div>
        <LinkItUrl className='color-primary community-item-link'>
          <p className='content-margin-base community-item-content'>{ state.post?.text }</p>
        </LinkItUrl>
        {
          state.post.openGraph && <OpenGraphPreview openGraph={state.post.openGraph}/>
        }
        <div className='community-item-time content-margin-base'>{ state.post.time } | { state.post.date }</div>
        <div className='community-item-footer'>
          <div className='num-comments'>
            <CommentIcon color={ICON_COLOR_PRIMARY} />
            <span className='num-comments-primary'>{ state.post.numberOfComments }</span>
          </div>
          <div role='button' onClick={() => likeAction( state.post )} className='num-likes'>
            <LikeIcon color={state.liked ? ICON_COLOR_PRIMARY : ICON_COLOR_GRAY} />
            <span style={{ color: state.liked ? ICON_COLOR_PRIMARY : ICON_COLOR_GRAY }}>{ state.post.likes?.length || 0 }</span>
          </div>
        </div>

        <div className='community-item-comments'>
          { state.comments.map((comment, i) => (
            <CommentItem
              comment={comment}
              key={i}
              post={state.post}
              likeAction={likeAction}
              ICON_COLOR_GRAY={ICON_COLOR_GRAY}
              ICON_COLOR_PRIMARY={ICON_COLOR_PRIMARY}
              fetchMorePostsTrigger={i === state.comments.length - 2}
              fetchMorePosts={fetchMorePosts}
            />
          ))}
          {
            state.paginatedPosts &&
            state.paginatedPosts.map((comment, i) =>
              <CommentItem
                key={i}
                likeAction={likeAction}
                comment={comment}
                post={state.post}
                ICON_COLOR_GRAY={ICON_COLOR_GRAY}
                ICON_COLOR_PRIMARY={ICON_COLOR_PRIMARY}
                fetchMorePostsTrigger={i === state.paginatedPosts.length - 2}
                fetchMorePosts={fetchMorePosts}
              />
            )
          }
          {
          // TODO: Add a loading spinner
            state.loadingMorePosts && <div>Loading more posts...</div>
          }
        </div>
      </div>
      <InputBar
        ref={inputRef}
        placeholder='Type your comment...'
        onSubmit={onAddComment}
      />
    </>
  );
};

export default CommunityItemOverview;
