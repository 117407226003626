import React from 'react';
import PropTypes from 'prop-types';

import { Routes, Route } from 'react-router';

import PublicRedirect from '../PublicRedirect';
import LoginRoutes from '../LoginRoutes';
import Splash from '../../pages/Splash';
import SignUpForm from '../../pages/SignUp/SignUpForm';
import CatchAll from '../CatchAll';
import PrivacyPolicy from '../../pages/SignUp/PrivacyPolicy';
import UnsubscribeEmail from '../../pages/UnsubscribeEmail';
import PublicResult from '../../pages/PublicResult';
import AuthenticateWithCustomToken from '../../pages/AuthenticateWithCustomToken';

/**
 * Public Routes
 * @name PublicRoutes
 * @param {bool} signedIn Checks if user is logged in
 */
const PublicRoutes = ({ signedIn }) => {
  return (
    <Routes>

      <Route element={<PublicRedirect signedIn={signedIn} />}>
        <Route exact path='/' element={<Splash />} />
        <Route path='login/*' element={<LoginRoutes />} />
        <Route path='signup' element={<SignUpForm />} />
      </Route>

      <Route path='/unsubscribe-email/:uid' element={<UnsubscribeEmail />} />

      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      <Route path='p/:resultID' element={<PublicResult signedIn={signedIn} />} />

      <Route path='/:targetUrl/persistenttoken/:OneTimeUseToken' element={<AuthenticateWithCustomToken signedIn={signedIn} />} />

      <Route path='*' element={<CatchAll />} />

    </Routes>

  );
};
PublicRoutes.propTypes = { signedIn: PropTypes.bool.isRequired };

export default PublicRoutes;
